import React from 'react';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import Logo from 'src/static/logo2.svg';
import BackImg from 'src/static/home-background.png';
import AppLink from 'src/static/360-app/Asambo-360-1.55.apk';
import LogoTransp from 'src/static/logo-transparent.png';
import Background from 'src/static/sign-in-background.png';
import BackgroundMobile from 'src/static/dash-mobile-back-img.png';
import DashLogoMobile from 'src/static/dash-mobile-logo.png';
import DashMobileImage1 from 'src/static/dash-mobile-img-1.svg';
import DashMobileImage2 from 'src/static/dash-mobile-img-2.svg';
import DashMobileImage3 from 'src/static/dash-mobile-img-3.svg';
import DashMobileImage4 from 'src/static/dash-mobile-img-4.svg';
import DashMobileImage5 from 'src/static/dash-mobile-img-5.svg';
import DashMobileImage6 from 'src/static/dash-mobile-img-6.svg';
import DashMobileImage7 from 'src/static/dash-mobile-img-7.svg';
import DashMobileImage8 from 'src/static/dash-mobile-img-8.svg';
import ConsultSpecialistIconMobile from 'src/static/consult-specialist-icon.svg';
import BackToDashIconMobile from 'src/static/back-to-dash-icon.svg';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import Fade from 'react-reveal/Fade';
import {
  LockOutlined,
  UserOutlined,
  DownloadOutlined
} from '@ant-design/icons';
import SharpIcon from 'src/static/circle-logo.svg';
import { FaDashcube } from '@react-icons/all-files/fa/FaDashcube';
import { saveAs } from 'file-saver';
import {
  Checkbox,
  Select,
  Row,
  Col,
  Spin,
  Input,
  Button,
  Modal,
  Card,
  Divider
} from 'antd';
import { ButtonRadius } from 'src/components/elements/Button';
import Notify from 'src/components/meta/Notification';
import lock from 'src/static/icons/input-icons/lock.svg';
import user from 'src/static/icons/input-icons/user.svg';
import eye from 'src/static/icons/password-show.svg';
import MediaQuery from 'react-responsive';

const FormItem = Form.Item;
const Inputs = styled(Input)`
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.13);
  height: 3.7em;
  // padding-left: 2em;
  border-radius: 3em;
  border: none;
  //.ant-input:focus {
  //  border-bottom: 2px solid rgba(0, 136, 142, 0.2);
  //}
`;
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
class PassFormClass extends React.Component {
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('The passwords do not match!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };
  render() {
    return (
      <Form layout="vertical">
        <FormItem label={<span>Password</span>}>
          {this.props.form.getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Password required' },
              {
                validator: this.validateToNextPassword
              }
            ]
          })(<Input.Password />)}
        </FormItem>
        <FormItem label={<span>Confirm Password</span>}>
          {this.props.form.getFieldDecorator('confirmpassword', {
            rules: [
              { required: true, message: 'Dealer name required' },
              {
                validator: this.compareToFirstPassword
              }
            ]
          })(<Input.Password />)}
        </FormItem>
      </Form>
    );
  }
}
const PassForm = Form.create()(PassFormClass);
class SignIn extends React.Component {
  static defaultProps = {
    error: null,
    firstLogin: false
  };
  componentWillMount = () => {
    if (window.cordova) {
      document.addEventListener('backbutton', this.handleBack);
    }
  };
  exit = false;
  handleBack = () => {
    Notify('info', 'Press back again to close the app', '', 2);
    if (this.exit) {
      navigator.app.exitApp();
    }
    this.exit = true;
    setTimeout(() => {
      this.exit = false;
    }, 2000);
  };
  componentWillUnmount = () => {
    if (window.cordova) {
      document.removeEventListener('backbutton', this.handleBack);
    }
  };
  handleSubmit = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.type = 'Technician';
        this.signIn(values);
      }
    });
  };

  signIn = values => {
    this.props
      .signIn(values)
      .then(() => {
        this.setState({ loading: false });
        if (this.props.user.firstLogin) {
          this.setState({ firstLogin: true });
        } else if (this.props.user.type === 'admin') {
          this.props.push('/admin/dashboard');
        } else if (this.props.user.type === 'manager') {
          this.props.push('/manager/surveys');
        } else if (this.props.user.type === 'user') {
          this.props.push('/user/dashboard');
        } else if (this.props.user.type === 'buyer') {
          this.props.push('/buyer/dashboard');
        }
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify('error', 'Error', e);
      });
  };

  toggleShowPass = () => {
    const { state } = this;
    this.state.passShow === 'password'
      ? this.setState({ passShow: 'text' })
      : this.setState({ passShow: 'password' });
  };

  constructor(props) {
    super(props);
    this.state = {
      mainView: true,
      loading: false,
      passShow: 'password',
      showConsultView: false,
      showDisciplineView: false,
      showEquityView: false,
      showSkillsView: false,
      showLegalView: false,
      showPOPIView: false,
      showBEEView: false,
      showPayrollView: false,
      showTranscriptionView: false
    };
  }

  passForm;
  handleChangePassword = e => {
    e.preventDefault();
    this.passForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        this.props
          .changePassword(values)
          .then(() => {
            Notify('success', 'Successfully set password');
            this.setState({ loading: false, firstLogin: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify('error', e);
          });
      }
    });
  };
  handleDownloadFile = () => {
    // Assuming you have the file URL or blob available
    const fileUrl = { AppLink };

    // Use the saveAs function from file-saver to trigger the file download
    saveAs(fileUrl, 'Asambo-360-1.10.apk');
  };
  render() {
    const { loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { showConsultView } = this.state;
    const { showDisciplineView } = this.state;
    const { showEquityView } = this.state;
    const { showSkillsView } = this.state;
    const { showLegalView } = this.state;
    const { showPOPIView } = this.state;
    const { showBEEView } = this.state;
    const { showPayrollView } = this.state;
    const { showTranscriptionView } = this.state;

    if (showConsultView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                +27 (18) 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                admin@asambo.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.asambo.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showConsultView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showDisciplineView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              // maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24} style={{ maxWidth: '27.3em', margin: "auto" }}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Sign In
              </Divider>
            </Col>
            <Col span={24}>
              <Form onSubmit={this.handleSubmit}>
                <Card
                  style={{
                    margin: 'auto',
                    // height: '70vh',
                    textAlign: 'center',
                    //boxShadow: "-1px 11px 13px -4px rgba(0,0,0,0.33)",
                    border: 'none',
                    // width: '100vw',
                    backgroundColor: 'transparent'
                  }}
                >

                  <div
                    style={{
                      maxWidth: '35em',
                      // minWidth: "22em",
                      margin: 'auto',
                      // marginTop: '30px'
                    }}
                  >
                    <FormItem>
                      {getFieldDecorator('username', {
                        rules: [
                          {
                            required: true,
                            message: 'Username required'
                          }
                        ]
                      })(
                        <Inputs
                          placeholder="Username"
                          prefix={
                            <UserOutlined
                              style={{
                                paddingRight: '0.5em',
                                color: 'lightgrey'
                              }}
                            />
                          }
                          style={{
                            width: '100%',
                            border: 0,
                            borderRadius: 50
                          }}
                        />
                      )}
                    </FormItem>
                    <FormItem style={{ marginTop: '0em' }}>
                      {getFieldDecorator('password', {
                        rules: [
                          {
                            required: true,
                            message: 'Password required'
                          }
                        ]
                      })(
                        <Inputs.Password
                          placeholder="Password"
                          prefix={
                            <LockOutlined
                              style={{
                                paddingRight: '0.5em',
                                color: 'lightgrey'
                              }}
                            />
                          }
                          type="password"
                          style={{
                            boxShadow:
                              '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                            height: '3.7em',
                            //paddingLeft: '2em',
                            borderRadius: '3em',
                            border: 'none'
                          }}
                        />
                      )}
                    </FormItem>
                    {/* <FormItem

                  //label={
                  //  <span style={{ color: "grey", fontSize: 15 }}>
                  //    Password
                  //  </span>
                  //}
                  >
                    {getFieldDecorator('password', {
                      rules: [{ message: 'Please read & accept Terms' }]
                    })(
                      <Checkbox
                        onChange={onChange}
                        style={{ fontFamily: 'Open Sans' }}
                      >
                        I agree the
                        <span style={{ fontWeight: 900 }}>
                          {' '}
                          Terms and Conditions
                        </span>
                      </Checkbox>
                    )}
                  </FormItem> */}
                    <FormItem>
                      <ButtonRadius
                        style={{
                          marginTop: '0.5em',
                          height: 55,
                          width: '100%',
                          fontSize: 16,
                          border: 'none',
                          fontFamily: 'Roboto Condensed',
                          textShadow: 'none',
                          background:
                            'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                        }}
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                      >
                        Sign In
                      </ButtonRadius>
                    </FormItem>
                  </div>
                </Card>
              </Form>
            </Col>

          </Row>
          <Button
            onClick={() => this.setState({ showDisciplineView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
          <Modal
            title="Set Password"
            visible={this.state.firstLogin}
            onOk={this.handleChangePassword}
            onCancel={e => this.setState({ firstLogin: false })}
            okText="Save"
            cancelText="Cancel"
          >
            <PassForm wrappedComponentRef={form => (this.passForm = form)} />
          </Modal>
        </div>
      );
    }

    if (showEquityView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                018 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                hr@asambo.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.asambo.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showEquityView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showSkillsView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                018 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                hr2@asambo.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.asambo.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showSkillsView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showLegalView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                011 609 2630
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={13}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '98%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em',
                  // paddingLeft: '0.5em'
                }}
              >
                gert@setonsmithlaw.co.za
              </Button>
            </Col>
            <Col span={11}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '98%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em',
                  // paddingLeft: '0.6em'
                }}
              >
                setonsmithlaw.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showLegalView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showPOPIView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                018 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                admin7@asambo.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.asambo.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showPOPIView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showBEEView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                018 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                elaine@asambo.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.asambo.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showBEEView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showPayrollView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                018 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em',
                  paddingLeft: '0.9em'
                }}
              >
                laurenp@yourside.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.asambo.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showPayrollView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    if (showTranscriptionView) {
      return (
        <div style={{ minHeight: '50em', backgroundColor: 'white' }}>
          <Row
            style={{
              margin: 'auto',
              backgroundImage: `url(${BackgroundMobile})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              textAlign: 'center',
              boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
              // marginTop: '0.8em',
              height: '17em',
              alignContent: 'space-evenly',
              marginBottom: 0
            }}
          >
            <Col span={24}>
              <Fade>
                <img
                  src={DashLogoMobile}
                  style={{
                    width: '60%'
                  }}
                />
              </Fade>
              <Fade top>
                <p
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 20,
                    fontWeight: 400,
                    color: 'white',
                    lineHeight: '1.2em'
                  }}
                >
                  Your Trusted Partner in day-to-day <br />
                  Labour Relations
                </p>
              </Fade>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '1.5em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-left"
                orientation="left"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 18,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '1em'
                }}
              >
                Get in touch
              </Divider>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  paddingTop: '0.5em',
                  marginBottom: '0em'
                }}
              >
                Talk to a Specialist:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  width: '100%',
                  height: '2.5em',
                  fontSize: 18,
                  marginTop: '0.5em'
                }}
              >
                018 297 7285
              </Button>
            </Col>
          </Row>
          <Row
            style={{
              paddingLeft: '1em',
              paddingRight: '1em',
              maxWidth: '27.3em',
              margin: 'auto',
              marginTop: '2em',
              textAlign: 'center'
            }}
          >
            <Col span={24}>
              <Divider
                className="custom-divider-right"
                orientation="right"
                orientationMargin={0}
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 15,
                  borderColor: '#C0BEBE',
                  width: 0,
                  marginTop: '0.8em'
                }}
              >
                {/* How Can We Assist You? */}
              </Divider>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Email Us:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                admin@cloudts.co.za
              </Button>
            </Col>
            <Col span={12}>
              <p
                style={{
                  fontFamily: 'Roboto Condensed',
                  fontSize: 22,
                  fontWeight: 400,
                  color: 'black',
                  lineHeight: '1.2em',
                  marginBottom: '0em'
                }}
              >
                Website:
              </p>
              <Button
                style={{
                  background:
                    'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                  color: 'white',
                  borderRadius: '2em',
                  fontFamily: 'Roboto Condensed',
                  border: 'none',
                  // width: '95%',
                  height: '3em',
                  fontSize: 14,
                  marginTop: '0.5em'
                }}
              >
                www.cloudts.co.za
              </Button>
            </Col>
          </Row>
          <Button
            onClick={() => this.setState({ showTranscriptionView: false })}
            style={{
              background:
                'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',

              color: 'white',
              // borderRadius: '0 0 2em 2em',
              fontFamily: 'Roboto Condensed',
              border: 'none',
              width: '100%',
              height: '3em',
              fontSize: 20,
              position: 'absolute',
              bottom: 0
            }}
          >
            <img
              src={BackToDashIconMobile}
              style={{
                width: '7%',
                marginTop: '-0.2em',
                marginRight: '0.5em'
              }}
            />
            Back to Dashboard
          </Button>
        </div>
      );
    }

    return (
      <>
        <MediaQuery minWidth={768}>
          <div
            style={{
              backgroundColor: '#0C3446',
              height: '50em'
            }}
          >
            <Row>
              <Col
                xs={24}
                md={24}
                style={{
                  minHeight: '40em',
                  backgroundColor: '#0C3446',
                  width: '100vw'
                }}
              >
                <Spin spinning={this.state.loading}>
                  <img
                    src={BackImg}
                    style={{
                      position: 'absolute',
                      width: '100vw',
                      height: '100vh',
                      top: 0,
                      left: 0
                    }}
                  />
                  {this.state.mainView === true ? (
                    <Row style={{ maxWidth: '40em', margin: 'auto' }}>
                      <Col
                        span={24}
                        style={{
                          margin: 'auto',
                          textAlign: 'center',
                          marginTop: '30vh'
                        }}
                      >
                        <p
                          style={{
                            fontSize: '27px',
                            fontWeight: 700,
                            color: 'white',
                            marginBottom: '-0em'
                          }}
                        >
                          Welcome to Asambo 360°
                        </p>
                        <p
                          style={{
                            fontSize: '16px',
                            fontWeight: 400,
                            color: 'white',
                            marginBottom: '1.5em'
                          }}
                        >
                          Choose Your Destination
                        </p>
                      </Col>
                      <Col span={12} style={{ margin: 'auto' }}>
                        <Card
                          onClick={e => this.setState({ mainView: false })}
                          style={{
                            boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.10)',
                            borderRadius: '0.7em',
                            border: 'none',
                            padding: '0em',
                            whiteSpace: 'nowrap',
                            fontFamily: 'Poppins',
                            padding: '0.6em',
                            minWidth: '11em',
                            width: '90%',
                            margin: 'auto',
                            overflow: 'hidden',
                            backgroundColor: '#0B2734'
                          }}
                        >
                          <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                              <span
                                style={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  color: 'white'
                                  //marginLeft: -10
                                }}
                              >
                                {' '}
                                <img src={Logo} />
                                <p
                                  style={{
                                    marginTop: 10
                                  }}
                                >
                                  Sign In
                                </p>
                              </span>{' '}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col span={12} style={{ margin: 'auto' }}>
                        <Card
                          // onClick={e => this.setState({ mainView: false })}
                          style={{
                            boxShadow: '0px 6px 10px 0px rgba(0, 0, 0, 0.10)',
                            borderRadius: '0.7em',
                            border: 'none',
                            padding: '0em',
                            whiteSpace: 'nowrap',
                            fontFamily: 'Poppins',
                            padding: '0em',
                            minWidth: '11em',
                            width: '90%',
                            margin: 'auto',
                            overflow: 'hidden',
                            backgroundColor: '#0B2734'
                          }}
                        >
                          <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                              <a href={AppLink} download>
                                <span
                                  style={{
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: 'white'
                                    //marginLeft: -10
                                  }}
                                >
                                  {' '}
                                  <DownloadOutlined
                                    style={{
                                      fontSize: '70px',
                                      color: 'white'
                                      // marginTop: props.collapsed ? "0.5em" : 0,
                                    }}
                                  />
                                  <p
                                    style={{
                                      marginTop: 10
                                    }}
                                  >
                                    360° Application
                                  </p>
                                </span>{' '}
                              </a>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  ) : (
                    <Form onSubmit={this.handleSubmit}>
                      <Card
                        style={{
                          margin: 'auto',
                          height: '70vh',
                          textAlign: 'center',
                          //boxShadow: "-1px 11px 13px -4px rgba(0,0,0,0.33)",
                          border: 'none',
                          width: '100vw',
                          backgroundColor: 'transparent'
                        }}
                      >
                        <img
                          src={Logo}
                          style={{
                            zIndex: 99,
                            marginTop: '6vw',
                            width: '12em',
                            fontSize: '22px'
                          }}
                        />
                        <h1
                          style={{
                            lineHeight: '5px',
                            fontSize: '50px',
                            color: 'white',
                            textAlign: 'center',
                            marginTop: '0.8em'
                          }}
                        >
                          {' '}
                          <p
                            style={{
                              fontSize: '27px',
                              fontWeight: 700,
                              color: 'white',
                              marginBottom: '-0.5em'
                            }}
                          >
                            Sign In to Asambo 360°
                          </p>
                          <p
                            style={{
                              fontFamily: 'Open Sans',
                              marginTop: '50px',
                              fontWeight: 400,
                              color: 'white',
                              fontSize: '16px',
                              fontWeight: '500'
                            }}
                          >
                            Sign into your account
                          </p>
                        </h1>
                        <div
                          style={{
                            maxWidth: '35em',
                            // minWidth: "22em",
                            margin: 'auto',
                            marginTop: '30px'
                          }}
                        >
                          <FormItem>
                            {getFieldDecorator('username', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Username required'
                                }
                              ]
                            })(
                              <Inputs
                                placeholder="Username"
                                prefix={
                                  <UserOutlined
                                    style={{
                                      paddingRight: '0.5em',
                                      color: 'lightgrey'
                                    }}
                                  />
                                }
                                style={{
                                  width: '100%',
                                  border: 0,
                                  borderRadius: 50
                                }}
                              />
                            )}
                          </FormItem>
                          <FormItem style={{ marginTop: '0em' }}>
                            {getFieldDecorator('password', {
                              rules: [
                                {
                                  required: true,
                                  message: 'Password required'
                                }
                              ]
                            })(
                              <Inputs.Password
                                placeholder="Password"
                                prefix={
                                  <LockOutlined
                                    style={{
                                      paddingRight: '0.5em',
                                      color: 'lightgrey'
                                    }}
                                  />
                                }
                                type="password"
                                style={{
                                  boxShadow:
                                    '0px 8px 15px 0px rgba(0, 0, 0, 0.13)',
                                  height: '3.7em',
                                  //paddingLeft: '2em',
                                  borderRadius: '3em',
                                  border: 'none'
                                }}
                              />
                            )}
                          </FormItem>
                          {/* <FormItem

                  //label={
                  //  <span style={{ color: "grey", fontSize: 15 }}>
                  //    Password
                  //  </span>
                  //}
                  >
                    {getFieldDecorator('password', {
                      rules: [{ message: 'Please read & accept Terms' }]
                    })(
                      <Checkbox
                        onChange={onChange}
                        style={{ fontFamily: 'Open Sans' }}
                      >
                        I agree the
                        <span style={{ fontWeight: 900 }}>
                          {' '}
                          Terms and Conditions
                        </span>
                      </Checkbox>
                    )}
                  </FormItem> */}
                          <FormItem>
                            <ButtonRadius
                              style={{
                                marginTop: '1em',
                                height: 55,
                                width: '100%',
                                fontSize: 14,
                                border: 'none',
                                fontFamily: 'Roboto Condensed',
                                textShadow: 'none',
                                background:
                                  'linear-gradient(0deg, rgba(1,44,70,1) 0%, rgba(0,112,168,1) 100%)',
                                boxShadow:
                                  '0px 6px 10px 0px rgba(0, 0, 0, 0.10)'
                              }}
                              type="primary"
                              htmlType="submit"
                              size="large"
                              block
                            >
                              Sign In
                            </ButtonRadius>
                          </FormItem>
                        </div>
                      </Card>
                    </Form>
                  )}
                </Spin>
              </Col>
              <span
                style={{
                  position: 'absolute',
                  bottom: '0.5em',
                  right: '1em',
                  color: 'lightgrey',
                  fontSize: 12,
                  textDecoration: 'underline'
                }}
              >
                {'v' + process.env.REACT_APP_VERSION_CNTRL}
              </span>
            </Row>
            <Modal
              title="Set Password"
              visible={this.state.firstLogin}
              onOk={this.handleChangePassword}
              onCancel={e => this.setState({ firstLogin: false })}
              okText="Save"
              cancelText="Cancel"
            >
              <PassForm wrappedComponentRef={form => (this.passForm = form)} />
            </Modal>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div
            style={{
              minHeight: '50em',
              backgroundColor: 'white'
            }}
          >
            <Row
              style={{
                margin: 'auto',
                backgroundImage: `url(${BackgroundMobile})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: 'center',
                boxShadow: '0px 9px 14px 0px rgba(0, 0, 0, 0.24)',
                // marginTop: '0.8em',
                height: '17em',
                alignContent: 'space-evenly',
                marginBottom: 0
              }}
            >

              <Col span={24}>
                <span
                  style={{
                    position: "absolute",
                    top: "0em",
                    right: "1em",
                    color: 'lightgrey',
                    fontSize: 12,
                    textDecoration: "underline",
                    zIndex: 99
                  }}

                >
                  {"v" + process.env.REACT_APP_VERSION_CNTRL}
                </span>
                <Fade>
                  <img
                    src={DashLogoMobile}
                    style={{
                      width: '60%'
                    }}
                  />
                </Fade>
                <Fade top>
                  <p
                    style={{
                      fontFamily: 'Roboto Condensed',
                      fontSize: 22,
                      fontWeight: 400,
                      color: 'white',
                      lineHeight: '1.2em'
                    }}
                  >
                    Your Trusted Partner in day-to-day <br />
                    Labour Relations
                  </p>
                </Fade>
              </Col>
            </Row>
            <Row
              style={{
                paddingLeft: '1em',
                paddingRight: '1em',
                maxWidth: '27.3em',
                margin: 'auto',
                marginTop: '1.5em'
              }}
            >
              <Col span={24}>
                <Divider
                  className="custom-divider-left"
                  orientation="left"
                  orientationMargin={0}
                  style={{
                    fontFamily: 'Roboto Condensed',
                    fontSize: 18,
                    borderColor: '#C0BEBE',
                    width: 0,
                    marginTop: '1em'
                  }}
                >
                  How Can We Assist You?
                </Divider>
              </Col>
            </Row>
            <Row
              style={{
                paddingLeft: '1em',
                paddingRight: '1em',
                marginTop: '0.5em'
              }}
            >
              <Col
                onClick={() => this.setState({ showDisciplineView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={200}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.15em'
                    }}
                  >
                    <img
                      src={DashMobileImage1}
                      style={{
                        width: '70%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    Discipline
                  </p>

                </Fade>
              </Col>
              <Col
                onClick={() => this.setState({ showEquityView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={300}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1em'
                    }}
                  >
                    <img
                      src={DashMobileImage2}
                      style={{
                        width: '100%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em',
                      lineHeight: '1.4em'
                    }}
                  >
                    Employment Equity
                  </p>
                </Fade>
              </Col>
              <Col
                onClick={() => this.setState({ showSkillsView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={400}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.2em'
                    }}
                  >
                    <img
                      src={DashMobileImage3}
                      style={{
                        width: '80%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    Skills Development
                  </p>
                </Fade>
              </Col>
              <Col
                onClick={() => this.setState({ showLegalView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={500}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.15em'
                    }}
                  >
                    <img
                      src={DashMobileImage4}
                      style={{
                        width: '90%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    Legal Services
                  </p>
                </Fade>
              </Col>
            </Row>
            <Fade left delay={800}>
              <Row
                style={{
                  paddingLeft: '1em',
                  paddingRight: '1em',
                  maxWidth: '27.3em',
                  margin: 'auto'
                }}
              >
                <Col span={24}>
                  <Divider
                    className="custom-divider-right"
                    orientation="right"
                    orientationMargin={0}
                    style={{
                      fontFamily: 'Roboto Condensed',
                      fontSize: 15,
                      borderColor: '#C0BEBE',
                      width: 0,
                      marginTop: '0.8em'
                    }}
                  >
                    {/* How Can We Assist You? */}
                  </Divider>
                </Col>
              </Row>
            </Fade>
            <Row
              style={{
                paddingLeft: '1em',
                paddingRight: '1em'
              }}
            >
              <Col
                onClick={() => this.setState({ showPOPIView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={600}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.2em'
                    }}
                  >
                    <img
                      src={DashMobileImage5}
                      style={{
                        width: '85%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    POPI Compliance
                  </p>
                </Fade>
              </Col>
              <Col
                onClick={() => this.setState({ showBEEView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={700}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.15em'
                    }}
                  >
                    <img
                      src={DashMobileImage6}
                      style={{
                        width: '70%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    B-BBEE Compliance
                  </p>
                </Fade>
              </Col>
              <Col
                onClick={() => this.setState({ showPayrollView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={800}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.25em'
                    }}
                  >
                    <img
                      src={DashMobileImage7}
                      style={{
                        width: '70%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    Payroll
                  </p>
                </Fade>
              </Col>
              <Col
                onClick={() => this.setState({ showTranscriptionView: true })}
                span={6}
                style={{
                  textAlign: 'center'
                }}
              >
                <Fade top delay={900}>
                  <div
                    style={{
                      border: '1px solid #ABABAB',
                      padding: '1em',
                      margin: 'auto',
                      textAlign: 'center',
                      borderRadius: '1em',
                      maxWidth: '4.6em',
                      height: '4.4em',
                      paddingTop: '1.2em'
                    }}
                  >
                    <img
                      src={DashMobileImage8}
                      style={{
                        width: '82%',
                        margin: 'auto'
                      }}
                    />
                  </div>
                  <p
                    style={{
                      fontFamily: 'Roboto Flex',
                      fontSize: 13,
                      fontWeight: 500,
                      color: 'black',
                      paddingTop: '0.7em',
                      lineHeight: '1.4em'
                    }}
                  >
                    Transcription Services
                  </p>
                </Fade>
              </Col>
            </Row>

            <Button
              onClick={() => this.setState({ showConsultView: true })}
              style={{
                background:
                  'linear-gradient(0deg, rgba(5,67,104,1) 0%, rgba(1,44,70,1) 100%',
                color: 'white',
                // borderRadius: '0 0 2em 2em',
                fontFamily: 'Roboto Condensed',
                border: 'none',
                width: '100%',
                height: '3em',
                fontSize: 20,
                position: 'absolute',
                bottom: 0
              }}
            >
              <img
                src={ConsultSpecialistIconMobile}
                style={{
                  width: '15%'
                }}
              />{' '}
              Consult a Specialist
            </Button>
          </div>

        </MediaQuery>
      </>
    );
  }
}

const WrappedSignIn = Form.create()(SignIn);

export default WrappedSignIn;

//for application build

// import React from "react";
// import styled from "styled-components";
// import SVG from "react-inlinesvg";
// import Logo from "src/static/logo2.svg";
// import BackImg from "src/static/home-background.png";
// // import AppLink from "src/static/360-app/Asambo-360-1.10.apk";
// import LogoTransp from "src/static/logo-transparent.png";
// import Background from "src/static/sign-in-background.png";
// import { Form } from "@ant-design/compatible";
// import "@ant-design/compatible/assets/index.css";
// import { LockOutlined, UserOutlined, DownloadOutlined } from "@ant-design/icons";
// import SharpIcon from "src/static/circle-logo.svg";
// import { FaDashcube } from "@react-icons/all-files/fa/FaDashcube";
// import { saveAs } from 'file-saver';
// import {
//   Checkbox,
//   Select,
//   Row,
//   Col,
//   Spin,
//   Input,
//   Button,
//   Modal,
//   Card,
// } from "antd";
// import { ButtonRadius } from "src/components/elements/Button";
// import Notify from "src/components/meta/Notification";
// import lock from "src/static/icons/input-icons/lock.svg";
// import user from "src/static/icons/input-icons/user.svg";
// import eye from "src/static/icons/password-show.svg";

// const FormItem = Form.Item;
// const Inputs = styled(Input)`
//   box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.13);
//   height: 3.7em;
//   padding-left: 2em;
//   border-radius: 3em;
//   border: none;
//   //.ant-input:focus {
//   //  border-bottom: 2px solid rgba(0, 136, 142, 0.2);
//   //}
// `;
// function onChange(e) {
//   console.log(`checked = ${e.target.checked}`);
// }
// class PassFormClass extends React.Component {
//   compareToFirstPassword = (rule, value, callback) => {
//     const { form } = this.props;
//     if (value && value !== form.getFieldValue("password")) {
//       callback("The passwords do not match!");
//     } else {
//       callback();
//     }
//   };

//   validateToNextPassword = (rule, value, callback) => {
//     const { form } = this.props;
//     if (value) {
//       form.validateFields(["confirm"], { force: true });
//     }
//     callback();
//   };
//   render() {
//     return (
//       <Form layout="vertical">
//         <FormItem label={<span>Password</span>}>
//           {this.props.form.getFieldDecorator("password", {
//             rules: [
//               { required: true, message: "Password required" },
//               {
//                 validator: this.validateToNextPassword,
//               },
//             ],
//           })(<Input.Password />)}
//         </FormItem>
//         <FormItem label={<span>Confirm Password</span>}>
//           {this.props.form.getFieldDecorator("confirmpassword", {
//             rules: [
//               { required: true, message: "Dealer name required" },
//               {
//                 validator: this.compareToFirstPassword,
//               },
//             ],
//           })(<Input.Password />)}
//         </FormItem>
//       </Form>
//     );
//   }
// }
// const PassForm = Form.create()(PassFormClass);
// class SignIn extends React.Component {
//   static defaultProps = {
//     error: null,
//     firstLogin: false,
//   };
//   componentWillMount = () => {
//     if (window.cordova) {
//       document.addEventListener("backbutton", this.handleBack);
//     }
//   };
//   exit = false;
//   handleBack = () => {
//     Notify("info", "Press back again to close the app", "", 2);
//     if (this.exit) {
//       navigator.app.exitApp();
//     }
//     this.exit = true;
//     setTimeout(() => {
//       this.exit = false;
//     }, 2000);
//   };
//   componentWillUnmount = () => {
//     if (window.cordova) {
//       document.removeEventListener("backbutton", this.handleBack);
//     }
//   };
//   handleSubmit = event => {
//     event.preventDefault();

//     this.props.form.validateFieldsAndScroll((err, values) => {
//       if (!err) {
//         values.type = "Technician";
//         this.signIn(values);
//       }
//     });
//   };

//   signIn = values => {
//     this.props
//       .signIn(values)
//       .then(() => {
//         this.setState({ loading: false });
//         if (this.props.user.firstLogin) {
//           this.setState({ firstLogin: true });
//         } else if (this.props.user.type === "admin") {
//           this.props.push("/admin/dashboard");
//         } else if (this.props.user.type === "manager") {
//           this.props.push("/manager/surveys");
//         } else if (this.props.user.type === "user") {
//           this.props.push("/user/dashboard");
//         } else if (this.props.user.type === "buyer") {
//           this.props.push("/buyer/dashboard");
//         }
//       })
//       .catch(e => {
//         this.setState({ loading: false });
//         Notify("error", "Error", e);
//       });
//   };

//   toggleShowPass = () => {
//     const { state } = this;
//     this.state.passShow === "password"
//       ? this.setState({ passShow: "text" })
//       : this.setState({ passShow: "password" });
//   };

//   constructor(props) {
//     super(props);
//     this.state = {
//       mainView: true,
//       loading: false,
//       passShow: "password",
//     };
//   }

//   passForm;
//   handleChangePassword = e => {
//     e.preventDefault();
//     this.passForm.props.form.validateFieldsAndScroll((err, values) => {
//       if (!err) {
//         this.setState({ loading: true });
//         this.props
//           .changePassword(values)
//           .then(() => {
//             Notify("success", "Successfully set password");
//             this.setState({ loading: false, firstLogin: false });
//           })
//           .catch(e => {
//             this.setState({ loading: false });
//             Notify("error", e);
//           });
//       }
//     });
//   };
//   // handleDownloadFile = () => {
//   //   // Assuming you have the file URL or blob available
//   //   const fileUrl = { AppLink };

//   //   // Use the saveAs function from file-saver to trigger the file download
//   //   saveAs(fileUrl, 'Asambo-360-1.10.apk');
//   // };
//   render() {
//     const { loading } = this.props;
//     const { getFieldDecorator } = this.props.form;

//     return (
//       <div
//         style={{
//           backgroundColor: "#0C3446",
//           height: "100vh",
//         }}
//       >
//         <Row>
//           <Col
//             xs={24}
//             md={24}
//             style={{
//               minHeight: "40em",
//               backgroundColor: "#0C3446",
//               width: "100vw",
//             }}
//           >
//             <Spin spinning={this.state.loading}>
//               <img
//                 src={BackImg}
//                 style={{
//                   position: "absolute",
//                   width: "100vw",
//                   height: "100vh",
//                   top: 0,
//                   left: 0,
//                 }}
//               />
//               {this.state.mainView === true ? (
//                 <Row style={{ maxWidth: "40em", margin: "auto" }}>
//                   <Col
//                     span={24}
//                     style={{
//                       margin: "auto",
//                       textAlign: "center",
//                       marginTop: "30vh",
//                     }}
//                   >
//                     <p
//                       style={{
//                         fontSize: "27px",
//                         fontWeight: 700,
//                         color: "white",
//                         marginBottom: "-0em",
//                       }}
//                     >
//                       Welcome to Asambo 360°
//                     </p>
//                     <p
//                       style={{
//                         fontSize: "16px",
//                         fontWeight: 400,
//                         color: "white",
//                         marginBottom: "1.5em",
//                       }}
//                     >
//                       Choose Your Destination
//                     </p>
//                   </Col>
//                   <Col span={24} style={{ margin: "auto" }}>
//                     <Card
//                       onClick={e => this.setState({ mainView: false })}
//                       style={{
//                         boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
//                         borderRadius: "0.7em",
//                         border: "none",
//                         padding: "0em",
//                         whiteSpace: "nowrap",
//                         fontFamily: "Poppins",
//                         padding: "0.6em",
//                         minWidth: "11em",
//                         width: "90%",
//                         margin: "auto",
//                         overflow: "hidden",
//                         backgroundColor: "#0B2734",
//                       }}
//                     >
//                       <Row>
//                         <Col span={24} style={{ textAlign: "center" }}>
//                           <span
//                             style={{
//                               fontWeight: 500,
//                               fontSize: 14,
//                               color: "white",
//                               //marginLeft: -10
//                             }}
//                           >
//                             {" "}
//                             <img src={Logo} />
//                             <p
//                               style={{
//                                 marginTop: 10,
//                               }}
//                             >
//                               Sign In
//                             </p>
//                           </span>{" "}
//                         </Col>
//                       </Row>
//                     </Card>
//                   </Col>
//                   {/* <Col span={12} style={{ margin: "auto" }}>
//                     <Card
//                       // onClick={e => this.setState({ mainView: false })}
//                       style={{
//                         boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
//                         borderRadius: "0.7em",
//                         border: "none",
//                         padding: "0em",
//                         whiteSpace: "nowrap",
//                         fontFamily: "Poppins",
//                         padding: "0em",
//                         minWidth: "11em",
//                         width: "90%",
//                         margin: "auto",
//                         overflow: "hidden",
//                         backgroundColor: "#0B2734",
//                       }}
//                     >
//                       <Row>

//                         <Col span={24} style={{ textAlign: "center" }}>
//                           <a href={AppLink} download>
//                             <span
//                               style={{
//                                 fontWeight: 500,
//                                 fontSize: 14,
//                                 color: "white",
//                                 //marginLeft: -10
//                               }}
//                             >
//                               {" "}
//                               <DownloadOutlined
//                                 style={{
//                                   fontSize: "70px",
//                                   color: "white",
//                                   // marginTop: props.collapsed ? "0.5em" : 0,
//                                 }}
//                               />
//                               <p
//                                 style={{
//                                   marginTop: 10,
//                                 }}
//                               >
//                                 360° Application
//                               </p>
//                             </span>{" "}
//                           </a>
//                         </Col>

//                       </Row>
//                     </Card>
//                   </Col> */}
//                 </Row>
//               ) : (
//                 <Form onSubmit={this.handleSubmit}>
//                   <Card
//                     style={{
//                       margin: "auto",
//                       height: "70vh",
//                       textAlign: "center",
//                       //boxShadow: "-1px 11px 13px -4px rgba(0,0,0,0.33)",
//                       border: "none",
//                       width: "100vw",
//                       backgroundColor: "transparent",
//                     }}
//                   >
//                     <img
//                       src={Logo}
//                       style={{
//                         zIndex: 99,
//                         marginTop: "6vw",
//                         width: "12em",
//                         fontSize: "22px",
//                       }}
//                     />
//                     <h1
//                       style={{
//                         lineHeight: "5px",
//                         fontSize: "50px",
//                         color: "white",
//                         textAlign: "center",
//                         marginTop: "0.8em",
//                       }}
//                     >
//                       {" "}
//                       <p
//                         style={{
//                           fontSize: "27px",
//                           fontWeight: 700,
//                           color: "white",
//                           marginBottom: "-0.5em",
//                         }}
//                       >
//                         Sign In to Asambo 360°
//                       </p>
//                       <p
//                         style={{
//                           fontFamily: "Open Sans",
//                           marginTop: "50px",
//                           fontWeight: 400,
//                           color: "white",
//                           fontSize: "16px",
//                           fontWeight: "500",
//                         }}
//                       >
//                         Sign into your account
//                       </p>
//                     </h1>
//                     <div
//                       style={{
//                         maxWidth: "35em",
//                         // minWidth: "22em",
//                         margin: "auto",
//                         marginTop: "30px",
//                       }}
//                     >
//                       <FormItem>
//                         {getFieldDecorator("username", {
//                           rules: [
//                             {
//                               required: true,
//                               message: "Username required",
//                             },
//                           ],
//                         })(
//                           <Inputs
//                             placeholder="Username"
//                             prefix={
//                               <UserOutlined
//                                 style={{
//                                   paddingRight: "0.5em",
//                                   color: "lightgrey",
//                                 }}
//                               />
//                             }
//                             style={{
//                               width: "100%",
//                               border: 0,
//                               borderRadius: 50,
//                             }}
//                           />
//                         )}
//                       </FormItem>
//                       <FormItem style={{ marginTop: "0em" }}>
//                         {getFieldDecorator("password", {
//                           rules: [
//                             { required: true, message: "Password required" },
//                           ],
//                         })(
//                           <Inputs.Password
//                             placeholder="Password"
//                             prefix={
//                               <LockOutlined
//                                 style={{
//                                   paddingRight: "0.5em",
//                                   color: "lightgrey",
//                                 }}
//                               />
//                             }
//                             type="password"
//                             style={{
//                               boxShadow: "0px 8px 15px 0px rgba(0, 0, 0, 0.13)",
//                               height: "3.7em",
//                               //paddingLeft: '2em',
//                               borderRadius: "3em",
//                               border: "none",
//                             }}
//                           />
//                         )}
//                       </FormItem>
//                       {/* <FormItem

//                   //label={
//                   //  <span style={{ color: "grey", fontSize: 15 }}>
//                   //    Password
//                   //  </span>
//                   //}
//                   >
//                     {getFieldDecorator('password', {
//                       rules: [{ message: 'Please read & accept Terms' }]
//                     })(
//                       <Checkbox
//                         onChange={onChange}
//                         style={{ fontFamily: 'Open Sans' }}
//                       >
//                         I agree the
//                         <span style={{ fontWeight: 900 }}>
//                           {' '}
//                           Terms and Conditions
//                         </span>
//                       </Checkbox>
//                     )}
//                   </FormItem> */}
//                       <FormItem>
//                         <ButtonRadius
//                           style={{
//                             marginTop: "1em",
//                             height: 55,
//                             width: "100%",
//                             fontSize: 14,
//                             border: "none",
//                             fontFamily: "Open Sans",
//                             textShadow: "none",
//                             backgroundColor: "#0B2734",
//                             boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
//                           }}
//                           type="primary"
//                           htmlType="submit"
//                           size="large"
//                           block
//                         >
//                           Sign In
//                         </ButtonRadius>
//                       </FormItem>
//                     </div>
//                   </Card>
//                 </Form>
//               )}
//             </Spin>
//           </Col>
//           <span
//             style={{
//               position: "absolute",
//               bottom: "0.5em",
//               right: "1em",
//               color: 'lightgrey',
//               fontSize: 12,
//               textDecoration: "underline"
//             }}

//           >
//             {"v" + process.env.REACT_APP_VERSION_CNTRL}
//           </span>
//         </Row>
//         <Modal
//           title="Set Password"
//           visible={this.state.firstLogin}
//           onOk={this.handleChangePassword}
//           onCancel={e => this.setState({ firstLogin: false })}
//           okText="Save"
//           cancelText="Cancel"
//         >
//           <PassForm wrappedComponentRef={form => (this.passForm = form)} />
//         </Modal>
//       </div>
//     );
//   }
// }

// const WrappedSignIn = Form.create()(SignIn);

// export default WrappedSignIn;
