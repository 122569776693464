import React, { PureComponent, useState } from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  TableOutlined,
  DeleteFilled,
  PlusCircleOutlined,
  AntDesignOutlined,
  InfoCircleOutlined,
  PauseCircleFilled,
  NotificationFilled,
  PlayCircleFilled,
  CloseCircleFilled,
  TrademarkCircleFilled,
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  MailFilled,
  RocketFilled,
  DownloadOutlined,
  ArrowLeftOutlined,
  EyeFilled,
} from "@ant-design/icons";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import { PDFViewer } from "@react-pdf/renderer";
import nodemailer from "nodemailer";
import { ButtonRadius } from "src/components/elements/Button";
import Resizer from "react-image-file-resizer";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { getToken } from "src/redux/selectors";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Icon,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Typography,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
  TimePicker,
  Tooltip,
  Upload,
  Image,
  Radio,
  Checkbox,
  message,
  Tag,
} from "antd";
import moment from "moment";
import {
  ReactAudioRecorder,
  RECORD_STATUS,
} from "@sarafhbk/react-audio-recorder";
import { Link } from "react-router-dom";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
// import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
import SignatureCanvas from "react-signature-canvas";

const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const { Text } = Typography;
const numeral = require("numeral");
const { TabPane } = Tabs;
// const onChange = (date, dateString) => {
//   console.log(date, dateString);
// };
// const { Dragger } = Upload;

// const props = {
//   name: "myRecording",
//   multiple: false,
//   action: process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock", // set the backend endpoint for file upload
//   customRequest: async ({ file, onError, onSuccess }) => {
//     try {
//       const formData = new FormData();
//       formData.append("myRecording", file, file.name);
//       const response = await fetch(
//         process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock",
//         {
//           method: "POST",
//           mode: "cors",
//           headers: {
//             Authorization: "Bearer " + getToken(),
//             "Content-Type": "application/json; charset=utf-8",
//           },
//           body: formData,
//         }
//       );

//       const data = await response.json();
//       onSuccess(data, response);
//     } catch (error) {
//       onError(error);
//     }
//   },
//   onChange(info) {
//     const { status } = info.file;
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
// };
const { RangePicker } = DatePicker;
const dateFormat = "DD MMMM YYYY";
const timeFormat = "HH:mm";
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 55px !important;
    border-radius: 0.5em !important;
    padding-top: 11px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    padding-top: 1.2em !important;
  }
`;
const StyledDatePicker = styled(RangePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
function callback1(key) {
  console.log(key);
}
function callback2(key) {
  console.log(key);
}
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
const EditableContext = React.createContext();
const Signature = () => {
  return (
    <>
      <SignatureCanvas
        penColor="black"
        canvasProps={{
          width: 2500,
          height: 100,
          className: "sigCanvas",
        }}
      />
    </>
  );
};
const columns = [
  {
    title: "Stock Nr.",
    dataIndex: "serial",
    key: "serial",
    width: "90%",
  },
  {
    title: "Tool Type",
    dataIndex: "size",
    key: "size",
    width: "90%",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "90%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "90%",
  },
  {
    title: "Meters",
    dataIndex: "meters",
    key: "meters",
    width: "90%",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
    width: "90%",
  },
];
const data = [];

for (let i = 199; i < 222; i++) {
  data.push({
    key: i,
    name: `Rig ${i}`,
    age: 33,
    address: ` ${i}`,
  });
}
const fixedColumns = [
  {
    title: "Name",
    dataIndex: "name",
    fixed: true,
    width: 120,
  },
  {
    title: "Description",
    dataIndex: "description",
  },
];

class StockFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visibleChargesNotif: false,
      visibleChargesInterview: false,
      visibleSendNotif: false,
      visibleSendEnquiry: false,
      visibleSendInterview: false,
      interpIsYes: true,
      isPostponed: false,
      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      // myRecordingNotification: null,
      // myRecordingInterview: null,
      replaceStatus: null,
      visiblePrep: [], // Start with an empty array
      visiblePrepInterview: [], // Start with an empty array
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
      selectedDate: null,
      selectedTime: null,
      branchImage: undefined,
      frontRightImage: undefined,
      rightSideImage: undefined,
      wheelsImage: undefined,
      frontLeftImage: undefined,
      frontImage: undefined,
      pdfUrl: null,
      signature: null,
      isDrawing: false,
      sigVisible: false,
      recordingLinkNotif: '', // Initialize recordingLinkNotif state
      recordingLinkInterview: '', // Initialize recordingLinkNotif state
      // activeKey: "Info",
      // siteSelected:
      //   this.props.sites.length > 0 ? this.props.sites[0].name : "None",
      siteSelectedName:
        this.props.sites.length > 0 ? this.props.sites[0].name : "None",

      sendEmail: "",
      employees: [],
    };
    this.handleEmailChangeNotification = this.handleEmailChangeNotification.bind(
      this
    );
    this.showNextPrep = this.showNextPrep.bind(this);
    this.handleEmailChangeEnquiry = this.handleEmailChangeEnquiry.bind(this);
    this.handleEmailChangeInterview = this.handleEmailChangeInterview.bind(
      this
    );
    // this.onFinishNotif = this.onFinishNotif.bind(this);
    // this.onFinishEnquiry = this.onFinishEnquiry.bind(this);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.beforeUpload = this.beforeUpload.bind(this);
    this.handleRecordingUploadNotification = this.handleRecordingUploadNotification.bind(
      this
    );
    this.handleRecordingNotification = this.handleRecordingNotification.bind(
      this
    );
    this.handleRecordingUploadEnquiry = this.handleRecordingUploadEnquiry.bind(
      this
    );
    this.handleRecordingInterview = this.handleRecordingInterview.bind(this);
    this.getBase64 = this.getBase64.bind(this);
  }
  handleLoadedDataNotif = async (audioResult) => {
    const recordingLinkNotif = await this.convertToDataURI(audioResult); // Convert to data URI
    this.setState({ recordingLinkNotif });

    // Download the audio when data is loaded (assuming audioResult is a URL)
    const audioBlob = await fetch(audioResult).then(response => response.blob());

    // Saving the recording using the provided onSaveRecording method
    this.props.onSaveRecording();

    // Optional: Provide a download link for the user
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(audioBlob);
    downloadLink.download = `Disciplinary Enquiry Recording - ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName} ${this.props.editStock.access} ${moment(this.props.editStock.enquiryDate).format("DD MMMM YYYY")} ${moment(this.props.editStock.enquiryTime).format("HH:mm")}.mp3`;
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  handleLoadedDataInterview = async (audioResult) => {
    const recordingLinkInterview = await this.convertToDataURI(audioResult); // Convert to data URI
    this.setState({
      recordingLinkInterview,
    });
    // Download the audio when data is loaded (assuming audioResult is a URL)
    const audioBlob = await fetch(audioResult).then(response => response.blob());
    this.props.onSaveRecording();
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(audioBlob);
    downloadLink.download = `Disciplinary Interview Recording - ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
      } ${this.props.editStock.access} ${moment(this.props.editStock.interviewDate).format(
        "DD MMMM YYYY"
      )} ${moment(this.props.editStock.interviewTime).format("HH:mm")}.mp3`;
    downloadLink.style.display = 'none';

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
  };
  convertToDataURI = (audioUrl) => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', audioUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.send();
    });
  };



  signaturePad = {};
  // handleTabChange = activeKey => {
  //   this.setState({ activeKey });
  // };
  clearSignature = () => {
    this.signaturePad.clear();
  };
  signaturePadEnquiry = {};

  clearSignatureEnquiry = () => {
    this.signaturePadEnquiry.clear();
  };
  signaturePadInterview = {};

  clearSignatureInterview = () => {
    this.signaturePadInterview.clear();
  };
  saveSignature = () => {
    const signature = this.signaturePad.toDataURL();
    this.setState({ signature, frontImage: signature, sigVisible: "redo" });
    Notify("success", "Saved", "Successfully saved signature.");
  };
  saveSignatureEnquiry = () => {
    const signature = this.signaturePadEnquiry.toDataURL();
    this.setState({ signature, branchImage: signature, sigVisible: "redo" });
    Notify("success", "Saved", "Successfully saved signature.");
  };
  saveSignatureInterview = () => {
    const signature = this.signaturePadInterview.toDataURL();
    this.setState({ signature, frontLeftImage: signature, sigVisible: "redo" });
    Notify("success", "Saved", "Successfully saved signature.");
  };
  RedoSignature = () => {
    // this.signaturePad.clear();

    this.setState({ sigVisible: true });
  };
  handleOnStart = () => {
    this.setState({ isDrawing: true });
  };

  handleOnEnd = () => {
    this.setState({ isDrawing: false });
  };
  handleEmailChangeNotification = value => {
    this.setState({ sendEmailNotification: value });
  };
  handleEmailChangeEnquiry = value => {
    this.setState({ sendEmailEnquiry: value });
  };
  handleEmailChangeInterview = value => {
    this.setState({ sendEmailInterview: value });
  };
  componentWillMount = () => {
    if (this.props.editStock) {
      this.setState({
        myRecordingNotification: this.props.editStock.myRecordingNotification,
        myRecordingInterview: this.props.editStock.myRecordingInterview,
        branchImage: this.props.editStock.branchImage,
        frontRightImage: this.props.editStock.frontRightImage,
        rightSideImage: this.props.editStock.rightSideImage,
        wheelsImage: this.props.editStock.wheelsImage,
        frontLeftImage: this.props.editStock.frontLeftImage,
        frontImage: this.props.editStock.frontImage,
      });
    }
  };
  handleBranchImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImage: imageUrl,
      });
    });
  };
  handleFrontRightImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontRightImage: imageUrl,
      });
    });
  };
  handleRightSideImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        rightSideImage: imageUrl,
      });
    });
  };
  handleWheelsImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        wheelsImage: imageUrl,
      });
    });
  };
  handleFrontLeftImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontLeftImage: imageUrl,
      });
    });
  };
  handleFrontImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontImage: imageUrl,
      });
    });
  };
  showModalChargesNotif = () => {
    this.setState({
      visibleChargesNotif: true,
    });
  };
  handleOkChargesNotif = e => {
    console.log(e);
    this.setState({
      visibleChargesNotif: false,
    });
  };
  showModalChargesInterview = () => {
    this.setState({
      visibleChargesInterview: true,
    });
  };
  handleOkChargesInterview = e => {
    console.log(e);
    this.setState({
      visibleChargesInterview: false,
    });
  };
  showModalNotif = () => {
    this.setState({
      visibleSendNotif: true,
    });
  };
  // showModalNotif = () => {
  //   const { form } = this.stockForm.props;
  //   form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       // Additional logic before calling handleAddStock, if needed

  //       this.handleAddStock(values);

  //       // Additional logic after calling handleAddStock, if needed
  //       this.setState({
  //         visibleSendNotif: true,
  //       });
  //     }
  //   });
  // };
  showModalEnquiry = () => {
    this.setState({
      visibleSendEnquiry: true,
    });
  };
  showModalInterview = () => {
    this.setState({
      visibleSendInterview: true,
    });
  };
  handleOkEnquiry = e => {
    console.log(e);
    this.setState({
      visibleSendEnquiry: false,
    });
  };
  handleOkNotif = e => {
    console.log(e);
    this.setState({
      visibleSendNotif: false,
    });
  };
  handleOkInterview = e => {
    console.log(e);
    this.setState({
      visibleSendInterview: false,
    });
  };
  handleCancel = e => {
    console.log(e);
    this.setState({
      visibleDateRequired: false,
      visibleChargesNotif: false,
      visibleChargesInterview: false,
      visibleSendNotif: false,
      visibleSendEnquiry: false,
      visibleSendInterview: false,
    });
  };
  selectManual = checked => {
    this.setState({
      interpIsYes: true,
    });
  };
  deSelectManual = checked => {
    this.setState({
      interpIsYes: false,
    });
  };
  selectPostponed = checked => {
    this.setState({
      isPostponed: true,
    });
  };
  deSelectPostponed = checked => {
    this.setState({
      isPostponed: false,
    });
  };
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  handleEmploymentDateChange = date => {
    this.setState({ selectedemploymentDate: date });
  };
  handleTimeChange = time => {
    this.setState({ selectedTime: time });
  };
  handleSelectEmployee = () => {
    this.setState({
      viewSelect: true,
    });
  };
  handleInputEmployee = () => {
    this.setState({
      viewSelect: false,
    });
  };

  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  }

  handleRecordingUploadNotification({ onSuccess, file }) {
    const formData = new FormData();
    formData.append("myRecordingNotification", file);

    axios
      .post(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        if (response.data && response.data.success) {
          onSuccess(response.data, file);
        } else {
          console.log("Upload failed");
        }
      })
      .catch(error => {
        console.log("Upload error:", error);
      });
  }

  handleRecordingNotification(info) {
    this.setState({
      loading: true,
    });
    this.getBase641(info.file, imageUrl => {
      this.setState({
        loading: false,
        myRecordingNotification: imageUrl,
      });
    });
  }
  handleRecordingUploadEnquiry({ onSuccess, file }) {
    const formData = new FormData();
    formData.append("myRecordingInterview", file);

    axios
      .post(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        if (response.data && response.data.success) {
          onSuccess(response.data, file);
        } else {
          console.log("Upload failed");
        }
      })
      .catch(error => {
        console.log("Upload error:", error);
      });
  }

  handleRecordingInterview(info) {
    this.setState({
      loading: true,
    });
    this.getBase641(info.file, imageUrl => {
      this.setState({
        loading: false,
        myRecordingInterview: imageUrl,
      });
    });
  }
  getBase641(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  handleSubmitNotif = values => {
    this.onFinishNotif(values);
  };

  handleSubmitEnquiry = values => {
    this.onFinishEnquiry(values);
  };
  onFinishNotif = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "subheader" },
        { text: `Notification to Attend Enquiry`, style: "subheader" },
        {
          text: `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            }  From: ${this.props.editStock.access}`,
        },
        {
          text: `\n 
          Dear ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            },\n
           Please find notification to attend enquiry below.\n
           Alleged Charge(s): See Annexure “A” Below
           \n Date and time of enquiry: ${moment(
              this.props.editStock.enquiryDate
            ).format("DD MMMM YYYY")}  ${moment(
              this.props.editStock.enquiryTime
            ).format("HH:mm")}
          \n ${this.props.editStock.casePostponed === "Yes"
              ? "Postponed to: " +
              moment(this.props.editStock.enquiryDatePostponed).format(
                "DD MMMM YYYY"
              ) +
              " " +
              moment(this.props.editStock.enquiryTimePostponed).format(
                "HH:mm"
              )
              : "Postponed: No"
            } 
         \n Venue of enquiry: ${this.props.editStock.enquiryVenue}\n
        1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
              this.props.editStock.enquiryDate
            )
              .subtract(1, "day")
              .format("DD MMMM YYYY")} at ${moment(
                this.props.editStock.enquiryTime
              ).format("HH:mm")} of the under mentioned:\n
          Name of your representative: ${this.props.editStock.representativeName !== "" &&
              this.props.editStock.representativeName !== undefined
              ? this.props.editStock.representativeName
              : "______________________________"
            }\n
        Name(s) of your witnesses: ${this.props.editStock.witnessesName !== "" &&
              this.props.editStock.witnessesName !== undefined
              ? this.props.editStock.witnessesName
              : "______________________________"
            }\n 
 Do you intend to make use of a interpreter: ${this.props.editStock.interpreterNeeded !== "" &&
              this.props.editStock.interpreterNeeded !== undefined
              ? this.props.editStock.interpreterNeeded
              : "Yes / No"
            }\n 
 If so n which language? ${this.props.editStock.interpreterLang !== "" &&
              this.props.editStock.interpreterLang !== undefined
              ? this.props.editStock.interpreterLang
              : "______________________________"
            }\n (If an interpreter is required such an interpreter will be allocated by management)\n
          Do you need any time to prepare: ${this.props.editStock.preparationNeeded !== "" &&
              this.props.editStock.preparationNeeded !== undefined
              ? this.props.editStock.preparationNeeded
              : "Yes / No"
            }\n
      1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
      Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
      ${this.props.editStock.frontImage !== undefined
              ? ""
              : "\nAccused Signature: ______________________________ \n"
            }
I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.
          \n Document issued by:
      ${this.props.editStock.access}\n

    
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
        {
          text: "Click here to view signature",
          link:
            process.env.REACT_APP_GLOBAL_SERVER +
            "asambo/api/images/" +
            this.props.editStock.frontImage,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        htmlStyle: {
          margin: [0, 10, 0, 0],
        },
      },
      defaultStyle: {
        font: "Roboto",
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1];

      const MSG = {
        to: this.state.sendEmailNotification,
        from: "no-reply@asambo.co.za",
        subject: `Notification to Attend Enquiry (${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Notification to attend enquiry_${this.props.editStock.employeeName
              } ${this.props.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Notification to attend enquiry - 

        To: ${this.props.editStock.employeeName} 

        From: ${this.props.editStock.access} `,
      };

      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendNotif: false,
        });
      });
    });
  };
  handleSubmitEnquiry = values => {
    this.onFinishEnquiry(values);
  };
  onFinishEnquiry = values => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "header" },
        `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
        }  From: ${this.props.editStock.access}`,
        { text: "Disciplinary Enquiry", style: "subheader" },
        `Please find Disciplinary Enquiry below.
        \n`,
        {
          style: "tableExample",
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.props.editStock.companyName}`],
              [
                `Date and time of proceedings`,
                `${moment(this.props.editStock.enquiryDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.props.editStock.enquiryTime).format("HH:mm")}`,
              ],
              [
                `\n ${this.props.editStock.casePostponed === "Yes"
                  ? "Postponed to "
                  : "Postponed"
                } 
               \n`,
                `\n ${this.props.editStock.casePostponed === "Yes"
                  ? moment(this.props.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.props.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "No"
                } 
               \n`,
              ],
              [`Accused Name`, `${this.props.editStock.accusedName}`],
              [`Position`, `${this.props.editStock.jobTitle}`],
              [`Representative`, `${this.props.editStock.representativeName}`],
              [`Initiator`, `${this.props.editStock.initiatorName}`],
              [`Chairperson`, `${this.props.editStock.chairpersonName}`],
              [`Accused Venue / Place`, `${this.props.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [`Plea by the accused`, `${this.props.editStock.accusedPlea}`],
              [
                `Determination by Chairperson`,
                `${this.props.editStock.determinationFinding}`,
              ],
              [
                `Date of employment`,
                `${this.props.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.props.editStock.prevTransgressions}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.props.editStock.mitigationCircumstances}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.props.editStock.aggrivatingCircumstances}`,
              ],
              [`Suspension`, `${this.props.editStock.suspensionType}`],
              [
                `Sanction / Warning (Months)`,
                `${this.props.editStock.warning +
                " " +
                this.props.editStock.sanctionMonthsDisciplinary +
                " months"}`,
              ],
              [`Verdict`, `${this.props.editStock.otherWarnings}`],
            ],
          },
        },
        {
          text:
            this.props.editStock.refuseSignDisciplinaryEnquiry === true
              ? `\n Refused to sign : ` +
              this.props.editStock.refuseReasonDisciplinaryEnquiry
              : `\n Click here to view signature`,
          link:
            this.props.editStock.refuseSignDisciplinaryEnquiry === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.props.editStock.branchImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; //

      const MSG = {
        to: this.state.sendEmailEnquiry,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Enquiry (${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
          })`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Enquiry_${this.props.editStock.employeeName
              } ${this.props.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Enquiry - 

        To: ${this.props.editStock.employeeName} 

        From: ${this.props.editStock.access} `,
      };

      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendEnquiry: false,
        });
      });
    });
  };
  handleSubmitInterview = values => {
    this.onFinishInterview(values);
  };
  onFinishInterview = values => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "header" },
        `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
        }  From: ${this.props.editStock.access}`,
        { text: "Disciplinary Interview", style: "subheader" },
        {
          text: ` Please find Disciplinary Interview below.
        \n`,
        },

        {
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.props.editStock.companyName}`],
              [
                `Date and time of interview`,
                `${moment(this.props.editStock.interviewDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.props.editStock.interviewTime).format(
                  "HH:mm"
                )}`,
              ],
              [`Accused Name`, `${this.props.editStock.accusedName}`],
              [`Position`, `${this.props.editStock.jobTitle}`],
              [
                `Representative`,
                `${this.props.editStock.representativeNameInterview}`,
              ],
              [`Initiator`, `${this.props.editStock.InitiatorInterview}`],
              [
                `Chairperson`,
                `${this.props.editStock.chairpersonNameInterview}`,
              ],
              [`Accused Venue / Place`, `${this.props.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [
                `Plea by the accused`,
                `${this.props.editStock.accusedPleaInterview}`,
              ],
              [
                `Determination by Chairperson`,
                `${this.props.editStock.determinationFindingInterview}`,
              ],
              [
                `Date of employment`,
                `${this.props.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.props.editStock.prevTransgressionsInterview}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.props.editStock.mitigationCircumstancesInterview}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.props.editStock.aggrivatingCircumstancesInterview}`,
              ],
              [
                `Sanction / Warning (Months)`,
                `${this.props.editStock.sanctionMonthsFinal + " months"}`,
              ],
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
              },
            },
          },
        },
        {
          text:
            this.props.editStock.refuseSignInterview === true
              ? `\n Refused to sign : ` +
              this.props.editStock.refuseReasonInterview
              : `\n Click here to view signature`,
          link:
            this.props.editStock.refuseSignInterview === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.props.editStock.frontLeftImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedChargesInterview}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1];

      const MSG = {
        to: this.state.sendEmailInterview,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Interview (${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Interview_${this.props.editStock.employeeName
              } ${this.props.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Interview - 

        To: ${this.props.editStock.employeeName} 

        From: ${this.props.editStock.access} `,
      };

      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendInterview: false,
        });
      });
    });
  };
  showNextPrep = () => {
    const { visiblePrep } = this.state;
    const lastVisibleRow = visiblePrep[visiblePrep.length - 1];

    // Check if the last visible row has a value
    const addonValue = this.props.form.getFieldValue(`allegedCharges${lastVisibleRow}`);
    if (addonValue) {
      const nextVisibleRow = lastVisibleRow + 1;
      const updatedVisiblePrep = [...visiblePrep, nextVisibleRow];
      this.setState({ visiblePrep: updatedVisiblePrep });
    } else {
      // Handle the case where the last row's field is empty
    }
  }
  showNextPrepInterview = () => {
    const { visiblePrepInterview } = this.state;
    const lastVisibleRow = visiblePrepInterview[visiblePrepInterview.length - 1];

    // Check if the last visible row has a value
    const addonValue = this.props.form.getFieldValue(`allegedChargesInterview${lastVisibleRow}`);
    if (addonValue) {
      const nextVisibleRow = lastVisibleRow + 1;
      const updatedVisiblePrepInterview = [...visiblePrepInterview, nextVisibleRow];
      this.setState({ visiblePrepInterview: updatedVisiblePrepInterview });
    } else {
      // Handle the case where the last row's field is empty
    }
  }
  componentDidMount() {
    const { form, editStock } = this.props;
    // this.handleReset();
    // Validate form fields

    // Initialize visibility states
    const newVisiblePrepInterview = [];
    const newVisiblePrep = [];

    if (editStock) {
      for (let i = 1; i <= 30; i++) {
        if (editStock[`allegedCharges${i}`]) {
          newVisiblePrep.push(i);
        }
        if (editStock[`allegedChargesInterview${i}`]) {
          newVisiblePrepInterview.push(i);
        }
      }

    } else {
      // Set default visibility if editStock is not available
      newVisiblePrepInterview.push(1);
      newVisiblePrep.push(1);
    }

    this.setState({
      visiblePrepInterview: newVisiblePrepInterview,
      visiblePrep: newVisiblePrep
    });
  }


  componentDidUpdate(prevProps) {
    if (prevProps.editStock !== this.props.editStock) {
      const newVisiblePrep = [];
      const newVisiblePrepInterview = [];

      // Loop to check for existing values and populate visiblePrep accordingly
      for (let i = 1; i <= 30; i++) {
        const prepStepField = `allegedCharges${i}`;
        if (this.props.editStock && this.props.editStock[prepStepField]) {
          newVisiblePrep.push(i);
        }
      }
      for (let i = 1; i <= 30; i++) {
        const prepStepField = `allegedChargesInterview${i}`;
        if (this.props.editStock && this.props.editStock[prepStepField]) {
          newVisiblePrepInterview.push(i);
        }
      }

      this.setState({
        visiblePrep: newVisiblePrep,
        visiblePrepInterview: newVisiblePrepInterview,
      });
    }
  }

  render() {
    const { signature, isDrawing, visiblePrep, visiblePrepInterview } = this.state;
    async function doesImageExist(url) {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok; // Returns true if the image exists, false otherwise
    }
    async function imageUrlToBase64(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    const generateNotificationPdf = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.props.editStock.frontImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [180, 180],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const prepRows = [];
        visiblePrep.forEach(row => {
          prepRows.push([
            {
              text: `Alleged Charge ${row}:`,
              style: 'tableChargeHeader',
              bold: true,
              margin: [0, 10, 0, 10]
            },
            {
              text: this.props.editStock ? this.props.editStock[`allegedCharges${row}`] : '',
              style: 'tableChargeContent',
            },
          ]);
        });
        const docDefinition = {
          content: [
            { text: `${this.props.editStock.access}`, style: "subheader" },
            { text: `Notification to Attend Enquiry`, style: "subheader" },
            {
              text: `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                }  From: ${this.props.editStock.access}`,
            },
            {
              text: `
            Dear ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                },\n
             Please find notification to attend enquiry below.\n
             Alleged Charge(s): See Annexure “A” Below 
             \n Date and time of enquiry: ${moment(
                  this.props.editStock.enquiryDate
                ).format("DD MMMM YYYY")}  ${moment(
                  this.props.editStock.enquiryTime
                ).format("HH:mm")}
            \n ${this.props.editStock.casePostponed === "Yes"
                  ? "Postponed to: " +
                  moment(this.props.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.props.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "Postponed: No"
                } 
           \n Venue of enquiry: ${this.props.editStock.enquiryVenue}\n
          1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
                  this.props.editStock.enquiryDate
                )
                  .subtract(1, "day")
                  .format("DD MMMM YYYY")} at ${moment(
                    this.props.editStock.enquiryTime
                  ).format("HH:mm")} of the under mentioned:\n
            Name of your representative: ${this.props.editStock.representativeName !== "" &&
                  this.props.editStock.representativeName !== undefined
                  ? this.props.editStock.representativeName
                  : "______________________________"
                }\n
          Name(s) of your witnesses: ${this.props.editStock.witnessesName !== "" &&
                  this.props.editStock.witnessesName !== undefined
                  ? this.props.editStock.witnessesName
                  : "______________________________"
                }\n 
   Do you intend to make use of a interpreter: ${this.props.editStock.interpreterNeeded !== "" &&
                  this.props.editStock.interpreterNeeded !== undefined
                  ? this.props.editStock.interpreterNeeded
                  : "Yes / No"
                }\n 
   If so n which language? ${this.props.editStock.interpreterLang !== "" &&
                  this.props.editStock.interpreterLang !== undefined
                  ? this.props.editStock.interpreterLang
                  : "______________________________"
                }\n (If an interpreter is required such an interpreter will be allocated by management)\n
            Do you need any time to prepare: ${this.props.editStock.preparationNeeded !== "" &&
                  this.props.editStock.preparationNeeded !== undefined
                  ? this.props.editStock.preparationNeeded
                  : "Yes / No"
                }\n
        1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
        Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
            \n Document issued by: ${this.props.editStock.access}
        
          `,
              style: "htmlStyle",
              margin: [0, 0, 0, 0],
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.props.editStock.frontImage !== undefined ? images : null,
            this.props.editStock.frontImage !== undefined ? { text: `_______________________________________`, style: "subheader" } : null,
            this.props.editStock.frontImage !== undefined ? {
              text: `I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.`,
            } : null,
            this.props.editStock.frontImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________________ \n  
            I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                  } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.`,
              },


            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...prepRows,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        // To download the generated PDF
        pdfDocGenerator.download(`Notification to Attend Enquiry_${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName}.pdf`);

        // To open the PDF in a new tab
        // pdfDocGenerator.open();
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const stopRecordingAndOpenEnquiry = stopRecording => {
      stopRecording();
      // Modal.info({
      //   title: "Recording Stopped",
      //   content: (
      //     <div>
      //       {/* <p>
      //         Please upload downloaded recording at the{" "}
      //         <bold style={{ fontWeight: 700 }}>Enquiry Recording </bold>
      //         section at the bottom of the page &{" "}
      //         <bold style={{ fontWeight: 700 }}>Save your form</bold> when done.
      //       </p>
      //       <p>
      //         <bold style={{ fontWeight: 700 }}>Recording Name:</bold>
      //         <br />
      //         Disciplinary Recording - {this.props.editStock.employeeName}{" "}
      //         {this.props.editStock.employeeLastName}{" "}
      //         {this.props.editStock.access}.mp3
      //       </p> */}
      //       <p>
      //         Please make sure to save your file before exiting.
      //       </p>
      //     </div>
      //   ),
      // });
    };
    const generateInterviewPdf = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.props.editStock.frontLeftImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [210, 210],
          margin: [0, 20, 0, 10]
          // pageBreak: 'before'
        }));
        const prepRowsInterview = [];
        visiblePrepInterview.forEach(row => {
          prepRowsInterview.push([
            {
              text: `Alleged Charge ${row}:`,
              style: 'tableChargeHeader',
              bold: true, // Adding bold property here
              margin: [0, 10, 0, 10]
            },
            {
              text: this.props.editStock ? this.props.editStock[`allegedChargesInterview${row}`] : '',
              style: 'tableChargeContent',
            },
            {

              text: `Accused's Plea to Charge ${row}: ${this.props.editStock && this.props.editStock[`accusedPleaInterview${row}`]
                ? this.props.editStock[`accusedPleaInterview${row}`]
                : ''}`,
              bold: true,
              margin: [0, 10, 0, 0]
            },
            {
              text: this.props.editStock && this.props.editStock[`accusedPleaInterview${row}`]
                ? this.props.editStock[`accusedPleaInterview${row}`]
                : '',
            },
          ]);
        });
        const docDefinition = {
          content: [
            { text: `${this.props.editStock.access}`, style: "header" },
            `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            }  From: ${this.props.editStock.access}`,
            { text: "Disciplinary Interview", style: "subheader" },
            {
              text: ` Please find Disciplinary Interview below.
            \n`,
            },

            {
              table: {
                body: [
                  [`Description`, `Details`],
                  [`Company Name`, `${this.props.editStock.companyName}`],
                  [
                    `Date and time of interview`,
                    `${moment(this.props.editStock.interviewDate).format(
                      "DD MMMM YYYY"
                    )} ${moment(this.props.editStock.interviewTime).format(
                      "HH:mm"
                    )}`,
                  ],
                  [`Accused Name`, `${this.props.editStock.accusedName}`],
                  [`Position`, `${this.props.editStock.jobTitle}`],
                  [
                    `Representative`,
                    `${this.props.editStock.representativeNameInterview}`,
                  ],
                  [`Initiator`, `${this.props.editStock.InitiatorInterview}`],
                  [
                    `Chairperson`,
                    `${this.props.editStock.chairpersonNameInterview}`,
                  ],
                  [`Accused Venue / Place`, `${this.props.editStock.access}`],
                  [`Alleged Charge(s)`, `See Annexure “A” Below `],

                  [
                    `Determination by Chairperson`,
                    `${this.props.editStock.determinationFindingInterview}`,
                  ],
                  [
                    `Date of employment`,
                    `${this.props.editStock.selectedemploymentDate}`,
                  ],
                  [
                    `Valid previous transgressions`,
                    `${this.props.editStock.prevTransgressionsInterview}`,
                  ],
                  [
                    `Mitigation circumstances`,
                    `${this.props.editStock.mitigationCircumstancesInterview}`,
                  ],
                  [
                    `Aggrivating circumstances`,
                    `${this.props.editStock.aggrivatingCircumstancesInterview}`,
                  ],
                  [
                    `Sanction / Warning (Months)`,
                    `${this.props.editStock.sanctionMonthsFinal + " months"}`,
                  ],
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: "black",
                  },
                },
              },
            },
            {
              text:
                this.props.editStock.refuseSignInterview === true
                  ? `\n Refused to sign : ` +
                  this.props.editStock.refuseReasonInterview
                  : null,
              // link:
              //   this.props.editStock.refuseSignDisciplinaryEnquiry === false
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //     "asambo/api/images/" +
              //     this.props.editStock.branchImage
              //     : null,
            },
            { text: `Accused Signature`, margin: [0, 10, 0, 10] },

            images,
            { text: `\n\n________________________________________`, style: "subheader" },
            {
              text: `I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                } hereby confirm that I understand the nature of the offence as well as the sanction taken above.`,
            },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...prepRowsInterview,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        // To download the generated PDF
        pdfDocGenerator.download(`Disciplinary Interview_${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName}.pdf`);

        // To open the PDF in a new tab
        // pdfDocGenerator.open();
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const generateEnquiryPdf = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.props.editStock.branchImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [210, 210],
          margin: [0, 20, 0, 10]
          // pageBreak: 'before'
        }));
        const prepRows = [];
        visiblePrep.forEach(row => {
          prepRows.push([
            {
              text: `Alleged Charge ${row}:`,
              style: 'tableChargeHeader',
              bold: true,
              margin: [0, 10, 0, 10]
            },
            {
              text: this.props.editStock ? this.props.editStock[`allegedCharges${row}`] : '',
              style: 'tableChargeContent',
            },
            {

              text: `Accused's Plea to Charge ${row}:`,
              bold: true,
              margin: [0, 10, 0, 0]
            },
            {
              text: this.props.editStock && this.props.editStock[`accusedPlea${row}`]
                ? this.props.editStock[`accusedPlea${row}`]
                : '',
            },
          ]);
        });
        const docDefinition = {
          content: [
            { text: `${this.props.editStock.access}`, style: "header" },
            `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            }  From: ${this.props.editStock.access}`,
            { text: "Disciplinary Enquiry", style: "subheader" },
            {
              text: ` Please find Disciplinary Enquiry below.
            \n`,
            },

            {
              table: {
                body: [
                  [`Description`, `Details`],
                  [`Company Name`, `${this.props.editStock.companyName}`],
                  [
                    `Date and time of proceedings`,
                    `${moment(this.props.editStock.enquiryDate).format(
                      "DD MMMM YYYY"
                    )} ${moment(this.props.editStock.enquiryTime).format("HH:mm")}`,
                  ],
                  [
                    `\n ${this.props.editStock.casePostponed === "Yes"
                      ? "Postponed to "
                      : "Postponed"
                    } 
                   \n`,
                    `\n ${this.props.editStock.casePostponed === "Yes"
                      ? moment(this.props.editStock.enquiryDatePostponed).format(
                        "DD MMMM YYYY"
                      ) +
                      " " +
                      moment(this.props.editStock.enquiryTimePostponed).format(
                        "HH:mm"
                      )
                      : "No"
                    } 
                   \n`,
                  ],
                  [`Accused Name`, `${this.props.editStock.accusedName}`],
                  [`Position`, `${this.props.editStock.jobTitle}`],
                  [`Representative`, `${this.props.editStock.representativeName}`],
                  [`Initiator`, `${this.props.editStock.initiatorName}`],
                  [`Chairperson`, `${this.props.editStock.chairpersonName}`],
                  [`Accused Venue / Place`, `${this.props.editStock.access}`],
                  [`Alleged Charge(s)`, `See Annexure “A” Below `],
                  [
                    `Determination by Chairperson`,
                    `${this.props.editStock.determinationFinding}`,
                  ],
                  [
                    `Date of employment`,
                    `${this.props.editStock.selectedemploymentDate}`,
                  ],
                  [
                    `Valid previous transgressions`,
                    `${this.props.editStock.prevTransgressions}`,
                  ],
                  [
                    `Mitigation circumstances`,
                    `${this.props.editStock.mitigationCircumstances}`,
                  ],
                  [
                    `Aggrivating circumstances`,
                    `${this.props.editStock.aggrivatingCircumstances}`,
                  ],
                  [`Suspension`, `${this.props.editStock.suspensionType}`],
                  [
                    `Sanction / Warning (Months)`,
                    `${this.props.editStock.warning +
                    " " +
                    this.props.editStock.sanctionMonthsDisciplinary +
                    " months"}`,
                  ],
                  [`Verdict`, `${this.props.editStock.otherWarnings}`],
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: "black",
                  },
                },
              },
            },
            {
              text:
                this.props.editStock.refuseSignDisciplinaryEnquiry === true
                  ? `\n Refused to sign : ` +
                  this.props.editStock.refuseReasonDisciplinaryEnquiry
                  : null,
              // link:
              //   this.props.editStock.refuseSignDisciplinaryEnquiry === false
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //     "asambo/api/images/" +
              //     this.props.editStock.branchImage
              //     : null,
            },
            { text: `Accused Signature`, margin: [0, 10, 0, 10] },

            images,
            { text: `\n\n____________________________________________`, style: "subheader" },
            {
              text: `I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                } hereby confirm that I understand the nature of the offence as well as the sanction taken above.`,
            },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...prepRows,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);

        // To download the generated PDF
        pdfDocGenerator.download(`Disciplinary Enquiry_${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName}.pdf`);

        // To open the PDF in a new tab
        // pdfDocGenerator.open();
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const stopRecordingAndOpenInterview = stopRecording => {
      stopRecording();
      // Modal.info({
      //   title: "Recording Stopped & Downloaded",
      //   content: (
      //     <div>
      //       {/* <p>
      //         Please upload downloaded recording at the{" "}
      //         <bold style={{ fontWeight: 700 }}>Interview Recording </bold>
      //         section at the bottom of the page &{" "}
      //         <bold style={{ fontWeight: 700 }}>Save your form</bold> when done.
      //       </p>
      //       <p>
      //         <bold style={{ fontWeight: 700 }}>Recording Name:</bold>
      //         <br />
      //         Disciplinary Interview Recording -{" "}
      //         {this.props.editStock.employeeName}{" "}
      //         {this.props.editStock.employeeLastName}{" "}
      //         {this.props.editStock.access}.mp3
      //       </p> */}
      //       <p>
      //         Please make sure to save your file before exiting.
      //       </p>
      //     </div>
      //   ),
      // });
    };
    const uniqueStock = [
      ...new Set(this.props.stock.map(stock => stock.employeeName)),
    ];
    // let stock = [];
    // stock.forEach(stock => {
    //   if (stock.access.includes(this.state.siteSelected)) {
    //     stock.push(stock);
    //   }
    // });
    const maxRowsPrep = 20;
    const rowsPrep = [];
    const rowsPrepEnquiry = [];
    // const rowsPrep = Array.from({ length: 30 }, () => null);
    if (!visiblePrep.includes(1)) {
      visiblePrep.push(1);
    }
    visiblePrep.forEach(row => {
      rowsPrep[row - 1] = (
        <Row gutter={24} key={row} id={`row-${row}`} style={{ backgroundColor: row % 2 == 0 ? "rgba(200, 200, 200, 0.10)" : "", padding: "1em", paddingBottom: "0em", borderRadius: "1.5em" }}>
          <Col span={24}>
            <span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge ${row}:`}</span>
            <pre style={{ marginTop: "0.5em", whiteSpace: "unset" }}>
              {this.props.editStock ? this.props.editStock[`allegedCharges${row}`] : null}
            </pre>

          </Col>
        </Row>
      );
    });
    visiblePrep.forEach(row => {
      rowsPrepEnquiry[row - 1] = (
        <Row gutter={24} key={row} id={`row-${row}`} style={{ backgroundColor: row % 2 == 0 ? "rgba(200, 200, 200, 0.10)" : "", padding: "1em", paddingBottom: "1em", borderRadius: "1,5em" }}>
          <Col span={24}>
            <span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge ${row}:`}</span>
            <pre style={{ marginTop: "0.5em", whiteSpace: "unset" }}>
              {this.props.editStock ? this.props.editStock[`allegedCharges${row}`] : null}
            </pre>
          </Col>
          <Col span={24} style={{ marginTop: "-0.5em" }}>
            <span style={{ fontWeight: 400, fontSize: 14 }}>
              <span style={{}}>
                Plea by the accused: ({" "}
                <span style={{ color: "rgb(0, 112, 168)" }}>
                  {this.props.editStock &&
                    this.props.editStock[`accusedPlea${row}`]
                    ? this.props.editStock[`accusedPlea${row}`]
                    : ""}
                </span>{" "}
                )
              </span>
            </span>
          </Col>
          {/* <Col span={24} style={{ marginTop: "1em" }}>
            <FormItem>
              {this.props.form.getFieldDecorator(`accusedPlea${row}`, {
                rules: [
                  {
                    required: false,
                  },
                ],
                // valuePropName: "checked",
                // initialValue: this.props.editStock
                //   ? this.props.editStock.accusedPleaInterview
                //   : "",
              })(
                <Radio.Group defaultValue={null} buttonStyle="solid">
                  <Radio.Button value="Guilty">Guilty</Radio.Button>
                  <Radio.Button value="Not Guilty">
                    Not Guilty
                  </Radio.Button>
                  <Radio.Button value="N/A">
                    N/A
                  </Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
          </Col> */}
        </Row>
      );
    });
    const maxRowsPrepInterview = 20;
    const rowsPrepInterview = [];
    // const rowsPrep = Array.from({ length: 30 }, () => null);
    if (!visiblePrepInterview.includes(1)) {
      visiblePrepInterview.push(1);
    }

    visiblePrepInterview.forEach(row => {
      rowsPrepInterview[row - 1] = (
        <Row gutter={24} key={row} id={`row-${row}`} style={{ backgroundColor: row % 2 == 0 ? "rgba(200, 200, 200, 0.10)" : "", padding: "1em", paddingBottom: "1em", borderRadius: "1,5em" }}>

          <Col span={24}>
            <span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge ${row}:`}</span>
            <pre style={{ marginTop: "0.5em", whiteSpace: "unset" }}>
              {this.props.editStock ? this.props.editStock[`allegedChargesInterview${row}`] : null}
            </pre>
          </Col>
          <Col span={24} style={{ marginTop: "-0.5em" }}>
            <span style={{ fontWeight: 400, fontSize: 14 }}>
              <span style={{}}>
                Plea by the accused: ({" "}
                <span style={{ color: "rgb(0, 112, 168)" }}>
                  {this.props.editStock &&
                    this.props.editStock[`accusedPleaInterview${row}`]
                    ? this.props.editStock[`accusedPleaInterview${row}`]
                    : ""}
                </span>{" "}
                )
              </span>
            </span>
          </Col>
          {/* <Col span={24} style={{ marginTop: "1em" }}>
            <FormItem>
              {this.props.form.getFieldDecorator(`accusedPleaInterview${row}`, {
                rules: [
                  {
                    required: false,
                  },
                ],
                // valuePropName: "checked",
                // initialValue: this.props.editStock
                //   ? this.props.editStock.accusedPleaInterview
                //   : "",
              })(
                <Radio.Group defaultValue={null} buttonStyle="solid">
                  <Radio.Button value="Guilty">Guilty</Radio.Button>
                  <Radio.Button value="Not Guilty">
                    Not Guilty
                  </Radio.Button>
                  <Radio.Button value="N/A">
                    N/A
                  </Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
          </Col> */}
        </Row>
      );
    });
    return (
      <div style={{ margin: "auto", paddingBottom: "8em" }}>
        <Modal
          title="Please Select Date of interview"
          visible={this.state.visibleDateRequired}
          onCancel={this.handleCancel}
          footer={[
            <Button type="primary" key="back" onClick={this.handleCancel}>
              Close
            </Button>,
          ]}
        >
          {" "}
          <p style={{ fontWeight: 400, fontSize: 14 }}>
            Please Select Date of interview under "Disciplinary Interview" tab
            to continue
          </p>
        </Modal>

        <Tabs
          // defaultActiveKey={this.state.currentTab}
          animated={true}
          // onChange={callback2}
          type="card"
          // activeKey={this.state.activeKey}
          onChange={this.handleTabChange}
        >
          {this.props.editStock ? null : (
            <TabPane tab="Employee Information" key="Info">
              <span style={{ fontWeight: 750, fontSize: 20 }}>
                {this.props.editInterview === "addInterview"
                  ? "Create Disciplinary Interview"
                  : "Create Notification to Attend Enquiry"}
                <br />
                <p style={{ fontWeight: 400, fontSize: 14 }}>
                  Please fill in all fields to create new employee or select an
                  existing employee to create new{" "}
                  {this.props.editInterview === "addInterview"
                    ? "Disciplinary Interview."
                    : "Notification to Attend Enquiry."}
                </p>{" "}
              </span>{" "}
              <Form>
                <Row style={{ marginTop: "2em" }}>
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Employee Name</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeName", {
                        rules: [
                          {
                            required: true,
                            message: "Employee Name required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName
                          : "",
                      })(
                        this.state.viewSelect ? (
                          <span>
                            <Select
                              allowClear={true}
                              style={{
                                width: this.state.disableInput ? "100%" : "67%",
                              }}
                              defaultValue={
                                this.props.editStock
                                  ? this.props.editStock.employeeName
                                  : ""
                              }
                              showSearchcePlace
                              value={this.state.employeeName}
                              optionFilterProp="children"
                              onChange={value => {
                                // this.setState({
                                //   disableInput: true,
                                // });
                                const stock = this.props.stock.find(
                                  emp => emp.employeeName === value
                                );
                                this.props.form.setFieldsValue({
                                  employeeLastName: stock
                                    ? stock.employeeLastName
                                    : "",
                                  employeeName: stock ? stock.employeeName : "",
                                  employeeNumber: stock
                                    ? stock.employeeNumber
                                    : "",
                                  employeeEmail: stock
                                    ? stock.employeeEmail
                                    : "",
                                  enquiryVenue: stock ? stock.access : "",
                                  venuePlace: stock ? stock.access : "",
                                  companyName: stock ? stock.access : "",
                                  jobTitle: stock ? stock.jobTitle : "",
                                  accusedName: stock
                                    ? stock.employeeName +
                                    " " +
                                    stock.employeeLastName
                                    : "",
                                  access: stock ? stock.access : "",
                                  selectedemploymentDate: stock
                                    ? moment(
                                      stock.selectedemploymentDate
                                    ).format("DD MMMM YYYY")
                                    : "",
                                });
                              }}
                            >
                              {uniqueStock
                                .filter(employeeName => {
                                  const stock = this.props.stock.find(
                                    emp =>
                                      emp.employeeName === employeeName &&
                                      emp.access === this.props.allSitesSelected
                                  );
                                  return stock != null;
                                })
                                .map(employeeName => {
                                  const stock = this.props.stock.find(
                                    emp => emp.employeeName === employeeName
                                  );

                                  return (
                                    <Option
                                      key={employeeName}
                                      value={employeeName}
                                    >
                                      {employeeName} {stock.employeeLastName}
                                    </Option>
                                  );
                                })}
                            </Select>
                            <Button
                              style={{
                                width: "33%",
                                backgroundColor: "#fafafa",
                                display: this.state.disableInput ? "none" : "",
                              }}
                              onClick={this.handleInputEmployee}
                            >
                              New Employee
                            </Button>
                          </span>
                        ) : (
                          <Input
                            value={this.state.employeeName}
                            addonAfter={
                              <span onClick={this.handleSelectEmployee}>
                                Select Employee
                              </span>
                            }
                          />
                        )
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />{" "}
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Employee Last Name</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeLastName", {
                        rules: [
                          {
                            required: true,
                            message: "Employee Last Name required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input
                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />{" "}
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Contact Number</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeNumber", {
                        rules: [
                          {
                            required: false,
                            message: "Employee Number required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeNumber
                          : "",
                      })(
                        <Input
                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Email</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeEmail", {
                        rules: [
                          {
                            required: false,
                            message: "Employee Email required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeEmail
                          : "",
                      })(
                        <Input
                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />{" "}
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Job Title</span>}
                    >
                      {this.props.form.getFieldDecorator("jobTitle", {
                        rules: [
                          {
                            required: false,
                            message: "Job Title required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.jobTitle
                          : "",
                      })(
                        <Input
                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Assign Employee to</span>}
                    >
                      {this.props.form.getFieldDecorator("access", {
                        rules: [
                          {
                            required: true,
                            message: "Access required",
                          },
                        ],
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.access
                        //   : this.state.siteSelected,
                      })(
                        <Select showSearch>
                          {this.props.sites.map(site => (
                            <Option key={site.name} value={site.name}>
                              {site.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>

                  <Col span={1} />
                  <Col xs={24} md={7}>
                    {this.state.viewSelect ? (
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Employment Date</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "selectedemploymentDate",
                          {
                            rules: [
                              {
                                required: false,
                                message: "Job Title required",
                              },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock.selectedemploymentDate
                              : "",
                          }
                        )(
                          <Input
                            disabled
                          // type={"number"}
                          />
                        )}
                      </FormItem>
                    ) : (
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Employment Date</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "selectedemploymentDate",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                          }
                        )(
                          <DatePicker
                            placeholder="Select Date"
                            value={this.state.selectedemploymentDate}
                            onChange={this.handleEmploymentDateChange}
                            format={dateFormat}
                          />
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.props.onOk}
                    size="large"
                    style={{
                      position: "fixed",
                      bottom: "6.5em",
                      right: "1em",
                      fontSize: "14px",
                      width: "4em",
                      height: "4em",
                      //height: "3em",
                      marginLeft: "0.7em",
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Cancel">
                  <Button
                    shape="round"
                    onClick={this.props.onCancel}
                    style={{
                      fontSize: "14px",
                      width: "4em",
                      height: "4em",
                      position: "fixed",
                      bottom: "2em",
                      right: "1em",
                      border: "1px solid #EEEFF0",
                      backgroundColor: "red",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip> */}
              </Form>
            </TabPane>
          )}
          {this.props.editInterview !== "editInterview" &&
            this.props.editInterview !== "addInterview" &&
            this.props.addNewNotification === false ? (
            <TabPane
              tab="Notification"
              key="Notification"
              onClick={e =>
                this.setState({
                  currentTab: "Notification",
                })
              }
            >
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Notification to Attend Enquiry
              </span>

              <Form>
                <Row
                  style={{
                    marginTop: "1em",
                  }}
                >
                  <Col xs={12} md={11}>
                    <FormItem label={<span>To</span>}>
                      {this.props.form.getFieldDecorator("accusedName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input
                          disabled

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={12} md={11}>
                    <FormItem label={<span>From</span>}>
                      {this.props.form.getFieldDecorator("access", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.access
                          : "",
                      })(
                        <Input
                          disabled

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>

                <p style={{ marginBottom: "0em", paddingTop: "2em", borderTop: "1px solid #e9e9e9", }}>
                  1.1 Alleged Charge(s)&nbsp;{" "} </p>
                <br />
                {rowsPrep}

                <Row
                  style={{
                    // marginTop: "-1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                    paddingBottom: "2em",
                  }}
                >
                  <Col xs={24} md={11}>
                    <span>
                      1.2 Date and time of enquiry
                      {this.props.editStock &&
                        this.props.editStock.casePostponed === "Yes" ? (
                        <br />
                      ) : null}
                      {" ("}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock &&
                          this.props.editStock.enquiryDate
                          ? moment(this.props.editStock.enquiryDate).format(
                            "DD MMMM YYYY"
                          )
                          : " Please set a date"}
                      </span>{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {" "}
                        {this.props.editStock &&
                          this.props.editStock.enquiryTime
                          ? moment(this.props.editStock.enquiryTime).format(
                            "HH:mm"
                          )
                          : null}
                      </span>{" "}
                      {this.props.editStock &&
                        this.props.editStock.casePostponed === "Yes" ? (
                        <span>
                          {" "}
                          - Postponed to :{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDatePostponed
                              ? moment(
                                this.props.editStock.enquiryDatePostponed
                              ).format("DD MMMM YYYY")
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTimePostponed
                              ? moment(
                                this.props.editStock.enquiryTimePostponed
                              ).format("HH:mm")
                              : null}
                          </span>
                        </span>
                      ) : null}
                      {")"}
                    </span>
                    <br />

                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>1.3 Venue of enquiry</span>}
                      style={{ color: "rgb(0, 112, 168)" }}
                    >
                      {this.props.form.getFieldDecorator("enquiryVenue", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.enquiryVenue === "" ||
                            this.props.editStock.enquiryVenue === null ||
                            this.props.editStock.enquiryVenue === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.enquiryVenue
                          : "",
                      })(
                        <Input
                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Case Postponed : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.casePostponed
                            ? this.props.editStock.casePostponed
                            : " No"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>

                  {this.state.isPostponed === true ? (
                    <span>
                      <Col span={24} style={{ marginTop: "-1em" }}>
                        <span>
                          Postponed to :{" ("}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDatePostponed
                              ? moment(
                                this.props.editStock.enquiryDatePostponed
                              ).format("DD MMMM YYYY")
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTimePostponed
                              ? moment(
                                this.props.editStock.enquiryTimePostponed
                              ).format("HH:mm")
                              : null}
                          </span>
                          {")"}
                        </span>
                        <br />
                      </Col>

                    </span>
                  ) : null}
                </Row>

                <Row
                  style={{
                    marginTop: "-0.5em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "3em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>
                        {" "}
                        1.4 Take note of your rights as set out hereunder:
                      </span>
                      <br />
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        Please inform{" "}
                        <span style={{ color: "#0070A8" }}>
                          Company Representative
                        </span>{" "}
                        by no later than{" "}
                        <span style={{ color: "#0070A8" }}>
                          {" "}
                          {this.props.editStock
                            ? this.props.editStock.enquiryDate
                              ? moment(this.props.editStock.enquiryDate)
                                .subtract(1, "day")
                                .format("DD MMMM YYYY")
                              : "select Date"
                            : null}
                        </span>{" "}
                        at{" "}
                        <span style={{ color: "#0070A8" }}>
                          {" "}
                          {this.props.editStock
                            ? this.props.editStock.enquiryTime
                              ? moment(this.props.editStock.enquiryTime).format(
                                "HH:mm"
                              )
                              : "select Time"
                            : null}
                        </span>{" "}
                        of the under mentioned:
                      </span>
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2em" }}>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Name of your representative</span>}
                    >
                      {this.props.form.getFieldDecorator("representativeName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.representativeName
                          : "",
                      })(
                        <Input
                          disabled
                        // type={"number"}
                        />
                      )}
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 13,
                          color: "grey",
                          lineHeight: "1.5em",
                        }}
                      >
                        (You are entitled to representation by any co-employee
                        available during the scheduled enquiry)
                      </p>
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Name(s) of your witnesses</span>}
                    >
                      {this.props.form.getFieldDecorator("witnessesName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.witnessesName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 13,
                          color: "grey",
                          lineHeight: "1.5em",
                        }}
                      >
                        (It remains your responsibility to arrange availability
                        of your witnesses during scheduled enquiry)
                      </p>
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Do you intend to make use of a interpreter : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.interpreterNeeded
                            ? this.props.editStock.interpreterNeeded
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>

                </Row>
                {this.state.interpIsYes === true ? (
                  <Row style={{ marginTop: "1em" }}>
                    <Col xs={24} md={11}>

                      <span>In which language? <span style={{ color: "rgb(0, 112, 168)" }}>{this.props.editStock && this.props.editStock.interpreterLang ? this.props.editStock.interpreterLang : null}</span></span>
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 13,
                          color: "grey",
                          lineHeight: "1.5em",
                        }}
                      >
                        (If an interpreter is required such an interpreter
                        will be allocated by management)
                      </p>

                    </Col>
                  </Row>
                ) : null}
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Do you need any time to prepare : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.preparationNeeded
                            ? this.props.editStock.preparationNeeded
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>

                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "3em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>
                        1.5 Any further information concerning the obove
                        mentioned enquiry can be obtained from the person
                        mentioned in 1.4 above.
                      </span>
                      <br />
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        Note: that if you fail to attend the scheduled
                        proceedings it will continue in your absence and you
                        will waive all rights in this respect.
                      </span>
                    </span>
                  </Col>
                </Row>

                <Row style={{ marginTop: "1em" }}>
                  <Col xs={24} md={24}>
                    <span>
                      <Cards
                        style={{
                          width: "100%",
                          // border: "1px solid #009bdf",
                          textAlign: "center",
                          marginBottom: 15,
                        }}

                        title={
                          <span style={{ color: "#009bdf" }}>
                            Accused Signature
                          </span>
                        }
                        cover={
                          this.props.editStock ? (
                            this.props.editStock.frontImage &&
                              this.state.sigVisible === false ? (
                              <span>
                                <img
                                  style={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                  alt="example"
                                  src={
                                    this.props.editStock.frontImage.search(
                                      "data"
                                    ) > -1
                                      ? this.props.editStock.frontImage
                                      : process.env.REACT_APP_GLOBAL_SERVER +
                                      "asambo/api/images/" +
                                      this.props.editStock.frontImage
                                  }
                                />
                                <br />
                              </span>
                            ) : null
                          ) : null
                        }
                      />
                    </span>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                        marginTop: "-0em",
                      }}
                    >
                      I{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock
                          ? this.props.editStock.employeeName
                          : null}{" "}
                        {this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : null}{" "}
                      </span>
                      the accused hereby acknowledge receipt of this document
                      and declare that I understand the contents thereof.
                    </p>

                  </Col>
                  {this.props.editStock
                    && this.props.editStock
                      .refuseReasonNotification ?
                    <Col span={24} style={{ marginTop: "0em" }}>
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Refuse Reason</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "refuseReasonNotification",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock.refuseReasonNotification
                              : "",
                          }
                        )(<TextArea disabled rows={4} />)}
                      </FormItem>
                    </Col>
                    : null}
                </Row>

                <Row style={{ marginTop: "2em" }}>
                  <Col span={24}>
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={this.showModalNotif}
                      // onClick={this.onAddNotification}
                      icon={<MailFilled style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "#009BDF",
                        border: "1px solid #009BDF",

                        height: "3em",
                        width: "12em",
                      }}
                    >
                      Send Notification
                    </Button> */}
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={generateNotificationPdf}
                      icon={<DownloadOutlined style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "rgb(0, 21, 41)",
                        border: "1px solid rgb(0, 21, 41)",
                        // marginLeft: "1em",
                        height: "3em",
                        width: "16em",
                      }}
                    >
                      Download Notification PDF
                    </Button> */}
                    <Modal
                      style={{ top: 20, minWidth: "90vw" }}
                      title={
                        this.props.editStock
                          ? "Alleged Charge(s) " +
                          this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : ""
                      }
                      visible={this.state.visibleChargesNotif}
                      onOk={this.handleOkChargesNotif}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button
                          key="back"
                          type="primary"
                          onClick={this.handleCancel}
                        >
                          Close Annexure “A”
                        </Button>,
                      ]}
                    >
                      <FormItem>
                        {this.props.form.getFieldDecorator("allegedCharges", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.allegedCharges
                            : "",
                        })(
                          <TextArea
                            style={{ color: "black" }}
                            disabled
                            // placeholder="Autosize height with minimum and maximum number of lines"
                            autoSize={{ minRows: 1, maxRows: 200 }}
                          />
                        )}
                      </FormItem>
                    </Modal>
                    <Modal
                      title="Send Notification"
                      visible={this.state.visibleSendNotif}
                      onOk={this.handleSubmitNotif}
                      okText="Send"
                      onCancel={this.handleCancel}
                    >
                      <Select
                        style={{ width: "100%" }}
                        type="emailNotification"
                        // value={this.state.sendEmailNotification}
                        onChange={this.handleEmailChangeNotification}
                        placeholder="Enter Email"
                      >
                        {this.props.sites.flatMap((site, index) =>
                          this.props.editStock ? (
                            this.props.editStock.access === site.name ? (
                              Array.isArray(site.email) ? (
                                site.email.map(email => (
                                  <Option
                                    key={`${email}-${index}`}
                                    value={email.trim()}
                                  >
                                    {email.trim()}
                                  </Option>
                                ))
                              ) : (
                                <Option
                                  key={`${site.email}-${index}`}
                                  value={site.email.trim()}
                                >
                                  {site.email.trim()}
                                </Option>
                              )
                            ) : null
                          ) : null
                        )}
                      </Select>

                      {/* <Input
                        type="emailNotification"
                        value={this.state.sendEmailNotification}
                        onChange={this.handleEmailChangeNotification}
                        placeholder="Enter Email"
                      /> */}
                    </Modal>
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.props.onOk}
                  size="large"
                  style={{
                    position: "fixed",
                    bottom: "6.5em",
                    right: "1em",
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
              <Tooltip placement="left" title="Cancel">
                <Button
                  shape="round"
                  onClick={this.props.onCancel}
                  style={{
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    position: "fixed",
                    bottom: "2em",
                    right: "1em",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "red",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip> */}
              </Form>
            </TabPane>
          ) : null}
          {this.props.editInterview !== "editInterview" &&
            this.props.editInterview !== "addInterview" &&
            this.props.addNewNotification === false ? (
            <TabPane tab="Disciplinary Enquiry" key="Disciplinary Enquiry">
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Disciplinary Enquiry
              </span>{" "}
              <Form>
                <Row style={{ marginTop: "2em" }}>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Company Name</span>}
                    >
                      {this.props.form.getFieldDecorator("companyName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.companyName === "" ||
                            this.props.editStock.companyName === null ||
                            this.props.editStock.companyName === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.companyName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <span>
                      Date and time of disciplinary proceedings
                      <br />
                      {this.props.editStock &&
                        this.props.editStock.casePostponed === "Yes" ? (
                        <span>
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDatePostponed
                              ? moment(
                                this.props.editStock.enquiryDatePostponed
                              ).format("DD MMMM YYYY")
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTimePostponed
                              ? moment(
                                this.props.editStock.enquiryTimePostponed
                              ).format("HH:mm")
                              : null}
                          </span>
                        </span>
                      ) : (
                        <span>
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDate
                              ? moment(this.props.editStock.enquiryDate).format(
                                "DD MMMM YYYY"
                              )
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTime
                              ? moment(this.props.editStock.enquiryTime).format(
                                "HH:mm"
                              )
                              : null}
                          </span>
                        </span>
                      )}
                    </span>
                    <br />
                    {/* <div style={{ display: "inline-flex" }}>
                      <FormItem>
                        {this.props.form.getFieldDecorator(
                          "disciplinaryProceedingsDate",
                          {
                            rules: [
                      
                            ],
                          }
                        )(
                          <DatePicker
                            placeholder="Select Date"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            format={dateFormat}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {this.props.form.getFieldDecorator(
                          "disciplinaryProceedingsTime",
                          {
                            rules: [
           
                            ],
                          }
                        )(
                          <TimePicker
                            style={{ marginLeft: "1em" }}
                            placeholder="Select Time"
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange}
                            format={timeFormat}
                          />
                        )}
                      </FormItem>
                    </div> */}
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Accused Name</span>}
                    >
                      {this.props.form.getFieldDecorator("accusedName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Position</span>}
                    >
                      {this.props.form.getFieldDecorator("jobTitle", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.jobTitle
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Representative</span>}
                    >
                      {this.props.form.getFieldDecorator("representativeName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.representativeName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator("chairpersonName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.chairpersonName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Venue / Place</span>}
                    >
                      {this.props.form.getFieldDecorator("enquiryVenue", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.enquiryVenue === "" ||
                            this.props.editStock.enquiryVenue === null ||
                            this.props.editStock.enquiryVenue === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.enquiryVenue
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                {/* <Col xs={0} md={2} />{" "} */}
                <p style={{ marginBottom: "0em", paddingTop: "2em", borderTop: "1px solid #e9e9e9", }}>
                  Alleged Charge(s)&nbsp;{" "} </p>
                <br />
                {rowsPrepEnquiry}
                {/* <Row
                  style={{
                    // marginTop: "-1em",
                    paddingBottom: "1em",
                  }}
                >
                  <Col xs={24} md={24}>
                    {visiblePrep.length < maxRowsPrep && (
                      <Button
                        style={{ margin: "0.5em", float: "right" }}
                        type="primary"
                        onClick={this.showNextPrep}
                      >
                        <PlusCircleOutlined /> Add Charge
                      </Button>
                    )}
                  </Col>
                </Row> */}

                {/* 
                <Row
                  style={{
                    marginTop: "2em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Plea by the accused: ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.accusedPlea
                            ? this.props.editStock.accusedPlea
                            : " Please select Plea"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "0.5em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator("accusedPlea", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="Guilty">Guilty</Radio.Button>
                          <Radio.Button value="Not Guilty">
                            Not Guilty
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row> */}

                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={
                        <span>Determination and finding by chairperson</span>
                      }
                    >
                      {this.props.form.getFieldDecorator(
                        "determinationFinding",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.determinationFinding
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Date of employment</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "selectedemploymentDate",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? moment(
                              this.props.editStock.selectedemploymentDate
                            ).format("DD MMMM YYYY")
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                    {/* <span
                      style={{ color: "rgb(0, 112, 168)", lineHeight: "3em" }}
                    >
                      {this.props.editStock
                        ? moment(
                            this.props.editStock.selectedemploymentDate
                          ).format("DD MMMM YYYY")
                        : null}
                    </span> */}
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Valid previous transgressions</span>}
                    >
                      {this.props.form.getFieldDecorator("prevTransgressions", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.prevTransgressions
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Mitigation circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "mitigationCircumstances",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.mitigationCircumstances
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Aggrivating circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "aggrivatingCircumstances",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.aggrivatingCircumstances
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict by Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator("chairpersonVerdict", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.chairpersonVerdict
                          : "",
                      })(
                        <Input

                        disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col> */}
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Suspension: ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.suspensionType
                            ? this.props.editStock.suspensionType
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>

                </Row>
                <Row
                  style={{
                    marginTop: "1.5em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Sanction / Warning (Months) : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.sanctionMonthsDisciplinary
                            ? this.props.editStock.sanctionMonthsDisciplinary +
                            " Months /"
                            : ""}
                        </span>{" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock && this.props.editStock.warning
                            ? this.props.editStock.warning
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>


                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict</span>}
                    >
                      {this.props.form.getFieldDecorator("otherWarnings", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.otherWarnings
                          : "",
                      })(<TextArea disabled rows={4} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={14}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>Right to Refer</span>
                      <br />
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        You have the right to refer conciliation within thirty
                        (30) days to relevant forum/council.
                      </span>
                    </span>
                  </Col>
                </Row>

                <Row style={{ marginTop: "1em" }}>
                  <Col xs={24} md={24}>
                    <span>
                      <Cards
                        style={{
                          width: "100%",
                          // border: "1px solid #009bdf",
                          textAlign: "center",
                          marginBottom: 15,
                        }}
                        // extra={
                        //   this.props.editStock ? (
                        //     this.props.editStock.frontImage ? (
                        //       <div
                        //         onClick={e =>
                        //           this.setState({ sigVisible: true })
                        //         }
                        //       >
                        //         <span style={{ color: "red" }}>
                        //           New Signature
                        //         </span>
                        //       </div>
                        //     ) : null
                        //   ) : null
                        // }
                        title={
                          <span style={{ color: "#009bdf" }}>
                            Accused Signature
                          </span>
                        }
                        cover={
                          this.props.editStock ? (
                            this.props.editStock.branchImage &&
                              this.state.sigVisible === false ? (
                              <span>
                                <img
                                  style={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                  alt="example"
                                  src={
                                    this.props.editStock.branchImage.search(
                                      "data"
                                    ) > -1
                                      ? this.props.editStock.branchImage
                                      : process.env.REACT_APP_GLOBAL_SERVER +
                                      "asambo/api/images/" +
                                      this.props.editStock.branchImage
                                  }
                                />
                                <br />
                              </span>
                            ) : null
                          ) : null
                        }
                      />
                    </span>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                        marginTop: "-0em",
                      }}
                    >
                      I{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock
                          ? this.props.editStock.employeeName
                          : null}{" "}
                        {this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : null}{" "}
                      </span>
                      hereby by confirm that I understand the nature of the charges and
                      my right to refer, I furthermore confirm receipt of a copy
                      of this document.
                    </p>

                  </Col>
                  {this.props.editStock
                    && this.props.editStock
                      .refuseReasonDisciplinaryEnquiry ?
                    <Col span={24} style={{ marginTop: "0em" }}>
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Refuse Reason</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "refuseReasonDisciplinaryEnquiry",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock
                                .refuseReasonDisciplinaryEnquiry
                              : "",
                          }
                        )(<TextArea disabled rows={4} />)}
                      </FormItem>
                    </Col>
                    : null}
                </Row>

                <Row style={{ marginTop: "1em" }}>
                  <Col span={24}>
                    {/* <Button
                      type="primary"
                      shape="round"
                      // onClick={this.showModalNotif}
                      onClick={this.showModalEnquiry}
                      icon={<MailFilled style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "#009BDF",
                        border: "1px solid #009BDF",

                        height: "3em",
                        width: "12em",
                      }}
                    >
                      Send Enquiry
                    </Button> */}
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={generateEnquiryPdf}
                      icon={<DownloadOutlined style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "rgb(0, 21, 41)",
                        border: "1px solid rgb(0, 21, 41)",
                        // marginLeft: "1em",
                        height: "3em",
                        width: "14.5em",
                      }}
                    >
                      Download Enquiry PDF
                    </Button> */}

                    <Modal
                      title="Send Disciplinary Enquiry"
                      visible={this.state.visibleSendEnquiry}
                      onOk={this.handleSubmitEnquiry}
                      okText="Send"
                      onCancel={this.handleCancel}
                    >
                      <Select
                        style={{ width: "100%" }}
                        type="emailEnquiry"
                        // value={this.state.sendEmailEnquiry}
                        onChange={this.handleEmailChangeEnquiry}
                        placeholder="Enter Email"
                      >
                        {this.props.sites.flatMap((site, index) =>
                          this.props.editStock ? (
                            this.props.editStock.access === site.name ? (
                              Array.isArray(site.email) ? (
                                site.email.map(email => (
                                  <Option
                                    key={`${email}-${index}`}
                                    value={email.trim()}
                                  >
                                    {email.trim()}
                                  </Option>
                                ))
                              ) : (
                                <Option
                                  key={`${site.email}-${index}`}
                                  value={site.email.trim()}
                                >
                                  {site.email.trim()}
                                </Option>
                              )
                            ) : null
                          ) : null
                        )}
                      </Select>
                      {/* <Input
                        type="emailEnquiry"
                        value={this.state.sendEmailEnquiry}
                        onChange={this.handleEmailChangeEnquiry}
                        placeholder="Enter Email"
                      /> */}
                    </Modal>
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.props.onOk}
                  size="large"
                  style={{
                    position: "fixed",
                    bottom: "6.5em",
                    right: "1em",
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
              <Tooltip placement="left" title="Cancel">
                <Button
                  shape="round"
                  onClick={this.props.onCancel}
                  style={{
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    position: "fixed",
                    bottom: "2em",
                    right: "1em",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "red",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip> */}
              </Form>
            </TabPane>
          ) : null}
          {this.props.editInterview === "editInterview" ||
            this.props.editInterview === "addInterview" ? (
            <TabPane tab="Disciplinary Interview" key="Disciplinary Interview">
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Disciplinary Interview
              </span>
              <Form>
                <Row style={{ marginTop: "2em" }}>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Company Name</span>}
                    >
                      {this.props.form.getFieldDecorator("companyName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.companyName === "" ||
                            this.props.editStock.companyName === null ||
                            this.props.editStock.companyName === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.companyName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />


                  <Col xs={24} md={11}>
                    <span>
                      Date and time of interview
                      {" ("}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock &&
                          this.props.editStock.interviewDate
                          ? moment(this.props.editStock.interviewDate).format(
                            "DD MMMM YYYY"
                          )
                          : " Please set a date"}
                      </span>{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {" "}
                        {this.props.editStock &&
                          this.props.editStock.interviewDate
                          ? moment(this.props.editStock.interviewTime).format(
                            "HH:mm"
                          )
                          : null}
                      </span>
                      {")"}
                    </span>
                    <br />

                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Accused Name</span>}
                    >
                      {this.props.form.getFieldDecorator("accusedName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Position</span>}
                    >
                      {this.props.form.getFieldDecorator("jobTitle", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.jobTitle
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Representative</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "representativeNameInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.representativeNameInterview
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "chairpersonNameInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.chairpersonNameInterview
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Venue / Place</span>}
                    >
                      {this.props.form.getFieldDecorator("venuePlace", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.venuePlace === "" ||
                            this.props.editStock.venuePlace === null ||
                            this.props.editStock.venuePlace === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.venuePlace
                          : "",
                      })(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />{" "} */}
                  {/* <Col xs={24} md={24}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={
                        <span>
                          Alleged Charge(s){" "}
                          <Button
                            style={{ float: "right" }}
                            type="primary"
                            onClick={this.showModalChargesInterview}
                          >
                            View Annexure “A”
                          </Button>
                        </span>
                      }
                    >
                      {this.props.form.getFieldDecorator(
                        "allegedChargesInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.allegedChargesInterview
                            : "",
                        }
                      )(
                        <TextArea
                          // placeholder="Autosize height with minimum and maximum number of lines"
                          autoSize={{ minRows: 1, maxRows: 10 }}
                        />
                      )}
                    </FormItem>
                  </Col> */}
                </Row>
                <p style={{ marginBottom: "0em", paddingTop: "2em", borderTop: "1px solid #e9e9e9", }}>
                  Alleged Charge(s)&nbsp;{" "} </p>
                <br />
                {rowsPrepInterview}

                {/* <Row
                  style={{
                    marginTop: "2em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Plea by the accused: ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.accusedPleaInterview
                            ? this.props.editStock.accusedPleaInterview
                            : " Please select Plea"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "accusedPleaInterview",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          // valuePropName: "checked",
                          // initialValue: this.props.editStock
                          //   ? this.props.editStock.accusedPleaInterview
                          //   : "",
                        }
                      )(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="Guilty">Guilty</Radio.Button>
                          <Radio.Button value="Not Guilty">
                            Not Guilty
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row> */}
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={
                        <span>Determination and finding by chairperson</span>
                      }
                    >
                      {this.props.form.getFieldDecorator(
                        "determinationFindingInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.determinationFindingInterview
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Date of employment</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "selectedemploymentDate",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? moment(
                              this.props.editStock.selectedemploymentDate
                            ).format("DD MMMM YYYY")
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Valid previous transgressions</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "prevTransgressionsInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.prevTransgressionsInterview
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Mitigation circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "mitigationCircumstancesInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock
                              .mitigationCircumstancesInterview
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Aggrivating circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "aggrivatingCircumstancesInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock
                              .aggrivatingCircumstancesInterview
                            : "",
                        }
                      )(
                        <Input

                          disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict by Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "chairpersonVerdictInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.chairpersonVerdictInterview
                            : "",
                        }
                      )(
                        <Input

                        disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col> */}
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Sanction / Warning (Months) : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.sanctionMonthsFinal
                            ? this.props.editStock.sanctionMonthsFinal +
                            " Months /"
                            : ""}
                        </span>{" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.warningFinal
                            ? this.props.editStock.warningFinal
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>


                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict</span>}
                    >
                      {this.props.form.getFieldDecorator("otherWarningsFinal", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.otherWarningsFinal
                          : "",
                      })(<TextArea disabled rows={4} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col xs={24} md={24}>
                    <span>
                      <Cards
                        style={{
                          width: "100%",
                          // border: "1px solid #009bdf",
                          textAlign: "center",
                          marginBottom: 15,
                        }}
                        // extra={
                        //   this.props.editStock ? (
                        //     this.props.editStock.frontImage ? (
                        //       <div
                        //         onClick={e =>
                        //           this.setState({ sigVisible: true })
                        //         }
                        //       >
                        //         <span style={{ color: "red" }}>
                        //           New Signature
                        //         </span>
                        //       </div>
                        //     ) : null
                        //   ) : null
                        // }
                        title={
                          <span style={{ color: "#009bdf" }}>
                            Accused Signature
                          </span>
                        }
                        cover={
                          this.props.editStock ? (
                            this.props.editStock.frontLeftImage &&
                              this.state.sigVisible === false ? (
                              <span>
                                <img
                                  style={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                  alt="example"
                                  src={
                                    this.props.editStock.frontLeftImage.search(
                                      "data"
                                    ) > -1
                                      ? this.props.editStock.frontLeftImage
                                      : process.env.REACT_APP_GLOBAL_SERVER +
                                      "asambo/api/images/" +
                                      this.props.editStock.frontLeftImage

                                  }
                                />
                                <br />
                              </span>
                            ) : null
                          ) : null
                        }
                      />

                    </span>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                        marginTop: "-0em",
                      }}
                    >
                      I{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock
                          ? this.props.editStock.employeeName
                          : null}{" "}
                        {this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : null}{" "}
                      </span>
                      hereby confirm that I understand the nature of the offence
                      as well as the sanction taken above.
                    </p>

                  </Col>
                  {this.props.editStock
                    && this.props.editStock
                      .refuseReasonInterview ?
                    <Col span={24} style={{ marginTop: "0em" }}>
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Refuse Reason</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "refuseReasonInterview",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock.refuseReasonInterview
                              : "",
                          }
                        )(<TextArea disabled rows={4} />)}
                      </FormItem>
                    </Col> : null}
                </Row>

                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "1em",
                  }}
                >
                  <Col xs={11} md={11} style={{}}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Initiator:</span>}
                    >
                      {this.props.form.getFieldDecorator("InitiatorInterview", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.access
                          : "",
                      })(
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: "black",
                            marginTop: "-1.2em",
                          }}
                        >
                          {this.props.editStock
                            ? this.props.editStock.access
                            : null}
                        </p>
                      )}
                    </FormItem>
                    {/* <Signature /> */}
                  </Col>
                  <Col xs={11} md={11} style={{ paddingBottom: "7em" }}>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Chairperson:
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {this.props.editStock
                        ? this.props.editStock.chairpersonNameInterview
                        : null}{" "}
                    </span>
                    {/* <Signature /> */}
                  </Col>
                </Row>
                <Row style={{ marginTop: "-3.5em" }}>
                  <Col span={24}>
                    {/* <Button
                      type="primary"
                      shape="round"
                      // onClick={this.showModalNotif}
                      onClick={this.showModalInterview}
                      icon={<MailFilled style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "#009BDF",
                        border: "1px solid #009BDF",

                        height: "3em",
                        width: "12em",
                      }}
                    >
                      Send Interview
                    </Button> */}
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={generateInterviewPdf}
                      icon={<DownloadOutlined style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "rgb(0, 21, 41)",
                        border: "1px solid rgb(0, 21, 41)",
                        // marginLeft: "1em",
                        height: "3em",
                        width: "15em",
                      }}
                    >
                      Download Interview PDF
                    </Button> */}
                    <Modal
                      style={{ top: 20, minWidth: "90vw" }}
                      title={
                        this.props.editStock
                          ? "Alleged Charge(s) " +
                          this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : ""
                      }
                      visible={this.state.visibleChargesInterview}
                      onOk={this.handleOkChargesInterview}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button
                          key="back"
                          type="primary"
                          onClick={this.handleCancel}
                        >
                          Close Annexure “A”
                        </Button>,
                      ]}
                    >
                      <FormItem>
                        {this.props.form.getFieldDecorator(
                          "allegedChargesInterview",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock.allegedChargesInterview
                              : "",
                          }
                        )(
                          <TextArea
                            style={{ color: "black" }}
                            disabled
                            // placeholder="Autosize height with minimum and maximum number of lines"
                            autoSize={{ minRows: 1, maxRows: 200 }}
                          />
                        )}
                      </FormItem>
                    </Modal>
                    <Modal
                      title="Send Disciplinary Interview"
                      visible={this.state.visibleSendInterview}
                      onOk={this.handleSubmitInterview}
                      okText="Send"
                      onCancel={this.handleCancel}
                    >
                      <Select
                        style={{ width: "100%" }}
                        type="emailInterview"
                        // value={this.state.sendEmailInterview}
                        onChange={this.handleEmailChangeInterview}
                        placeholder="Enter Email"
                      >
                        {this.props.sites.flatMap((site, index) =>
                          this.props.editStock ? (
                            this.props.editStock.access === site.name ? (
                              Array.isArray(site.email) ? (
                                site.email.map(email => (
                                  <Option
                                    key={`${email}-${index}`}
                                    value={email.trim()}
                                  >
                                    {email.trim()}
                                  </Option>
                                ))
                              ) : (
                                <Option
                                  key={`${site.email}-${index}`}
                                  value={site.email.trim()}
                                >
                                  {site.email.trim()}
                                </Option>
                              )
                            ) : null
                          ) : null
                        )}
                      </Select>

                      {/* <Input
                        type="emailInterview"
                        value={this.state.sendEmailInterview}
                        onChange={this.handleEmailChangeInterview}
                        placeholder="Enter Email"
                      /> */}
                    </Modal>
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.props.onOk}
                  size="large"
                  style={{
                    position: "fixed",
                    bottom: "6.5em",
                    right: "1em",
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
              <Tooltip placement="left" title="Cancel">
                <Button
                  shape="round"
                  onClick={this.props.onCancel}
                  style={{
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    position: "fixed",
                    bottom: "2em",
                    right: "1em",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "red",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip> */}
              </Form>
            </TabPane>
          ) : null}
        </Tabs>
      </div >
    );
  }
}
const StockForm = Form.create()(StockFormClass);

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      filteredInfo: null,
      rejectReason: "",
      jobNumbers: [],
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      qty: 1,
      searchQuery: [],
      selectedEmployees: [],
      visiblePrep: [], // Start with an empty array
      qtyError: false,
      itemCodes: [],
      addNewNotification: false,
      siteSelected: "",
      siteSelectedName:
        this.props.sites.length > 0 ? this.props.sites[0].name : "None",
      currentPage: 1,
      totalSites: 0,
      searchQuerySites: "",
    };
    this.handleEmailChangeNotification = this.handleEmailChangeNotification.bind(
      this
    );
    this.handleEmailChangeEnquiry = this.handleEmailChangeEnquiry.bind(this);
    this.handleEmailChangeInterview = this.handleEmailChangeInterview.bind(
      this
    );
  }
  updateSignal;
  async componentDidMount() {
    this.setState({ loading: true });

    try {
      await Promise.all([
        // this.fetchAllEmployeeName(),
        this.fetchAllSites(),
        // this.fetchAllUsers()
      ]);

      this.setState({
        loading: false,
        siteSelected: this.props.sites[0]._id
      });

      // this.updateSignal = {
      //   signal: msg => {
      //     this.fetchAllStock()
      //       .then(() => {
      //         let itemCodes = this.props.stock
      //           .filter(stock => stock.itemCode)
      //           .map(stock => `Part: ${stock.itemCode} - Location: ${stock.location}`);
      //         this.setState({ itemCodes, loading: false });
      //       })
      //       .catch(e => {
      //         this.setState({ loading: false });
      //         Notify("error", e);
      //       });
      //   }
      // };

      //window.pubnub.addListener(this.updateSignal);
      window.addEventListener("resize", this.resizeEvent);

      if (window.cordova) {
        document.addEventListener("backbutton", this.handleBack);
      }
    } catch (error) {
      this.setState({ loading: false });
      Notify("error", error);
    }
  }

  // fetchAllEmployeeName = async () => {
  //   try {
  //     await this.props.findAllEmployeeName(null, this.state.siteSelected);
  //   } catch (error) {
  //     throw new Error(`Error fetching stock: ${error}`);
  //   }
  // };

  fetchAllSites = async () => {
    const { currentPage, searchQuerySites } = this.state;
    try {
      await this.props.findAllSites({ searchQuerySites, page: currentPage, limit: 100000 })
    } catch (error) {
      throw new Error(`Error fetching sites: ${error}`);
    }
  };

  // fetchAllUsers = async () => {
  //   try {
  //     await this.props.findAllUsers();
  //   } catch (error) {
  //     throw new Error(`Error fetching users: ${error}`);
  //   }
  // };

  // Other event listeners and logic...


  handleBack = () => {
    if (this.state.addStockVisible) {
      this.setState({
        addStockVisible: false,
        viewSiteVisible: false,
        viewSelectedEmployee: false,
      });
    } else {
      this.props.history.goBack();
    }
  };
  handleBackToSites = () => {
    this.setState({
      addStockVisible: false,
      viewSiteVisible: false,
      viewSelectedEmployee: false,
      editStock: undefined,
    });
  };
  handleBackToEmployees = site => {
    this.setState({
      // siteSelected: site.name,
      addStockVisible: false,
      viewSiteVisible: true,
      viewSelectedEmployee: false,
      editStock: undefined,
    });
  };
  // componentWillUnmount = () => {
  //   window.pubnub.removeListener(this.updateSignal);
  //   window.removeEventListener("resize", this.resizeEvent);
  //   if (window.cordova) {
  //     document.removeEventListener("backbutton", this.handleBack);
  //   }
  // };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSelectStock = e => {
    this.props.setStockDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/stockdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;
    if (order === "ascend") {
      stock.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  stockForm;
  onAddStock = site => {
    this.setState({
      // siteSelected: site.name,
      addStockVisible: true,
      editStock: undefined,
      addInterviewVisible: null,
      addNewNotification: true,
    });
  };
  onAddStockInterview = site => {
    this.setState({
      // siteSelected: site.name,
      addStockVisible: true,
      editStock: undefined,
      addInterviewVisible: "addInterview",
      addNewNotification: true,
    });
  };
  onAddNotification = e => {
    this.setState({ addNotificationVisible: true });
  };

  onEditStock = (e, site) => {
    this.setState({
      viewSelectedEmployee: true,
      editStock: e,
      // siteSelected: site.name,
    });
  };

  showModalEnquiry = (stock) => {
    this.setState({
      visibleSendEnquiry: true,
      editStock: stock, // Store the stock in the state to use it in the modal
    });
  };
  showModalInterview = (stock) => {
    this.setState({
      visibleSendInterview: true,
      editStock: stock, // Store the stock in the state to use it in the modal
    });
  };
  handleOkEnquiry = e => {
    console.log(e);
    this.setState({
      visibleSendEnquiry: false,
    });
  };
  handleOkNotif = e => {
    console.log(e);
    this.setState({
      visibleSendNotif: false,
    });
  };
  handleOkInterview = e => {
    console.log(e);
    this.setState({
      visibleSendInterview: false,
    });
  };
  handleCancel = e => {
    console.log(e);
    this.setState({
      visibleSendNotif: false,
      visibleSendEnquiry: false,
      visibleSendInterview: false,
    });
  };
  handleSubmitNotif = values => {
    this.onFinishNotif(values);
  };


  handleSubmitEnquiry = values => {
    this.onFinishEnquiry(values);
  };
  onFinishNotif = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.state.editStock.access}`, style: "subheader" },
        { text: `Notification to Attend Enquiry`, style: "subheader" },
        {
          text: `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            }  From: ${this.state.editStock.access}`,
        },
        {
          text: `\n 
          Dear ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            },\n
           Please find notification to attend enquiry below.\n
           Alleged Charge(s): See Annexure “A” Below
           \n Date and time of enquiry: ${moment(
              this.state.editStock.enquiryDate
            ).format("DD MMMM YYYY")}  ${moment(
              this.state.editStock.enquiryTime
            ).format("HH:mm")}
          \n ${this.state.editStock.casePostponed === "Yes"
              ? "Postponed to: " +
              moment(this.state.editStock.enquiryDatePostponed).format(
                "DD MMMM YYYY"
              ) +
              " " +
              moment(this.state.editStock.enquiryTimePostponed).format(
                "HH:mm"
              )
              : "Postponed: No"
            } 
         \n Venue of enquiry: ${this.state.editStock.enquiryVenue}\n
        1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
              this.state.editStock.enquiryDate
            )
              .subtract(1, "day")
              .format("DD MMMM YYYY")} at ${moment(
                this.state.editStock.enquiryTime
              ).format("HH:mm")} of the under mentioned:\n
          Name of your representative: ${this.state.editStock.representativeName !== "" &&
              this.state.editStock.representativeName !== undefined
              ? this.state.editStock.representativeName
              : "______________________________"
            }\n
        Name(s) of your witnesses: ${this.state.editStock.witnessesName !== "" &&
              this.state.editStock.witnessesName !== undefined
              ? this.state.editStock.witnessesName
              : "______________________________"
            }\n 
 Do you intend to make use of a interpreter: ${this.state.editStock.interpreterNeeded !== "" &&
              this.state.editStock.interpreterNeeded !== undefined
              ? this.state.editStock.interpreterNeeded
              : "Yes / No"
            }\n 
 If so n which language? ${this.state.editStock.interpreterLang !== "" &&
              this.state.editStock.interpreterLang !== undefined
              ? this.state.editStock.interpreterLang
              : "______________________________"
            }\n (If an interpreter is required such an interpreter will be allocated by management)\n
          Do you need any time to prepare: ${this.state.editStock.preparationNeeded !== "" &&
              this.state.editStock.preparationNeeded !== undefined
              ? this.state.editStock.preparationNeeded
              : "Yes / No"
            }\n
      1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
      Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
      ${this.state.editStock.frontImage !== undefined
              ? ""
              : "\nAccused Signature: ______________________________ \n"
            }
I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.
          \n Document issued by:
      ${this.state.editStock.access}\n

    
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
        {
          text: "Click here to view signature",
          link:
            process.env.REACT_APP_GLOBAL_SERVER +
            "asambo/api/images/" +
            this.state.editStock.frontImage,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.state.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        htmlStyle: {
          margin: [0, 10, 0, 0],
        },
      },
      defaultStyle: {
        font: "Roboto",
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

      // Send the email with the PDF file as an attachment
      const MSG = {
        to: this.state.sendEmailNotification,
        from: "no-reply@asambo.co.za",
        subject: `Notification to Attend Enquiry (${this.state.editStock.employeeName
          } ${this.state.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Notification to attend enquiry_${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Notification to attend enquiry - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
      };

      // send the email using fetch or any other email sending library
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendNotif: false,
        });
      });
    });
  };
  handleSubmitEnquiry = values => {
    this.onFinishEnquiry(values);
  };
  onFinishEnquiry = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.state.editStock.access}`, style: "header" },
        `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
        }  From: ${this.state.editStock.access}`,
        { text: "Disciplinary Enquiry", style: "subheader" },
        `Please find Disciplinary Enquiry below.
        \n`,
        {
          style: "tableExample",
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.state.editStock.companyName}`],
              [
                `Date and time of proceedings`,
                `${moment(this.state.editStock.enquiryDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.state.editStock.enquiryTime).format("HH:mm")}`,
              ],
              [
                `\n ${this.state.editStock.casePostponed === "Yes"
                  ? "Postponed to "
                  : "Postponed"
                } 
               \n`,
                `\n ${this.state.editStock.casePostponed === "Yes"
                  ? moment(this.state.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.state.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "No"
                } 
               \n`,
              ],
              [`Accused Name`, `${this.state.editStock.accusedName}`],
              [`Position`, `${this.state.editStock.jobTitle}`],
              [`Representative`, `${this.state.editStock.representativeName}`],
              [`Initiator`, `${this.state.editStock.initiatorName}`],
              [`Chairperson`, `${this.state.editStock.chairpersonName}`],
              [`Accused Venue / Place`, `${this.state.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [`Plea by the accused`, `${this.state.editStock.accusedPlea}`],
              [
                `Determination by Chairperson`,
                `${this.state.editStock.determinationFinding}`,
              ],
              [
                `Date of employment`,
                `${this.state.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.state.editStock.prevTransgressions}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.state.editStock.mitigationCircumstances}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.state.editStock.aggrivatingCircumstances}`,
              ],
              [`Suspension`, `${this.state.editStock.suspensionType}`],
              [
                `Sanction / Warning (Months)`,
                `${this.state.editStock.warning +
                " " +
                this.state.editStock.sanctionMonthsDisciplinary +
                " months"}`,
              ],
              [`Verdict`, `${this.state.editStock.otherWarnings}`],
            ],
          },
        },
        {
          text:
            this.state.editStock.refuseSignDisciplinaryEnquiry === true
              ? `\n Refused to sign : ` +
              this.state.editStock.refuseReasonDisciplinaryEnquiry
              : `\n Click here to view signature`,
          link:
            this.state.editStock.refuseSignDisciplinaryEnquiry === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.state.editStock.branchImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.state.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

      // Send the email with the PDF file as an attachment
      const MSG = {
        to: this.state.sendEmailEnquiry,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Enquiry (${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
          })`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Enquiry_${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Enquiry - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
      };

      // send the email using fetch or any other email sending library
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendEnquiry: false,
        });
      });
    });
  };
  handleSubmitInterview = values => {
    this.onFinishInterview(values);
  };
  onFinishInterview = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.state.editStock.access}`, style: "header" },
        `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
        }  From: ${this.state.editStock.access}`,
        { text: "Disciplinary Interview", style: "subheader" },
        {
          text: ` Please find Disciplinary Interview below.
        \n`,
        },

        {
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.state.editStock.companyName}`],
              [
                `Date and time of interview`,
                `${moment(this.state.editStock.interviewDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.state.editStock.interviewTime).format(
                  "HH:mm"
                )}`,
              ],
              [`Accused Name`, `${this.state.editStock.accusedName}`],
              [`Position`, `${this.state.editStock.jobTitle}`],
              [
                `Representative`,
                `${this.state.editStock.representativeNameInterview}`,
              ],
              [`Initiator`, `${this.state.editStock.InitiatorInterview}`],
              [
                `Chairperson`,
                `${this.state.editStock.chairpersonNameInterview}`,
              ],
              [`Accused Venue / Place`, `${this.state.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [
                `Plea by the accused`,
                `${this.state.editStock.accusedPleaInterview}`,
              ],
              [
                `Determination by Chairperson`,
                `${this.state.editStock.determinationFindingInterview}`,
              ],
              [
                `Date of employment`,
                `${this.state.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.state.editStock.prevTransgressionsInterview}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.state.editStock.mitigationCircumstancesInterview}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.state.editStock.aggrivatingCircumstancesInterview}`,
              ],
              [
                `Sanction / Warning (Months)`,
                `${this.state.editStock.sanctionMonthsFinal + " months"}`,
              ],
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
              },
            },
          },
        },
        {
          text:
            this.state.editStock.refuseSignInterview === true
              ? `\n Refused to sign : ` +
              this.state.editStock.refuseReasonInterview
              : `\n Click here to view signature`,
          link:
            this.state.editStock.refuseSignInterview === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.state.editStock.frontLeftImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.state.editStock.allegedChargesInterview}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

      // Send the email with the PDF file as an attachment
      const MSG = {
        to: this.state.sendEmailInterview,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Interview (${this.state.editStock.employeeName
          } ${this.state.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Interview_${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Interview - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
      };

      // send the email using fetch or any other email sending library
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendInterview: false,
        });
      });
    });
  };
  handleSubmitInterview = values => {
    this.onFinishInterview(values);
  };

  handleEmailChangeNotification = value => {
    this.setState({ sendEmailNotification: value });
  };
  handleEmailChangeEnquiry = value => {
    this.setState({ sendEmailEnquiry: value });
  };
  handleEmailChangeInterview = value => {
    this.setState({ sendEmailInterview: value });
  };
  onEditEmployee = e => {
    this.setState({
      addStockVisible: true,
      editStock: e,
      sigVisible: false,
      addInterviewVisible: "none",
      addNewNotification: false,
    });
  };
  onEditSendNotif = e => {
    this.setState({
      visibleSendNotif: true,
      editStock: e,
    });
  };
  onEditEmployeeInterview = e => {
    this.setState({
      addInterviewVisible: "editInterview",
      addStockVisible: true,
      editStock: e,
      addNewNotification: false,
    });
  };
  onEditSendInterview = e => {
    this.setState({
      visibleSendInterview: true,
      editStock: e,
    });
  };
  onViewSite = site => {
    this.setState(
      { siteSelected: site.name, viewSiteVisible: true, selectedEmployees: [] },
      () => {
        // After updating state, call the Redux action
        this.props.findAllEmployeeName(null, site.name);
      }
    );
  };
  handleCancelAll = e => {
    this.setState({ loading: true });
    this.props
      .findAllStock(this.state.selectedEmployees)
      .then(() => {
        this.stockForm.props.form.resetFields();

        this.setState({
          loading: false,
          addStockVisible: false,
          viewSiteVisible: true,
          viewSelectedEmployee: false,
          editStock: undefined,
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  handleAddStock = e => {
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // const formData = new FormData();

        this.setState({ loading: true });
        values.myRecordingNotification = this.stockForm.state.myRecordingNotification;
        values.myRecordingInterview = this.stockForm.state.myRecordingInterview;
        values.branchImage = this.stockForm.state.branchImage;
        values.frontRightImage = this.stockForm.state.frontRightImage;
        values.rightSideImage = this.stockForm.state.rightSideImage;
        values.wheelsImage = this.stockForm.state.wheelsImage;
        values.frontLeftImage = this.stockForm.state.frontLeftImage;
        values.frontImage = this.stockForm.state.frontImage;

        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock(this.state.selectedEmployees)
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved");
                } else {
                  Notify("success", "Successfully added");
                }

                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                // this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  editStock: undefined,
                  addStockVisible: false,
                  viewSiteVisible: true,
                  viewSelectedEmployee: false,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  onSaveRecording = e => {

    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // const formData = new FormData();

        this.setState({ loading: true });
        values.myRecordingNotification = this.stockForm.state.myRecordingNotification;
        values.myRecordingInterview = this.stockForm.state.myRecordingInterview;
        values.branchImage = this.stockForm.state.branchImage;
        values.frontRightImage = this.stockForm.state.frontRightImage;
        values.rightSideImage = this.stockForm.state.rightSideImage;
        values.wheelsImage = this.stockForm.state.wheelsImage;
        values.frontLeftImage = this.stockForm.state.frontLeftImage;
        values.frontImage = this.stockForm.state.frontImage;

        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }
        this.props
          .addStock(values)
          .then(() => {
            this.props
              .findAllStock(this.state.selectedEmployees)
              .then(() => {
                if (this.state.editStock) {
                  Notify("success", "Successfully saved");
                } else {
                  Notify("success", "Successfully added");
                }

                this.stockForm.props.form.resetFields();
                let itemCodes = [];
                this.props.stock.forEach(stock => {
                  if (stock.itemCode) {
                    itemCodes.push(
                      "Part: " +
                      stock.itemCode +
                      " - Location: " +
                      stock.location
                    );
                  }
                });
                // this.setState({ itemCodes: itemCodes });
                this.setState({
                  loading: false,
                  editStock: undefined,
                  addStockVisible: false,
                  viewSiteVisible: true,
                  viewSelectedEmployee: false,
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };
  // handleAddNotification = e => {
  //   e.preventDefault();
  //   this.notificationForm.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       this.setState({ loading: true });
  //       if (this.state.editStock) {
  //         values._id = this.state.editStock._id;
  //       }
  //       this.props
  //         .addStock(values)
  //         .then(() => {
  //           this.props
  //             .findAllStock(this.state.selectedEmployees)
  //             .then(() => {
  //               if (this.state.editStock) {
  //                 Notify("success", "Successfully saved");
  //               } else {
  //                 Notify("success", "Successfully added");
  //               }
  //               this.notificationForm.props.form.resetFields();
  //               let itemCodes = [];
  //               this.props.stock.forEach(stock => {
  //                 if (stock.itemCode) {
  //                   itemCodes.push(
  //                     "Part: " +
  //                       stock.itemCode +
  //                       " - Location: " +
  //                       stock.location
  //                   );
  //                 }
  //               });
  //               this.setState({ itemCodes: itemCodes });
  //               this.setState({
  //                 loading: false,
  //                 addStockVisible: false,
  //                 viewSiteVisible: true,
  //                 viewSelectedEmployee: false,
  //                 editStock: undefined,
  //               });
  //             })
  //             .catch(e => {
  //               this.setState({ loading: false });
  //               Notify("error", e);
  //             });
  //         })
  //         .catch(e => {
  //           this.setState({ loading: false });
  //           Notify("error", e);
  //         });
  //     }
  //   });
  // };
  // handleAddDisciplinaryEnquiry = e => {
  //   e.preventDefault();
  //   this.disciplinaryEnquiryForm.props.form.validateFieldsAndScroll(
  //     (err, values) => {
  //       if (!err) {
  //         this.setState({ loading: true });
  //         if (this.state.editStock) {
  //           values._id = this.state.editStock._id;
  //         }
  //         this.props
  //           .addStock(values)
  //           .then(() => {
  //             this.props
  //               .findAllStock(this.state.selectedEmployees)
  //               .then(() => {
  //                 if (this.state.editStock) {
  //                   Notify("success", "Successfully saved");
  //                 } else {
  //                   Notify("success", "Successfully added");
  //                 }
  //                 this.disciplinaryEnquiryForm.props.form.resetFields();
  //                 let itemCodes = [];
  //                 this.props.stock.forEach(stock => {
  //                   if (stock.itemCode) {
  //                     itemCodes.push(
  //                       "Part: " +
  //                         stock.itemCode +
  //                         " - Location: " +
  //                         stock.location
  //                     );
  //                   }
  //                 });
  //                 this.setState({ itemCodes: itemCodes });
  //                 this.setState({
  //                   loading: false,
  //                   addStockVisible: false,
  //                   viewSiteVisible: true,
  //                   viewSelectedEmployee: false,
  //                   editStock: undefined,
  //                 });
  //               })
  //               .catch(e => {
  //                 this.setState({ loading: false });
  //                 Notify("error", e);
  //               });
  //           })
  //           .catch(e => {
  //             this.setState({ loading: false });
  //             Notify("error", e);
  //           });
  //       }
  //     }
  //   );
  // };
  // handleAddDisciplinaryInterview = e => {
  //   e.preventDefault();
  //   this.disciplinaryInterviewForm.props.form.validateFieldsAndScroll(
  //     (err, values) => {
  //       if (!err) {
  //         this.setState({ loading: true });
  //         if (this.state.editStock) {
  //           values._id = this.state.editStock._id;
  //         }
  //         this.props
  //           .addStock(values)
  //           .then(() => {
  //             this.props
  //               .findAllStock(this.state.selectedEmployees)
  //               .then(() => {
  //                 if (this.state.editStock) {
  //                   Notify("success", "Successfully saved");
  //                 } else {
  //                   Notify("success", "Successfully added");
  //                 }
  //                 this.disciplinaryInterviewForm.props.form.resetFields();
  //                 let itemCodes = [];
  //                 this.props.stock.forEach(stock => {
  //                   if (stock.itemCode) {
  //                     itemCodes.push(
  //                       "Part: " +
  //                         stock.itemCode +
  //                         " - Location: " +
  //                         stock.location
  //                     );
  //                   }
  //                 });
  //                 this.setState({ itemCodes: itemCodes });
  //                 this.setState({
  //                   loading: false,
  //                   addStockVisible: false,
  //                   viewSiteVisible: true,
  //                   viewSelectedEmployee: false,
  //                   editStock: undefined,
  //                 });
  //               })
  //               .catch(e => {
  //                 this.setState({ loading: false });
  //                 Notify("error", e);
  //               });
  //           })
  //           .catch(e => {
  //             this.setState({ loading: false });
  //             Notify("error", e);
  //           });
  //       }
  //     }
  //   );
  // };
  handleDeleteStock = e => {
    this.setState({ loading: true, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock(this.state.selectedEmployees)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted", "Successfully deleted.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };
  checkQty = e => {
    let setQty = e;
    if (setQty !== "" && setQty !== null) {
      if (this.state.stockOut.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true,
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false,
        });
      }
    } else {
      this.setState({
        qty: 1,
        qtyError: false,
      });
    }
  };
  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  viewRig = rig => {
    this.setState({ currentRig: rig, viewRig: true });
  };
  handleSearchName = () => {
    if (this.state.selectedEmployees.length > 0) {
      this.setState({ loading: true }); // Set loading state to true before API call
      this.props
        .findAllStock(this.state.selectedEmployees)
        .then(() => {
          this.setState({ loading: false }); // Set loading state to false after API call
        })
        .catch(e => {
          this.setState({ loading: false });
          Notify("error", e);
        });
    }
  };

  render() {
    const { visiblePrep } = this.state;
    async function doesImageExist(url) {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok; // Returns true if the image exists, false otherwise
    }
    async function imageUrlToBase64(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    const onFinishNotif = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.state.editStock.frontImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [180, 180],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const chargeContent = [];

        for (let i = 1; i <= 20; i++) {
          const chargeKey = `allegedCharges${i}`;
          const chargeValue = this.state.editStock[chargeKey];
          const pleaKey = `accusedPlea${i}`;
          const pleaValue = this.state.editStock[pleaKey];

          if (chargeValue) {
            chargeContent.push(
              {
                text: `Alleged Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: chargeValue,
                style: 'tableChargeContent',
              },
              {
                text: `Accused's Plea to Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: pleaValue, // Change pleaKey to pleaValue here
                style: 'tableChargeContent',
              }
            );
          }
        }
        const docDefinition = {
          content: [
            { text: `${this.state.editStock.access}`, style: "subheader" },
            { text: `Notification to Attend Enquiry`, style: "subheader" },
            {
              text: `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                }  From: ${this.state.editStock.access}`,
            },
            {
              text: `
            Dear ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                },\n
             Please find notification to attend enquiry below.\n
             Alleged Charge(s): See Annexure “A” Below 
             \n Date and time of enquiry: ${moment(
                  this.state.editStock.enquiryDate
                ).format("DD MMMM YYYY")}  ${moment(
                  this.state.editStock.enquiryTime
                ).format("HH:mm")}
            \n ${this.state.editStock.casePostponed === "Yes"
                  ? "Postponed to: " +
                  moment(this.state.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.state.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "Postponed: No"
                } 
           \n Venue of enquiry: ${this.state.editStock.enquiryVenue}\n
          1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
                  this.state.editStock.enquiryDate
                )
                  .subtract(1, "day")
                  .format("DD MMMM YYYY")} at ${moment(
                    this.state.editStock.enquiryTime
                  ).format("HH:mm")} of the under mentioned:\n
            Name of your representative: ${this.state.editStock.representativeName !== "" &&
                  this.state.editStock.representativeName !== undefined
                  ? this.state.editStock.representativeName
                  : "______________________________"
                }\n
          Name(s) of your witnesses: ${this.state.editStock.witnessesName !== "" &&
                  this.state.editStock.witnessesName !== undefined
                  ? this.state.editStock.witnessesName
                  : "______________________________"
                }\n 
   Do you intend to make use of a interpreter: ${this.state.editStock.interpreterNeeded !== "" &&
                  this.state.editStock.interpreterNeeded !== undefined
                  ? this.state.editStock.interpreterNeeded
                  : "Yes / No"
                }\n 
   If so n which language? ${this.state.editStock.interpreterLang !== "" &&
                  this.state.editStock.interpreterLang !== undefined
                  ? this.state.editStock.interpreterLang
                  : "______________________________"
                }\n (If an interpreter is required such an interpreter will be allocated by management)\n
            Do you need any time to prepare: ${this.state.editStock.preparationNeeded !== "" &&
                  this.state.editStock.preparationNeeded !== undefined
                  ? this.state.editStock.preparationNeeded
                  : "Yes / No"
                }\n
        1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
        Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
            \n Document issued by: ${this.state.editStock.access}
        
          `,
              style: "htmlStyle",
              margin: [0, 0, 0, 0],
            },
            {
              text:
                this.state.editStock.refuseReasonNotification === true
                  ? `\n Refused to sign : ` +
                  this.state.editStock.refuseReasonNotification
                  : null,
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.state.editStock.frontImage !== undefined ? images : null,
            this.state.editStock.frontImage !== undefined ? { text: `_______________________________________`, style: "subheader" } : null,
            this.state.editStock.frontImage !== undefined ? {
              text: `I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.`,
            } : null,
            this.state.editStock.frontImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________________ \n  
            I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                  } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.`,
              },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...chargeContent,
            this.state.editStock.finalRuling ?
              { text: `Ruling`, pageBreak: "before", style: "subheader" } : null, // Add a new page
            this.state.editStock.finalRuling ?
              {
                text: `
            ${this.state.editStock.finalRuling}
          `,
                style: "htmlStyle",
                margin: [0, 10, 0, 0],
              } : null,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
          const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

          // Send the email with the PDF file as an attachment
          const MSG = {
            to: this.state.sendEmailNotification,
            from: "no-reply@asambo.co.za",
            subject: `Notification to Attend Enquiry (${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName})`,
            text: " ",
            attachments: [
              {
                filename: `Notification to attend enquiry_${this.state.editStock.employeeName
                  } ${this.state.editStock.employeeLastName}.pdf`,
                content: pdfData,
                type: "application/pdf",
                disposition: "attachment",
              },
            ],
            html: `Dear Client, 

        please find attached Notification to attend enquiry - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
          };

          // send the email using fetch or any other email sending library
          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          }).then(async res => {
            if (!res.ok) {
              console.log("handle error");
            }
            console.log("handle success");
            this.setState({
              visibleSendNotif: false,
            });
          });
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const onFinishEnquiry = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.state.editStock.branchImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [180, 180],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const chargeContent = [];

        for (let i = 1; i <= 20; i++) {
          const chargeKey = `allegedCharges${i}`;
          const chargeValue = this.state.editStock[chargeKey];
          const pleaKey = `accusedPlea${i}`;
          const pleaValue = this.state.editStock[pleaKey];

          if (chargeValue) {
            chargeContent.push(
              {
                text: `Alleged Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: chargeValue,
                style: 'tableChargeContent',
              },
              {
                text: `Accused's Plea to Charge ${i}: ${pleaValue}`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },

            );
          }
        }
        const docDefinition = {
          content: [
            { text: `${this.state.editStock.access}`, style: "header" },
            `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            }  From: ${this.state.editStock.access}`,
            { text: "Disciplinary Enquiry", style: "subheader" },
            {
              text: ` Please find Disciplinary Enquiry below.
            \n`,
            },

            {
              table: {
                body: [
                  [`Description`, `Details`],
                  [`Company Name`, `${this.state.editStock.companyName}`],
                  [
                    `Date and time of proceedings`,
                    `${moment(this.state.editStock.enquiryDate).format(
                      "DD MMMM YYYY"
                    )} ${moment(this.state.editStock.enquiryTime).format("HH:mm")}`,
                  ],
                  [
                    `\n ${this.state.editStock.casePostponed === "Yes"
                      ? "Postponed to "
                      : "Postponed"
                    } 
                   \n`,
                    `\n ${this.state.editStock.casePostponed === "Yes"
                      ? moment(this.state.editStock.enquiryDatePostponed).format(
                        "DD MMMM YYYY"
                      ) +
                      " " +
                      moment(this.state.editStock.enquiryTimePostponed).format(
                        "HH:mm"
                      )
                      : "No"
                    } 
                   \n`,
                  ],
                  [`Accused Name`, `${this.state.editStock.accusedName}`],
                  [`Position`, `${this.state.editStock.jobTitle}`],
                  [`Representative`, `${this.state.editStock.representativeName}`],
                  [`Initiator`, `${this.state.editStock.initiatorName}`],
                  [`Chairperson`, `${this.state.editStock.chairpersonName}`],
                  [`Accused Venue / Place`, `${this.state.editStock.access}`],
                  [`Alleged Charge(s)`, `See Annexure “A” Below `],

                  [
                    `Determination by Chairperson`,
                    `${this.state.editStock.determinationFinding}`,
                  ],
                  [
                    `Date of employment`,
                    `${this.state.editStock.selectedemploymentDate}`,
                  ],
                  [
                    `Valid previous transgressions`,
                    `${this.state.editStock.prevTransgressions}`,
                  ],
                  [
                    `Mitigation circumstances`,
                    `${this.state.editStock.mitigationCircumstances}`,
                  ],
                  [
                    `Aggrivating circumstances`,
                    `${this.state.editStock.aggrivatingCircumstances}`,
                  ],
                  [`Suspension`, `${this.state.editStock.suspensionType}`],
                  [
                    `Sanction / Warning (Months)`,
                    `${this.state.editStock.warning +
                    " " +
                    this.state.editStock.sanctionMonthsDisciplinary +
                    " months"}`,
                  ],
                  [`Verdict`, `${this.state.editStock.otherWarnings}`],
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: "black",
                  },
                },
              },
            },
            {
              text:
                this.state.editStock.refuseSignDisciplinaryEnquiry === true
                  ? `\n Refused to sign : ` +
                  this.state.editStock.refuseReasonDisciplinaryEnquiry
                  : null,
              // link:
              //   this.state.editStock.refuseSignDisciplinaryEnquiry === false
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //     "asambo/api/images/" +
              //     this.state.editStock.branchImage
              //     : null,
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.state.editStock.branchImage !== undefined ? images : null,
            this.state.editStock.branchImage !== undefined ? { text: `_______________________________________`, style: "subheader" } : null,
            this.state.editStock.branchImage !== undefined ? {
              text: `I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                } hereby by confirm that I understand the nature of the charges and my right to refer, I furthermore confirm receipt of a copy of this document.`,
            } : null,
            this.state.editStock.branchImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________________ \n  
          I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                  } hereby by confirm that I understand the nature of the charges and my right to refer, I furthermore confirm receipt of a copy of this document.`,
              },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...chargeContent,
            this.state.editStock.finalRuling ?
              { text: `Ruling`, pageBreak: "before", style: "subheader" } : null, // Add a new page
            this.state.editStock.finalRuling ?
              {
                text: `
        ${this.state.editStock.finalRuling}
      `,
                style: "htmlStyle",
                margin: [0, 10, 0, 0],
              } : null,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
          const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

          // Send the email with the PDF file as an attachment
          const MSG = {
            to: this.state.sendEmailEnquiry,
            from: "no-reply@asambo.co.za",
            subject: `Disciplinary Enquiry (${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
              })`,
            text: " ",
            attachments: [
              {
                filename: `Disciplinary Enquiry_${this.state.editStock.employeeName
                  } ${this.state.editStock.employeeLastName}.pdf`,
                content: pdfData,
                type: "application/pdf",
                disposition: "attachment",
              },
            ],
            html: `Dear Client, 
    
            please find attached Disciplinary Enquiry - 
    
            To: ${this.state.editStock.employeeName} 
    
            From: ${this.state.editStock.access} `,
          };

          // send the email using fetch or any other email sending library
          // send the email using fetch or any other email sending library
          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          }).then(async res => {
            if (!res.ok) {
              console.log("handle error");
            }
            console.log("handle success");
            this.setState({
              visibleSendEnquiry: false,
            });
          });
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const onFinishInterview = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.state.editStock.frontLeftImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [180, 180],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const chargeContent = [];

        for (let i = 1; i <= 20; i++) {
          const chargeKey = `allegedChargesInterview${i}`;
          const chargeValue = this.state.editStock[chargeKey];
          const pleaKey = `accusedPleaInterview${i}`;
          const pleaValue = this.state.editStock[pleaKey];

          if (chargeValue) {
            chargeContent.push(
              {
                text: `Alleged Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: chargeValue,
                style: 'tableChargeContent',
              },
              {
                text: `Accused's Plea to Charge ${i}: ${pleaValue}`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
            );
          }
        }
        const docDefinition = {

          content: [
            { text: `${this.state.editStock.access}`, style: "header" },
            `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            }  From: ${this.state.editStock.access}`,
            { text: "Disciplinary Interview", style: "subheader" },
            {
              text: ` Please find Disciplinary Interview below.
              \n`,
            },

            {
              table: {
                body: [
                  [`Description`, `Details`],
                  [`Company Name`, `${this.state.editStock.companyName}`],
                  [
                    `Date and time of interview`,
                    `${moment(this.state.editStock.interviewDate).format(
                      "DD MMMM YYYY"
                    )} ${moment(this.state.editStock.interviewTime).format(
                      "HH:mm"
                    )}`,
                  ],
                  [`Accused Name`, `${this.state.editStock.accusedName}`],
                  [`Position`, `${this.state.editStock.jobTitle}`],
                  [
                    `Representative`,
                    `${this.state.editStock.representativeNameInterview}`,
                  ],
                  [`Initiator`, `${this.state.editStock.InitiatorInterview}`],
                  [
                    `Chairperson`,
                    `${this.state.editStock.chairpersonNameInterview}`,
                  ],
                  [`Accused Venue / Place`, `${this.state.editStock.access}`],
                  [`Alleged Charge(s)`, `See Annexure “A” Below `],
                  [
                    `Determination by Chairperson`,
                    `${this.state.editStock.determinationFindingInterview}`,
                  ],
                  [
                    `Date of employment`,
                    `${this.state.editStock.selectedemploymentDate}`,
                  ],
                  [
                    `Valid previous transgressions`,
                    `${this.state.editStock.prevTransgressionsInterview}`,
                  ],
                  [
                    `Mitigation circumstances`,
                    `${this.state.editStock.mitigationCircumstancesInterview}`,
                  ],
                  [
                    `Aggrivating circumstances`,
                    `${this.state.editStock.aggrivatingCircumstancesInterview}`,
                  ],
                  [
                    `Sanction / Warning (Months)`,
                    `${this.state.editStock.sanctionMonthsFinal + " months"}`,
                  ],
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: "black",
                  },
                },
              },
            },
            {
              text:
                this.state.editStock.refuseSignInterview === true
                  ? `\n Refused to sign : ` +
                  this.state.editStock.refuseReasonInterview
                  : null,
              // link:
              //   this.state.editStock.refuseSignDisciplinaryEnquiry === false
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //     "asambo/api/images/" +
              //     this.state.editStock.branchImage
              //     : null,
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.state.editStock.frontLeftImage !== undefined ? images : null,
            this.state.editStock.frontLeftImage !== undefined ? { text: `_______________________________________`, style: "subheader" } : null,
            this.state.editStock.frontLeftImage !== undefined ? {
              text: `I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                } hereby confirm that I understand the nature of the offence as well as the sanction taken above.`,
            } : null,
            this.state.editStock.frontLeftImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________________ \n  
          I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                  } hereby confirm that I understand the nature of the offence as well as the sanction taken above.`,
              },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...chargeContent,
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
          },
          defaultStyle: {
            // alignment: 'justify'
          },
        };

        pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
          const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

          // Send the email with the PDF file as an attachment
          const MSG = {
            to: this.state.sendEmailInterview,
            from: "no-reply@asambo.co.za",
            subject: `Disciplinary Interview (${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName})`,
            text: " ",
            attachments: [
              {
                filename: `Disciplinary Interview_${this.state.editStock.employeeName
                  } ${this.state.editStock.employeeLastName}.pdf`,
                content: pdfData,
                type: "application/pdf",
                disposition: "attachment",
              },
            ],
            html: `Dear Client, 
      
            please find attached Disciplinary Interview - 
      
            To: ${this.state.editStock.employeeName} 
      
            From: ${this.state.editStock.access} `,
          };

          // send the email using fetch or any other email sending library
          // send the email using fetch or any other email sending library
          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          }).then(async res => {
            if (!res.ok) {
              console.log("handle error");
            }
            console.log("handle success");
            this.setState({
              visibleSendInterview: false,
            });
          });
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    function EmployeeFilter(props, rigs) {
      return props.employeeName === props.employeeName;
    }
    let uniqueItems = [];


    this.props.stock.forEach(rig => {
      let existingItem = uniqueItems.find(
        uniqueItem =>
          uniqueItem.employeeName === rig.employeeName &&
          uniqueItem.employeeLastName === rig.employeeLastName
      );

      if (!existingItem && rig.access === this.state.siteSelected) {
        uniqueItems.push(rig);
      }
    });
    const { stock, employees } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    let technicianOptions = this.state.technicians.map(d => (
      <Option key={d.username} value={d.name}>
        {d.name + " - " + d.username.split("|")[1]}
      </Option>
    ));
    let unRigs = [];
    this.props.stock.forEach(rig => {
      if (rig.access === this.state.siteSelected) {
        unRigs.push(rig);
      }
    });
    let rigs = [];
    let unProcessedRigs = JSON.parse(JSON.stringify(unRigs));
    unProcessedRigs.forEach(rig => {
      if (rig.cost) {
        rig.cost.forEach((cost, index) => {
          if (
            moment(cost.date).isBetween(
              this.state.startDate,
              this.state.endDate,
              moment.startOf,
              "[]"
            )
          ) {
            rig.totalCost =
              rig.totalCost && rig.totalCost !== "None"
                ? rig.totalCost + parseFloat(cost.price)
                : parseFloat(cost.price);
          } else {
            rig.cost.splice(index, 1);
          }
        });
        rigs.push(rig);
      }
      if (!rig.cost) {
        rigs.push(rig);
      }
    });
    let totalCost = 0;
    let totalMeters = 0;
    rigs.forEach(rig => {
      if (!rig.totalCost) {
        rig.totalCost = 0;
      }
      totalCost = totalCost + rig.totalCost;
      totalMeters = totalMeters + (rig.meters ? rig.meters : 0);
    });
    let currentRig;
    let currentRigTotal = 0;
    if (this.state.currentRig) {
      currentRig = rigs.find(rig => rig._id === this.state.currentRig._id);
      currentRigTotal = currentRig.totalCost;
    }
    const filteredSites = this.props.sites.filter(site => {
      return this.props.user.access.includes(site._id);
    });

    // const interviewDate = moment(stock.interviewDate).add(
    //   stock.sanctionMonthsFinal,
    //   "month"
    // );
    // const editInterviewDate = moment(stock.interviewDate);
    // const isLater = interviewDate.isAfter(editInterviewDate);

    // const enquiryDates = moment(stock.enquiryDate).subtract(
    //   stock.sanctionMonthsDisciplinary,
    //   "month"
    // );
    // const editEnquiryDate = moment(stock.enquiryDate);
    // const isLaterEnquiry = editEnquiryDate.isAfter(enquiryDates);
    if (!this.state.viewRig) {
      return (
        <div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              textAlign: "center",
              maxWidth: "90em",
              margin: "auto",
              display:
                !this.state.viewSiteVisible &&
                  !this.state.addStockVisible &&
                  !this.state.viewSelectedEmployee
                  ? "flex"
                  : "none",
            }}
          >
            <span style={{ fontWeight: 750, fontSize: 25 }}>
              Employee Management
            </span>{" "}
          </Row>

          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              textAlign: "center",
              maxWidth: "90em",
              margin: "auto",
              display:
                this.state.viewSiteVisible &&
                  !this.state.addStockVisible &&
                  !this.state.viewSelectedEmployee
                  ? "flex"
                  : "none",
            }}
          >
            <Col xs={24} md={14} style={{ textAlign: "left" }}>
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleBackToSites}
                  size="large"
                  style={{
                    fontSize: "14px",
                    width: "3em",
                    height: "3em",
                    //height: "3em",
                    marginRight: "0.4em",
                    padding: 0,
                  }}
                >
                  <ArrowLeftOutlined />
                </Button>{" "}
                {this.state.siteSelected} Employees
              </span>{" "}
            </Col>

          </Row>
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              textAlign: "center",
              maxWidth: "90em",
              margin: "auto",
              display:
                this.state.viewSelectedEmployee && !this.state.addStockVisible
                  ? "flex"
                  : "none",
            }}
          >
            {/* <Col xs={24} md={18} style={{ textAlign: "left" }}>
              <StyledSelect
                value={this.state.siteSelected}
                onChange={e => {
                  this.setState({ siteSelected: e });
                }}
                defaultValue={this.state.siteSelected}
                style={{ height: 55, marginLeft: "1em" }}
              >
                {this.props.sites.map(site => (
                  <Option value={site.name}>{site.name}</Option>
                ))}
              </StyledSelect>
              <br /> <br />
              <Button
                type="primary"
                shape="round"
                onClick={this.onAddNotification}
                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                size="large"
                style={{
                  marginRight: "0.5em",
                  height: "3em",
                  width: "12em",
                }}
              >
                Add Notification
              </Button>
            </Col> */}
          </Row>
          <div style={{ maxWidth: "90em", margin: "auto" }}>
            <Spin spinning={this.state.loading}>
              {this.state.addStockVisible ? (
                <Row
                  style={{
                    background: "white",
                    borderRadius: 5,
                    marginTop: 20,
                    display:
                      this.state.addStockVisible ||
                        this.state.addNotificationVisible
                        ? "contents"
                        : "none",
                  }}
                // type="flex"
                // justify="space-around"
                // align="middle"
                >
                  {/* <h1
                    style={{
                      fontWeight: 750,
                      fontSize: 20,
                      float: "right",
                      marginLeft: "-7em",
                    }}
                  >
                    {this.state.editStock
                      ? this.state.editStock.employeeName +
                      " " +
                      this.state.editStock.employeeLastName
                      : null}
                  </h1> */}

                  <StockForm
                    editStock={this.state.editStock}
                    addNewNotification={this.state.addNewNotification}
                    editInterview={this.state.addInterviewVisible}
                    sites={this.props.sites}
                    users={this.props.users}
                    allSitesSelected={this.state.siteSelected}
                    stock={this.props.stock}
                    onSaveRecording={e => this.onSaveRecording(e)}
                    wrappedComponentRef={form => (this.stockForm = form)}
                    // onOk={this.handleAddStock}
                    // onCancel={e =>
                    //   this.setState({
                    //     addStockVisible: false,
                    //     viewSiteVisible: true,
                    //     viewSelectedEmployee: false,
                    //   })
                    // }
                    okText={this.state.editStock ? "Save" : "Add Stock"}
                  />

                  <Tooltip placement="left" title="Cancel">
                    <Button
                      shape="round"
                      onClick={this.handleCancelAll}
                      style={{
                        fontSize: "14px",
                        width: "4em",
                        height: "4em",
                        position: "fixed",
                        bottom: "5em",
                        right: "1em",
                        border: "1px solid #EEEFF0",
                        backgroundColor: "red",
                        color: "white"
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                  </Tooltip>
                </Row>
              )
                : (
                  <span>
                    <Row
                      style={{
                        background: "white",
                        borderRadius: 5,
                        marginTop: 20,
                        display: !this.state.viewSiteVisible ? "flex" : "none",
                      }}
                      type="flex"
                      justify="start"
                      align="middle"
                    >
                      <Col span={24}>
                        <StyledTable
                          style={{ background: "white" }}
                          rowKey="_id"
                          size="middle"
                          pagination={{
                            defaultPageSize: 25,
                          }}
                          expandedRowRender={
                            window.innerWidth <= 420
                              ? record => (
                                <Card
                                  size="small"
                                  title={record.itemCode}
                                // style={{ width: 300, marginLeft: -35 }}
                                >
                                  <p>Contact Name: {record.contactName}</p>
                                  <p>Number: {record.number}</p>
                                  <p>Address: {record.address}</p>
                                </Card>
                              )
                              : null
                          }
                          dataSource={filteredSites}
                          onChange={this.handleSort}
                          onRow={record => {
                            if (parseInt(record.qty) === 0) {
                              return { style: { background: "#ffd3d3" } };
                            }
                          }}
                        >
                          <Column
                            {...this.getColumnSearchProps("name")}
                            title="Site Name"
                            dataIndex="name"
                            key="name"
                          />
                          {window.innerWidth > 420 ? (
                            <Column
                              //{...this.getColumnSearchProps("name")}
                              title="Address"
                              dataIndex="address"
                              key="address"
                            />
                          ) : null}
                          {window.innerWidth > 420 ? (
                            <Column
                              //{...this.getColumnSearchProps("name")}
                              title="Contact Name"
                              dataIndex="contactName"
                              key="contactName"
                            />
                          ) : null}
                          {window.innerWidth > 420 ? (
                            <Column
                              //{...this.getColumnSearchProps("name")}
                              title="Number"
                              dataIndex="number"
                              key="number"
                            />
                          ) : null}
                          {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Email"
                      dataIndex="email"
                      key="email"
                    /> */}
                          <Column
                            title="Actions"
                            // width={200}
                            render={(text, site) => (
                              <span>
                                {/* <Button
                            style={{
                              backgroundColor: "#009BDF",
                              color: "white",
                              borderRadius: "3em",
                            }}
                            key={site.name}
                            onClick={() => this.onViewSite(site)}
                          >
                            View
                          </Button> */}
                                <EditFilled
                                  style={{
                                    backgroundColor: "rgb(0, 155, 223)",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  key={site.name}
                                  onClick={() => this.onViewSite(site)}
                                />

                                {/* <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: "#CFD5E3",
                              color: "white",
                              padding: "0.5em",
                              borderRadius: "3em",
                            }}
                            onClick={e => {
                              this.setState({
                                deleteVisible: true,
                                deleteStock: stock,
                              });
                            }}
                          /> */}
                              </span>
                            )}
                          />
                        </StyledTable>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        background: "white",
                        borderRadius: 5,
                        // marginTop: 20,
                        display:
                          this.state.viewSelectedEmployee &&
                            !this.state.addStockVisible
                            ? "flex"
                            : "none",
                      }}
                      type="flex"
                      justify="start"
                      align="middle"
                    >
                      <Tabs
                        // defaultActiveKey={1}
                        animated={true}
                        onChange={callback1}
                        // activeKey={this.state.currentTab}
                        type="card"
                        style={{ width: "100%" }}
                        tabBarExtraContent={{
                          left: (
                            <span
                              style={{
                                fontWeight: 750,
                                fontSize: 18,
                                marginRight: "1em",
                              }}
                            >
                              <Button
                                type="primary"
                                shape="round"
                                onClick={this.handleBackToEmployees}
                                size="large"
                                style={{
                                  fontSize: "14px",
                                  width: "3em",
                                  height: "3em",
                                  //height: "3em",
                                  marginRight: "0.6em",
                                  padding: 0,
                                }}
                              >
                                <ArrowLeftOutlined />
                              </Button>{" "}
                              {/* {this.state.editStock
                                ? this.state.editStock.employeeName
                                : null}{" "}
                              {this.state.editStock
                                ? this.state.editStock.employeeLastName
                                : null}
                              's Charge(s) */}
                            </span>
                          ),
                        }}
                      >
                        <TabPane tab="Notification & Enquiry" key="Enquiry">
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Disciplinary Date:{" "}
                                        {moment(record.enquiryDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Enquiry Date:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsDisciplinary ===
                                              "N/A" ||
                                              moment(record.enquiryDate)
                                                .add(
                                                  record.sanctionMonthsDisciplinary,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsDisciplinary ===
                                            undefined
                                            ? "None"
                                            : record.sanctionMonthsDisciplinary}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsDisciplinary ===
                                              "N/A" ||
                                              moment(record.enquiryDate)
                                                .add(
                                                  record.sanctionMonthsDisciplinary,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsDisciplinary ===
                                            "N/A"
                                            ? "N/A"
                                            : moment(record.enquiryDate)
                                              .add(
                                                record.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.editStock
                                  ? stock.filter(
                                    stock =>
                                      stock.employeeName ===
                                      this.state.editStock.employeeName &&
                                      stock.employeeLastName ===
                                      this.state.editStock.employeeLastName &&
                                      stock.employeeEmail ===
                                      this.state.editStock.employeeEmail &&
                                      !stock.interviewDate
                                  )
                                  : null
                              }
                              onChange={this.handleSort}
                              onRow={record => {
                                if (parseInt(record.qty) === 0) {
                                  return { style: { background: "#ffd3d3" } };
                                }
                              }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}

                              <Column
                                {...this.getColumnSearchProps("allegedCharges1")}
                                title="Alleged Charge(s)"
                                dataIndex="allegedCharges1"
                                key="allegedCharges1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedCharges1
                                        ? "None"
                                        : record.allegedCharges1.substring(0, 20)}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />

                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("enquiryDate")}
                                  title="Disciplinary Date"
                                  dataIndex="enquiryDate"
                                  key="enquiryDate"
                                  render={(text, stock) => (
                                    <span>
                                      {moment(stock.enquiryDate).format(
                                        "DD MMMM YYYY"
                                      ) +
                                        " " +
                                        moment(stock.enquiryTime).format("HH:mm")}
                                      {stock.casePostponed === "Yes" ? (
                                        <span>
                                          <Tag
                                            style={{
                                              marginLeft: "0.3em",
                                              fontSize: "10px",
                                              padding: "2px",
                                            }}
                                            color={"orange"}
                                          >
                                            Postponed to
                                          </Tag>
                                          {moment(stock.enquiryDatePostponed).format(
                                            "DD MMMM YYYY"
                                          ) +
                                            " " +
                                            moment(stock.enquiryTimePostponed).format(
                                              "HH:mm"
                                            )}
                                        </span>
                                      ) : null}
                                    </span>
                                  )}
                                />
                              ) : null}
                              {/* {window.innerWidth > 420 ? (
                          <Column
                            {...this.getColumnSearchProps("enquiryDate")}
                            title="Postponed to"
                            dataIndex="enquiryDatePostponed"
                            key="enquiryDatePostponed"
                            render={(text, stock) => (
                              <span>
                                <Tag
                                  color={
                                    stock.sanctionMonthsDisciplinary ===
                                      "N/A" ||
                                    moment(stock.enquiryDate)
                                      .add(
                                        stock.sanctionMonthsDisciplinary,
                                        "month"
                                      )
                                      .isAfter(moment().format())
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {stock.sanctionMonthsDisciplinary ===
                                  undefined
                                    ? "None"
                                    : stock.sanctionMonthsDisciplinary}
                                </Tag>
                                {moment(stock.enquiryDatePostponed).format(
                                  "DD MMMM YYYY"
                                ) +
                                  " " +
                                  moment(stock.enquiryTimePostponed).format(
                                    "HH:mm"
                                  )}
                              </span>
                            )}
                          />
                        ) : null} */}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsDisciplinary === "N/A" ||
                                          moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === undefined
                                        ? "None"
                                        : stock.sanctionMonthsDisciplinary}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.casePostponed === "Yes"
                                          ? stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDatePostponed)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                          : stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDate)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === "N/A"
                                        ? "N/A"
                                        : stock.casePostponed === "Yes"
                                          ? moment(stock.enquiryDatePostponed)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")
                                          : moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warning")}
                                  title="Warning"
                                  dataIndex="warning"
                                  key="warning"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warning === undefined
                                        ? "None"
                                        : stock.warning}
                                    </Tag>
                                  )}
                                />
                              ) : null}

                              <Column
                                title="Actions"
                                // width={200}
                                width={130}
                                align="right"
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditEmployee(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Notification">
                                      <NotificationFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendNotif(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Enquiry">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.showModalEnquiry(stock)}
                                      />
                                    </Tooltip>
                                    {/* <Divider type="vertical" />
                              <Tooltip title="Delete">
                                <DeleteFilled
                                  style={{
                                    backgroundColor: "crimson",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => {
                                    this.setState({
                                      deleteVisible: true,
                                      deleteStock: stock,
                                    });
                                  }}
                                />
                              </Tooltip> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                        <TabPane
                          tab="Disciplinary Interview"
                          key="Disciplinary Interview2"
                        >
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Interview Date:{" "}
                                        {moment(record.interviewDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Months:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === undefined
                                            ? "None"
                                            : record.sanctionMonthsFinal}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === "N/A"
                                            ? "N/A"
                                            : moment(record.interviewDate)
                                              .add(
                                                record.sanctionMonthsFinal,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.editStock
                                  ? stock.filter(
                                    stock =>
                                      stock.employeeName ===
                                      this.state.editStock.employeeName &&
                                      stock.employeeLastName ===
                                      this.state.editStock.employeeLastName &&
                                      stock.employeeEmail ===
                                      this.state.editStock.employeeEmail &&
                                      stock.interviewDate
                                  )
                                  : null
                              }
                              onChange={this.handleSort}
                            // onRow={record => {
                            //   if (parseInt(record.qty) === 0) {
                            //     return {
                            //       style: {
                            //         display: this.state.editStock
                            //           ? rigs.filter(
                            //               rig =>
                            //                 rig.employeeName ===
                            //                 this.state.editStock.employeeName
                            //             )
                            //           : "#ffd3d3",
                            //       },
                            //     };
                            //   }
                            // }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}

                              <Column
                                {...this.getColumnSearchProps(
                                  "allegedChargesInterview1"
                                )}
                                title="Alleged Charge(s)"
                                dataIndex="allegedChargesInterview1"
                                key="allegedChargesInterview1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedChargesInterview1
                                        ? "None"
                                        : record.allegedChargesInterview1.substring(
                                          0,
                                          20
                                        )}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />

                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("interviewDate")}
                                  title="Interview Date"
                                  dataIndex="interviewDate"
                                  key="interviewDate"
                                  render={(text, stock) =>
                                    moment(stock.interviewDate).format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " " +
                                    moment(stock.interviewTime).format("HH:mm")
                                  }
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === undefined
                                        ? "None"
                                        : stock.sanctionMonthsFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === "N/A"
                                        ? "N/A"
                                        : stock.sanctionMonthsFinal === "Final"
                                          ? "Final"
                                          : moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warningFinal")}
                                  title="Warning"
                                  dataIndex="warningFinal"
                                  key="warningFinal"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warningFinal === undefined
                                        ? "None"
                                        : stock.warningFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              <Column
                                title="Actions"
                                // width={200}
                                width={90}
                                align="right"
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e =>
                                          this.onEditEmployeeInterview(stock)
                                        }
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Interview">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendInterview(stock)}
                                      />
                                    </Tooltip>
                                    {/* <Divider type="vertical" />
                              <Tooltip title="Delete">
                                <DeleteFilled
                                  style={{
                                    backgroundColor: "crimson",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => {
                                    this.setState({
                                      deleteVisible: true,
                                      deleteStock: stock,
                                    });
                                  }}
                                />
                              </Tooltip> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                      </Tabs>
                    </Row>
                    <Row
                      style={{
                        background: "white",
                        borderRadius: 5,
                        marginTop: 20,
                        display:
                          this.state.viewSiteVisible &&
                            !this.state.addStockVisible &&
                            !this.state.viewSelectedEmployee &&
                            !this.state.addNotificationVisible
                            ? "flex"
                            : "none",
                      }}
                      type="flex"
                      justify="start"
                      align="middle"
                    >
                      <Col xs={24} md={24} >
                        <span style={{ display: "flex", marginBottom: "1em" }}>
                          <Select
                            size='large'
                            showSearch
                            allowClear
                            mode="multiple"
                            value={this.state.selectedEmployees} // Bind selected values to state
                            // onChange={(values) => {
                            //   this.setState({ selectedEmployees: values });
                            // }}
                            onChange={(values) => {
                              this.setState({ selectedEmployees: values }, () => {
                                // this.handleSearchName();
                              });
                            }}
                            style={{ width: '100%' }}
                            placeholder="Select employee(s)"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props.employees
                              .filter((employee, index, self) =>
                                index === self.findIndex((t) => (
                                  t.employeeName === employee.employeeName && t.employeeLastName === employee.employeeLastName
                                ))
                              )
                              .filter(employee => employee.access.includes(this.state.siteSelected))
                              .map(employee => (
                                <Option key={employee._id} value={employee.employeeName}>
                                  {employee.employeeName} {employee.employeeLastName}
                                </Option>
                              ))}
                          </Select>
                          <Button size="large" type="primary" onClick={this.handleSearchName}>
                            Search
                          </Button>
                        </span>
                      </Col>
                      <Col span={24}>
                        <StyledTable
                          style={{ background: "white" }}
                          rowKey="_id"
                          size="middle"
                          pagination={{
                            defaultPageSize: 25,
                          }}
                          expandedRowRender={
                            window.innerWidth <= 420
                              ? (record) => {
                                // Check if any record for this employee has warningFinal as "Suspended"
                                const isSuspended = this.props.employees.some(
                                  (emp) =>
                                    emp.employeeName === record.employeeName &&
                                    emp.employeeLastName === record.employeeLastName &&
                                    emp.warning === "Suspended"
                                );

                                return (
                                  <Card size="small" title={record.itemCode}>
                                    {/* <p>Last: {record.employeeLastName}</p> */}
                                    <p>Email: {record.employeeEmail}</p>
                                    <p>Contact Number: {record.employeeNumber}</p>
                                    <p>Job Title: {record.jobTitle}</p>
                                    {/* Display suspension status */}
                                    {/* <p>
                                      Suspended:{" "}
                                      <Tag color={isSuspended ? "red" : "green"}>
                                        {isSuspended ? <CheckOutlined /> : <CloseOutlined />}
                                      </Tag>
                                    </p> */}
                                  </Card>
                                );
                              }
                              : null
                          }
                          dataSource={this.state.selectedEmployees != undefined && this.state.selectedEmployees.length > 0 ? uniqueItems : null}

                          onChange={this.handleSort}
                          onRow={record => {
                            if (record.employeeName === record.employeeName) {
                              return { style: { color: "red" } };
                            }
                          }}
                        >
                          <Column
                            {...this.getColumnSearchProps("employeeName")}
                            title="Name"
                            key="employeeName"
                            render={(text, record) => (
                              <span>
                                {record.employeeName} {record.employeeLastName}
                              </span>
                            )}
                          />
                          {/* {window.innerWidth > 420 ? ( */}
                          {/* <Column
                                {...this.getColumnSearchProps("employeeLastName")}
                                title="Surname"
                                dataIndex="employeeLastName"
                                key="employeeLastName"
                              /> */}
                          <Column
                            title="Suspended"
                            dataIndex="warning"
                            key="warning"
                            render={(text, employee) => {
                              // Check if any record for this employee has warningFinal as "Suspended"
                              const isSuspended = this.props.employees.some(
                                (emp) =>
                                  emp.employeeName === employee.employeeName &&
                                  emp.employeeLastName === employee.employeeLastName &&
                                  emp.warning === "Suspended" ||
                                  emp.employeeName === employee.employeeName &&
                                  emp.employeeLastName === employee.employeeLastName &&
                                  emp.warningFinal === "Suspended"
                              );

                              return (
                                <Tag color={isSuspended ? "red" : "green"}>
                                  {isSuspended ? <CheckOutlined /> : <CloseOutlined />}
                                </Tag>
                              );
                            }}
                          />
                          {window.innerWidth > 420 ? (
                            <Column
                              title="Email"
                              dataIndex="employeeEmail"
                              key="employeeEmail"
                            />
                          ) : null}
                          {window.innerWidth > 420 ? (
                            <Column
                              title="Contact Number"
                              dataIndex="employeeNumber"
                              key="employeeNumber"
                            />
                          ) : null}
                          {window.innerWidth > 420 ? (
                            <Column
                              title="Job Title"
                              dataIndex="jobTitle"
                              key="jobTitle"
                            />
                          ) : null}
                          <Column
                            title="Actions"
                            // width={100}
                            render={(text, stock) => (
                              <span>
                                {/* <Button
                            style={{
                              backgroundColor: "#009BDF",
                              color: "white",
                              borderRadius: "3em",
                            }}
                            onClick={e => this.onEditStock(stock)}
                          >
                            Edit
                          </Button> */}
                                <EditFilled
                                  style={{
                                    backgroundColor: "rgb(0, 155, 223)",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => this.onEditStock(stock)}
                                />
                                {/* <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: "#CFD5E3",
                              color: "white",
                              padding: "0.5em",
                              borderRadius: "3em",
                            }}
                            onClick={e => {
                              this.setState({
                                deleteVisible: true,
                                deleteStock: stock,
                              });
                            }}
                          /> */}
                              </span>
                            )}
                          />
                        </StyledTable>
                      </Col>
                    </Row>

                  </span>
                )}
            </Spin>
          </div>
          <Modal
            title="Send Notification"
            visible={this.state.visibleSendNotif}
            onOk={onFinishNotif}
            okText="Send"
            onCancel={this.handleCancel}
          >
            <Select
              style={{ width: "100%" }}
              type="emailNotification"
              // value={this.state.sendEmailNotification}
              onChange={this.handleEmailChangeNotification}
              placeholder="Enter Email"
            >
              {this.props.sites.flatMap((site, index) =>
                this.state.editStock ? (
                  this.state.editStock.access === site.name ? (
                    Array.isArray(site.email) ? (
                      site.email.map(email => (
                        <Option key={`${email}-${index}`} value={email.trim()}>
                          {email.trim()}
                        </Option>
                      ))
                    ) : (
                      <Option
                        key={`${site.email}-${index}`}
                        value={site.email.trim()}
                      >
                        {site.email.trim()}
                      </Option>
                    )
                  ) : null
                ) : null
              )}
            </Select>

            {/* <Input
                        type="emailNotification"
                        value={this.state.sendEmailNotification}
                        onChange={this.handleEmailChangeNotification}
                        placeholder="Enter Email"
                      /> */}
          </Modal>
          <Modal
            title="Send Disciplinary Interview"
            visible={this.state.visibleSendInterview}
            onOk={onFinishInterview}
            okText="Send"
            onCancel={this.handleCancel}
          >
            <Select
              style={{ width: "100%" }}
              type="emailInterview"
              // value={this.state.sendEmailInterview}
              onChange={this.handleEmailChangeInterview}
              placeholder="Enter Email"
            >
              {this.props.sites.flatMap((site, index) =>
                this.state.editStock ? (
                  this.state.editStock.access === site.name ? (
                    Array.isArray(site.email) ? (
                      site.email.map(email => (
                        <Option key={`${email}-${index}`} value={email.trim()}>
                          {email.trim()}
                        </Option>
                      ))
                    ) : (
                      <Option
                        key={`${site.email}-${index}`}
                        value={site.email.trim()}
                      >
                        {site.email.trim()}
                      </Option>
                    )
                  ) : null
                ) : null
              )}
            </Select>

            {/* <Input
                        type="emailInterview"
                        value={this.state.sendEmailInterview}
                        onChange={this.handleEmailChangeInterview}
                        placeholder="Enter Email"
                      /> */}
          </Modal>
          <Modal
            title="Send Disciplinary Enquiry"
            visible={this.state.visibleSendEnquiry}
            onOk={onFinishEnquiry}
            okText="Send"
            onCancel={this.handleCancel}
          >
            <Select
              style={{ width: "100%" }}
              type="emailEnquiry"
              // value={this.state.sendEmailEnquiry}
              onChange={this.handleEmailChangeEnquiry}
              placeholder="Enter Email"
            >
              {this.props.sites.flatMap((site, index) =>
                this.state.editStock ? (
                  this.state.editStock.access === site.name ? (
                    Array.isArray(site.email) ? (
                      site.email.map(email => (
                        <Option key={`${email}-${index}`} value={email.trim()}>
                          {email.trim()}
                        </Option>
                      ))
                    ) : (
                      <Option
                        key={`${site.email}-${index}`}
                        value={site.email.trim()}
                      >
                        {site.email.trim()}
                      </Option>
                    )
                  ) : null
                ) : null
              )}
            </Select>
            {/* <Input
                        type="emailEnquiry"
                        value={this.state.sendEmailEnquiry}
                        onChange={this.handleEmailChangeEnquiry}
                        placeholder="Enter Email"
                      /> */}
          </Modal>
          <Modal
            title={"Delete Notification"}
            visible={this.state.deleteVisible}
            onOk={this.handleDeleteStock}
            onCancel={this.cancelDelete}
            okText="Accept"
            cancelText="Cancel"
          >
            <span>
              {" "}
              Are you sure you want to delete
              {/* {this.props.stock.map(stock => ( */}
              {/* <span>{stock.currentRig}</span> */}
              {/* ))} */}
            </span>
          </Modal>
        </div>
      );
    }
  }
}

export default Stock;
