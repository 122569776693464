import React from "react";
import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  PageHeader,
  Badge,
  Table,
  Input,
  Modal,
  Select,
  Spin,
  Divider,
  Card,
  Typography,
  Tabs,
  AutoComplete,
  InputNumber,
  Pagination
} from "antd";
import {
  BellOutlined,
  ArrowLeftOutlined,
  EyeFilled,
  TeamOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import Notify from "src/components/meta/Notification";
import Highlighter from "react-highlight-words";
const { Title } = Typography;
const { Column } = Table;
const FormItem = Form.Item;

const { TabPane } = Tabs;
const { Search } = Input;
const { Option } = Select;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const StyledInputNumber = styled(InputNumber)`
  font-weight: 500 !important;
  color: #525f7f !important;
  font-size: 15px !important;
  cursor: not-allowed;
  .ant-input-number-disabled .ant-input-number-input {
    font-weight: 500 !important;
    color: #525f7f !important;
    font-size: 15px !important;
    cursor: not-allowed;
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
const StyledAutoComplete = styled(AutoComplete)`
  .ant-select-selector {
    width: 100%;

    padding-top: 0px !important;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    padding-top: 0em !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    margin-top: 10px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    top: 0;
    right: 11px;
    bottom: 0;
    left: 11px;
    padding-top: 10px !important;
  }
`;
function callback(key) {
  console.log(key);
}

class SitesFormClass extends React.Component {
  state = {
    descriptions: this.props.descriptions,
  };
  selectItem = value => {
    if (value.substr(0, 4) === "Add ") {
      let parsedValue = value.substr(4, value.length);
      this.props.form.setFieldsValue({ description: parsedValue });
      let descriptions = this.state.descriptions;
      descriptions.pop();
      descriptions.push({ value: parsedValue });
      this.setState({ descriptions: descriptions });
    }
  };
  searchItem = (value, b) => {
    let descriptions = this.state.descriptions;
    if (descriptions[descriptions.length - 1].value.substr(0, 4) === "Add ") {
      descriptions.pop();
    }
    if (
      !descriptions.find(
        descriptions => descriptions.value.toLowerCase() === value.toLowerCase()
      ) &&
      value !== ""
    ) {
      descriptions.push({ value: "Add " + value });
    }
    this.setState({ descriptions: descriptions });
  };
  render() {
    return (
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <p>Contact : {this.props.editSites.contactName}</p>
          </Col>
          <Col span={24}>
            <p>Number : {this.props.editSites.number}</p>
          </Col>
          <Col span={24}>
            <p style={{ overflow: "hidden" }}>
              {this.props.editSites.email &&
                `Email: ${this.props.editSites.email}`}
            </p>
          </Col>
          <Col span={24}>
            <p> Address : {this.props.editSites.address}</p>
          </Col>
          <Col span={2} />
        </Row>
      </Form>
    );
  }
}
const SitesForm = Form.create()(SitesFormClass);

class Sites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      closed: false,
      rejectReason: "",
      editSites: undefined,
      size: "large",
      flag: false,
      currentTab: "All",
      currentPage: 1,
      totalSites: 0,
      searchQuerySites: "",
    };
  }

  componentDidMount() {
    this.loadSites();
    window.addEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.addEventListener("backbutton", this.handleBack);
    }
  }

  loadSites = () => {
    const { currentPage, searchQuerySites } = this.state;
    this.setState({ loading: true });
    this.props
      .findAllSites({ searchQuerySites, page: currentPage, limit: 100000 })
      .then(response => {
        // Assume your Redux reducer stores sites and total in the returned data.
        // Here, we update the total count from the API response.
        this.setState({
          loading: false,
          totalSites: response.data.total
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  handleSearch = value => {
    // Update search query and reset to first page
    this.setState({ searchQuerySites: value, currentPage: 1 }, this.loadSites);
  };

  handlePageChange = page => {
    this.setState({ currentPage: page }, this.loadSites);
  };


  handleBack = () => {
    if (this.state.addSitesVisible) {
      this.setState({ addSitesVisible: false });
    } else {
      this.props.history.goBack();
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  stopProp = e => {
    e.stopPropagation();
  };

  sitesForm;
  onAddSites = e => {
    this.setState({ addSitesVisible: true, editSites: undefined });
  };

  handleAddSites = e => {
    const { currentPage, searchQuerySites } = this.state;
    e.preventDefault();
    this.sitesForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        if (this.state.editSites) {
          values._id = this.state.editSites._id;
        }
        this.props
          .addSites(values)
          .then(() => {
            this.props
              .findAllSites({ searchQuerySites, page: currentPage, limit: 100000 })
              .then(() => {
                if (this.state.editSites) {
                  Notify("success", "Successfully saved site");
                } else {
                  Notify("success", "Successfully added site");
                }
                this.sitesForm.props.form.resetFields();
                this.setState({
                  loading: false,
                  addSitesVisible: false,
                  editSites: undefined,
                  currentTab: "All",
                });
              })
              .catch(e => {
                this.setState({ loading: false });
                Notify("error", e);
              });
          })

          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
      }
    });
  };

  handleEditSites = e => {
    this.setState({ addSitesVisible: true, editSites: e });
  };

  handleDeleteSites = e => {
    const { currentPage, searchQuerySites } = this.state;
    const id = e.currentTarget.getAttribute("data");
    var that = this;
    Modal.confirm({
      centered: true,
      content: (
        <div>
          <h4>Are you sure you want to delete this site?</h4>
        </div>
      ),
      onOk() {
        that.setState({ loading: true });
        that.props
          .deleteSites(id)
          .then(() => {
            that.props
              .findAllSites({ searchQuerySites, page: currentPage, limit: 100000 })
              .then(() => {
                that.setState({ loading: false, currentTab: "All" });
                // that.props.find140mm();
                // that.props.find171mm();
                Notify("success", "Successfully deleted site");
              })
              .catch(e => {
                that.setState({ loading: false });
                Notify("error", e);
              });
          })
          .catch(e => {
            that.setState({ loading: false });
            Notify("error", e);
          });
      },
      oncancel() { },
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  changeTypeTab = key => {
    this.setState({ currentTab: key });
  };

  render() {
    let descriptions = this.props.sites.map(site => {
      return { value: site.description };
    });
    const { sites } = this.props;
    const { totalSites, currentPage, loading } = this.state;
    const { size } = this.state;
    // let sites = [];
    // this.props.sites.forEach(site => {
    //   if (this.props.user.access.includes(site._id)) {
    //     sites.push(site);
    //   }
    // });
    // let currentSites;
    // if (this.state.currentTab === "All") {
    //   currentSites = sites;
    // } else {
    //   currentSites = sites.filter(site => site.size === this.state.currentTab);
    // }
    return (
      <div style={{ maxWidth: "90em", margin: "auto" }}>
        <Spin spinning={this.state.loading}>
          {this.state.addSitesVisible ? (
            <Card
              bordered={false}
              style={{
                margin: "auto",
                marginTop: "3em",
                maxWidth: "50em",
                minWidth: "20em",
                backgroundColor: "white",
                borderRadius: "0.8em",
                boxShadow: "0px 6px 15px 0px rgba(0, 0, 0, 0.08)",
              }}
            >
              <Row style={{}}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={e => this.setState({ addSitesVisible: false })}
                    size="large"
                    style={{
                      float: "left",
                      fontSize: "14px",
                      width: "3em",
                      height: "3em",
                      //height: "3em",
                      marginRight: "0.4em",
                      padding: 0,
                    }}
                  >
                    <ArrowLeftOutlined />
                  </Button>
                  <h1
                    style={{
                      marginRight: "3em",
                      fontSize: "20px",
                      fontWeight: 700,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {this.state.editSites ? this.state.editSites.name : null}
                  </h1>
                </Col>
                {/* <Col
                  span={24}
                  style={{ textAlign: "center", marginTop: "-1em" }}
                >
                  <p
                    style={{
                      fontWeight: 400,
                      fontSize: "15px",
                      whiteSpace: "nowrap",
                      marginTop: "0.5em",
                      marginBottom: "0em",
                    }}
                  >
                    {this.state.editSites
                      ? "Please click save to edit site"
                      : "Fill in all fields and click save to add a new site"}
                  </p>
                </Col> */}
              </Row>
              <div
                style={{
                  padding: "3em",
                  paddingTop: "1em",
                  paddingBottom: "4em",
                  margin: "auto",
                  marginTop: "2em",
                  maxWidth: "35em",
                }}
              >
                <SitesForm
                  descriptions={descriptions}
                  editSites={this.state.editSites}
                  wrappedComponentRef={form => (this.sitesForm = form)}
                />{" "}
                {/* <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleAddSites}
                  size="large"
                  style={{
                    float: "right",
                    fontSize: "14px",
                    width: "7em",
                    height: "3em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  Save
                </Button> */}
              </div>
            </Card>
          ) : (
            <Row>
              <Row
                type="flex"
                justify="start"
                align="middle"
                style={{
                  display:
                    !this.state.viewSiteVisible &&
                      !this.state.addStockVisible &&
                      !this.state.viewSelectedEmployee
                      ? "flex"
                      : "none",
                }}
              >
                <span style={{ fontWeight: 750, fontSize: 25 }}>
                  Company Information
                </span>{" "}
              </Row>
              <Col span={24} style={{ marginTop: "1em" }}>
                {/* <Button
                  type="primary"
                  shape="round"
                  onClick={this.onAddSites}
                  icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                  size="large"
                  style={{
                    marginBottom: "0.5em",
                    height: "3em",
                    width: "9em",
                  }}
                >
                  Add Site
                </Button> */}
                <Search size='large'
                  placeholder="Search Company"
                  onSearch={this.handleSearch}
                  enterButton
                  style={{ marginBottom: 16, }}
                />
                <StyledTable
                  bordered={false}
                  dataSource={sites}
                  rowKey="_id"
                  size="middle"
                  loading={loading}
                  pagination={false} // disable built-in pagination; we use our custom one below
                  style={{ minWidth: "15em", marginTop: "0.5em" }}
                >
                  <Column
                    //{...this.getColumnSearchProps("name")}
                    title="Site Name"
                    dataIndex="name"
                    key="name"
                  />
                  {window.innerWidth > 420 ? (
                    <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Address"
                      dataIndex="address"
                      key="address"
                    />
                  ) : null}
                  {window.innerWidth > 420 ? (
                    <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Contact Name"
                      dataIndex="contactName"
                      key="contactName"
                    />
                  ) : null}

                  <Column
                    //{...this.getColumnSearchProps("name")}
                    title="Number"
                    dataIndex="number"
                    key="number"
                  />

                  {/* {window.innerWidth > 420 ? (
                    <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Email"
                      dataIndex="email"
                      key="email"
                    />
                  ) : null} */}
                  <Column
                    //{...this.getColumnSearchProps("actions")}
                    title="Actions"
                    render={(text, sites) => (
                      <span>
                        <Button
                          style={{
                            backgroundColor: "#009BDF",
                            color: "white",
                            borderRadius: "3em",
                            padding: 0,
                            width: "2.3em",
                          }}
                          onClick={e => this.handleEditSites(sites)}
                        >
                          <EyeFilled />
                        </Button>
                        {/* <Divider type="vertical" />
                        <DeleteFilled
                          style={{
                            backgroundColor: "#CFD5E3",
                            color: "white",
                            padding: "0.5em",
                            borderRadius: "3em",
                          }}
                          data={sites._id}
                          onClick={this.handleDeleteSites}
                        /> */}
                      </span>
                    )}
                  />
                </StyledTable>
                <Pagination
                  current={currentPage}
                  total={totalSites}
                  pageSize={30}
                  onChange={this.handlePageChange}
                  style={{ marginTop: 16, textAlign: "center" }}
                />
              </Col>
            </Row>
          )}
        </Spin>
      </div>
    );
  }
}

const WrappedSites = Form.create()(Sites);
export default WrappedSites;
