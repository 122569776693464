import { getToken } from "src/redux/selectors";

export function addSites(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/sites/addsites",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add sites" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteSites(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/sites/deletesites",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete sites" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}
// actions/sites.js
export function searchSites(searchQuery) {
  return dispatch => {
    // Dispatch an action to indicate the search has started.
    dispatch({ type: "SITE_SEARCH_REQUEST" });

    // Build the API URL with the search query.
    const url = `${process.env.REACT_APP_GLOBAL_SERVER}asambo/api/sites/findallsites?searchQuery=${encodeURIComponent(searchQuery)}`;

    return fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(async res => {
        if (!res.ok) {
          const errorText = await res.text();
          // Dispatch a failure action if the response is not OK.
          dispatch({ type: "SITE_SEARCH_FAILURE", error: errorText });
          throw new Error(errorText);
        }
        return res.json();
      })
      .then(data => {
        // Dispatch a success action with the sites data.
        dispatch({ type: "SITE_SEARCH_SUCCESS", payload: data.sites || [] });
      })
      .catch(error => {
        // Optionally, dispatch a failure action if fetch fails.
        dispatch({ type: "SITE_SEARCH_FAILURE", error: error.message });
      });
  };
}

export function findAllSites({ searchQuerySites, page, limit }) {
  let url = process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/sites/findallsites";
  const params = [];
  if (searchQuerySites) {
    params.push(`searchQuerySites=${encodeURIComponent(searchQuerySites)}`);
  }
  if (page) {
    params.push(`page=${encodeURIComponent(page)}`);
  }
  if (limit) {
    params.push(`limit=${encodeURIComponent(limit)}`);
  }
  if (params.length > 0) {
    url += "?" + params.join("&");
  }

  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          const data = await res.json();
          return resolve(
            dispatch({
              type: "SITES_FIND_ALLSITES",
              data,
            })
          );
        })
        .catch(err => {
          throw err.message;
        });
    });
}




export function setEditSites(sites) {
  return dispatch =>
    dispatch({
      type: "SITESS_SET_EDITSITES",
      data: sites,
    });
}

export function setSitesDetailsId(id) {
  return dispatch =>
    dispatch({
      type: "SITESS_SET_SITESDETAILSID",
      data: id,
    });
}
