import React from "react";
import styled from "styled-components";
import { ScanOutlined, SearchOutlined, StockOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { connect } from "react-redux";
import "@ant-design/compatible/assets/index.css";
import { FaUserFriends } from "@react-icons/all-files/fa/FaUserFriends";
import {
  Row,
  Col,
  Spin,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Badge,
  Typography,
  Space,
  Progress,
  Tooltip,
} from "antd";
import { MinusCircleFilled, DeleteFilled } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import prepDefinition from "src/components/meta/PdfDefinition";
import { updateRig1, updateRig2 } from "src/redux/actions/status";
import moment from "moment";
import NewIcon from "src/static/new-icon.svg";
import TotalIcon from "src/static/new-icon.svg";
import { RiProfileFill } from "@react-icons/all-files/ri/RiProfileFill";
import SharpIcon from "src/static/circle-logo.svg";
import DamagedIcon from "src/static/damaged-icon.svg";
import LostIcon from "src/static/lost-icon.svg";
import ToolsIcon from "src/static/tools-icon.png";
import RigsIcon from "src/static/rigs-icon.png";
import CostIcon from "src/static/cost-icon.png";
import PitIcon from "src/static/pit-icon.png";
import UsersIcon from "src/static/user-icon.png";
import damagesIcon from "src/static/damage-icon.png";
import Notify from "src/components/meta/Notification";
import { withRouter, Link } from "react-router-dom";

const { Title } = Typography;

const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const numeral = require("numeral");
const { TabPane } = Tabs;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledCard = styled(Card)`
  .ant-card-body {
    height: 5.4em;
    padding: 10px !important;
    padding-top: 10px !important;
    padding-bottom: 3px !important;
    display: flex;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;

class Dashboard extends React.Component {
  static defaultProps = {
    error: null,
    member: {},
    loading: false,
    closed: false,
    rejectReason: "",
    size: "large",
    flag: false,
  };
  componentWillMount = () => {
    this.setState({ loading: true });
    // this.props
    //   .findAllStock()
    //   .then(() => {
    //     let itemCodes = [];
    //     this.props.stock.forEach(stock => {
    //       if (stock.itemCode) {
    //         itemCodes.push(
    //           "Part: " + stock.itemCode + " - Location: " + stock.location
    //         );
    //       }
    //     });
    //     this.setState({ loading: false, itemCodes: itemCodes });
    //   })
    //   .catch(e => {
    //     this.setState({ loading: false });
    //     Notify("error", e);
    //   });
    this.props
      .findAllUsers()
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
    this.updateSignal = {
      signal: msg => {
        this.props
          .findAllSites()
          // .findAllSites(this.props.user.access)
          .then(() => {
            this.setState({ loading: false });
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        //     this.props
        //       .findAllStock()
        //       .then(() => {
        //         let itemCodes = [];
        //         this.props.stock.forEach(stock => {
        //           if (stock.itemCode) {
        //             itemCodes.push(
        //               "Part: " + stock.itemCode + " - Location: " + stock.location
        //             );
        //           }
        //         });
        //         this.setState({ loading: false, itemCodes: itemCodes });
        //       })
        //       .catch(e => {
        //         this.setState({ loading: false });
        //         Notify("error", e);
        //       });
      },
    };
    //window.pubnub.addListener(this.updateSignal);
    window.addEventListener("resize", this.resizeEvent);
    //if (window.cordova) {
    //  document.addEventListener("backbutton", this.handleBack);
    //}
  };
  handleSubmit = event => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (typeof values.remember === "undefined") {
          values.remember = false;
        }
        this.signIn(values);
      }
    });
  };

  signIn = values => {
    this.setState({ loading: true });
    this.props
      .signIn(values, this.props.currentLocale)
      .then(() => {
        this.setState({ loading: false });
        this.props.push("/dashboard");
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", window.i18n.translate("Notify3"), e);
      });
  };

  toggleShowPass = () => {
    this.state.passShow === "password"
      ? this.setState({ passShow: "text" })
      : this.setState({ passShow: "password" });
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      passShow: "password",
    };
  }

  handleLocaleSwitch = locale => {
    this.setState({ loading: true });
    this.props
      .changeLocale(locale)
      .then(() => {
        this.props.form.resetFields();
        this.setState({ loading: false });
        // location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });

  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  render() {
    // const filteredSites = this.props.sites.filter(site => {
    //   // Check if there exists a stock item that matches the conditions
    //   const existsInStock = this.props.stock.some(stockItem => {
    //     return (
    //       stockItem.authorizedUsers === this.props.user.username && stockItem.access === site.name ||
    //       stockItem.authorizedUsersInterview === this.props.user.username && stockItem.access === site.name
    //     );
    //   });

    //   // Return true to include the site in filteredSites if a matching stock item was found
    //   return existsInStock;
    // });
    // let sites = [];
    // this.props.sites.forEach(site => {
    //   if (this.props.user.access.includes(site._id)) {
    //     sites.push(site);
    //   }
    // });
    return (
      <div style={{ maxWidth: "90em", margin: "auto", minHeight: "60em" }}>
        <Row
          type="flex"
          //justify='start'
          //align='middle'
          style={{
            margin: "-20px",
            marginBottom: "0.5em",
            background: "rgb(0, 21, 41)",
            paddingTop: "0.5em",
            paddingBottom: "0.5em",
            // height: "7.5em",
          }}
        >
          <Col
            xs={12}
            md={12}
            style={{
              paddingTop: "0.5em",
              background: "rgb(0, 21, 41)",
              // borderBottom: "1px solid white",
            }}
          >
            <span
              style={{
                fontWeight: 400,
                fontSize: 15,
                color: "white",
                marginLeft: 10,
                marginBottom: -10,
                fontFamily: "Roboto",
              }}
            >
              Welcome
            </span>
            <br />
            <span
              style={{
                fontWeight: 500,
                fontSize: 16,
                color: "rgb(0, 155, 223)",
                marginLeft: 10,

                fontFamily: "Roboto",
              }}
            >
              {this.props.user.name}
            </span>
          </Col>
          <Col
            xs={12}
            md={12}
            style={{
              background: "rgb(0, 21, 41)",
              // borderBottom: "1px solid white",
              paddingTop: "0.5em",
            }}
          >
            {/* <span
                style={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "white",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                Drilling Month
              </span>
              <br />
              <span
                style={{
                  position: "absolute",
                  right: 0,
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#FF9D66",
                  float: "right",
                  marginRight: 10,

                  fontFamily: "Roboto",
                }}
              >
                1 Sep - 30 Sep
              </span> */}
            {/* <span
              style={{
                fontWeight: 400,
                fontSize: 15,
                color: "white",
                float: "right",
                marginRight: 10,
                fontFamily: "Roboto",
              }}
            >
              {this.props.sites
                ? this.props.sites.find(
                    site => site._id === this.props.user.access
                  )
                  ? this.props.sites.find(
                      site => site._id === this.props.user.access
                    ).name
                  : "Unknown"
                : "Unknown"}
            </span> */}
            <br />
            <span
              style={{
                position: "absolute",
                right: 0,
                fontWeight: 500,
                fontSize: 16,
                color: "rgb(0, 155, 223)",
                float: "right",
                marginRight: 10,

                fontFamily: "Roboto",
              }}
            >
              {moment().format("DD/MM/YY")}
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "15%" }}>
          <Col xs={24} md={8} style={{ margin: "auto", marginTop: "1em" }}>
            <Link to="/user/stock">
              <Card
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                  height: "14em",
                  minWidth: "11em",
                  width: "90%",
                  margin: "auto",
                  overflow: "hidden",
                  backgroundColor: "rgb(0, 21, 41)",
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <img
                      src={SharpIcon}
                      style={
                        {
                          // marginTop: 10,
                          // marginLeft: 10,
                          // top: "-5em",
                          // left: "-5em",
                          // width: "15em",
                          // height: "15em",
                          // opacity: 0.3,
                          // position: "absolute",
                        }
                      }
                    />
                    {/* <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "white",
                        marginTop: -76,
                      }}
                    >
                      {filteredSites.length}
                    </p> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "white",
                        //marginLeft: -10
                      }}
                    >
                      <p
                        style={{
                          marginTop: 10,
                          marginLeft: -11,
                        }}
                      >
                        Site Management
                      </p>
                    </span>{" "}
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
          <Col xs={24} md={8} style={{ margin: "auto", marginTop: "1em" }}>
            <Link to="/user/sitemanagement">
              <Card
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                  height: "14em",
                  minWidth: "11em",
                  width: "90%",
                  margin: "auto",
                  overflow: "hidden",
                  backgroundColor: "rgb(0, 21, 41)",
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <img
                      src={SharpIcon}
                      style={
                        {
                          // marginTop: 10,
                          // marginLeft: 10,
                          // top: "-5em",
                          // left: "-5em",
                          // width: "15em",
                          // height: "15em",
                          // opacity: 0.3,
                          // position: "absolute",
                        }
                      }
                    />
                    {/* <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "white",
                        marginTop: -76,
                      }}
                    >
                      {this.props.sites.length}
                    </p> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "white",
                        //marginLeft: -10
                      }}
                    >
                      <p
                        style={{
                          marginTop: 10,
                          marginLeft: -11,
                        }}
                      >
                        Company Information
                      </p>
                    </span>{" "}
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>

          <Col xs={24} md={8} style={{ margin: "auto", marginTop: "1em" }}>
            <Link to="/user/account">
              <Card
                style={{
                  boxShadow: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
                  borderRadius: "0.7em",
                  border: "none",
                  padding: "0em",
                  whiteSpace: "nowrap",
                  fontFamily: "Poppins",
                  height: "14em",
                  minWidth: "11em",
                  width: "90%",
                  margin: "auto",
                  overflow: "hidden",
                  backgroundColor: "rgb(0, 21, 41)",
                }}
              >
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <img
                      src={SharpIcon}
                      style={
                        {
                          // marginTop: 10,
                          // marginLeft: 10,
                          // top: "-5em",
                          // left: "-5em",
                          // width: "15em",
                          // height: "15em",
                          // opacity: 0.3,
                          // position: "absolute",
                        }
                      }
                    />
                    {/* <p
                      style={{
                        fontWeight: 900,
                        fontSize: 30,
                        color: "white",
                        marginTop: -73,
                      }}
                    >
                      <RiProfileFill

                      />
                    </p> */}
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <span
                      style={{
                        fontWeight: 500,
                        fontSize: 14,
                        color: "white",
                        //marginLeft: -10
                      }}
                    >
                      <p
                        style={{
                          marginTop: 10,
                          marginLeft: -11,
                        }}
                      >
                        My Profile
                      </p>
                    </span>{" "}
                  </Col>
                </Row>
              </Card>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
