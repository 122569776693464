import React, { PureComponent, useState, useRef, useCallback } from "react";
import styled from "styled-components";
import {
  ScanOutlined,
  SearchOutlined,
  StockOutlined,
  TableOutlined,
  DeleteFilled,
  PlusCircleOutlined,
  AntDesignOutlined,
  InfoCircleOutlined,
  PauseCircleFilled,
  NotificationFilled,
  PlayCircleFilled,
  CloseCircleFilled,
  TrademarkCircleFilled,
  CheckOutlined,
  CloseOutlined,
  EditFilled,
  MailFilled,
  RocketFilled,
  DownloadOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Cookies } from "react-cookie";
import axios from "axios";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
// import { PDFViewer } from "@react-pdf/renderer";
import nodemailer from "nodemailer";
import { ButtonRadius } from "src/components/elements/Button";
import Resizer from "react-image-file-resizer";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { getToken } from "src/redux/selectors";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { FaDashcube } from "@react-icons/all-files/fa/FaDashcube";
import { FaBuilding } from "@react-icons/all-files/fa/FaBuilding";
import { FaUserFriends } from "@react-icons/all-files/fa/FaUserFriends";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaCopy } from "@react-icons/all-files/fa/FaCopy";
import {
  Row,
  Col,
  Spin,
  Progress,
  Layout,
  Button,
  List,
  Tabs,
  Popover,
  Input,
  Table,
  Divider,
  Modal,
  Select,
  Card,
  InputNumber,
  Typography,
  Switch,
  AutoComplete,
  Badge,
  DatePicker,
  TimePicker,
  Tooltip,
  Image,
  Upload,
  Radio,
  Checkbox,
  message,
  Tag,
  Pagination
} from "antd";
import moment from "moment";
// import {
//   ReactAudioRecorder,
//   RECORD_STATUS,
// } from "@sarafhbk/react-audio-recorder";
import { Link } from "react-router-dom";
import Notify from "src/components/meta/Notification";
import noImage from "src/static/icons/carImageUpload/no-image-available.svg";
import Countdown from "react-countdown-now";
import Highlighter from "react-highlight-words";
// import pdfMake from "pdfmake/build/pdfmake";
import vfsFonts from "pdfmake/build/vfs_fonts";
import prepDefinition from "src/components/meta/PdfDefinition2";
import prepDefinition2 from "src/components/meta/PdfDefinition3";
import SignatureCanvas from "react-signature-canvas";
import Recorder from "recorder-js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
const { Search } = Input;
const FormItem = Form.Item;
const Option = Select.Option;
const { Text } = Typography;
const numeral = require("numeral");
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = "DD MMMM YYYY";
const timeFormat = "HH:mm";
const Cards = styled(Card)`
  .ant-card-body {
    display: none !important;
  }
`;
const StyledSelect = styled(Select)`
  .ant-select-selector {
    width: 100%;
    height: 55px !important;
    border-radius: 0.5em !important;
    padding-top: 11px !important;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    color: red !important;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ant-select-selection-placeholder {
    color: rgba(0, 0, 0, 0.45) !important;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    cursor: auto;
    padding-top: 1.2em !important;
  }
`;
const StyledDatePicker = styled(RangePicker)`

  padding-left: 1.2em !important;
  height: 45px !important;

    box-shadow:none !important;
  background-color: white!important;
  background-image: none;
  border: none;
  border: 0px solid black !important;
  border: 1px solid lightgrey !important;
  border-radius: 0px;
  color: black!important;
  //box-shadow: rgba(0, 0, 0, 0.11) 2px 8px 13px 5px !important;
  
  :placeholder {
    color: lightgrey !important;
  }
  :hover {
    border-color: red !important;

    box-shadow:none !important;
}
  :active{
    border-color: red !important;
   
     box-shadow:none !important;
   
  }
  :focus{
    border-color: red !important;
    
     box-shadow:none !important;
   
  }
  }
`;
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: white;
    background: #001529 !important;
    font-size: 13px;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s;

    font-weight: 500;
    color: #525f7f;
    font-size: 13px;
  }
`;
const StyledTabs = styled(Tabs)`
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: 0px solid #f0f0f0 !important;
    content: "";
  }
`;
const Selection = styled(Select)`
  .ant-select-selection {
    border: 1px solid #bdb2b2;
    height: 45px;
    font-size: 12px;
  }
  .ant-select-selection__placeholder {
    top: 21px;
  }
  .ant-select-selection__rendered {
    height: 44px;
  }
  .ant-select-selection-selected-value {
    position: relative;
    top: 6px;
  }
`;
const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    height: 0.6em !important;
    width: 0.6em !important;
  }
`;
function callback1(key) {
  console.log(key);
}
function callback2(key) {
  console.log(key);
}
function onChange(e) {
  console.log(`checked = ${e.target.checked}`);
}
const EditableContext = React.createContext();
const Signature = () => {
  return (
    <>
      <SignatureCanvas
        penColor="black"
        canvasProps={{
          width: 2500,
          height: 100,
          className: "sigCanvas",
        }}
      />
    </>
  );
};
const columns = [
  {
    title: "Stock Nr.",
    dataIndex: "serial",
    key: "serial",
    width: "90%",
  },
  {
    title: "Tool Type",
    dataIndex: "size",
    key: "size",
    width: "90%",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    width: "90%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "90%",
  },
  {
    title: "Meters",
    dataIndex: "meters",
    key: "meters",
    width: "90%",
  },
  {
    title: "Cost",
    dataIndex: "cost",
    key: "cost",
    width: "90%",
  },
];
const data = [];

for (let i = 199; i < 222; i++) {
  data.push({
    key: i,
    name: `Rig ${i}`,
    age: 33,
    address: ` ${i}`,
  });
}
const fixedColumns = [
  {
    title: "Name",
    dataIndex: "name",
    fixed: true,
    width: 120,
  },
  {
    title: "Description",
    dataIndex: "description",
  },
];

class StockFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visibleChargesNotif: false,
      visibleChargesInterview: false,
      visibleSendNotif: false,
      visibleSendEnquiry: false,
      visibleSendInterview: false,
      interpIsYes: true,
      isPostponed: false,
      itemCodes: [],
      removedMeters: null,
      newBitStatus: null,
      replaceSize: null,
      replaceDesc: null,
      // myRecordingNotification: null,
      // myRecordingInterview: null,
      replaceStatus: null,
      visiblePrep: [],
      visiblePrepInterview: [],
      replaceLastUser: null,
      selectedBit: null,
      replaceSerial: null,
      visible: false,
      selectedDate: null,
      selectedTime: null,
      branchImage: undefined,
      frontRightImage: undefined,
      rightSideImage: undefined,
      wheelsImage: undefined,
      frontLeftImage: undefined,
      frontImage: undefined,
      pdfUrl: null,
      signature: null,
      isDrawing: false,
      sigVisible: false,
      recordingId: undefined,

      // activeKey: "Info",
      // siteSelected:
      //   this.props.sites.length > 0 ? this.props.sites[0].name : "None",
      siteSelectedName:
        this.props.sites.length > 0 ? this.props.sites[0].name : "None",

      sendEmail: "",
      employees: [],
    };
    this.handleEmailChangeNotification = this.handleEmailChangeNotification.bind(
      this
    );
    this.handleEmailChangeEnquiry = this.handleEmailChangeEnquiry.bind(this);
    this.handleEmailChangeInterview = this.handleEmailChangeInterview.bind(
      this
    );
    // this.onFinishNotif = this.onFinishNotif.bind(this);
    // this.onFinishEnquiry = this.onFinishEnquiry.bind(this);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.beforeUpload = this.beforeUpload.bind(this);
    this.handleRecordingUploadNotification = this.handleRecordingUploadNotification.bind(
      this
    );
    this.handleRecordingNotification = this.handleRecordingNotification.bind(
      this
    );
    this.handleRecordingUploadEnquiry = this.handleRecordingUploadEnquiry.bind(
      this
    );
    this.handleRecordingInterview = this.handleRecordingInterview.bind(this);
    this.getBase64 = this.getBase64.bind(this);
  }

  convertToDataURI = (audioUrl) => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', audioUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.send();
    });
  };
  generateNotificationPdf = () => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "subheader" },
        { text: `Notification to Attend Enquiry`, style: "subheader" },
        {
          text: `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            }  From: ${this.props.editStock.access}`,
        },
        {
          text: `\n 
          Dear ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            },\n
           Please find notification to attend enquiry below.\n
           Alleged Charge(s): See Annexure “A” Below 
           \n Date and time of enquiry: ${moment(
              this.props.editStock.enquiryDate
            ).format("DD MMMM YYYY")}  ${moment(
              this.props.editStock.enquiryTime
            ).format("HH:mm")}
          \n ${this.props.editStock.casePostponed === "Yes"
              ? "Postponed to: " +
              moment(this.props.editStock.enquiryDatePostponed).format(
                "DD MMMM YYYY"
              ) +
              " " +
              moment(this.props.editStock.enquiryTimePostponed).format(
                "HH:mm"
              )
              : "Postponed: No"
            } 
         \n Venue of enquiry: ${this.props.editStock.enquiryVenue}\n
        1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
              this.props.editStock.enquiryDatePostponed ? this.props.editStock.enquiryDatePostponed : this.props.editStock.enquiryDate
            )
              .subtract(1, "day")
              .format("DD MMMM YYYY")} at ${moment(
                this.props.editStock.enquiryTime
              ).format("HH:mm")} of the under mentioned:\n
          Name of your representative: ${this.props.editStock.representativeName !== "" &&
              this.props.editStock.representativeName !== undefined
              ? this.props.editStock.representativeName
              : "______________________________"
            }\n
        Name(s) of your witnesses: ${this.props.editStock.witnessesName !== "" &&
              this.props.editStock.witnessesName !== undefined
              ? this.props.editStock.witnessesName
              : "______________________________"
            }\n 
 Do you intend to make use of a interpreter: ${this.props.editStock.interpreterNeeded !== "" &&
              this.props.editStock.interpreterNeeded !== undefined
              ? this.props.editStock.interpreterNeeded
              : "Yes / No"
            }\n 
 If so n which language? ${this.props.editStock.interpreterLang !== "" &&
              this.props.editStock.interpreterLang !== undefined
              ? this.props.editStock.interpreterLang
              : "______________________________"
            }\n (If an interpreter is required such an interpreter will be allocated by management)\n
          Do you need any time to prepare: ${this.props.editStock.preparationNeeded !== "" &&
              this.props.editStock.preparationNeeded !== undefined
              ? this.props.editStock.preparationNeeded
              : "Yes / No"
            }\n
      1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
      Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
      
      ${this.props.editStock.frontImage !== undefined
              ? ""
              : "\nAccused Signature: ______________________________ \n"
            }
    
I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.
          \n Document issued by:
      ${this.props.editStock.access}\n
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
        {
          text: "Click here to view signature",
          link:
            process.env.REACT_APP_GLOBAL_SERVER +
            "asambo/api/images/" +
            this.props.editStock.frontImage,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        htmlStyle: {
          margin: [0, 10, 0, 0],
        },
      },
      defaultStyle: {
        font: "Roboto",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob(blob => {
      const url = URL.createObjectURL(blob);


      const downloadLink = document.createElement("a");
      downloadLink.download = `Notification to Attend Enquiry_${this.props.editStock.employeeName
        } ${this.props.editStock.employeeLastName}.pdf`;
      downloadLink.href = url;
      downloadLink.click();
    });
  };
  generateEnquiryPdf = () => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "header" },
        `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
        }  From: ${this.props.editStock.access}`,
        { text: "Disciplinary Enquiry", style: "subheader" },
        {
          text: ` Please find Disciplinary Enquiry below.
        \n`,
        },

        {
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.props.editStock.companyName}`],
              [
                `Date and time of proceedings`,
                `${moment(this.props.editStock.enquiryDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.props.editStock.enquiryTime).format("HH:mm")}`,
              ],
              [
                `\n ${this.props.editStock.casePostponed === "Yes"
                  ? "Postponed to "
                  : "Postponed"
                } 
               \n`,
                `\n ${this.props.editStock.casePostponed === "Yes"
                  ? moment(this.props.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.props.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "No"
                } 
               \n`,
              ],
              [`Accused Name`, `${this.props.editStock.accusedName}`],
              [`Position`, `${this.props.editStock.jobTitle}`],
              [`Representative`, `${this.props.editStock.representativeName}`],
              [`Initiator`, `${this.props.editStock.initiatorName}`],
              [`Chairperson`, `${this.props.editStock.chairpersonName}`],
              [`Accused Venue / Place`, `${this.props.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [`Plea by the accused`, `${this.props.editStock.accusedPlea}`],
              [
                `Determination by Chairperson`,
                `${this.props.editStock.determinationFinding}`,
              ],
              [
                `Date of employment`,
                `${this.props.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.props.editStock.prevTransgressions}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.props.editStock.mitigationCircumstances}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.props.editStock.aggrivatingCircumstances}`,
              ],
              [`Suspension`, `${this.props.editStock.suspensionType}`],
              [
                `Sanction / Warning (Months)`,
                `${this.props.editStock.warning +
                " " +
                this.props.editStock.sanctionMonthsDisciplinary +
                " months"}`,
              ],
              [`Verdict`, `${this.props.editStock.otherWarnings}`],
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
              },
            },
          },
        },
        {
          text:
            this.props.editStock.refuseSignDisciplinaryEnquiry === true
              ? `\n Refused to sign : ` +
              this.props.editStock.refuseReasonDisciplinaryEnquiry
              : `\n Click here to view signature`,
          link:
            this.props.editStock.refuseSignDisciplinaryEnquiry === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.props.editStock.branchImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob(blob => {
      const url = URL.createObjectURL(blob);

      // Create a download link for the PDF
      const downloadLink = document.createElement("a");
      downloadLink.download = `Disciplinary Enquiry_${this.props.editStock.employeeName
        } ${this.props.editStock.employeeLastName}.pdf`;
      downloadLink.href = url;

      downloadLink.click();
    });
  };
  generateInterviewPdf = () => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "header" },
        `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
        }  From: ${this.props.editStock.access}`,
        { text: "Disciplinary Interview", style: "subheader" },
        {
          text: ` Please find Disciplinary Interview below.
        \n`,
        },

        {
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.props.editStock.companyName}`],
              [
                `Date and time of interview`,
                `${moment(this.props.editStock.interviewDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.props.editStock.interviewTime).format(
                  "HH:mm"
                )}`,
              ],
              [`Accused Name`, `${this.props.editStock.accusedName}`],
              [`Position`, `${this.props.editStock.jobTitle}`],
              [
                `Representative`,
                `${this.props.editStock.representativeNameInterview}`,
              ],
              [`Initiator`, `${this.props.editStock.InitiatorInterview}`],
              [
                `Chairperson`,
                `${this.props.editStock.chairpersonNameInterview}`,
              ],
              [`Accused Venue / Place`, `${this.props.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [
                `Plea by the accused`,
                `${this.props.editStock.accusedPleaInterview}`,
              ],
              [
                `Determination by Chairperson`,
                `${this.props.editStock.determinationFindingInterview}`,
              ],
              [
                `Date of employment`,
                `${this.props.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.props.editStock.prevTransgressionsInterview}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.props.editStock.mitigationCircumstancesInterview}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.props.editStock.aggrivatingCircumstancesInterview}`,
              ],
              [
                `Sanction / Warning (Months)`,
                `${this.props.editStock.sanctionMonthsFinal + " months"}`,
              ],
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
              },
            },
          },
        },
        {
          text:
            this.props.editStock.refuseSignInterview === true
              ? `\n Refused to sign : ` +
              this.props.editStock.refuseReasonInterview
              : `\n Click here to view signature`,
          link:
            this.props.editStock.refuseSignInterview === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.props.editStock.frontLeftImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedChargesInterview}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getBlob(blob => {
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.download = `Disciplinary Interview_${this.props.editStock.employeeName
        } ${this.props.editStock.employeeLastName}.pdf`;
      downloadLink.href = url;
      downloadLink.click();
    });
  };
  signaturePad = {};
  // handleTabChange = activeKey => {
  //   this.setState({ activeKey });
  // };
  clearSignature = () => {
    this.signaturePad.clear();
  };
  signaturePadEnquiry = {};

  clearSignatureEnquiry = () => {
    this.signaturePadEnquiry.clear();
  };
  signaturePadInterview = {};

  clearSignatureInterview = () => {
    this.signaturePadInterview.clear();
  };
  saveSignature = () => {
    const signature = this.signaturePad.toDataURL();
    this.setState({ signature, frontImage: signature, sigVisible: "redo" });
    Notify("success", "Saved", "Successfully stored signature.");
  };
  saveSignatureEnquiry = () => {
    const signature = this.signaturePadEnquiry.toDataURL();
    this.setState({ signature, branchImage: signature, sigVisible: "redo" });
    Notify("success", "Saved", "Successfully stored signature.");
  };
  saveSignatureInterview = () => {
    const signature = this.signaturePadInterview.toDataURL();
    this.setState({ signature, frontLeftImage: signature, sigVisible: "redo" });
    Notify("success", "Saved", "Successfully stored signature.");
  };
  RedoSignature = () => {
    // this.signaturePad.clear();

    this.setState({ sigVisible: true });
  };
  handleOnStart = () => {
    this.setState({ isDrawing: true });
  };

  handleOnEnd = () => {
    this.setState({ isDrawing: false });
  };
  handleEmailChangeNotification = value => {
    this.setState({ sendEmailNotification: value });
  };
  handleEmailChangeEnquiry = value => {
    this.setState({ sendEmailEnquiry: value });
  };
  handleEmailChangeInterview = value => {
    this.setState({ sendEmailInterview: value });
  };
  componentWillMount = () => {
    if (this.props.editStock) {
      this.setState({
        myRecordingNotification: this.props.editStock.myRecordingNotification,
        myRecordingInterview: this.props.editStock.myRecordingInterview,
        branchImage: this.props.editStock.branchImage,
        frontRightImage: this.props.editStock.frontRightImage,
        rightSideImage: this.props.editStock.rightSideImage,
        wheelsImage: this.props.editStock.wheelsImage,
        frontLeftImage: this.props.editStock.frontLeftImage,
        frontImage: this.props.editStock.frontImage,
      });
    }
  };
  handleBranchImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        branchImage: imageUrl,
      });
    });
  };
  handleFrontRightImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontRightImage: imageUrl,
      });
    });
  };
  handleRightSideImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        rightSideImage: imageUrl,
      });
    });
  };
  handleWheelsImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        wheelsImage: imageUrl,
      });
    });
  };
  handleFrontLeftImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontLeftImage: imageUrl,
      });
    });
  };
  handleFrontImage = info => {
    this.setState({
      loading: true,
    });
    this.getBase64(info.file, imageUrl => {
      this.setState({
        loading: false,
        frontImage: imageUrl,
      });
    });
  };
  showModalChargesNotif = () => {
    this.setState({
      visibleChargesNotif: true,
    });
  };
  handleOkChargesNotif = e => {
    console.log(e);
    this.setState({
      visibleChargesNotif: false,
    });
  };
  showModalChargesInterview = () => {
    this.setState({
      visibleChargesInterview: true,
    });
  };
  handleOkChargesInterview = e => {
    console.log(e);
    this.setState({
      visibleChargesInterview: false,
    });
  };
  showModalNotif = () => {
    this.setState({
      visibleSendNotif: true,
    });
  };

  showModalEnquiry = () => {
    this.setState({
      visibleSendEnquiry: true,
    });
  };
  showModalInterview = () => {
    this.setState({
      visibleSendInterview: true,
    });
  };
  handleOkEnquiry = e => {
    console.log(e);
    this.setState({
      visibleSendEnquiry: false,
    });
  };
  handleOkNotif = e => {
    console.log(e);
    this.setState({
      visibleSendNotif: false,
    });
  };
  handleOkInterview = e => {
    console.log(e);
    this.setState({
      visibleSendInterview: false,
    });
  };
  handleCancel = e => {
    console.log(e);
    this.setState({
      visibleDateRequired: false,
      visibleChargesNotif: false,
      visibleChargesInterview: false,
      visibleSendNotif: false,
      visibleSendEnquiry: false,
      visibleSendInterview: false,
    });
  };
  selectManual = checked => {
    this.setState({
      interpIsYes: true,
    });
  };
  deSelectManual = checked => {
    this.setState({
      interpIsYes: false,
    });
  };
  selectPostponed = checked => {
    this.setState({
      isPostponed: true,
    });
  };
  deSelectPostponed = checked => {
    this.setState({
      isPostponed: false,
    });
  };
  selectRuling = checked => {
    this.setState({
      isRuling: true,
    });
  };
  deSelectRuling = checked => {
    this.setState({
      isRuling: false,
    });
  };
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };
  handleEmploymentDateChange = date => {
    this.setState({ selectedemploymentDate: date });
  };
  handleTimeChange = time => {
    this.setState({ selectedTime: time });
  };
  handleSelectEmployee = () => {
    this.setState({
      viewSelect: true,
    });
  };
  handleInputEmployee = () => {
    this.setState({
      viewSelect: false,
    });
  };

  beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  }

  fetchAudioFile = async (url) => {
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    return arrayBuffer;
  };
  decodeAudioData = async (arrayBuffer) => {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return audioBuffer;
  };
  encodeWAV = (audioBuffer) => {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const format = 1; // PCM
    const bitDepth = 16;
    const blockAlign = numberOfChannels * (bitDepth / 8);
    const byteRate = sampleRate * blockAlign;
    const buffer = new ArrayBuffer(44 + audioBuffer.length * 2);
    const view = new DataView(buffer);

    /* RIFF identifier */
    this.writeString(view, 0, 'RIFF');
    /* file length */
    view.setUint32(4, 36 + audioBuffer.length * 2, true);
    /* RIFF type */
    this.writeString(view, 8, 'WAVE');
    /* format chunk identifier */
    this.writeString(view, 12, 'fmt ');
    /* format chunk length */
    view.setUint32(16, 16, true);
    /* sample format (raw) */
    view.setUint16(20, format, true);
    /* channel count */
    view.setUint16(22, numberOfChannels, true);
    /* sample rate */
    view.setUint32(24, sampleRate, true);
    /* byte rate (sample rate * block align) */
    view.setUint32(28, byteRate, true);
    /* block align (channel count * bytes per sample) */
    view.setUint16(32, blockAlign, true);
    /* bits per sample */
    view.setUint16(34, bitDepth, true);
    /* data chunk identifier */
    this.writeString(view, 36, 'data');
    /* data chunk length */
    view.setUint32(40, audioBuffer.length * 2, true);

    this.floatTo16BitPCM(view, 44, audioBuffer.getChannelData(0));

    return buffer;
  };

  writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  floatTo16BitPCM = (output, offset, input) => {
    for (let i = 0; i < input.length; i++, offset += 2) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
    }
  };
  handleDownloadRecording = async () => {
    try {
      const recordingURL = this.props.editStock && this.props.editStock.recordingLinkNotif
        ? this.props.editStock.recordingLinkNotif.endsWith(".wav") // Check for .wav
          ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkNotif // File already has .wav
          : this.props.editStock.recordingLinkNotif.endsWith(".mp3") // Check for .mp3
            ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkNotif // File already has .mp3
            : process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkNotif + ".mp3" // Default to .wav if no extension
        : null;


      if (recordingURL) {
        // Check for permissions before downloading
        this.requestManualStoragePermissions(async () => {
          // Fetch the audio file
          const arrayBuffer = await this.fetchAudioFile(recordingURL);

          // Decode the audio data
          const audioBuffer = await this.decodeAudioData(arrayBuffer);

          // Encode the audio data to WAV
          const wavBuffer = this.encodeWAV(audioBuffer);
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`;

          // Set the download filename
          const filename = this.props.editStock.access && this.props.editStock.employeeName && this.props.editStock.employeeLastName
            ? `${this.props.editStock.access}_${this.props.editStock.employeeName}_${this.props.editStock.employeeLastName}_Recording_${formattedDate}.mp3`
            : `Multi_Recording_${formattedDate}.mp3`;

          // Trigger download
          this.saveFile(new Blob([wavBuffer], { type: 'audio/wav' }), filename);
        });
      } else {
        console.log("No recording URL found");
      }
    } catch (error) {
      console.log("Download error:", error);
    }
  };

  handleDownloadRecordingInterview = async () => {
    try {
      const recordingURL = this.props.editStock && this.props.editStock.recordingLinkInterview
        ? this.props.editStock.recordingLinkInterview.endsWith(".wav") // Check for .wav
          ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkInterview // File already has .wav
          : this.props.editStock.recordingLinkInterview.endsWith(".mp3") // Check for .mp3
            ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkInterview // File already has .mp3
            : process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkInterview + ".mp3" // Default to .wav if no extension
        : null;

      if (recordingURL) {
        // Check for permissions before downloading
        this.requestManualStoragePermissions(async () => {
          // Fetch the audio file
          const arrayBuffer = await this.fetchAudioFile(recordingURL);

          // Decode the audio data
          const audioBuffer = await this.decodeAudioData(arrayBuffer);

          // Encode the audio data to WAV
          const wavBuffer = this.encodeWAV(audioBuffer);
          const date = new Date();
          const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`;

          // Set the download filename
          const filename = this.props.editStock.access && this.props.editStock.employeeName && this.props.editStock.employeeLastName
            ? `${this.props.editStock.access}_${this.props.editStock.employeeName}_${this.props.editStock.employeeLastName}_Interview_Recording_${formattedDate}.mp3`
            : `Interview_Recording_${formattedDate}.mp3`;

          // Trigger download
          this.saveFile(new Blob([wavBuffer], { type: 'audio/wav' }), filename);
        });
      } else {
        console.log("No recording URL found");
      }
    } catch (error) {
      console.log("Download error:", error);
    }
  };
  requestManualStoragePermissions = (callback) => {
    // Check if running in a Cordova environment
    if (window.cordova && window.cordova.plugins.permissions) {
      const permissions = window.cordova.plugins.permissions;

      // List of permissions to request
      const permissionsList = [
        permissions.WRITE_EXTERNAL_STORAGE,
        permissions.READ_EXTERNAL_STORAGE
      ];

      // Check if permissions are already granted
      permissions.hasPermission(permissionsList, (status) => {
        if (!status.hasPermission) {
          // Request permissions if not granted
          permissions.requestPermissions(permissionsList, (status) => {
            if (status.hasPermission) {
              // alert('Permissions granted!');
              // Call the callback function to proceed with file operations
              if (callback) callback();
            } else {
              alert('Permissions denied. Unable to save files.');
            }
          }, (error) => {
            alert('Failed to request permissions: ' + JSON.stringify(error));
          });
        } else {
          alert('Permissions already granted!');
          // Call the callback function to proceed with file operations
          if (callback) callback();
        }
      }, (error) => {
        alert('Failed to check permissions: ' + JSON.stringify(error));
      });
    } else {
      alert('Cordova or permissions plugin is not available.');
    }
  };


  handleRecordingUploadNotification({ onSuccess, file }) {
    const formData = new FormData();
    formData.append("myRecordingNotification", file);

    axios
      .post(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        if (response.data && response.data.success) {
          onSuccess(response.data, file);
        } else {
          console.log("Upload failed");
        }
      })
      .catch(error => {
        console.log("Upload error:", error);
      });
  }

  handleRecordingNotification(info) {
    this.setState({
      loading: true,
    });
    this.getBase641(info.file, imageUrl => {
      this.setState({
        loading: false,
        myRecordingNotification: imageUrl,
      });
    });
  }
  handleRecordingUploadEnquiry({ onSuccess, file }) {
    const formData = new FormData();
    formData.append("myRecordingInterview", file);

    axios
      .post(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        if (response.data && response.data.success) {
          onSuccess(response.data, file);
        } else {
          console.log("Upload failed");
        }
      })
      .catch(error => {
        console.log("Upload error:", error);
      });
  }

  handleRecordingInterview(info) {
    this.setState({
      loading: true,
    });
    this.getBase641(info.file, imageUrl => {
      this.setState({
        loading: false,
        myRecordingInterview: imageUrl,
      });
    });
  }
  getBase641(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  handleImageUpload = ({ onSuccess, file }) => {
    onSuccess(true, file);
  };
  handleSetWheelsImage = (image) => {
    this.setState({ wheelsImage: image, wheelsImages: image });
  };
  handleSetFrontRightImage = (image) => {
    this.setState({ frontRightImage: image, frontRightImages: image });
  };
  handleSetRightSideImage = (image) => {
    this.setState({ rightSideImage: image, rightSideImages: image });
  };
  beforeUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      Notify("error", window.i18n.translate("ImageSmaller"));
    }
    return false;
  };
  getBase64 = (img, callback) => {
    Resizer.imageFileResizer(img, 800, 800, "PNG", 100, 0, uri => {
      callback(uri);
    });
  };
  handleSubmitNotif = values => {
    this.onFinishNotif(values);
  };
  onFinishNotif = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "subheader" },
        { text: `Notification to Attend Enquiry`, style: "subheader" },
        {
          text: `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            }  From: ${this.props.editStock.access}`,
        },
        {
          text: `\n 
          Dear ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            },\n
           Please find notification to attend enquiry below.\n
           Alleged Charge(s): See Annexure “A” Below
           \n Date and time of enquiry: ${moment(
              this.props.editStock.enquiryDate
            ).format("DD MMMM YYYY")}  ${moment(
              this.props.editStock.enquiryTime
            ).format("HH:mm")}
          \n ${this.props.editStock.casePostponed === "Yes"
              ? "Postponed to: " +
              moment(this.props.editStock.enquiryDatePostponed).format(
                "DD MMMM YYYY"
              ) +
              " " +
              moment(this.props.editStock.enquiryTimePostponed).format(
                "HH:mm"
              )
              : "Postponed: No"
            } 
         \n Venue of enquiry: ${this.props.editStock.enquiryVenue}\n
        1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
              this.props.editStock.enquiryDatePostponed ? this.props.editStock.enquiryDatePostponed : this.props.editStock.enquiryDate
            )
              .subtract(1, "day")
              .format("DD MMMM YYYY")} at ${moment(
                this.props.editStock.enquiryTime
              ).format("HH:mm")} of the under mentioned:\n
          Name of your representative: ${this.props.editStock.representativeName !== "" &&
              this.props.editStock.representativeName !== undefined
              ? this.props.editStock.representativeName
              : "______________________________"
            }\n
        Name(s) of your witnesses: ${this.props.editStock.witnessesName !== "" &&
              this.props.editStock.witnessesName !== undefined
              ? this.props.editStock.witnessesName
              : "______________________________"
            }\n 
 Do you intend to make use of a interpreter: ${this.props.editStock.interpreterNeeded !== "" &&
              this.props.editStock.interpreterNeeded !== undefined
              ? this.props.editStock.interpreterNeeded
              : "Yes / No"
            }\n 
 If so n which language? ${this.props.editStock.interpreterLang !== "" &&
              this.props.editStock.interpreterLang !== undefined
              ? this.props.editStock.interpreterLang
              : "______________________________"
            }\n (If an interpreter is required such an interpreter will be allocated by management)\n
          Do you need any time to prepare: ${this.props.editStock.preparationNeeded !== "" &&
              this.props.editStock.preparationNeeded !== undefined
              ? this.props.editStock.preparationNeeded
              : "Yes / No"
            }\n
      1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
      Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
      ${this.props.editStock.frontImage !== undefined
              ? ""
              : "\nAccused Signature: ______________________________ \n"
            }
I ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
            } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.
          \n Document issued by:
      ${this.props.editStock.access}\n

    
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
        {
          text: "Click here to view signature",
          link:
            process.env.REACT_APP_GLOBAL_SERVER +
            "asambo/api/images/" +
            this.props.editStock.frontImage,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        htmlStyle: {
          margin: [0, 10, 0, 0],
        },
      },
      defaultStyle: {
        font: "Roboto",
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1];

      const MSG = {
        to: this.state.sendEmailNotification,
        from: "no-reply@asambo.co.za",
        subject: `Notification to Attend Enquiry (${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Notification to attend enquiry_${this.props.editStock.employeeName
              } ${this.props.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Notification to attend enquiry - 

        To: ${this.props.editStock.employeeName} 

        From: ${this.props.editStock.access} `,
      };

      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendNotif: false,
        });
      });
    });
  };
  handleSubmitEnquiry = values => {
    this.onFinishEnquiry(values);
  };
  onFinishEnquiry = values => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "header" },
        `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
        }  From: ${this.props.editStock.access}`,
        { text: "Disciplinary Enquiry", style: "subheader" },
        `Please find Disciplinary Enquiry below.
        \n`,
        {
          style: "tableExample",
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.props.editStock.companyName}`],
              [
                `Date and time of proceedings`,
                `${moment(this.props.editStock.enquiryDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.props.editStock.enquiryTime).format("HH:mm")}`,
              ],
              [
                `\n ${this.props.editStock.casePostponed === "Yes"
                  ? "Postponed to "
                  : "Postponed"
                } 
               \n`,
                `\n ${this.props.editStock.casePostponed === "Yes"
                  ? moment(this.props.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.props.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "No"
                } 
               \n`,
              ],
              [`Accused Name`, `${this.props.editStock.accusedName}`],
              [`Position`, `${this.props.editStock.jobTitle}`],
              [`Representative`, `${this.props.editStock.representativeName}`],
              [`Initiator`, `${this.props.editStock.initiatorName}`],
              [`Chairperson`, `${this.props.editStock.chairpersonName}`],
              [`Accused Venue / Place`, `${this.props.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [`Plea by the accused`, `${this.props.editStock.accusedPlea}`],
              [
                `Determination by Chairperson`,
                `${this.props.editStock.determinationFinding}`,
              ],
              [
                `Date of employment`,
                `${this.props.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.props.editStock.prevTransgressions}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.props.editStock.mitigationCircumstances}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.props.editStock.aggrivatingCircumstances}`,
              ],
              [`Suspension`, `${this.props.editStock.suspensionType}`],
              [
                `Sanction / Warning (Months)`,
                `${this.props.editStock.warning +
                " " +
                this.props.editStock.sanctionMonthsDisciplinary +
                " months"}`,
              ],
              [`Verdict`, `${this.props.editStock.otherWarnings}`],
            ],
          },
        },
        {
          text:
            this.props.editStock.refuseSignDisciplinaryEnquiry === true
              ? `\n Refused to sign : ` +
              this.props.editStock.refuseReasonDisciplinaryEnquiry
              : `\n Click here to view signature`,
          link:
            this.props.editStock.refuseSignDisciplinaryEnquiry === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.props.editStock.branchImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; //

      const MSG = {
        to: this.state.sendEmailEnquiry,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Enquiry (${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
          })`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Enquiry_${this.props.editStock.employeeName
              } ${this.props.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Enquiry - 

        To: ${this.props.editStock.employeeName} 

        From: ${this.props.editStock.access} `,
      };

      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendEnquiry: false,
        });
      });
    });
  };
  handleSubmitInterview = values => {
    this.onFinishInterview(values);
  };
  onFinishInterview = values => {
    const docDefinition = {
      content: [
        { text: `${this.props.editStock.access}`, style: "header" },
        `To: ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
        }  From: ${this.props.editStock.access}`,
        { text: "Disciplinary Interview", style: "subheader" },
        {
          text: ` Please find Disciplinary Interview below.
        \n`,
        },

        {
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.props.editStock.companyName}`],
              [
                `Date and time of interview`,
                `${moment(this.props.editStock.interviewDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.props.editStock.interviewTime).format(
                  "HH:mm"
                )}`,
              ],
              [`Accused Name`, `${this.props.editStock.accusedName}`],
              [`Initiator`, `${this.props.editStock.initiatorNameInterview}`],
              [`Position`, `${this.props.editStock.jobTitle}`],
              [
                `Representative`,
                `${this.props.editStock.representativeNameInterview}`,
              ],
              [`Initiator`, `${this.props.editStock.InitiatorInterview}`],
              [
                `Chairperson`,
                `${this.props.editStock.chairpersonNameInterview}`,
              ],
              [`Accused Venue / Place`, `${this.props.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [
                `Plea by the accused`,
                `${this.props.editStock.accusedPleaInterview}`,
              ],
              [
                `Determination by Chairperson`,
                `${this.props.editStock.determinationFindingInterview}`,
              ],
              [
                `Date of employment`,
                `${this.props.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.props.editStock.prevTransgressionsInterview}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.props.editStock.mitigationCircumstancesInterview}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.props.editStock.aggrivatingCircumstancesInterview}`,
              ],
              [
                `Sanction / Warning (Months)`,
                `${this.props.editStock.sanctionMonthsFinal + " months"}`,
              ],
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
              },
            },
          },
        },
        {
          text:
            this.props.editStock.refuseSignInterview === true
              ? `\n Refused to sign : ` +
              this.props.editStock.refuseReasonInterview
              : `\n Click here to view signature`,
          link:
            this.props.editStock.refuseSignInterview === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.props.editStock.frontLeftImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" },
        {
          text: `Alleged Charge(s): \n\n
          ${this.props.editStock.allegedChargesInterview}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1];

      const MSG = {
        to: this.state.sendEmailInterview,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Interview (${this.props.editStock.employeeName
          } ${this.props.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Interview_${this.props.editStock.employeeName
              } ${this.props.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Interview - 

        To: ${this.props.editStock.employeeName} 

        From: ${this.props.editStock.access} `,
      };

      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendInterview: false,
        });
      });
    });
  };
  showNextPrep = () => {
    const { visiblePrep } = this.state;
    const lastVisibleRow = visiblePrep[visiblePrep.length - 1];
    const addonValue = this.props.form.getFieldValue(`allegedCharges${lastVisibleRow}`);
    if (addonValue) {
      const nextVisibleRow = lastVisibleRow + 1;
      const updatedVisiblePrep = [...visiblePrep, nextVisibleRow];
      this.setState({ visiblePrep: updatedVisiblePrep });
    }
  }
  showNextPrepInterview = () => {
    const { visiblePrepInterview } = this.state;
    const lastVisibleRow = visiblePrepInterview[visiblePrepInterview.length - 1];
    const addonValue = this.props.form.getFieldValue(`allegedChargesInterview${lastVisibleRow}`);
    if (addonValue) {
      const nextVisibleRow = lastVisibleRow + 1;
      const updatedVisiblePrepInterview = [...visiblePrepInterview, nextVisibleRow];
      this.setState({ visiblePrepInterview: updatedVisiblePrepInterview });
    }
  }

  componentDidMount() {
    const { form, editStock } = this.props;
    // this.handleReset();
    const newVisiblePrepInterview = [];
    const newVisiblePrep = [];

    if (editStock) {
      for (let i = 1; i <= 30; i++) {
        if (editStock[`allegedCharges${i}`]) {
          newVisiblePrep.push(i);
        }
        if (editStock[`allegedChargesInterview${i}`]) {
          newVisiblePrepInterview.push(i);
        }
      }

    } else {
      newVisiblePrepInterview.push(1);
      newVisiblePrep.push(1);
    }

    this.setState({
      visiblePrepInterview: newVisiblePrepInterview,
      visiblePrep: newVisiblePrep
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editStock !== this.props.editStock) {
      const newVisiblePrep = [];
      const newVisiblePrepInterview = [];

      for (let i = 1; i <= 30; i++) {
        const prepStepField = `allegedCharges${i}`;
        if (this.props.editStock && this.props.editStock[prepStepField]) {
          newVisiblePrep.push(i);
        }
      }
      for (let i = 1; i <= 30; i++) {
        const prepStepField = `allegedChargesInterview${i}`;
        if (this.props.editStock && this.props.editStock[prepStepField]) {
          newVisiblePrepInterview.push(i);
        }
      }

      this.setState({
        visiblePrep: newVisiblePrep,
        visiblePrepInterview: newVisiblePrepInterview,
      });
    }
  }

  render() {
    const { signature, isDrawing, visiblePrep, visiblePrepInterview } = this.state;
    const stopRecordingAndOpenEnquiry = stopRecording => {
      stopRecording();
      // Modal.info({
      //   title: "Recording Stopped",
      //   content: (
      //     <div>
      //       {/* <p>
      //         Please upload downloaded recording at the{" "}
      //         <bold style={{ fontWeight: 700 }}>Enquiry Recording </bold>
      //         section at the bottom of the page &{" "}
      //         <bold style={{ fontWeight: 700 }}>Save your form</bold> when done.
      //       </p>
      //       <p>
      //         <bold style={{ fontWeight: 700 }}>Recording Name:</bold>
      //         <br />
      //         Disciplinary Recording - {this.props.editStock.employeeName}{" "}
      //         {this.props.editStock.employeeLastName}{" "}
      //         {this.props.editStock.access}.mp3
      //       </p> */}
      //       <p>
      //         Please make sure to save your file before exiting.
      //       </p>
      //     </div>
      //   ),
      // });
    };
    const stopRecordingAndOpenInterview = stopRecording => {
      stopRecording();
      // Modal.info({
      //   title: "Recording Stopped & Downloaded",
      //   content: (
      //     <div>
      //       {/* <p>
      //         Please upload downloaded recording at the{" "}
      //         <bold style={{ fontWeight: 700 }}>Interview Recording </bold>
      //         section at the bottom of the page &{" "}
      //         <bold style={{ fontWeight: 700 }}>Save your form</bold> when done.
      //       </p>
      //       <p>
      //         <bold style={{ fontWeight: 700 }}>Recording Name:</bold>
      //         <br />
      //         Disciplinary Interview Recording -{" "}
      //         {this.props.editStock.employeeName}{" "}
      //         {this.props.editStock.employeeLastName}{" "}
      //         {this.props.editStock.access}.mp3
      //       </p> */}
      //       <p>
      //         Please make sure to save your file before exiting.
      //       </p>
      //     </div>
      //   ),
      // });
    };
    const uniqueStock = [
      ...new Set(this.props.stock.map(stock => stock.employeeName)),
    ];
    // let stock = [];
    // stock.forEach(stock => {
    //   if (stock.access.includes(this.state.siteSelected)) {
    //     stock.push(stock);
    //   }
    // });
    const maxRowsPrep = 20;
    const rowsPrep = [];
    const rowsPrepEnquiry = [];
    // const rowsPrep = Array.from({ length: 30 }, () => null);
    if (!visiblePrep.includes(1)) {
      visiblePrep.push(1);
    }
    visiblePrep.forEach(row => {
      rowsPrep[row - 1] = (
        <Row gutter={24} key={row} id={`row-${row}`} style={{ backgroundColor: row % 2 == 0 ? "rgba(200, 200, 200, 0.10)" : "", padding: "1em", paddingBottom: "0em", borderRadius: "1.5em" }}>
          <Col span={24}>
            <FormItem
              labelCol={{ span: 24 }}
              label={<span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge ${row}:`}</span>}
            >
              {this.props.form.getFieldDecorator(`allegedCharges${row}`, {
                rules: [
                  {
                    // required: row === 1,
                    message: "Step required",
                  },
                ],
                initialValue: this.props.editStock ? this.props.editStock[`allegedCharges${row}`] : "",
              })(
                <TextArea autoSize allowClear />
              )}
            </FormItem>
          </Col>
        </Row>
      );
    });
    visiblePrep.forEach(row => {
      rowsPrepEnquiry[row - 1] = (
        <Row gutter={24} key={row} id={`row-${row}`} style={{ backgroundColor: row % 2 == 0 ? "rgba(200, 200, 200, 0.10)" : "", padding: "1em", paddingBottom: "0.2em", borderRadius: "1,5em" }}>
          <Col span={24}>
            <span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge ${row}:`}</span>
            <pre style={{ marginTop: "0.5em", whiteSpace: "unset" }}>
              {this.props.editStock ? this.props.editStock[`allegedCharges${row}`] : null}
            </pre>
          </Col>
          <Col span={24} style={{ marginTop: "-0.5em" }}>
            <span style={{ fontWeight: 400, fontSize: 14 }}>
              <span style={{}}>
                Plea by the accused: ({" "}
                <span style={{ color: "rgb(0, 112, 168)" }}>
                  {this.props.editStock &&
                    this.props.editStock[`accusedPlea${row}`]
                    ? this.props.editStock[`accusedPlea${row}`]
                    : " Please select Plea"}
                </span>{" "}
                )
              </span>
            </span>
          </Col>
          <Col span={24} style={{ marginTop: "1em" }}>
            <FormItem>
              {this.props.form.getFieldDecorator(`accusedPlea${row}`, {
                rules: [
                  {
                    required: false,
                  },
                ],
                // valuePropName: "checked",
                // initialValue: this.props.editStock
                //   ? this.props.editStock.accusedPleaInterview
                //   : "",
              })(
                <Radio.Group defaultValue={null} buttonStyle="solid">
                  <Radio.Button value="Guilty">Guilty</Radio.Button>
                  <Radio.Button value="Not Guilty">
                    Not Guilty
                  </Radio.Button>
                  <Radio.Button value="N/A">
                    N/A
                  </Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
      );
    });
    const maxRowsPrepInterview = 20;
    const rowsPrepInterview = [];
    // const rowsPrep = Array.from({ length: 30 }, () => null);
    if (!visiblePrepInterview.includes(1)) {
      visiblePrepInterview.push(1);
    }

    visiblePrepInterview.forEach(row => {
      rowsPrepInterview[row - 1] = (
        <Row gutter={24} key={row} id={`row-${row}`} style={{ backgroundColor: row % 2 == 0 ? "rgba(200, 200, 200, 0.10)" : "", padding: "1em", paddingBottom: "0.2em", borderRadius: "1,5em" }}>

          <Col span={24}>
            <FormItem
              labelCol={{ span: 24 }}
              label={<span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge ${row}:`}</span>}
            >
              {this.props.form.getFieldDecorator(`allegedChargesInterview${row}`, {
                rules: [
                  {
                    // required: row === 1,
                    message: "Step required",
                  },
                ],
                initialValue: this.props.editStock ? this.props.editStock[`allegedChargesInterview${row}`] : "",
              })(
                <TextArea autoSize allowClear />
              )}
            </FormItem>
          </Col>
          <Col span={24} style={{ marginTop: "-0.5em" }}>
            <span style={{ fontWeight: 400, fontSize: 14 }}>
              <span style={{}}>
                Plea by the accused: ({" "}
                <span style={{ color: "rgb(0, 112, 168)" }}>
                  {this.props.editStock &&
                    this.props.editStock[`accusedPleaInterview${row}`]
                    ? this.props.editStock[`accusedPleaInterview${row}`]
                    : " Please select Plea"}
                </span>{" "}
                )
              </span>
            </span>
          </Col>
          <Col span={24} style={{ marginTop: "1em" }}>
            <FormItem>
              {this.props.form.getFieldDecorator(`accusedPleaInterview${row}`, {
                rules: [
                  {
                    required: false,
                  },
                ],
                // valuePropName: "checked",
                // initialValue: this.props.editStock
                //   ? this.props.editStock.accusedPleaInterview
                //   : "",
              })(
                <Radio.Group defaultValue={null} buttonStyle="solid">
                  <Radio.Button value="Guilty">Guilty</Radio.Button>
                  <Radio.Button value="Not Guilty">
                    Not Guilty
                  </Radio.Button>
                  <Radio.Button value="N/A">
                    N/A
                  </Radio.Button>
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        </Row>
      );
    });
    const ImageUpload = ({ setWheelsImage }) => {
      const [showCamera, setShowCamera] = useState(false);
      const [loading, setLoading] = useState(false);
      const [wheelsImages, setLocalWheelsImage] = useState(null);

      const handleActivateCamera = () => {
        setShowCamera(true);
      };
      const handleCloseCamera = () => {
        setShowCamera(false);
      };
      const handleTakePhoto = () => {
        setLoading(true);
        if (showCamera) {

          setShowCamera(false);
        }

      };

      const onTakePhoto = (dataUri) => {
        setLoading(false);
        setLocalWheelsImage(dataUri);
        setWheelsImage(dataUri);
      };

      return (
        <Row>
          <Col xs={24} md={24}>
            <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1.5em" }}>

              {!showCamera && <Button type="primary" onClick={handleActivateCamera}>Capture Image to Upload</Button>}
              {showCamera && (
                <>
                  <Camera
                    onTakePhoto={(dataUri) => onTakePhoto(dataUri)}
                    isImageMirror={false}
                    idealFacingMode="environment"
                    isMaxResolution={true}
                    isFullscreen={false}
                    sizeFactor={1}
                    imageType="png"
                    imageCompression={0.97}
                  />
                  {/* <Button onClick={handleTakePhoto}>Take Photo</Button> */}
                  <Button type="primary" style={{ backgroundColor: "red", borderColor: "red" }} onClick={handleCloseCamera}>Cancel</Button>
                </>
              )}
              {/* {loading && <p>Loading...</p>}
              {wheelsImage && (
                <div>
                  <img src={wheelsImage} alt="Captured" style={{ width: '200px', height: 'auto' }} />
                </div>
              )} */}
            </div>
          </Col></Row>
      );
    };
    const ImageUploadFrontRightImage = ({ setFrontRightImage }) => {
      const [showCamera, setShowCamera] = useState(false);
      const [loading, setLoading] = useState(false);
      const [frontRightImages, setLocalFrontRightImage] = useState(null);

      const handleActivateCamera = () => {
        setShowCamera(true);
      };
      const handleCloseCamera = () => {
        setShowCamera(false);
      };
      const handleTakePhoto = () => {
        setLoading(true);
        if (showCamera) {

          setShowCamera(false);
        }

      };

      const onTakePhoto = (dataUri) => {
        setLoading(false);
        setLocalFrontRightImage(dataUri);
        setFrontRightImage(dataUri);
      };

      return (
        <Row>
          <Col xs={24} md={24}>
            <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1.5em" }}>

              {!showCamera && <Button type="primary" onClick={handleActivateCamera}>Capture Image to Upload</Button>}
              {showCamera && (
                <>
                  <Camera
                    onTakePhoto={(dataUri) => onTakePhoto(dataUri)}
                    isImageMirror={false}
                    idealFacingMode="environment"
                    isMaxResolution={true}
                    isFullscreen={false}
                    sizeFactor={1}
                    imageType="png"
                    imageCompression={0.97}
                  />
                  {/* <Button onClick={handleTakePhoto}>Take Photo</Button> */}
                  <Button type="primary" style={{ backgroundColor: "red", borderColor: "red" }} onClick={handleCloseCamera}>Cancel</Button>
                </>
              )}
              {/* {loading && <p>Loading...</p>}
              {frontRightImage && (
                <div>
                  <img src={frontRightImage} alt="Captured" style={{ width: '200px', height: 'auto' }} />
                </div>
              )} */}
            </div>
          </Col></Row>
      );
    };
    const ImageUploadRightSideImage = ({ setRightSideImage }) => {
      const [showCamera, setShowCamera] = useState(false);
      const [loading, setLoading] = useState(false);
      const [rightSideImages, setLocalRightSideImage] = useState(null);

      const handleActivateCamera = () => {
        setShowCamera(true);
      };
      const handleCloseCamera = () => {
        setShowCamera(false);
      };
      const handleTakePhoto = () => {
        setLoading(true);
        if (showCamera) {

          setShowCamera(false);
        }

      };

      const onTakePhoto = (dataUri) => {
        setLoading(false);
        setLocalRightSideImage(dataUri);
        setRightSideImage(dataUri);
      };

      return (
        <Row>
          <Col xs={24} md={24}>
            <div style={{ textAlign: "center", marginTop: "1em", marginBottom: "1.5em" }}>

              {!showCamera && <Button type="primary" onClick={handleActivateCamera}>Capture Image to Upload</Button>}
              {showCamera && (
                <>
                  <Camera
                    onTakePhoto={(dataUri) => onTakePhoto(dataUri)}
                    isImageMirror={false}
                    idealFacingMode="environment"
                    isMaxResolution={true}
                    isFullscreen={false}
                    sizeFactor={1}
                    imageType="png"
                    imageCompression={0.97}
                  />
                  {/* <Button onClick={handleTakePhoto}>Take Photo</Button> */}
                  <Button type="primary" style={{ backgroundColor: "red", borderColor: "red" }} onClick={handleCloseCamera}>Cancel</Button>
                </>
              )}
              {/* {loading && <p>Loading...</p>}
              {rightSideImage && (
                <div>
                  <img src={rightSideImage} alt="Captured" style={{ width: '200px', height: 'auto' }} />
                </div>
              )} */}
            </div>
          </Col></Row>
      );
    };
    return (
      <div style={{ margin: "auto" }}>
        <Modal
          title="Please Select Date of interview"
          visible={this.state.visibleDateRequired}
          onCancel={this.handleCancel}
          footer={[
            <Button type="primary" key="back" onClick={this.handleCancel}>
              Close
            </Button>,
          ]}
        >
          {" "}
          <p style={{ fontWeight: 400, fontSize: 14 }}>
            Please Select Date of interview under "Disciplinary Interview" tab
            to continue
          </p>
        </Modal>

        <Tabs
          // defaultActiveKey={this.state.currentTab}
          animated={true}
          // onChange={callback2}
          type="card"
          // activeKey={this.state.activeKey}
          onChange={this.handleTabChange}
        >
          {this.props.editStock ? null : (
            <TabPane tab="Employee Information" key="Info">
              <span style={{ fontWeight: 750, fontSize: 20 }}>
                {this.props.editInterview === "addInterview"
                  ? "Create Disciplinary Interview"
                  : "Create Notification to Attend Enquiry"}
                <br />
                <p style={{ fontWeight: 400, fontSize: 14 }}>
                  Please fill in all fields to create new employee or select an
                  existing employee to create new{" "}
                  {this.props.editInterview === "addInterview"
                    ? "Disciplinary Interview."
                    : "Notification to Attend Enquiry."}
                </p>{" "}
              </span>{" "}
              <Form>
                <Row style={{ marginTop: "2em" }}>
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Employee Name</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeName", {
                        rules: [
                          {
                            required: true,
                            message: "Employee Name required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName
                          : "",
                      })(
                        this.state.viewSelect ? (
                          <span>
                            <Select
                              allowClear={true}
                              style={{
                                width: this.state.disableInput ? "100%" : "67%",
                              }}
                              defaultValue={
                                this.props.editStock
                                  ? this.props.editStock.employeeName
                                  : ""
                              }
                              showSearchcePlace
                              value={this.state.employeeName}
                              optionFilterProp="children"
                              onChange={value => {
                                // this.setState({
                                //   disableInput: true,
                                // });
                                const stock = this.props.stock.find(
                                  emp => emp.employeeName === value
                                );
                                this.props.form.setFieldsValue({
                                  employeeLastName: stock
                                    ? stock.employeeLastName
                                    : "",
                                  employeeName: stock ? stock.employeeName : "",
                                  employeeNumber: stock
                                    ? stock.employeeNumber
                                    : "",
                                  employeeEmail: stock
                                    ? stock.employeeEmail
                                    : "",
                                  enquiryVenue: stock ? stock.access : "",
                                  venuePlace: stock ? stock.access : "",
                                  companyName: stock ? stock.access : "",
                                  jobTitle: stock ? stock.jobTitle : "",
                                  accusedName: stock
                                    ? stock.employeeName +
                                    " " +
                                    stock.employeeLastName
                                    : "",
                                  access: stock ? stock.access : "",
                                  selectedemploymentDate: stock
                                    ? moment(
                                      stock.selectedemploymentDate
                                    ).format("DD MMMM YYYY")
                                    : "",
                                });
                              }}
                            >
                              {uniqueStock
                                .filter(employeeName => {
                                  const stock = this.props.stock.find(
                                    emp =>
                                      emp.employeeName === employeeName &&
                                      emp.access === this.props.allSitesSelected
                                  );
                                  return stock != null;
                                })
                                .map(employeeName => {
                                  const stock = this.props.stock.find(
                                    emp => emp.employeeName === employeeName
                                  );

                                  return (
                                    <Option
                                      key={employeeName}
                                      value={employeeName}
                                    >
                                      {employeeName} {stock.employeeLastName}
                                    </Option>
                                  );
                                })}
                            </Select>
                            <Button
                              style={{
                                width: "33%",
                                backgroundColor: "#fafafa",
                                display: this.state.disableInput ? "none" : "",
                              }}
                              onClick={this.handleInputEmployee}
                            >
                              New Employee
                            </Button>
                          </span>
                        ) : (
                          <Input
                            value={this.state.employeeName}
                            addonAfter={
                              <span onClick={this.handleSelectEmployee}>
                                Select Employee
                              </span>
                            }
                          />
                        )
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />{" "}
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Employee Last Name</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeLastName", {
                        rules: [
                          {
                            required: true,
                            message: "Employee Last Name required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input
                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />{" "}
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Contact Number</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeNumber", {
                        rules: [
                          {
                            required: false,
                            message: "Employee Number required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeNumber
                          : "",
                      })(
                        <Input
                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Email</span>}
                    >
                      {this.props.form.getFieldDecorator("employeeEmail", {
                        rules: [
                          {
                            required: false,
                            message: "Employee Email required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeEmail
                          : "",
                      })(
                        <Input
                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />{" "}
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Job Title</span>}
                    >
                      {this.props.form.getFieldDecorator("jobTitle", {
                        rules: [
                          {
                            required: false,
                            message: "Job Title required",
                          },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.jobTitle
                          : "",
                      })(
                        <Input
                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={1} />
                  <Col span={7}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Assign to</span>}
                    >
                      {this.props.form.getFieldDecorator("access", {
                        rules: [
                          {
                            required: true,
                            message: "Access required",
                          },
                        ],
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.access
                        //   : this.state.siteSelected,
                      })(
                        <Select showSearch>
                          {this.props.sites.map(site => (
                            <Option key={site.name} value={site.name}>
                              {site.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={7}>
                    {this.state.viewSelect ? (
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Employment Date</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "selectedemploymentDate",
                          {
                            rules: [
                              {
                                required: true,
                                message: "Job Title required",
                              },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock.selectedemploymentDate
                              : "",
                          }
                        )(
                          <Input
                          // disabled
                          // type={"number"}
                          />
                        )}
                      </FormItem>
                    ) : (
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>Employment Date</span>}
                      >
                        {this.props.form.getFieldDecorator(
                          "selectedemploymentDate",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                          }
                        )(
                          <DatePicker
                            placeholder="Select Date"
                            value={this.state.selectedemploymentDate}
                            onChange={this.handleEmploymentDateChange}
                            format={dateFormat}
                          />
                        )}
                      </FormItem>
                    )}
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                  <Button
                    type="primary"
                    shape="round"
                    onClick={this.props.onOk}
                    size="large"
                    style={{
                      position: "fixed",
                      bottom: "6.5em",
                      right: "1em",
                      fontSize: "14px",
                      width: "4em",
                      height: "4em",
                      //height: "3em",
                      marginLeft: "0.7em",
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip>
                <Tooltip placement="left" title="Cancel">
                  <Button
                    shape="round"
                    onClick={this.props.onCancel}
                    style={{
                      fontSize: "14px",
                      width: "4em",
                      height: "4em",
                      position: "fixed",
                      bottom: "2em",
                      right: "1em",
                      border: "1px solid #EEEFF0",
                      backgroundColor: "red",
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip> */}
              </Form>
            </TabPane>
          )}
          {this.props.editInterview !== "editInterview" &&
            this.props.editInterview !== "addInterview" &&
            this.props.addNewNotification === false && this.props.selectedStockIds.length === 0 ? (
            <TabPane
              tab="Notification"
              key="Notification"
              onClick={e =>
                this.setState({
                  currentTab: "Notification",
                })
              }
            >
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Notification to Attend Enquiry
              </span>

              <Form>
                <Row
                  style={{
                    marginTop: "1em",
                  }}
                >
                  <Col xs={12} md={11}>
                    <FormItem label={<span>To</span>}>
                      {this.props.form.getFieldDecorator("accusedName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input
                          disabled

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={12} md={11}>
                    <FormItem label={<span>From</span>}>
                      {this.props.form.getFieldDecorator("access", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.access
                          : "",
                      })(
                        <Input
                          disabled

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <p style={{ marginBottom: "0em", paddingTop: "2em", borderTop: "1px solid #e9e9e9", }}>
                  1.1 Alleged Charge(s)&nbsp;{" "} </p>
                <br />
                {rowsPrep}
                <Row
                  style={{
                    // marginTop: "-1em",
                    paddingBottom: "1em",
                  }}
                >
                  <Col xs={24} md={24}>
                    {visiblePrep.length < maxRowsPrep && (
                      <Button
                        style={{ margin: "0.5em", float: "right" }}
                        type="primary"
                        onClick={this.showNextPrep}
                      >
                        <PlusCircleOutlined /> Add Charge
                      </Button>
                    )}
                  </Col> </Row>
                <Row
                  style={{
                    // marginTop: "-1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                    paddingBottom: "2em",
                  }}
                >


                  {/* <Col xs={0} md={2} />{" "} */}
                  <Col xs={24} md={11}>
                    <span>
                      1.2 Date and time of enquiry
                      {this.props.editStock &&
                        this.props.editStock.casePostponed === "Yes" ? (
                        <br />
                      ) : null}
                      {" ("}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock &&
                          this.props.editStock.enquiryDate
                          ? moment(this.props.editStock.enquiryDate).format(
                            "DD MMMM YYYY"
                          )
                          : " Please set a date"}
                      </span>{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {" "}
                        {this.props.editStock &&
                          this.props.editStock.enquiryTime
                          ? moment(this.props.editStock.enquiryTime).format(
                            "HH:mm"
                          )
                          : null}
                      </span>{" "}
                      {this.props.editStock &&
                        this.props.editStock.casePostponed === "Yes" ? (
                        <span>
                          {" "}
                          - Postponed to :{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDatePostponed
                              ? moment(
                                this.props.editStock.enquiryDatePostponed
                              ).format("DD MMMM YYYY")
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTimePostponed
                              ? moment(
                                this.props.editStock.enquiryTimePostponed
                              ).format("HH:mm")
                              : null}
                          </span>
                        </span>
                      ) : null}
                      {")"}
                    </span>
                    <br />
                    <div style={{ display: "inline-flex" }}>
                      <FormItem>
                        {this.props.form.getFieldDecorator("enquiryDate", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                        })(
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Select Date"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            format={dateFormat}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {this.props.form.getFieldDecorator("enquiryTime", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                        })(
                          <TimePicker
                            style={{ marginLeft: "1em", width: "100%" }}
                            placeholder="Select Time"
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange}
                            format={timeFormat}
                          />
                        )}
                      </FormItem>
                    </div>
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>1.3 Venue of enquiry</span>}
                    >
                      {this.props.form.getFieldDecorator("enquiryVenue", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.enquiryVenue === "" ||
                            this.props.editStock.enquiryVenue === null ||
                            this.props.editStock.enquiryVenue === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.enquiryVenue
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Case Postponed : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.casePostponed
                            ? this.props.editStock.casePostponed
                            : " No"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem
                    // labelCol={{ span: 24 }}
                    // label={<span>Case Postponed ?</span>}
                    >
                      {this.props.form.getFieldDecorator("casePostponed", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        // valuePropName: "checked",
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.accusedPleaInterview
                        //   : "",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button
                            onClick={this.selectPostponed}
                            value="Yes"
                          >
                            Yes
                          </Radio.Button>
                          <Radio.Button
                            onClick={this.deSelectPostponed}
                            value="No"
                          >
                            No
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                  {this.state.isPostponed === true ? (
                    <span>
                      <Col span={24} style={{ marginTop: "-1em" }}>
                        <span>
                          Postponed to :{" ("}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDatePostponed
                              ? moment(
                                this.props.editStock.enquiryDatePostponed
                              ).format("DD MMMM YYYY")
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTimePostponed
                              ? moment(
                                this.props.editStock.enquiryTimePostponed
                              ).format("HH:mm")
                              : null}
                          </span>
                          {")"}
                        </span>
                        <br />
                      </Col>
                      <Col span={24}>
                        <div style={{ display: "inline-flex" }}>
                          <FormItem>
                            {this.props.form.getFieldDecorator(
                              "enquiryDatePostponed",
                              {
                                rules: [
                                  // {
                                  //   required: true,
                                  //   message: "Employee Name required",
                                  // },
                                ],
                              }
                            )(
                              <DatePicker
                                style={{ width: "100%" }}
                                placeholder="Postponed Date"
                                value={this.state.selectedDate}
                                onChange={this.handleDateChange}
                                format={dateFormat}
                              />
                            )}
                          </FormItem>
                          <FormItem>
                            {this.props.form.getFieldDecorator(
                              "enquiryTimePostponed",
                              {
                                rules: [
                                  // {
                                  //   required: true,
                                  //   message: "Employee Name required",
                                  // },
                                ],
                              }
                            )(
                              <TimePicker
                                style={{ marginLeft: "1em", width: "100%" }}
                                placeholder="Postponed Time"
                                value={this.state.selectedTime}
                                onChange={this.handleTimeChange}
                                format={timeFormat}
                              />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                    </span>
                  ) : null}
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Ruling : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.hasRuling
                            ? this.props.editStock.hasRuling
                            : " "}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem
                    // labelCol={{ span: 24 }}
                    // label={<span>Case Postponed ?</span>}
                    >
                      {this.props.form.getFieldDecorator("hasRuling", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        // valuePropName: "checked",
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.accusedPleaInterview
                        //   : "",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button
                            onClick={this.selectRuling}
                            value="Yes"
                          >
                            Yes
                          </Radio.Button>
                          <Radio.Button
                            onClick={this.deSelectRuling}
                            value="No"
                          >
                            No
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                  {this.props.editStock && this.props.editStock.hasRuling === "Yes" && this.state.isRuling != false || this.state.isRuling === true ? (

                    <Col span={24} style={{ marginTop: "-1em" }}>
                      <FormItem style={{ width: "100%" }}
                      // labelCol={{ span: 24 }}
                      // label={<span style={{ color: "rgb(0, 112, 168)" }}>{`Alleged Charge:`}</span>}
                      >
                        {this.props.form.getFieldDecorator(`finalRuling`, {
                          rules: [
                            {
                              // required: row === 1,
                              message: "Step required",
                            },
                          ],
                          initialValue: this.props.editStock ? this.props.editStock.finalRuling : "",
                        })(
                          <TextArea style={{ width: "100%" }} placeholder='Ruling' autoSize allowClear />
                        )}
                      </FormItem>
                    </Col>

                  ) : null}
                </Row>

                <Row
                  style={{
                    marginTop: "-0.5em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "3em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>
                        {" "}
                        1.4 Take note of your rights as set out hereunder:
                      </span>
                      <br />
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        Please inform{" "}
                        <span style={{ color: "#0070A8" }}>
                          Company Representative
                        </span>{" "}
                        by no later than{" "}
                        <span style={{ color: "#0070A8" }}>
                          {" "}
                          {this.props.editStock
                            ? this.props.editStock.enquiryDate
                              ? moment(this.props.editStock.enquiryDate)
                                .subtract(1, "day")
                                .format("DD MMMM YYYY")
                              : "select Date"
                            : null}
                        </span>{" "}
                        at{" "}
                        <span style={{ color: "#0070A8" }}>
                          {" "}
                          {this.props.editStock
                            ? this.props.editStock.enquiryTime
                              ? moment(this.props.editStock.enquiryTime).format(
                                "HH:mm"
                              )
                              : "select Time"
                            : null}
                        </span>{" "}
                        of the under mentioned:
                      </span>
                    </span>
                  </Col>
                </Row>
                <Row style={{ marginTop: "2em" }}>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Name of your representative</span>}
                    >
                      {this.props.form.getFieldDecorator("representativeName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.representativeName
                          : "",
                      })(
                        <Input
                        // disabled
                        // type={"number"}
                        />
                      )}
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 13,
                          color: "grey",
                          lineHeight: "1.5em",
                        }}
                      >
                        (You are entitled to representation by any co-employee
                        available during the scheduled enquiry)
                      </p>
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Name(s) of your witnesses</span>}
                    >
                      {this.props.form.getFieldDecorator("witnessesName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.witnessesName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: 13,
                          color: "grey",
                          lineHeight: "1.5em",
                        }}
                      >
                        (It remains your responsibility to arrange availability
                        of yoor witnesses during scheduled enquiry)
                      </p>
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Do you intend to make use of a interpreter : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.interpreterNeeded
                            ? this.props.editStock.interpreterNeeded
                            : " Please select an option"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator("interpreterNeeded", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        // valuePropName: "checked",
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.accusedPleaInterview
                        //   : "",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button onClick={this.selectManual} value="Yes">
                            Yes
                          </Radio.Button>
                          <Radio.Button
                            onClick={this.deSelectManual}
                            value="No"
                          >
                            No
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                {this.state.interpIsYes === true ? (
                  <Row style={{ marginTop: "0em" }}>
                    <Col xs={24} md={11}>
                      <FormItem
                        labelCol={{ span: 24 }}
                        label={<span>In which language?</span>}
                      >
                        {this.props.form.getFieldDecorator("interpreterLang", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.interpreterLang
                            : "",
                        })(
                          <Select>
                            <Option key="Afrikaans" value="Afrikaans">
                              Afrikaans
                            </Option>
                            <Option key="English" value="English">
                              English
                            </Option>
                            <Option key="Zulu" value="Zulu">
                              Zulu
                            </Option>
                            <Option key="Xhosa" value="Xhosa">
                              Xhosa
                            </Option>
                            <Option key="Northern Sotho" value="Northern Sotho">
                              Northern Sotho
                            </Option>
                            <Option key="Tswana" value="Tswana">
                              Tswana
                            </Option>
                            <Option key="Sotho" value="Sotho">
                              Sotho
                            </Option>
                            <Option key="Tsonga" value="Tsonga">
                              Tsonga
                            </Option>
                            <Option key="Swati" value="Swati">
                              Swati
                            </Option>
                            <Option key="Venda" value="Venda">
                              Venda
                            </Option>
                            <Option key="Ndebele" value="Ndebele">
                              Ndebele
                            </Option>
                            <Option
                              key="SA Sign Language"
                              value="SA Sign Language"
                            >
                              SA Sign Language
                            </Option>
                          </Select>
                        )}
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 13,
                            color: "grey",
                            lineHeight: "1.5em",
                          }}
                        >
                          (If an interpreter is required such an interpreter
                          will be allocated by management)
                        </p>
                      </FormItem>
                    </Col>
                  </Row>
                ) : null}
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Do you need any time to prepare : ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.preparationNeeded
                            ? this.props.editStock.preparationNeeded
                            : " Please select an option"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator("preparationNeeded", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        // valuePropName: "checked",
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.accusedPleaInterview
                        //   : "",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="Yes">Yes</Radio.Button>
                          <Radio.Button value="No">No</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "3em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>
                        1.5 Any further information concerning the obove
                        mentioned enquiry can be obtained from the person
                        mentioned in 1.4 above.
                      </span>
                      <br />
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        Note: that if you fail to attend the scheduled
                        proceedings it will continue in your absence and you
                        will waive all rights in this respect.
                      </span>
                    </span>
                  </Col>
                </Row>

                <Row style={{ marginTop: "1em" }}>
                  <Col xs={24} md={24}>
                    <span>
                      <Cards
                        style={{
                          width: "100%",
                          // border: "1px solid #009bdf",
                          textAlign: "center",
                          marginBottom: 15,
                        }}
                        // extra={
                        //   this.props.editStock ? (
                        //     this.props.editStock.frontImage ? (
                        //       <div
                        //         onClick={e =>
                        //           this.setState({ sigVisible: true })
                        //         }
                        //       >
                        //         <span style={{ color: "red" }}>
                        //           New Signature
                        //         </span>
                        //       </div>
                        //     ) : null
                        //   ) : null
                        // }
                        title={
                          <span style={{ color: "#009bdf" }}>
                            Accused Signature
                          </span>
                        }
                        cover={
                          this.props.editStock ? (
                            this.state.frontImage &&
                              this.state.sigVisible === false ? (
                              <span>
                                <img
                                  style={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                  alt="example"
                                  src={
                                    this.props.editStock.frontImage.search(
                                      "data"
                                    ) > -1
                                      ? this.props.editStock.frontImage
                                      : process.env.REACT_APP_GLOBAL_SERVER +
                                      "asambo/api/images/" +
                                      this.props.editStock.frontImage
                                  }
                                />
                                <br />
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                  onClick={this.RedoSignature}
                                >
                                  Redo
                                </Button>
                              </span>
                            ) : this.state.frontImage &&
                              this.state.sigVisible === "redo" ? (
                              <span>
                                <p
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: "black",
                                    marginTop: "8em",
                                    marginBottom: "3em",
                                  }}
                                >
                                  Remember to save to store{" "}
                                  <span style={{ color: "rgb(0, 112, 168)" }}>
                                    {this.props.editStock
                                      ? this.props.editStock.employeeName
                                      : null}{" "}
                                    {this.props.editStock
                                      ? this.props.editStock.employeeLastName
                                      : null}
                                  </span>
                                  's signature.
                                </p>
                                <br />
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                  onClick={this.RedoSignature}
                                >
                                  Redo
                                </Button>
                              </span>
                            ) : (
                              <span
                                style={{
                                  margin: "auto",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  style={{
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                >
                                  <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{
                                      width: 500,
                                      height: 300,
                                      margin: "auto",
                                    }}
                                    onBegin={this.handleOnStart}
                                    onEnd={this.handleOnEnd}
                                    ref={ref => {
                                      this.signaturePad = ref;
                                    }}
                                  />
                                </span>
                                <br />
                                <div
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#009BDF",
                                      border: "1px solid #009BDF",
                                      height: "3em",
                                      width: "7em",
                                    }}
                                    onClick={this.clearSignature}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                      backgroundColor: "#009BDF",
                                      border: "1px solid #009BDF",
                                      marginLeft: "0.5em",
                                      height: "3em",
                                      width: "7em",
                                    }}
                                    onClick={this.saveSignature}
                                  >
                                    Save
                                  </Button>
                                  {isDrawing && <div>Signing...</div>}
                                  <Upload
                                    name="frontImage"
                                    showUploadList={false}
                                    beforeUpload={this.beforeUpload}
                                    customRequest={this.handleImageUpload}
                                    onChange={this.handleFrontImage}
                                    loading={this.state.loading}
                                  />
                                </div>
                              </span>
                            )
                          ) : (
                            <span
                              style={{
                                margin: "auto",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  borderBottom: "1px solid #009bdf",
                                }}
                              >
                                <SignatureCanvas
                                  penColor="black"
                                  canvasProps={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                  }}
                                  onBegin={this.handleOnStart}
                                  onEnd={this.handleOnEnd}
                                  ref={ref => {
                                    this.signaturePad = ref;
                                  }}
                                />
                              </span>
                              <br />
                              <div
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "1em",
                                }}
                              >
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                  }}
                                  onClick={this.clearSignature}
                                >
                                  Clear
                                </Button>
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "#009BDF",
                                    border: "1px solid #009BDF",
                                    marginLeft: "0.5em",
                                    height: "3em",
                                    width: "7em",
                                  }}
                                  onClick={this.saveSignature}
                                >
                                  Save
                                </Button>
                                {isDrawing && <div>Signing...</div>}
                                <Upload
                                  name="frontImage"
                                  showUploadList={false}
                                  beforeUpload={this.beforeUpload}
                                  customRequest={this.handleImageUpload}
                                  onChange={this.handleFrontImage}
                                  loading={this.state.loading}
                                />
                              </div>
                            </span>
                          )
                        }
                      />
                    </span>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                        marginTop: "-0em",
                      }}
                    >
                      I{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock
                          ? this.props.editStock.employeeName
                          : null}{" "}
                        {this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : null}{" "}
                      </span>
                      the accused hereby acknowledge receipt of this document
                      and declare that I understand the contents thereof.
                    </p>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "refuseSignNotification",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          valuePropName: "checked",
                          initialValue: this.props.editStock
                            ? this.props.editStock.refuseSignNotification
                              ? true
                              : false
                            : false,
                        }
                      )(
                        <Checkbox
                          style={{ marginTop: "0em", lineHeight: "1em" }}
                        >
                          Refuse to Sign
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24} style={{ marginTop: "-1em" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Refuse Reason</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "refuseReasonNotification",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.refuseReasonNotification
                            : "",
                        }
                      )(<TextArea rows={4} />)}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={12}>
                    <Cards
                      style={{
                        width: "100%",
                        border: "1px solid rgb(0, 155, 223)",
                        marginBottom: 10,
                      }}
                      // extra={
                      //   this.props.editStock.frontRightImage ? (
                      //     <div
                      //       onClick={e =>
                      //         this.setState({ frontRightImage: null })
                      //       }
                      //     >
                      //       <CloseCircleFilled style={{ color: "red" }} />
                      //       <span style={{ color: "red" }}>Remove</span>
                      //     </div>
                      //   ) : (
                      //     ""
                      //   )
                      // }
                      title={
                        <span style={{ color: "rgb(0, 155, 223)" }}>
                          Client Notification Document
                        </span>
                      }
                      cover={

                        this.props.editStock ? (
                          this.state.frontRightImages != undefined ?
                            <Image
                              style={{
                                // width: "10em",
                                objectFit: "contain",
                                margin: "auto",
                              }}
                              alt="example"
                              src={
                                this.state.frontRightImages
                              }
                            />
                            :
                            this.props.editStock.frontRightImage ? (
                              <Image
                                style={{
                                  // width: "10em",
                                  objectFit: "contain",
                                  margin: "auto",
                                }}
                                alt="example"
                                src={
                                  this.props.editStock.frontRightImage.search(
                                    "data"
                                  ) > -1
                                    ? this.props.editStock.frontRightImage
                                    : process.env.REACT_APP_GLOBAL_SERVER +
                                    "asambo/api/images/" +
                                    this.props.editStock.frontRightImage
                                }
                              />
                            ) : null
                        ) : null
                      }
                      actions={[
                        <Upload
                          maxCount={1}
                          name="frontRightImage"
                          showUploadList={true}
                          beforeUpload={this.beforeUpload}
                          customRequest={this.handleImageUpload}
                          onChange={this.handleFrontRightImage}
                          loading={this.state.loading}
                          accept="image/*"
                        >
                          {/* <Button>Upload Image From Files</Button> */}
                        </Upload>

                      ]}
                    />
                    <ImageUploadFrontRightImage
                      setFrontRightImage={this.handleSetFrontRightImage} // Pass the function as prop
                    />
                  </Col>
                </Row>

                <Row style={{ marginTop: "2em" }}>
                  <Col span={24}>
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={this.showModalNotif}
                      // onClick={this.onAddNotification}
                      icon={<MailFilled style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "#009BDF",
                        border: "1px solid #009BDF",

                        height: "3em",
                        width: "12em",
                      }}
                    >
                      Send Notification
                    </Button> */}
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={this.generateNotificationPdf}
                      icon={<DownloadOutlined style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "rgb(0, 21, 41)",
                        border: "1px solid rgb(0, 21, 41)",
                        marginBottom: "3em",
                        height: "3em",
                        width: "16em",
                      }}
                    >
                      Download Notification PDF
                    </Button> */}
                    <Modal
                      style={{ top: 20, minWidth: "90vw" }}
                      title={
                        this.props.editStock
                          ? "Alleged Charge(s) " +
                          this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : ""
                      }
                      visible={this.state.visibleChargesNotif}
                      onOk={this.handleOkChargesNotif}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button
                          key="back"
                          type="primary"
                          onClick={this.handleCancel}
                        >
                          Close Annexure “A”
                        </Button>,
                      ]}
                    >
                      <FormItem>
                        {this.props.form.getFieldDecorator("allegedCharges", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.allegedCharges
                            : "",
                        })(
                          <TextArea
                            style={{ color: "black" }}
                            disabled
                            // placeholder="Autosize height with minimum and maximum number of lines"
                            autoSize={{ minRows: 1, maxRows: 200 }}
                          />
                        )}
                      </FormItem>
                    </Modal>
                    <Modal
                      title="Send Notification"
                      visible={this.state.visibleSendNotif}
                      onOk={this.handleSubmitNotif}
                      okText="Send"
                      onCancel={this.handleCancel}
                    >
                      <Select
                        style={{ width: "100%" }}
                        type="emailNotification"
                        // value={this.state.sendEmailNotification}
                        onChange={this.handleEmailChangeNotification}
                        placeholder="Enter Email"
                      >
                        {this.props.sites.flatMap((site, index) =>
                          this.props.editStock ? (
                            this.props.editStock.access === site.name ? (
                              Array.isArray(site.email) ? (
                                site.email.map(email => (
                                  <Option
                                    key={`${email}-${index}`}
                                    value={email.trim()}
                                  >
                                    {email.trim()}
                                  </Option>
                                ))
                              ) : (
                                <Option
                                  key={`${site.email}-${index}`}
                                  value={site.email.trim()}
                                >
                                  {site.email.trim()}
                                </Option>
                              )
                            ) : null
                          ) : null
                        )}
                      </Select>

                      {/* <Input
                        type="emailNotification"
                        value={this.state.sendEmailNotification}
                        onChange={this.handleEmailChangeNotification}
                        placeholder="Enter Email"
                      /> */}
                    </Modal>
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.props.onOk}
                  size="large"
                  style={{
                    position: "fixed",
                    bottom: "6.5em",
                    right: "1em",
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
              <Tooltip placement="left" title="Cancel">
                <Button
                  shape="round"
                  onClick={this.props.onCancel}
                  style={{
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    position: "fixed",
                    bottom: "2em",
                    right: "1em",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "red",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip> */}
              </Form>
            </TabPane>
          ) : null}
          {this.props.editInterview !== "editInterview" &&
            this.props.editInterview !== "addInterview" &&
            this.props.addNewNotification === false ? (
            <TabPane tab="Disciplinary Enquiry" key="Disciplinary Enquiry">
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Disciplinary Enquiry
              </span>{" "}
              <Form>
                <Row style={{ marginTop: "2em" }}>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Company Name</span>}
                    >
                      {this.props.form.getFieldDecorator("companyName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.companyName === "" ||
                            this.props.editStock.companyName === null ||
                            this.props.editStock.companyName === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.companyName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <span>
                      Date and time of disciplinary proceedings
                      <br />
                      {this.props.editStock &&
                        this.props.editStock.casePostponed === "Yes" ? (
                        <span>
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDatePostponed
                              ? moment(
                                this.props.editStock.enquiryDatePostponed
                              ).format("DD MMMM YYYY")
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTimePostponed
                              ? moment(
                                this.props.editStock.enquiryTimePostponed
                              ).format("HH:mm")
                              : null}
                          </span>
                        </span>
                      ) : (
                        <span>
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {this.props.editStock &&
                              this.props.editStock.enquiryDate
                              ? moment(this.props.editStock.enquiryDate).format(
                                "DD MMMM YYYY"
                              )
                              : " Please set a date"}
                          </span>{" "}
                          <span style={{ color: "rgb(0, 112, 168)" }}>
                            {" "}
                            {this.props.editStock &&
                              this.props.editStock.enquiryTime
                              ? moment(this.props.editStock.enquiryTime).format(
                                "HH:mm"
                              )
                              : null}
                          </span>
                        </span>
                      )}
                    </span>
                    <br />
                    <br />
                    {/* <div style={{ display: "inline-flex" }}>
                      <FormItem>
                        {this.props.form.getFieldDecorator(
                          "disciplinaryProceedingsDate",
                          {
                            rules: [
                      
                            ],
                          }
                        )(
                          <DatePicker
                            placeholder="Select Date"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            format={dateFormat}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {this.props.form.getFieldDecorator(
                          "disciplinaryProceedingsTime",
                          {
                            rules: [
           
                            ],
                          }
                        )(
                          <TimePicker
                            style={{ marginLeft: "1em" }}
                            placeholder="Select Time"
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange}
                            format={timeFormat}
                          />
                        )}
                      </FormItem>
                    </div> */}
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Initiator</span>}
                    >
                      {this.props.form.getFieldDecorator("initiatorName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.initiatorName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Accused Name</span>}
                    >
                      {this.props.form.getFieldDecorator("accusedName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Position</span>}
                    >
                      {this.props.form.getFieldDecorator("jobTitle", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.jobTitle
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Representative</span>}
                    >
                      {this.props.form.getFieldDecorator("representativeName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.representativeName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator("chairpersonName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.chairpersonName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Venue / Place</span>}
                    >
                      {this.props.form.getFieldDecorator("enquiryVenue", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.enquiryVenue === "" ||
                            this.props.editStock.enquiryVenue === null ||
                            this.props.editStock.enquiryVenue === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.enquiryVenue
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />{" "} */}
                  <p style={{ marginBottom: "0em", paddingTop: "2em", borderTop: "1px solid #e9e9e9", }}>
                    Alleged Charge(s)&nbsp;{" "} </p>
                  <br />
                  {rowsPrepEnquiry}
                  {/* <Row
                    style={{
                      // marginTop: "-1em",
                      paddingBottom: "1em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {visiblePrep.length < maxRowsPrep && (
                        <Button
                          style={{ margin: "0.5em", float: "right" }}
                          type="primary"
                          onClick={this.showNextPrep}
                        >
                          <PlusCircleOutlined /> Add Charge
                        </Button>
                      )}
                    </Col>
                  </Row> */}
                </Row>

                {/* <Row
                  style={{
                    marginTop: "2em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Plea by the accused: ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.accusedPlea
                            ? this.props.editStock.accusedPlea
                            : " Please select Plea"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator("accusedPlea", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        // valuePropName: "checked",
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.accusedPleaInterview
                        //   : "",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="Guilty">Guilty</Radio.Button>
                          <Radio.Button value="Not Guilty">
                            Not Guilty
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row> */}

                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >


                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Date of employment</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "selectedemploymentDate",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? moment(
                              this.props.editStock.selectedemploymentDate
                            ).format("DD MMMM YYYY")
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                    {/* <span
                      style={{ color: "rgb(0, 112, 168)", lineHeight: "3em" }}
                    >
                      {this.props.editStock
                        ? moment(
                            this.props.editStock.selectedemploymentDate
                          ).format("DD MMMM YYYY")
                        : null}
                    </span> */}
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Valid previous transgressions</span>}
                    >
                      {this.props.form.getFieldDecorator("prevTransgressions", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.prevTransgressions
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Mitigation circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "mitigationCircumstances",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.mitigationCircumstances
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Aggrivating circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "aggrivatingCircumstances",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.aggrivatingCircumstances
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={24}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={
                        <span>Determination and finding by chairperson</span>
                      }
                    >
                      {this.props.form.getFieldDecorator(
                        "determinationFinding",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.determinationFinding
                            : "",
                        }
                      )(
                        <TextArea rows={4}

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict by Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator("chairpersonVerdict", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.chairpersonVerdict
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col> */}
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Suspension: ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.suspensionType
                            ? this.props.editStock.suspensionType
                            : " Please select suspension"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator("suspensionType", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        // valuePropName: "checked",
                        // initialValue: this.props.editStock
                        //   ? this.props.editStock.accusedPleaInterview
                        //   : "",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="Paid">Paid</Radio.Button>
                          <Radio.Button value="Unpaid">Unpaid</Radio.Button>
                          <Radio.Button value="N/A">N/A</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1.5em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Sanction / Warning (Months) : <br />({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.sanctionMonthsDisciplinary
                            ? this.props.editStock.sanctionMonthsDisciplinary +
                            " Months /"
                            : " Please select Sanction"}
                        </span>{" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock && this.props.editStock.warning
                            ? this.props.editStock.warning
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "sanctionMonthsDisciplinary",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          // valuePropName: "checked",
                          // initialValue: this.props.editStock
                          //   ? this.props.editStock.accusedPleaInterview
                          //   : "",
                        }
                      )(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="3">3</Radio.Button>
                          <Radio.Button value="6">6</Radio.Button>
                          <Radio.Button value="9">9</Radio.Button>
                          <Radio.Button value="12">12</Radio.Button>
                          {/* <Radio.Button value="Final">Final</Radio.Button> */}
                          <Radio.Button value="N/A">N/A</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                    <FormItem>
                      {this.props.form.getFieldDecorator("warning", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        valuePropName: "checked",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Written Warning"
                          >
                            Written Warning
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Final Written Warning"
                          >
                            Final Written Warning
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="All Inclusive Final Written Warning"
                          >
                            All Inclusive Final Written Warning
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Consultation"
                          >
                            Consultation
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Dismissed"
                          >
                            Dismissed
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Suspended"
                          >
                            Suspended
                          </Radio.Button>
                          <Radio.Button style={{ margin: "0.6em" }} value="N/A">
                            N/A
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>

                  <Col span={24} style={{}}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict</span>}
                    >
                      {this.props.form.getFieldDecorator("otherWarnings", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.otherWarnings
                          : "",
                      })(<TextArea rows={4} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>Right to Refer</span>
                      <br />
                      <span style={{ fontWeight: 400, fontSize: 14 }}>
                        You have the right to refer conciliation within thirty
                        (30) days to relevant forum/council.
                      </span>
                    </span>
                  </Col>
                </Row>

                <Row style={{ marginTop: "1em" }}>
                  <Col xs={24} md={24}>
                    <span>
                      <Cards
                        style={{
                          width: "100%",
                          // border: "1px solid #009bdf",
                          textAlign: "center",
                          marginBottom: 15,
                        }}
                        // extra={
                        //   this.props.editStock ? (
                        //     this.props.editStock.frontImage ? (
                        //       <div
                        //         onClick={e =>
                        //           this.setState({ sigVisible: true })
                        //         }
                        //       >
                        //         <span style={{ color: "red" }}>
                        //           New Signature
                        //         </span>
                        //       </div>
                        //     ) : null
                        //   ) : null
                        // }
                        title={
                          <span style={{ color: "#009bdf" }}>
                            Accused Signature
                          </span>
                        }
                        cover={
                          this.props.editStock ? (
                            this.state.branchImage &&
                              this.state.sigVisible === false ? (
                              <span>
                                <img
                                  style={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                  alt="example"
                                  src={
                                    this.props.editStock.branchImage.search(
                                      "data"
                                    ) > -1
                                      ? this.props.editStock.branchImage
                                      : process.env.REACT_APP_GLOBAL_SERVER +
                                      "asambo/api/images/" +
                                      this.props.editStock.branchImage
                                  }
                                />
                                <br />
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                  onClick={this.RedoSignature}
                                >
                                  Redo
                                </Button>
                              </span>
                            ) : this.state.branchImage &&
                              this.state.sigVisible === "redo" ? (
                              <span>
                                <p
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: "black",
                                    marginTop: "8em",
                                    marginBottom: "3em",
                                  }}
                                >
                                  Remember to save to store{" "}
                                  <span style={{ color: "rgb(0, 112, 168)" }}>
                                    {this.props.editStock
                                      ? this.props.editStock.employeeName
                                      : null}{" "}
                                    {this.props.editStock
                                      ? this.props.editStock.employeeLastName
                                      : null}
                                  </span>
                                  's signature.
                                </p>
                                <br />
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                  onClick={this.RedoSignature}
                                >
                                  Redo
                                </Button>
                              </span>
                            ) : (
                              <span
                                style={{
                                  margin: "auto",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  style={{
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                >
                                  <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{
                                      width: 500,
                                      height: 300,
                                      margin: "auto",
                                    }}
                                    onBegin={this.handleOnStart}
                                    onEnd={this.handleOnEnd}
                                    ref={ref => {
                                      this.signaturePadEnquiry = ref;
                                    }}
                                  />
                                </span>
                                <br />
                                <div
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#009BDF",
                                      border: "1px solid #009BDF",
                                      height: "3em",
                                      width: "7em",
                                    }}
                                    onClick={this.clearSignatureEnquiry}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                      backgroundColor: "#009BDF",
                                      border: "1px solid #009BDF",
                                      marginLeft: "0.5em",
                                      height: "3em",
                                      width: "7em",
                                    }}
                                    onClick={this.saveSignatureEnquiry}
                                  >
                                    Save
                                  </Button>
                                  {isDrawing && <div>Signing...</div>}
                                  <Upload
                                    name="branchImage"
                                    showUploadList={false}
                                    beforeUpload={this.beforeUpload}
                                    customRequest={this.handleImageUpload}
                                    onChange={this.handleBranchImage}
                                    loading={this.state.loading}
                                  />
                                </div>
                              </span>
                            )
                          ) : (
                            <span
                              style={{ margin: "auto", textAlign: "center" }}
                            >
                              <span
                                style={{ borderBottom: "1px solid #009bdf" }}
                              >
                                <SignatureCanvas
                                  penColor="black"
                                  canvasProps={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                  }}
                                  onBegin={this.handleOnStart}
                                  onEnd={this.handleOnEnd}
                                  ref={ref => {
                                    this.signaturePadEnquiry = ref;
                                  }}
                                />
                              </span>
                              <br />
                              <div
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "1em",
                                }}
                              >
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                  }}
                                  onClick={this.clearSignatureEnquiry}
                                >
                                  Clear
                                </Button>
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "#009BDF",
                                    border: "1px solid #009BDF",
                                    marginLeft: "0.5em",
                                    height: "3em",
                                    width: "7em",
                                  }}
                                  onClick={this.saveSignatureEnquiry}
                                >
                                  Save
                                </Button>
                                {isDrawing && <div>Signing...</div>}
                                <Upload
                                  name="branchImage"
                                  showUploadList={false}
                                  beforeUpload={this.beforeUpload}
                                  customRequest={this.handleImageUpload}
                                  onChange={this.handleBranchImage}
                                  loading={this.state.loading}
                                />
                              </div>
                            </span>
                          )
                        }
                      />
                    </span>

                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                        marginTop: "-0em",
                      }}
                    >
                      I{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock
                          ? this.props.editStock.employeeName
                          : null}{" "}
                        {this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : null}{" "}
                      </span>
                      by confirm that I understand the nature of the charges and
                      my right to refer, I furthermore confirm receipt of a copy
                      of this document.
                    </p>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "refuseSignDisciplinaryEnquiry",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          valuePropName: "checked",
                          initialValue: this.props.editStock
                            ? this.props.editStock.refuseSignDisciplinaryEnquiry
                              ? true
                              : false
                            : false,
                        }
                      )(
                        <Checkbox
                          style={{ marginTop: "0em", lineHeight: "1em" }}
                        >
                          Refuse to Sign
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24} style={{ marginTop: "-1em" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Refuse Reason</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "refuseReasonDisciplinaryEnquiry",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock
                              .refuseReasonDisciplinaryEnquiry
                            : "",
                        }
                      )(<TextArea rows={4} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    // marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>
                        Statement of Accused
                      </span>
                      <br /> <br />
                      <div>
                        {/* <ReactAudioRecorder
                          render={({
                            timer,
                            stopRecording,
                            startRecording,
                            resumeRecording,
                            pauseRecording,
                            audioResult,
                            status,
                            errorMessage,
                          }) => (
                            <div className="container">
                              <div className="inner-container">
                                <div className="buttons">
                                  <button
                                    className="btn-play"
                                    style={{ zIndex: 8 }}
                                    onClick={
                                      status === RECORD_STATUS.RECORDING
                                        ? pauseRecording
                                        : resumeRecording
                                    }
                                  >
                                    {status === RECORD_STATUS.RECORDING ? (
                                      <PauseCircleFilled />
                                    ) : (
                                      <PlayCircleFilled />
                                    )}
                                  </button>
                                  <button
                                    className="btn-record"
                                    style={{
                                      backgroundColor: "#009BDF",
                                      zIndex:
                                        status === RECORD_STATUS.RECORDING
                                          ? 8
                                          : 10,
                                    }}
                                    onClick={startRecording}
                                  >
                                    <TrademarkCircleFilled />
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor:
                                        status === RECORD_STATUS.RECORDING
                                          ? "red"
                                          : "#009BDF",
                                      zIndex:
                                        status === RECORD_STATUS.RECORDING
                                          ? 10
                                          : 8,
                                    }}
                                    className="btn-stop"
                                    onClick={() =>
                                      stopRecordingAndOpenEnquiry(stopRecording)
                                    }
                                  >
                                    <CloseCircleFilled />
                                  </button>
                                </div>
                                <p
                                  style={{
                                    visibility:
                                      status === RECORD_STATUS.RECORDING ||
                                        status === RECORD_STATUS.PAUSED
                                        ? "visible"
                                        : "hidden",
                                  }}
                                  className={`timer ${status === RECORD_STATUS.PAUSED
                                    ? "blink-animation"
                                    : ""
                                    }`}
                                >
                                  {new Date(timer * 1000)
                                    .toISOString()
                                    .substr(11, 8)}
                                </p>

                                <audio
                                  style={{ display: "none" }}
                                  controls
                                  src={audioResult}
                                  onLoadedData={() => this.handleLoadedDataNotif(audioResult)}
                                />
                              </div>
                            </div>
                          )}
                        /> */}
                      </div>
                    </span>
                  </Col>
                  <Col span={20} style={{ display: "none" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Recording Link</span>}
                    >
                      {this.props.form.getFieldDecorator("recordingLinkNotif", {
                        initialValue: this.props.editStock
                          ? this.props.recordingLinkNotif ? this.props.recordingLinkNotif : this.props.editStock.recordingLinkNotif
                          : "",
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <Cards
                      style={{
                        width: "100%",
                        border: "1px solid rgb(0, 155, 223)",
                        marginBottom: 10,
                      }}
                      // extra={
                      //   this.props.editStock.frontRightImage ? (
                      //     <div
                      //       onClick={e =>
                      //         this.setState({ frontRightImage: null })
                      //       }
                      //     >
                      //       <CloseCircleFilled style={{ color: "red" }} />
                      //       <span style={{ color: "red" }}>Remove</span>
                      //     </div>
                      //   ) : (
                      //     ""
                      //   )
                      // }
                      title={
                        <span style={{ color: "rgb(0, 155, 223)" }}>
                          Disciplinary Enquiry Document
                        </span>
                      }
                      cover={

                        this.props.editStock ? (
                          this.state.rightSideImages != undefined ?
                            <Image
                              style={{
                                // width: "10em",
                                objectFit: "contain",
                                margin: "auto",
                              }}
                              alt="example"
                              src={
                                this.state.rightSideImages
                              }
                            />
                            :
                            this.props.editStock.rightSideImage ? (
                              <Image
                                style={{
                                  // width: "10em",
                                  objectFit: "contain",
                                  margin: "auto",
                                }}
                                alt="example"
                                src={
                                  this.props.editStock.rightSideImage.search(
                                    "data"
                                  ) > -1
                                    ? this.props.editStock.rightSideImage
                                    : process.env.REACT_APP_GLOBAL_SERVER +
                                    "asambo/api/images/" +
                                    this.props.editStock.rightSideImage
                                }
                              />
                            ) : null
                        ) : null
                      }
                      actions={[
                        <Upload
                          maxCount={1}
                          name="rightSideImage"
                          showUploadList={true}
                          beforeUpload={this.beforeUpload}
                          customRequest={this.handleImageUpload}
                          onChange={this.handleRightSideImage}
                          loading={this.state.loading}
                          accept="image/*"
                        >
                          {/* <Button>Upload Image From Files</Button> */}
                        </Upload>

                      ]}
                    />
                    <ImageUploadRightSideImage
                      setRightSideImage={this.handleSetRightSideImage} // Pass the function as prop
                    />
                  </Col>
                  <Col sm={0} md={1} />
                  <Col xs={24} md={11}>
                    <Form.Item
                      name="myRecordingNotification"
                      // label="Audio Recording"
                      rules={[
                        {
                          required: true,
                          message: "Please upload an audio recording.",
                        },
                      ]}
                    >
                      <Cards
                        style={{
                          width: "20em",
                          border: "1px solid rgb(0, 155, 223)",
                          marginBottom: 10,
                        }}
                        // extra={
                        //   // this.state.myRecordingNotification ? (
                        //   //   <div
                        //   //     onClick={e =>
                        //   //       this.setState({ myRecordingNotification: null })
                        //   //     }
                        //   //   >
                        //   //     <CloseCircleFilled style={{ color: "red" }} />
                        //   //     <span style={{ color: "red" }}>Remove</span>
                        //   //   </div>
                        //   // ) : (
                        //   <a
                        //     href={
                        //       this.props.editStock
                        //         ? this.props.editStock.recordingLinkNotif
                        //         : null
                        //     }
                        //     download={
                        //       this.props.editStock
                        //         ? `Disciplinary Enquiry Recording - ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                        //         } ${this.props.editStock.access} ${moment(this.props.editStock.enquiryDate).format(
                        //           "DD MMMM YYYY"
                        //         )} ${moment(this.props.editStock.enquiryTime).format("HH:mm")}.mp3` // Specify the desired filename here
                        //         : null
                        //     }
                        //   >
                        //     Download
                        //   </a>
                        //   // )
                        // }
                        // extra={
                        //   <Button
                        //     icon={<DownloadOutlined />}
                        //     onClick={this.handleDownloadRecording}
                        //   >
                        //     Download
                        //   </Button>
                        // }
                        title={
                          <span style={{ color: "rgb(0, 155, 223)" }}>
                            Enquiry Recording
                          </span>
                        }
                        cover={
                          <audio
                            controls="download"
                            src={
                              this.props.editStock && this.props.editStock.recordingLinkNotif
                                ? this.props.editStock.recordingLinkNotif.endsWith(".wav") // Check for .wav
                                  ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkNotif // File already has .wav
                                  : this.props.editStock.recordingLinkNotif.endsWith(".mp3") // Check for .mp3
                                    ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkNotif // File already has .mp3
                                    : process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkNotif + ".mp3" // Default to .wav if no extension
                                : null
                            }
                          />
                        }
                      // actions={[
                      //   <Upload
                      //     name="myRecordingNotification"
                      //     showUploadList={true}
                      //     beforeUpload={this.beforeUpload}
                      //     customRequest={
                      //       this.handleRecordingUploadNotification
                      //     }
                      //     onChange={this.handleRecordingNotification}
                      //     loading={this.state.loading}
                      //     accept="audio/*"
                      //   >
                      //     <Button>Upload Recording</Button>
                      //   </Upload>,
                      // ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row style={{ marginTop: "1em" }}>
                  <Col span={24}>
                    {/* <Button
                      type="primary"
                      shape="round"
                      // onClick={this.showModalNotif}
                      onClick={this.showModalEnquiry}
                      icon={<MailFilled style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "#009BDF",
                        border: "1px solid #009BDF",

                        height: "3em",
                        width: "12em",
                      }}
                    >
                      Send Enquiry
                    </Button> */}
                    {/* <Button
                      type="primary"
                      shape="round"
                      onClick={this.generateEnquiryPdf}
                      icon={<DownloadOutlined style={{ fontSize: "110%" }} />}
                      size="large"
                      style={{
                        backgroundColor: "rgb(0, 21, 41)",
                        border: "1px solid rgb(0, 21, 41)",
                        marginBottom: "3em",
                        height: "3em",
                        width: "14.5em",
                      }}
                    >
                      Download Enquiry PDF
                    </Button> */}

                    <Modal
                      title="Send Disciplinary Enquiry"
                      visible={this.state.visibleSendEnquiry}
                      onOk={this.handleSubmitEnquiry}
                      okText="Send"
                      onCancel={this.handleCancel}
                    >
                      <Select
                        style={{ width: "100%" }}
                        type="emailEnquiry"
                        // value={this.state.sendEmailEnquiry}
                        onChange={this.handleEmailChangeEnquiry}
                        placeholder="Enter Email"
                      >
                        {this.props.sites.flatMap((site, index) =>
                          this.props.editStock ? (
                            this.props.editStock.access === site.name ? (
                              Array.isArray(site.email) ? (
                                site.email.map(email => (
                                  <Option
                                    key={`${email}-${index}`}
                                    value={email.trim()}
                                  >
                                    {email.trim()}
                                  </Option>
                                ))
                              ) : (
                                <Option
                                  key={`${site.email}-${index}`}
                                  value={site.email.trim()}
                                >
                                  {site.email.trim()}
                                </Option>
                              )
                            ) : null
                          ) : null
                        )}
                      </Select>
                      {/* <Input
                        type="emailEnquiry"
                        value={this.state.sendEmailEnquiry}
                        onChange={this.handleEmailChangeEnquiry}
                        placeholder="Enter Email"
                      /> */}
                    </Modal>
                  </Col>
                </Row>
                {/* <Tooltip placement="left" title="Save">
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.props.onOk}
                  size="large"
                  style={{
                    position: "fixed",
                    bottom: "6.5em",
                    right: "1em",
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    //height: "3em",
                    marginLeft: "0.7em",
                  }}
                >
                  <CheckOutlined />
                </Button>
              </Tooltip>
              <Tooltip placement="left" title="Cancel">
                <Button
                  shape="round"
                  onClick={this.props.onCancel}
                  style={{
                    fontSize: "14px",
                    width: "4em",
                    height: "4em",
                    position: "fixed",
                    bottom: "2em",
                    right: "1em",
                    border: "1px solid #EEEFF0",
                    backgroundColor: "red",
                  }}
                >
                  <CloseOutlined />
                </Button>
              </Tooltip> */}
              </Form>
            </TabPane>
          ) : null}
          {this.props.editInterview === "editInterview" ||
            this.props.editInterview === "addInterview" && this.props.selectedStockIds.length === 0 ? (
            <TabPane tab="Disciplinary Interview" key="Disciplinary Interview">
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Disciplinary Interview
              </span>
              <Form>
                <Row style={{ marginTop: "2em" }}>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Company Name</span>}
                    >
                      {this.props.form.getFieldDecorator("companyName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.companyName === "" ||
                            this.props.editStock.companyName === null ||
                            this.props.editStock.companyName === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.companyName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Initiator</span>}
                    >
                      {this.props.form.getFieldDecorator("initiatorNameInterview", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.initiatorNameInterview
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <span>
                      Date and time of interview :{" ("}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock &&
                          this.props.editStock.interviewDate
                          ? moment(this.props.editStock.interviewDate).format(
                            "DD MMMM YYYY"
                          )
                          : " Please set a date"}
                      </span>{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {" "}
                        {this.props.editStock &&
                          this.props.editStock.interviewDate
                          ? moment(this.props.editStock.interviewTime).format(
                            "HH:mm"
                          )
                          : null}
                      </span>
                      {")"}
                    </span>
                    <br />
                    <div style={{ display: "inline-flex" }}>
                      <FormItem>
                        {this.props.form.getFieldDecorator("interviewDate", {
                          rules: [
                            {
                              required:
                                this.props.editInterview !== "addInterview"
                                  ? false
                                  : true,
                              message: e =>
                                this.setState({
                                  visibleDateRequired: true,
                                }),
                            },
                          ],
                        })(
                          <DatePicker
                            placeholder="Select Date"
                            value={this.state.selectedDate}
                            onChange={this.handleDateChange}
                            format={dateFormat}
                          />
                        )}
                      </FormItem>
                      <FormItem>
                        {this.props.form.getFieldDecorator("interviewTime", {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                        })(
                          <TimePicker
                            style={{ marginLeft: "1em" }}
                            placeholder="Select Time"
                            value={this.state.selectedTime}
                            onChange={this.handleTimeChange}
                            format={timeFormat}
                          />
                        )}
                      </FormItem>
                    </div>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Accused Name</span>}
                    >
                      {this.props.form.getFieldDecorator("accusedName", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Position</span>}
                    >
                      {this.props.form.getFieldDecorator("jobTitle", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.jobTitle
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Representative</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "representativeNameInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.representativeNameInterview
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />{" "}
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "chairpersonNameInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.chairpersonNameInterview
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Venue / Place</span>}
                    >
                      {this.props.form.getFieldDecorator("venuePlace", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.venuePlace === "" ||
                            this.props.editStock.venuePlace === null ||
                            this.props.editStock.venuePlace === undefined
                            ? this.props.editStock.access
                            : this.props.editStock.venuePlace
                          : "",
                      })(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />{" "} */}
                  <p style={{ marginBottom: "0em", paddingTop: "2em", borderTop: "1px solid #e9e9e9", }}>
                    Alleged Charge(s)&nbsp;{" "} </p>
                  <br />
                  {rowsPrepInterview}
                  <Row
                    style={{
                      // marginTop: "-1em",
                      paddingBottom: "1em",
                    }}
                  >
                    <Col xs={24} md={24}>
                      {visiblePrepInterview.length < maxRowsPrepInterview && (
                        <Button
                          style={{ margin: "0.5em", float: "right" }}
                          type="primary"
                          onClick={this.showNextPrepInterview}
                        >
                          <PlusCircleOutlined /> Add Charge
                        </Button>
                      )}
                    </Col> </Row>
                </Row>
                {/* <Row
                  style={{
                    marginTop: "2em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Plea by the accused: ({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.accusedPleaInterview
                            ? this.props.editStock.accusedPleaInterview
                            : " Please select Plea"}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "accusedPleaInterview",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          // valuePropName: "checked",
                          // initialValue: this.props.editStock
                          //   ? this.props.editStock.accusedPleaInterview
                          //   : "",
                        }
                      )(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="Guilty">Guilty</Radio.Button>
                          <Radio.Button value="Not Guilty">
                            Not Guilty
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>
                </Row> */}
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Date of employment</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "selectedemploymentDate",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? moment(
                              this.props.editStock.selectedemploymentDate
                            ).format("DD MMMM YYYY")
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Valid previous transgressions</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "prevTransgressionsInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.prevTransgressionsInterview
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>

                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Mitigation circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "mitigationCircumstancesInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock
                              .mitigationCircumstancesInterview
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Aggrivating circumstances</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "aggrivatingCircumstancesInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock
                              .aggrivatingCircumstancesInterview
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={24}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={
                        <span>Determination and finding by chairperson</span>
                      }
                    >
                      {this.props.form.getFieldDecorator(
                        "determinationFindingInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.determinationFindingInterview
                            : "",
                        }
                      )(
                        <TextArea rows={4}

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col>
                  {/* <Col xs={0} md={2} />
                  <Col xs={24} md={11}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict by Chairperson</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "chairpersonVerdictInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.chairpersonVerdictInterview
                            : "",
                        }
                      )(
                        <Input

                        // disabled
                        // type={"number"}
                        />
                      )}
                    </FormItem>
                  </Col> */}
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{}}>
                        Sanction / Warning (Months) : <br />({" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.sanctionMonthsFinal
                            ? this.props.editStock.sanctionMonthsFinal +
                            " Months /"
                            : " Please select Sanction"}
                        </span>{" "}
                        <span style={{ color: "rgb(0, 112, 168)" }}>
                          {this.props.editStock &&
                            this.props.editStock.warningFinal
                            ? this.props.editStock.warningFinal
                            : ""}
                        </span>{" "}
                        )
                      </span>
                    </span>
                  </Col>
                  <Col span={24} style={{ marginTop: "1em" }}>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "sanctionMonthsFinal",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          // valuePropName: "checked",
                          // initialValue: this.props.editStock
                          //   ? this.props.editStock.accusedPleaInterview
                          //   : "",
                        }
                      )(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button value="3">3</Radio.Button>
                          <Radio.Button value="6">6</Radio.Button>
                          <Radio.Button value="9">9</Radio.Button>
                          <Radio.Button value="12">12</Radio.Button>
                          {/* <Radio.Button value="Final">Final</Radio.Button> */}
                          <Radio.Button value="N/A">N/A</Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                    <FormItem>
                      {this.props.form.getFieldDecorator("warningFinal", {
                        rules: [
                          {
                            required: false,
                          },
                        ],
                        valuePropName: "checked",
                      })(
                        <Radio.Group defaultValue={null} buttonStyle="solid">
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Written Warning"
                          >
                            Written Warning
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Final Written Warning"
                          >
                            Final Written Warning
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="All Inclusive Final Written Warning"
                          >
                            All Inclusive Final Written Warning
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Consultation"
                          >
                            Consultation
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Dismissed"
                          >
                            Dismissed
                          </Radio.Button>
                          <Radio.Button
                            style={{ margin: "0.6em" }}
                            value="Suspended"
                          >
                            Suspended
                          </Radio.Button>
                          <Radio.Button style={{ margin: "0.6em" }} value="N/A">
                            N/A
                          </Radio.Button>
                        </Radio.Group>
                      )}
                    </FormItem>
                  </Col>

                  <Col span={24} style={{}}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Verdict</span>}
                    >
                      {this.props.form.getFieldDecorator("otherWarningsFinal", {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "Employee Name required",
                          // },
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.otherWarningsFinal
                          : "",
                      })(<TextArea rows={4} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col xs={24} md={24}>
                    <span>
                      <Cards
                        style={{
                          width: "100%",
                          // border: "1px solid #009bdf",
                          textAlign: "center",
                          marginBottom: 15,
                        }}
                        // extra={
                        //   this.props.editStock ? (
                        //     this.props.editStock.frontImage ? (
                        //       <div
                        //         onClick={e =>
                        //           this.setState({ sigVisible: true })
                        //         }
                        //       >
                        //         <span style={{ color: "red" }}>
                        //           New Signature
                        //         </span>
                        //       </div>
                        //     ) : null
                        //   ) : null
                        // }
                        title={
                          <span style={{ color: "#009bdf" }}>
                            Accused Signature
                          </span>
                        }
                        cover={
                          this.props.editStock ? (
                            this.state.frontLeftImage &&
                              this.state.sigVisible === false ? (
                              <span>
                                <img
                                  style={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                  alt="example"
                                  src={
                                    this.props.editStock.frontLeftImage.search(
                                      "data"
                                    ) > -1
                                      ? this.props.editStock.frontLeftImage
                                      : process.env.REACT_APP_GLOBAL_SERVER +
                                      "asambo/api/images/" +
                                      this.props.editStock.frontLeftImage
                                  }
                                />
                                <br />
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                  onClick={this.RedoSignature}
                                >
                                  Redo
                                </Button>
                              </span>
                            ) : this.state.frontLeftImage &&
                              this.state.sigVisible === "redo" ? (
                              <span>
                                <p
                                  style={{
                                    fontWeight: 400,
                                    fontSize: 14,
                                    color: "black",
                                    marginTop: "8em",
                                    marginBottom: "3em",
                                  }}
                                >
                                  Remember to save to store{" "}
                                  <span style={{ color: "rgb(0, 112, 168)" }}>
                                    {this.props.editStock
                                      ? this.props.editStock.employeeName
                                      : null}{" "}
                                    {this.props.editStock
                                      ? this.props.editStock.employeeLastName
                                      : null}
                                  </span>
                                  's signature.
                                </p>
                                <br />
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                  onClick={this.RedoSignature}
                                >
                                  Redo
                                </Button>
                              </span>
                            ) : (
                              <span
                                style={{
                                  margin: "auto",
                                  textAlign: "center",
                                }}
                              >
                                <span
                                  style={{
                                    borderBottom: "1px solid #009bdf",
                                  }}
                                >
                                  <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{
                                      width: 500,
                                      height: 300,
                                      margin: "auto",
                                    }}
                                    onBegin={this.handleOnStart}
                                    onEnd={this.handleOnEnd}
                                    ref={ref => {
                                      this.signaturePadInterview = ref;
                                    }}
                                  />
                                </span>
                                <br />
                                <div
                                  style={{
                                    marginTop: "1em",
                                    marginBottom: "1em",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                      backgroundColor: "transparent",
                                      color: "#009BDF",
                                      border: "1px solid #009BDF",
                                      height: "3em",
                                      width: "7em",
                                    }}
                                    onClick={this.clearSignatureInterview}
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    type="primary"
                                    shape="round"
                                    style={{
                                      backgroundColor: "#009BDF",
                                      border: "1px solid #009BDF",
                                      marginLeft: "0.5em",
                                      height: "3em",
                                      width: "7em",
                                    }}
                                    onClick={this.saveSignatureInterview}
                                  >
                                    Save
                                  </Button>
                                  {isDrawing && <div>Signing...</div>}
                                  <Upload
                                    name="frontLeftImage"
                                    showUploadList={false}
                                    beforeUpload={this.beforeUpload}
                                    customRequest={this.handleImageUpload}
                                    onChange={this.handleFrontImage}
                                    loading={this.state.loading}
                                  />
                                </div>
                              </span>
                            )
                          ) : (
                            <span
                              style={{
                                margin: "auto",
                                textAlign: "center",
                              }}
                            >
                              <span
                                style={{
                                  borderBottom: "1px solid #009bdf",
                                }}
                              >
                                <SignatureCanvas
                                  penColor="black"
                                  canvasProps={{
                                    width: 500,
                                    height: 300,
                                    margin: "auto",
                                  }}
                                  onBegin={this.handleOnStart}
                                  onEnd={this.handleOnEnd}
                                  ref={ref => {
                                    this.signaturePadInterview = ref;
                                  }}
                                />
                              </span>
                              <br />
                              <div
                                style={{
                                  marginTop: "1em",
                                  marginBottom: "1em",
                                }}
                              >
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "transparent",
                                    color: "#009BDF",
                                    border: "1px solid #009BDF",
                                    height: "3em",
                                    width: "7em",
                                  }}
                                  onClick={this.clearSignatureInterview}
                                >
                                  Clear
                                </Button>
                                <Button
                                  type="primary"
                                  shape="round"
                                  style={{
                                    backgroundColor: "#009BDF",
                                    border: "1px solid #009BDF",
                                    marginLeft: "0.5em",
                                    height: "3em",
                                    width: "7em",
                                  }}
                                  onClick={this.saveSignatureInterview}
                                >
                                  Save
                                </Button>
                                {isDrawing && <div>Signing...</div>}
                                <Upload
                                  name="branchImage"
                                  showUploadList={false}
                                  beforeUpload={this.beforeUpload}
                                  customRequest={this.handleImageUpload}
                                  onChange={this.handleFrontImage}
                                  loading={this.state.loading}
                                />
                              </div>
                            </span>
                          )
                        }
                      />
                    </span>
                    <p
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                        marginTop: "-0em",
                      }}
                    >
                      I{" "}
                      <span style={{ color: "rgb(0, 112, 168)" }}>
                        {this.props.editStock
                          ? this.props.editStock.employeeName
                          : null}{" "}
                        {this.props.editStock
                          ? this.props.editStock.employeeLastName
                          : null}{" "}
                      </span>
                      hereby confirm that I understand the nature of the offence
                      as well as the sanction taken above.
                    </p>
                    <FormItem>
                      {this.props.form.getFieldDecorator(
                        "refuseSignInterview",
                        {
                          rules: [
                            {
                              required: false,
                            },
                          ],
                          valuePropName: "checked",
                          initialValue: this.props.editStock
                            ? this.props.editStock.refuseSignInterview
                              ? true
                              : false
                            : false,
                        }
                      )(
                        <Checkbox
                          style={{ marginTop: "0em", lineHeight: "1em" }}
                        >
                          Refuse to Sign
                        </Checkbox>
                      )}
                    </FormItem>
                  </Col>
                  <Col span={24} style={{ marginTop: "-1em" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Refuse Reason</span>}
                    >
                      {this.props.form.getFieldDecorator(
                        "refuseReasonInterview",
                        {
                          rules: [
                            // {
                            //   required: true,
                            //   message: "Employee Name required",
                            // },
                          ],
                          initialValue: this.props.editStock
                            ? this.props.editStock.refuseReasonInterview
                            : "",
                        }
                      )(<TextArea rows={4} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row
                  style={{
                    // marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "2em",
                  }}
                >
                  <Col span={24}>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      <span style={{ color: "#0070A8" }}>
                        Statement of Accused
                      </span>
                      <br /> <br />
                      <div>
                        {/* <ReactAudioRecorder
                          render={({
                            timer,
                            stopRecording,
                            startRecording,
                            resumeRecording,
                            pauseRecording,
                            audioResult,
                            status,
                            errorMessage,
                          }) => (
                            <div className="container">
                              <div className="inner-container">
                                <div className="buttons">
                                  <button
                                    className="btn-play"
                                    style={{ zIndex: 8 }}
                                    onClick={
                                      status === RECORD_STATUS.RECORDING
                                        ? pauseRecording
                                        : resumeRecording
                                    }
                                  >
                                    {status === RECORD_STATUS.RECORDING ? (
                                      <PauseCircleFilled />
                                    ) : (
                                      <PlayCircleFilled />
                                    )}
                                  </button>
                                  <button
                                    className="btn-record"
                                    style={{
                                      backgroundColor: "#009BDF",
                                      zIndex:
                                        status === RECORD_STATUS.RECORDING
                                          ? 8
                                          : 10,
                                    }}
                                    onClick={startRecording}
                                  >
                                    <TrademarkCircleFilled />
                                  </button>
                                  <button
                                    style={{
                                      backgroundColor:
                                        status === RECORD_STATUS.RECORDING
                                          ? "red"
                                          : "#009BDF",
                                      zIndex:
                                        status === RECORD_STATUS.RECORDING
                                          ? 10
                                          : 8,
                                    }}
                                    className="btn-stop"
                                    onClick={() =>
                                      stopRecordingAndOpenInterview(
                                        stopRecording
                                      )
                                    }
                                  >
                                    <CloseCircleFilled />
                                  </button>
                                </div>
                                <p
                                  style={{
                                    visibility:
                                      status === RECORD_STATUS.RECORDING ||
                                        status === RECORD_STATUS.PAUSED
                                        ? "visible"
                                        : "hidden",
                                  }}
                                  className={`timer ${status === RECORD_STATUS.PAUSED
                                    ? "blink-animation"
                                    : ""
                                    }`}
                                >
                                  {new Date(timer * 1000)
                                    .toISOString()
                                    .substr(11, 8)}
                                </p>
                                <audio
                                  style={{ display: "none" }}
                                  controls
                                  src={audioResult}
                                  onLoadedData={() => this.handleLoadedDataInterview(audioResult)}
                                />
                              </div>
                            </div>
                          )}
                        /> */}
                      </div>
                    </span>
                  </Col>
                  <Col span={20} style={{ display: "none" }}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Recording Link</span>}
                    >
                      {this.props.form.getFieldDecorator("recordingLinkInterview", {
                        initialValue: this.props.editStock
                          ? this.props.recordingLinkInterview ? this.props.recordingLinkInterview : this.props.editStock.recordingLinkInterview
                          : "",
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={24} md={11}>
                    <Cards
                      style={{
                        width: "100%",
                        border: "1px solid rgb(0, 155, 223)",
                        marginBottom: 10,
                      }}
                      // extra={
                      //   this.props.editStock.frontRightImage ? (
                      //     <div
                      //       onClick={e =>
                      //         this.setState({ frontRightImage: null })
                      //       }
                      //     >
                      //       <CloseCircleFilled style={{ color: "red" }} />
                      //       <span style={{ color: "red" }}>Remove</span>
                      //     </div>
                      //   ) : (
                      //     ""
                      //   )
                      // }
                      title={
                        <span style={{ color: "rgb(0, 155, 223)" }}>
                          Disc. Interview Document
                        </span>
                      }
                      cover={

                        this.props.editStock ? (
                          this.state.wheelsImages != undefined ?
                            <Image
                              style={{
                                // width: "10em",
                                objectFit: "contain",
                                margin: "auto",
                              }}
                              alt="example"
                              src={
                                this.state.wheelsImages
                              }
                            />
                            :
                            this.props.editStock.wheelsImage ? (
                              <Image
                                style={{
                                  // width: "10em",
                                  objectFit: "contain",
                                  margin: "auto",
                                }}
                                alt="example"
                                src={

                                  this.props.editStock.wheelsImage.search(
                                    "data"
                                  ) > -1
                                    ? this.props.editStock.wheelsImage
                                    : process.env.REACT_APP_GLOBAL_SERVER +
                                    "asambo/api/images/" +
                                    this.props.editStock.wheelsImage
                                }
                              />
                            ) : null
                        ) : null
                      }
                      actions={[
                        <Upload
                          maxCount={1}
                          name="wheelsImage"
                          showUploadList={true}
                          beforeUpload={this.beforeUpload}
                          customRequest={this.handleImageUpload}
                          onChange={this.handleWheelsImage}
                          loading={this.state.loading}
                          accept="image/*"
                        >
                          {/* <Button>Upload Image From Files</Button> */}
                        </Upload>,

                      ]}
                    />
                    <ImageUpload
                      setWheelsImage={this.handleSetWheelsImage} // Pass the function as prop
                    />
                  </Col>
                  <Col sm={0} md={1} />
                  <Col xs={24} md={11}>
                    <Form.Item
                      name="myRecordingInterview"
                      // label="Audio Recording"
                      rules={[
                        {
                          required: true,
                          message: "Please upload an audio recording.",
                        },
                      ]}
                    >
                      <Cards
                        style={{
                          // width: "20em",
                          border: "1px solid rgb(0, 155, 223)",
                          marginBottom: 10,
                        }}
                        // extra={
                        //   // this.state.myRecordingInterview ? (
                        //   //   <div
                        //   //     onClick={e =>
                        //   //       this.setState({ myRecordingInterview: null })
                        //   //     }
                        //   //   >
                        //   //     <CloseCircleFilled style={{ color: "red" }} />
                        //   //     <span style={{ color: "red" }}>Remove</span>
                        //   //   </div>
                        //   // ) : (
                        //   <a
                        //     href={
                        //       this.props.editStock
                        //         ? this.props.editStock.recordingLinkInterview
                        //         : null
                        //     }
                        //     download={
                        //       this.props.editStock
                        //         ? `Disciplinary Interview Recording - ${this.props.editStock.employeeName} ${this.props.editStock.employeeLastName
                        //         } ${this.props.editStock.access} ${moment(this.props.editStock.interviewDate).format(
                        //           "DD MMMM YYYY"
                        //         )} ${moment(this.props.editStock.interviewTime).format("HH:mm")}.mp3` // Specify the desired filename here
                        //         : null
                        //     }
                        //   >
                        //     Download
                        //   </a>
                        //   // )

                        // }
                        // extra={
                        //   <Button
                        //     icon={<DownloadOutlined />}
                        //     onClick={this.handleDownloadRecordingInterview}
                        //   >
                        //     Download
                        //   </Button>

                        // }
                        title={
                          <span style={{ color: "rgb(0, 155, 223)" }}>
                            Interview Recording
                          </span>
                        }
                        cover={
                          <audio
                            controls="download"
                            src={
                              this.props.editStock && this.props.editStock.recordingLinkInterview
                                ? this.props.editStock.recordingLinkInterview.endsWith(".wav") // Check for .wav
                                  ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkInterview // File already has .wav
                                  : this.props.editStock.recordingLinkInterview.endsWith(".mp3") // Check for .mp3
                                    ? process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkInterview // File already has .mp3
                                    : process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/recordings/" + this.props.editStock.recordingLinkInterview + ".mp3" // Default to .wav if no extension
                                : null
                            }
                          />
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{
                    marginTop: "1em",
                    borderTop: "1px solid #e9e9e9",
                    paddingTop: "1em",
                  }}
                >
                  <Col xs={11} md={11} style={{}}>
                    <FormItem
                      labelCol={{ span: 24 }}
                      label={<span>Initiator:</span>}
                    >
                      {this.props.form.getFieldDecorator("InitiatorInterview", {
                        rules: [
                        ],
                        initialValue: this.props.editStock
                          ? this.props.editStock.access
                          : "",
                      })(
                        <p
                          style={{
                            fontWeight: 400,
                            fontSize: 14,
                            color: "black",
                            marginTop: "-1.2em",
                          }}
                        >
                          {this.props.editStock
                            ? this.props.editStock.access
                            : null}
                        </p>
                      )}
                    </FormItem>
                  </Col>
                  <Col xs={11} md={11} style={{ paddingBottom: "7em" }}>
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      Chairperson:
                    </span>
                    <br />
                    <span
                      style={{
                        fontWeight: 400,
                        fontSize: 14,
                        color: "black",
                      }}
                    >
                      {this.props.editStock
                        ? this.props.editStock.chairpersonNameInterview
                        : null}{" "}
                    </span>
                    {/* <Signature /> */}
                  </Col>
                </Row>
                <Row style={{ marginTop: "-1.5em" }}>
                  <Col span={24}>
                    <Modal
                      style={{ top: 20, minWidth: "90vw" }}
                      title={
                        this.props.editStock
                          ? "Alleged Charge(s) " +
                          this.props.editStock.employeeName +
                          " " +
                          this.props.editStock.employeeLastName
                          : ""
                      }
                      visible={this.state.visibleChargesInterview}
                      onOk={this.handleOkChargesInterview}
                      onCancel={this.handleCancel}
                      footer={[
                        <Button
                          key="back"
                          type="primary"
                          onClick={this.handleCancel}
                        >
                          Close Annexure “A”
                        </Button>,
                      ]}
                    >
                      <FormItem>
                        {this.props.form.getFieldDecorator(
                          "allegedChargesInterview",
                          {
                            rules: [
                              // {
                              //   required: true,
                              //   message: "Employee Name required",
                              // },
                            ],
                            initialValue: this.props.editStock
                              ? this.props.editStock.allegedChargesInterview
                              : "",
                          }
                        )(
                          <TextArea
                            style={{ color: "black" }}
                            disabled
                            // placeholder="Autosize height with minimum and maximum number of lines"
                            autoSize={{ minRows: 1, maxRows: 200 }}
                          />
                        )}
                      </FormItem>
                    </Modal>
                    <Modal
                      title="Send Disciplinary Interview"
                      visible={this.state.visibleSendInterview}
                      onOk={this.handleSubmitInterview}
                      okText="Send"
                      onCancel={this.handleCancel}
                    >
                      <Select
                        style={{ width: "100%" }}
                        type="emailInterview"
                        // value={this.state.sendEmailInterview}
                        onChange={this.handleEmailChangeInterview}
                        placeholder="Enter Email"
                      >
                        {this.props.sites.flatMap((site, index) =>
                          this.props.editStock ? (
                            this.props.editStock.access === site.name ? (
                              Array.isArray(site.email) ? (
                                site.email.map(email => (
                                  <Option
                                    key={`${email}-${index}`}
                                    value={email.trim()}
                                  >
                                    {email.trim()}
                                  </Option>
                                ))
                              ) : (
                                <Option
                                  key={`${site.email}-${index}`}
                                  value={site.email.trim()}
                                >
                                  {site.email.trim()}
                                </Option>
                              )
                            ) : null
                          ) : null
                        )}
                      </Select>
                    </Modal>
                  </Col>
                </Row>

              </Form>
            </TabPane>
          ) : null}
        </Tabs>
      </div>
    );
  }
}
const StockForm = Form.create()(StockFormClass);

const RECORD_STATUS = {
  IDLE: "IDLE",
  RECORDING: "RECORDING",
  PAUSED: "PAUSED",
};

class Stock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      recordingId: null,
      isRecording: false,
      isPaused: false,
      timer: 0,
      status: null, // Tracks recording status

      selectedStockIds: [],
      loading: false,
      loadingProgress: 0,
      closed: false,
      filteredInfo: null,
      rejectReason: "",
      jobNumbers: [],
      jobNumber: undefined,
      technician: undefined,
      technicians: [],
      qty: 1,
      searchQuery: [],
      selectedEmployees: [],
      visiblePrep: [],
      qtyError: false,
      itemCodes: [],
      addNewNotification: false,
      siteSelected: "",
      recording: false,
      isRecording: false,
      siteSelectedName:
        this.props.sites.length > 0 ? this.props.sites[0].name : "None",
      currentPage: 1,
      totalSites: 0,
      searchQuerySites: "",
      visibleSave: false,
      visibleSaveMulti: false,
      visibleCancel: false,
    };
    this.mediaRecorder = null;
    this.audioChunks = [];
    this.handleEmailChangeNotification = this.handleEmailChangeNotification.bind(
      this
    );
    this.handleEmailChangeEnquiry = this.handleEmailChangeEnquiry.bind(this);
    this.handleEmailChangeInterview = this.handleEmailChangeInterview.bind(
      this
    );
  }

  loadSites = () => {
    const { currentPage, searchQuerySites } = this.state;
    this.setState({ loading: true });
    this.props
      .findAllSites({ searchQuerySites, page: currentPage, limit: 30 })
      .then(response => {
        // Assume your Redux reducer stores sites and total in the returned data.
        // Here, we update the total count from the API response.
        this.setState({
          loading: false,
          totalSites: response.data.total
        });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };

  handleSearchSites = value => {
    // Update search query and reset to first page
    this.setState({ searchQuerySites: value, currentPage: 1 }, this.loadSites);
  };

  handlePageChange = page => {
    this.setState({ currentPage: page }, this.loadSites);
  };

  startRecording = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: {
          sampleRate: 16000, // 16 kHz for smaller files
          channelCount: 1, // Mono audio
        },
      })
      .then((stream) => {
        this.mediaStream = stream;

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/webm;codecs=opus", // WebM format with Opus codec
          audioBitsPerSecond: 24000, // 24 kbps bitrate for compressed quality
        });

        const date = new Date();
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}_${date
            .getHours()
            .toString()
            .padStart(2, "0")}-${date.getMinutes().toString().padStart(2, "0")}-${date
              .getSeconds()
              .toString()
              .padStart(2, "0")}`;

        const recordingId = this.state.editStock.access
          ? `${this.state.editStock.access}_${this.state.editStock.employeeName}_${this.state.editStock.employeeLastName}_Recording_${formattedDate}`
          : `Multi_Recording_${formattedDate}`;

        this.setState({
          recordingId,
          isRecording: true,
          isPaused: false,
          timer: 0,
          status: RECORD_STATUS.RECORDING,
        });

        this.timerInterval = setInterval(() => {
          if (this.state.status === RECORD_STATUS.RECORDING) {
            this.setState((prevState) => ({ timer: prevState.timer + 1 }));
          }
        }, 1000);

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            const formData = new FormData();
            formData.append("audioChunk", event.data);
            formData.append("recordingId", this.state.recordingId);

            fetch("https://www.vcsappcloud.com/asambo/api/stock/stream", {
              method: "POST",
              body: formData,
            })
              .then((response) => {
                if (response.ok) {
                  console.log("Chunk uploaded successfully.");
                } else {
                  console.error("Failed to upload chunk:", response.statusText);
                }
              })
              .catch((error) => {
                console.error("Error uploading chunk:", error);
              });
          }
        };

        mediaRecorder.onstop = () => {
          clearInterval(this.timerInterval);
          this.setState({ status: RECORD_STATUS.IDLE });
        };

        this.mediaRecorder = mediaRecorder;
        mediaRecorder.start(5000); // Emit chunks every 5 seconds
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  pauseRecording = () => {
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
      this.mediaRecorder.pause();
      this.setState({ isPaused: true, status: RECORD_STATUS.PAUSED });
    }
  };

  resumeRecording = () => {
    if (this.mediaRecorder && this.mediaRecorder.state === "paused") {
      this.mediaRecorder.resume();
      this.setState({ isPaused: false, status: RECORD_STATUS.RECORDING });
    }
  };

  stopRecording = () => {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop(); // Stop the MediaRecorder
      clearInterval(this.timerInterval); // Clear the timer interval
      this.setState({
        isRecording: false,
        status: RECORD_STATUS.IDLE, // Reset the recording status
      });

      // Call the handleAddStock function
      if (this.state.selectedStockIds.length > 0) {
        this.handleAddStockMulti({
          preventDefault: () => { }, // Provide a mock preventDefault to avoid form submission errors
        });
      } else {
        this.handleAddStock({
          preventDefault: () => { }, // Provide a mock preventDefault to avoid form submission errors
        });
      }
    }

    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop()); // Stop all media tracks
    }
  };


  // stopRecording = () => {

  //   if (this.mediaRecorder) {
  //     this.mediaRecorder.stop();

  //     // Send a finalize request after stopping the recording
  //     fetch("https://www.vcsappcloud.com/asambo/api/stock/finalize", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ recordingId: this.state.recordingId }), // Send recordingId
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log("Finalization response:", data);
  //       })
  //       .catch((error) => {
  //         console.error("Error finalizing recording:", error);
  //       });

  //     this.setState({ isRecording: false });
  //     console.log("Recording ID during stop:", this.state.recordingId);
  //   }
  // };

  updateLoadingProgress = (increment) => {
    this.setState(prevState => {
      const newProgress = prevState.loadingProgress + increment;
      console.log('Updating progress:', newProgress);
      return { loadingProgress: newProgress };
    });
  };


  handleLoadedDataNotif = async (audioResult) => {
    this.setState({ loading: true, loadingProgress: 0 });

    try {
      const recordingLinkNotif = await this.convertToDataURI(audioResult);
      this.setState({ recordingLinkNotif });

      const audioBlob = await fetch(audioResult).then(response => response.blob());

      // Create a formatted date string
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`;

      // Construct the filename using employee name, last name, and date
      const filename = this.state.editStock.access && this.state.editStock.employeeName && this.state.editStock.employeeLastName
        ? `${this.state.editStock.access}_${this.state.editStock.employeeName}_${this.state.editStock.employeeLastName}_Recording_${formattedDate}.mp3`
        : `Multi_Recording_${formattedDate}.mp3`;

      // Download the audio file
      this.downloadRecording(audioBlob, filename);

      if (this.state.selectedStockIds.length === 0) {
        await this.onSaveRecordingSingle();
      } else {
        await this.onSaveRecordingMultiple();
      }

    } catch (e) {
      this.setState({ loadingProgress: 66, loading: false });
      console.error('Error occurred:', e);
      Notify("error", e.message);
    }
  };

  handleLoadedDataInterview = async (audioResult) => {
    this.setState({ loading: true, loadingProgress: 0 });

    try {
      const recordingLinkInterview = await this.convertToDataURI(audioResult);
      this.setState({ recordingLinkInterview });

      const audioBlob = await fetch(audioResult).then(response => response.blob());

      // Create a formatted date string
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}-${date.getMinutes().toString().padStart(2, '0')}-${date.getSeconds().toString().padStart(2, '0')}`;

      // Construct the filename using employee name, last name, and date
      const filename = this.state.editStock.access && this.state.editStock.employeeName && this.state.editStock.employeeLastName
        ? `${this.state.editStock.access}_${this.state.editStock.employeeName}_${this.state.editStock.employeeLastName}_Interview_Recording_${formattedDate}.mp3`
        : `Interview_Recording_${formattedDate}.mp3`;


      // Download the audio file
      this.downloadRecording(audioBlob, filename);

      if (this.state.selectedStockIds.length === 0) {
        await this.onSaveRecordingSingle();
      } else {
        await this.onSaveRecordingMultiple();
      }

    } catch (e) {
      this.setState({ loadingProgress: 66, loading: false });
      Notify("error", e.message);
    }
  };
  // Utility function to download the recording
  downloadRecording = (blob, filename) => {
    // Check if the app is running in a Cordova environment
    if (window.cordova) {
      // Request storage permissions for Android
      if (window.cordova.platformId === 'android') {
        this.requestStoragePermissions(blob, filename);
      } else {
        // If not Android, proceed to save the file directly
        this.saveFile(blob, filename);
      }
    } else {
      // Web environment: fallback to browser download
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  };

  // Function to request storage permissions at runtime
  // Function to request storage permissions at runtime
  requestStoragePermissions = (blob, filename) => {
    // Check if running in a Cordova environment
    if (window.cordova && window.cordova.plugins.permissions) {
      const permissions = window.cordova.plugins.permissions;

      // List of permissions to request
      const permissionsList = [
        permissions.WRITE_EXTERNAL_STORAGE,
        permissions.READ_EXTERNAL_STORAGE
      ];

      // Check if permissions are already granted
      permissions.hasPermission(permissionsList, (status) => {
        if (!status.hasPermission) {
          // Request permissions if not granted
          permissions.requestPermissions(permissionsList, (status) => {
            if (status.hasPermission) {
              // alert('Permissions granted!');
              // Proceed with file operations
              this.saveFile(blob, filename);
            } else {
              alert('Permissions denied. Unable to save files.');
            }
          }, (error) => {
            alert('Failed to request permissions: ' + JSON.stringify(error));
          });
        } else {
          alert('Permissions already granted!');
          // Proceed with file operations
          this.saveFile(blob, filename);
        }
      }, (error) => {
        alert('Failed to check permissions: ' + JSON.stringify(error));
      });
    } else {
      alert('Cordova or permissions plugin is not available.');
    }
  };


  // Save file using Cordova's File API
  saveFile = (blob, filename) => {
    // Use external root directory (public storage)
    window.resolveLocalFileSystemURL(window.cordova.file.externalRootDirectory, (directoryEntry) => {
      // Create the 'Download' directory if it doesn't exist
      directoryEntry.getDirectory('Download', { create: true, exclusive: false }, (downloadDirEntry) => {
        // Check if the file already exists in the 'Download' directory
        downloadDirEntry.getFile(filename, { create: false }, (fileEntry) => {
          // If the file exists, remove it first
          fileEntry.remove(() => {
            // Now create the new file
            this.createAndWriteFile(downloadDirEntry, blob, filename);
          }, (deleteError) => {
            alert('Failed to delete existing file: ' + JSON.stringify(deleteError));
          });
        }, (error) => {
          // If the file doesn't exist, proceed to create a new file
          if (error.code === 1) { // Code 1: NOT_FOUND_ERR
            this.createAndWriteFile(downloadDirEntry, blob, filename);
          } else {
            alert('Failed to access file system: ' + JSON.stringify(error));
          }
        });
      }, (error) => {
        alert('Failed to create or access the Download directory: ' + JSON.stringify(error));
      });
    }, (error) => {
      alert('Failed to resolve file system: ' + JSON.stringify(error));
    });
  };

  // Helper function to create and write a new file
  createAndWriteFile = (directoryEntry, blob, filename) => {
    directoryEntry.getFile(filename, { create: true, exclusive: false }, (fileEntry) => {
      fileEntry.createWriter((fileWriter) => {
        fileWriter.onwriteend = () => {
          alert('File downloaded successfully!');
        };
        fileWriter.onerror = (e) => {
          alert('Failed to download file: ' + JSON.stringify(e));
        };
        fileWriter.write(blob);
      });
    }, (error) => {
      alert('Failed to create file: ' + JSON.stringify(error));
    });
  };




  convertToDataURI = (audioUrl) => {
    return new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', audioUrl, true);
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.send();
    });
  };

  async componentDidMount() {
    this.setState({ loading: true, loadingProgress: 10 });

    try {
      const fetchTasks = [
        // this.fetchAllEmployeeName(),
        this.fetchAllStock(),
        this.fetchAllSites(),
        this.fetchAllUsers()
      ];

      const increment = 100 / fetchTasks.length;

      await Promise.all(fetchTasks.map(task => task.then(() => this.updateLoadingProgress(increment))));
      setTimeout(() => {
        this.setState({
          loadingProgress: 100,
          loading: false,
          siteSelected: this.props.sites[0]._id
        });
      }, 300);

      window.addEventListener("resize", this.resizeEvent);

      if (window.cordova) {
        document.addEventListener("backbutton", this.handleBack);
      }
    } catch (error) {
      this.setState({ loading: false, loadingProgress: 66 });
      Notify("error", error);
    }
  }


  fetchAllStock = async () => {
    const { currentPage, searchQuerySites } = this.state;
    try {
      await this.props.findAllStock(this.state.selectedEmployees);
    } catch (error) {
      throw new Error(`Error fetching stock: ${error}`);
    }
  };
  // fetchAllEmployeeName = async () => {
  //   try {
  //     await this.props.findAllEmployeeName();
  //   } catch (error) {
  //     throw new Error(`Error fetching stock: ${error}`);
  //   }
  // };

  fetchAllSites = async () => {
    const { currentPage, searchQuerySites } = this.state;
    try {
      const response = await this.props.findAllSites({ searchQuerySites, page: currentPage, limit: 30 });
      // Update both the sites and the total count here
      this.setState({
        totalSites: response.data.total,
        // Optionally, update your sites array if that's part of your state
        sites: response.data.allSites
      });
    } catch (error) {
      throw new Error(`Error fetching sites: ${error}`);
    }
  };

  fetchAllUsers = async () => {
    try {
      await this.props.findAllUsers();
    } catch (error) {
      throw new Error(`Error fetching users: ${error}`);
    }
  };

  handleRowSelectionChange = selectedRowKeys => {
    this.setState({ selectedStockIds: selectedRowKeys });
  };

  renderStockForms = () => {
    const { selectedStockIds } = this.state;
    const { stock } = this.props;

    if (selectedStockIds.length === 0) {
      return (
        <StockForm
          editStock={this.state.editStock}
          selectedStockIds={selectedStockIds}
          addNewNotification={this.state.addNewNotification}
          recordingLinkNotif={this.state.recordingId}
          recordingLinkInterview={this.state.recordingId}
          editInterview={this.state.addInterviewVisible}
          sites={this.props.sites}
          users={this.props.users}
          allSitesSelected={this.state.siteSelected}
          stock={this.props.stock}
          wrappedComponentRef={form => (this.stockForm = form)}
          okText={this.state.editStock ? "Save" : "Add Stock"}
        />
      );
    } else {
      return (
        <Tabs type="card"
          animated={true}>
          {selectedStockIds.map(stockId => {
            const selectedStock = stock.find(item => item._id === stockId);
            const { employeeName, employeeLastName } = selectedStock;
            return (
              <TabPane tab={`${employeeName} ${employeeLastName}`} key={stockId}>
                <StockForm
                  key={stockId}
                  recordingLinkNotif={this.state.recordingId}

                  recordingLinkInterview={this.state.recordingId}
                  selectedStockIds={selectedStockIds}
                  editStock={selectedStock}
                  addNewNotification={this.state.addNewNotification}
                  editInterview={this.state.addInterviewVisible}
                  sites={this.props.sites}
                  users={this.props.users}
                  allSitesSelected={this.state.siteSelected}
                  stock={this.props.stock}
                  wrappedComponentRef={form => (this[`stockForm_${stockId}`] = form)}
                  okText={this.state.editStock ? "Save" : "Add Stock"}
                />
              </TabPane>
            );
          })}
        </Tabs>
      );
    }
  }
  handleBack = () => {
    if (this.state.addStockVisible) {
      this.setState({
        addStockVisible: false,
        viewSiteVisible: false,
        selectedStockIds: [],
        viewSelectedEmployee: false,
      });
    } else {
      this.props.history.goBack();
    }
  };
  handleBackToSites = () => {
    this.setState({
      addStockVisible: false,
      viewSiteVisible: false,
      selectedStockIds: [],
      viewSelectedEmployee: false,
      editStock: undefined,
    });
  };
  handleBackToEmployees = site => {
    this.setState({
      // siteSelected: site.name,
      addStockVisible: false,
      viewSiteVisible: true,
      selectedStockIds: [],
      viewSelectedEmployee: false,
      editStock: undefined,
    });
  };
  componentWillUnmount = () => {
    // window.pubnub.removeListener(this.updateSignal);
    window.removeEventListener("resize", this.resizeEvent);
    if (window.cordova) {
      document.removeEventListener("backbutton", this.handleBack);
    }
  };

  resizeEvent = () => {
    this.setState({ loading: false });
  };
  parseString = string => {
    return parseInt(string.split("R")[1].replace(/,/g, ""));
  };
  formatNumber = number => {
    return "R" + numeral(number).format("0,0");
  };

  handleSelectStock = e => {
    this.props.setStockDetailsId(e.currentTarget.getAttribute("data"));
    this.props.push("/admin/stockdetails");
  };
  handleSort = (pagination, filters, sorter) => {
    const columnKey = sorter.columnKey;
    const order = sorter.order;
    let stock = this.state.stock;
    if (order === "ascend") {
      stock.sort((a, b) => parseInt(a[columnKey]) - parseInt(b[columnKey]));
    } else if (order === "descend") {
      stock.sort((a, b) => parseInt(b[columnKey]) - parseInt(a[columnKey]));
    }
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };
  clearFilters = () => {
    this.setState({ filteredInfo: null });
  };
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        ""
      ),
  });
  stockForm;
  onAddStock = site => {
    this.setState({
      // siteSelected: site.name,
      addStockVisible: true,
      editStock: undefined,
      addInterviewVisible: null,
      addNewNotification: true,
    });
  };
  onAddStockInterview = site => {
    this.setState({
      // siteSelected: site.name,
      addStockVisible: true,
      editStock: undefined,
      addInterviewVisible: "addInterview",
      addNewNotification: true,
    });
  };
  onAddNotification = e => {
    this.setState({ addNotificationVisible: true });
  };

  onEditStock = (e, site) => {
    this.setState({
      viewSelectedEmployee: true,
      editStock: e,
      // siteSelected: site.name,
    });
  };

  showModalEnquiry = (stock) => {
    this.setState({
      visibleSendEnquiry: true,
      editStock: stock, // Store the stock in the state to use it in the modal
    });
  };
  showModalInterview = (stock) => {
    this.setState({
      visibleSendInterview: true,
      editStock: stock, // Store the stock in the state to use it in the modal
    });
  };
  handleOkEnquiry = e => {
    console.log(e);
    this.setState({
      visibleSendEnquiry: false,
    });
  };
  handleOkNotif = e => {
    console.log(e);
    this.setState({
      visibleSendNotif: false,
    });
  };
  handleOkInterview = e => {
    console.log(e);
    this.setState({
      visibleSendInterview: false,
    });
  };
  handleCancel = e => {
    console.log(e);
    this.setState({
      visibleSendNotif: false,
      visibleSendEnquiry: false,
      visibleSendInterview: false,
    });
  };
  handleSubmitNotif = values => {
    this.onFinishNotif(values);
  };

  onFinishNotif = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.state.editStock.access}`, style: "subheader" },
        { text: `Notification to Attend Enquiry`, style: "subheader" },
        {
          text: `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            }  From: ${this.state.editStock.access}`,
        },
        {
          text: `\n 
          Dear ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            },\n
           Please find notification to attend enquiry below.\n
           Alleged Charge(s): See Annexure “A” Below
           \n Date and time of enquiry: ${moment(
              this.state.editStock.enquiryDate
            ).format("DD MMMM YYYY")}  ${moment(
              this.state.editStock.enquiryTime
            ).format("HH:mm")}
          \n ${this.state.editStock.casePostponed === "Yes"
              ? "Postponed to: " +
              moment(this.state.editStock.enquiryDatePostponed).format(
                "DD MMMM YYYY"
              ) +
              " " +
              moment(this.state.editStock.enquiryTimePostponed).format(
                "HH:mm"
              )
              : "Postponed: No"
            } 
         \n Venue of enquiry: ${this.state.editStock.enquiryVenue}\n
        1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
              this.state.editStock.enquiryDatePostponed ? this.state.editStock.enquiryDatePostponed : this.state.editStock.enquiryDate
            )
              .subtract(1, "day")
              .format("DD MMMM YYYY")} at ${moment(
                this.state.editStock.enquiryTime
              ).format("HH:mm")} of the under mentioned:\n
          Name of your representative: ${this.state.editStock.representativeName !== "" &&
              this.state.editStock.representativeName !== undefined
              ? this.state.editStock.representativeName
              : "______________________________"
            }\n
        Name(s) of your witnesses: ${this.state.editStock.witnessesName !== "" &&
              this.state.editStock.witnessesName !== undefined
              ? this.state.editStock.witnessesName
              : "______________________________"
            }\n 
 Do you intend to make use of a interpreter: ${this.state.editStock.interpreterNeeded !== "" &&
              this.state.editStock.interpreterNeeded !== undefined
              ? this.state.editStock.interpreterNeeded
              : "Yes / No"
            }\n 
 If so n which language? ${this.state.editStock.interpreterLang !== "" &&
              this.state.editStock.interpreterLang !== undefined
              ? this.state.editStock.interpreterLang
              : "______________________________"
            }\n (If an interpreter is required such an interpreter will be allocated by management)\n
          Do you need any time to prepare: ${this.state.editStock.preparationNeeded !== "" &&
              this.state.editStock.preparationNeeded !== undefined
              ? this.state.editStock.preparationNeeded
              : "Yes / No"
            }\n
      1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
      Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
      ${this.state.editStock.frontImage !== undefined
              ? ""
              : "\nAccused Signature: ______________________________ \n"
            }
I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.
          \n Document issued by:
      ${this.state.editStock.access}\n

    
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
        {
          text: "Click here to view signature",
          link:
            process.env.REACT_APP_GLOBAL_SERVER +
            "asambo/api/images/" +
            this.state.editStock.frontImage,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.state.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        htmlStyle: {
          margin: [0, 10, 0, 0],
        },
      },
      defaultStyle: {
        font: "Roboto",
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

      // Send the email with the PDF file as an attachment
      const MSG = {
        to: this.state.sendEmailNotification,
        from: "no-reply@asambo.co.za",
        subject: `Notification to Attend Enquiry (${this.state.editStock.employeeName
          } ${this.state.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Notification to attend enquiry_${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Notification to attend enquiry - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
      };

      // send the email using fetch or any other email sending library
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendNotif: false,
        });
      });
    });
  };
  handleSubmitEnquiry = values => {
    this.onFinishEnquiry(values);
  };
  onFinishEnquiry = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.state.editStock.access}`, style: "header" },
        `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
        }  From: ${this.state.editStock.access}`,
        { text: "Disciplinary Enquiry", style: "subheader" },
        `Please find Disciplinary Enquiry below.
        \n`,
        {
          style: "tableExample",
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.state.editStock.companyName}`],
              [
                `Date and time of proceedings`,
                `${moment(this.state.editStock.enquiryDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.state.editStock.enquiryTime).format("HH:mm")}`,
              ],
              [
                `\n ${this.state.editStock.casePostponed === "Yes"
                  ? "Postponed to "
                  : "Postponed"
                } 
               \n`,
                `\n ${this.state.editStock.casePostponed === "Yes"
                  ? moment(this.state.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.state.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "No"
                } 
               \n`,
              ],
              [`Accused Name`, `${this.state.editStock.accusedName}`],
              [`Position`, `${this.state.editStock.jobTitle}`],
              [`Representative`, `${this.state.editStock.representativeName}`],
              [`Initiator`, `${this.state.editStock.initiatorName}`],
              [`Chairperson`, `${this.state.editStock.chairpersonName}`],
              [`Accused Venue / Place`, `${this.state.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [`Plea by the accused`, `${this.state.editStock.accusedPlea}`],
              [
                `Determination by Chairperson`,
                `${this.state.editStock.determinationFinding}`,
              ],
              [
                `Date of employment`,
                `${this.state.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.state.editStock.prevTransgressions}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.state.editStock.mitigationCircumstances}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.state.editStock.aggrivatingCircumstances}`,
              ],
              [`Suspension`, `${this.state.editStock.suspensionType}`],
              [
                `Sanction / Warning (Months)`,
                `${this.state.editStock.warning +
                " " +
                this.state.editStock.sanctionMonthsDisciplinary +
                " months"}`,
              ],
              [`Verdict`, `${this.state.editStock.otherWarnings}`],
            ],
          },
        },
        {
          text:
            this.state.editStock.refuseSignDisciplinaryEnquiry === true
              ? `\n Refused to sign : ` +
              this.state.editStock.refuseReasonDisciplinaryEnquiry
              : `\n Click here to view signature`,
          link:
            this.state.editStock.refuseSignDisciplinaryEnquiry === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.state.editStock.branchImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.state.editStock.allegedCharges}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

      // Send the email with the PDF file as an attachment
      const MSG = {
        to: this.state.sendEmailEnquiry,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Enquiry (${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
          })`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Enquiry_${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Enquiry - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
      };

      // send the email using fetch or any other email sending library
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendEnquiry: false,
        });
      });
    });
  };
  handleSubmitInterview = values => {
    this.onFinishInterview(values);
  };
  onFinishInterview = values => {
    // Generate the PDF file
    const docDefinition = {
      content: [
        { text: `${this.state.editStock.access}`, style: "header" },
        `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
        }  From: ${this.state.editStock.access}`,
        { text: "Disciplinary Interview", style: "subheader" },
        {
          text: ` Please find Disciplinary Interview below.
        \n`,
        },

        {
          table: {
            body: [
              [`Description`, `Details`],
              [`Company Name`, `${this.state.editStock.companyName}`],
              [
                `Date and time of interview`,
                `${moment(this.state.editStock.interviewDate).format(
                  "DD MMMM YYYY"
                )} ${moment(this.state.editStock.interviewTime).format(
                  "HH:mm"
                )}`,
              ],
              [`Accused Name`, `${this.state.editStock.accusedName}`],
              [`Initiator`, `${this.state.editStock.initiatorNameInterview}`],
              [`Position`, `${this.state.editStock.jobTitle}`],
              [
                `Representative`,
                `${this.state.editStock.representativeNameInterview}`,
              ],
              [`Initiator`, `${this.state.editStock.InitiatorInterview}`],
              [
                `Chairperson`,
                `${this.state.editStock.chairpersonNameInterview}`,
              ],
              [`Accused Venue / Place`, `${this.state.editStock.access}`],
              [`Alleged Charge(s)`, `See Annexure “A” Below `],
              [
                `Plea by the accused`,
                `${this.state.editStock.accusedPleaInterview}`,
              ],
              [
                `Determination by Chairperson`,
                `${this.state.editStock.determinationFindingInterview}`,
              ],
              [
                `Date of employment`,
                `${this.state.editStock.selectedemploymentDate}`,
              ],
              [
                `Valid previous transgressions`,
                `${this.state.editStock.prevTransgressionsInterview}`,
              ],
              [
                `Mitigation circumstances`,
                `${this.state.editStock.mitigationCircumstancesInterview}`,
              ],
              [
                `Aggrivating circumstances`,
                `${this.state.editStock.aggrivatingCircumstancesInterview}`,
              ],
              [
                `Sanction / Warning (Months)`,
                `${this.state.editStock.sanctionMonthsFinal + " months"}`,
              ],
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
              },
              tableExample: {
                margin: [0, 5, 0, 15],
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: "black",
              },
            },
          },
        },
        {
          text:
            this.state.editStock.refuseSignInterview === true
              ? `\n Refused to sign : ` +
              this.state.editStock.refuseReasonInterview
              : `\n Click here to view signature`,
          link:
            this.state.editStock.refuseSignInterview === false
              ? process.env.REACT_APP_GLOBAL_SERVER +
              "asambo/api/images/" +
              this.state.editStock.frontLeftImage
              : null,
        },
        { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
        {
          text: `Alleged Charge(s): \n\n
          ${this.state.editStock.allegedChargesInterview}
        `,
          style: "htmlStyle",
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5],
        },
        tableExample: {
          margin: [0, 5, 0, 15],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: "black",
        },
      },
      defaultStyle: {
        // alignment: 'justify'
      },
    };

    pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
      const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

      // Send the email with the PDF file as an attachment
      const MSG = {
        to: this.state.sendEmailInterview,
        from: "no-reply@asambo.co.za",
        subject: `Disciplinary Interview (${this.state.editStock.employeeName
          } ${this.state.editStock.employeeLastName})`,
        text: " ",
        attachments: [
          {
            filename: `Disciplinary Interview_${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName}.pdf`,
            content: pdfData,
            type: "application/pdf",
            disposition: "attachment",
          },
        ],
        html: `Dear Client, 

        please find attached Disciplinary Interview - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
      };

      // send the email using fetch or any other email sending library
      fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(MSG),
      }).then(async res => {
        if (!res.ok) {
          console.log("handle error");
        }
        console.log("handle success");
        this.setState({
          visibleSendInterview: false,
        });
      });
    });
  };
  handleEmailChangeNotification = value => {
    this.setState({ sendEmailNotification: value });
  };
  handleEmailChangeEnquiry = value => {
    this.setState({ sendEmailEnquiry: value });
  };
  handleEmailChangeInterview = value => {
    this.setState({ sendEmailInterview: value });
  };
  onEditEmployee = e => {
    this.setState({
      addStockVisible: true,
      editStock: e,
      sigVisible: false,
      addInterviewVisible: "none",
      addNewNotification: false,
    });
  };
  onEditSendNotif = (stock) => {
    this.setState({
      visibleSendNotif: true,
      editStock: stock, // Store the stock in the state to use it in the modal
    });
  };
  onEditEmployeeInterview = e => {
    this.setState({
      addInterviewVisible: "editInterview",
      addStockVisible: true,
      editStock: e,
      addNewNotification: false,
    });
  };
  onEditSendInterview = e => {
    this.setState({
      visibleSendInterview: true,
      editStock: e,
    });
  };
  onViewSite = site => {
    this.setState(
      { siteSelected: site.name, viewSiteVisible: true, selectedEmployees: [] },
      () => {
        // After updating state, call the Redux action
        this.props.findAllEmployeeName(null, site.name);
      }
    );
  };
  handleCancelAll = e => {
    if (this.props && typeof this.props.findAllStock === 'function') {
      this.setState({ loading: true, loadingProgress: 10 });
      const findAllStockResult = this.props.findAllStock(this.state.selectedEmployees);
      console.log('findAllStockResult:', findAllStockResult); // Log the result to check if it's a promise

      if (findAllStockResult && typeof findAllStockResult.then === 'function') {
        findAllStockResult
          .then(() => {
            this.updateLoadingProgress(60); // Update progress after successful findAllStock
            this.stockForm.props.form.resetFields();

            setTimeout(() => {
              this.setState({
                loading: false,
                loadingProgress: 100,
                addStockVisible: false,
                selectedStockIds: [],
                viewSiteVisible: true,
                viewSelectedEmployee: false,
                editStock: undefined,
                visibleCancel: false
              });
            }, 300); // 3-second delay to simulate completion
          })
          .catch(e => {
            this.setState({
              loading: false,
              loadingProgress: 0,
              addStockVisible: false,
              selectedStockIds: [],
              viewSiteVisible: true,
              viewSelectedEmployee: false,
              editStock: undefined,
              visibleCancel: false
            });
            Notify("error", e);
          });
      } else {
        console.error('Error: findAllStock did not return a promise');
        this.setState({
          loading: false,
          loadingProgress: 0,
          addStockVisible: false,
          selectedStockIds: [],
          viewSiteVisible: true,
          viewSelectedEmployee: false,
          editStock: undefined,
          visibleCancel: false
        });
      }
    } else {
      console.error('Error: findAllStock is not a function or props is undefined');
      this.setState({
        loading: false,
        loadingProgress: 0,
        addStockVisible: false,
        selectedStockIds: [],
        viewSiteVisible: true,
        viewSelectedEmployee: false,
        editStock: undefined,
        visibleCancel: false
      });
    }
  };


  // handleAddStock = e => {
  //   e.preventDefault();
  //   this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       // const formData = new FormData();

  //       this.setState({ loading: true });
  //       values.myRecordingNotification = this.stockForm.state.myRecordingNotification;
  //       values.myRecordingInterview = this.stockForm.state.myRecordingInterview;
  //       values.branchImage = this.stockForm.state.branchImage;
  //       values.frontRightImage = this.stockForm.state.frontRightImage;
  //       values.rightSideImage = this.stockForm.state.rightSideImage;
  //       values.wheelsImage = this.stockForm.state.wheelsImage;
  //       values.frontLeftImage = this.stockForm.state.frontLeftImage;
  //       values.frontImage = this.stockForm.state.frontImage;

  //       if (this.state.editStock) {
  //         values._id = this.state.editStock._id;
  //       }
  //       this.props
  //         .addStock(values)
  //         .then(() => {
  //           this.props
  //             .findAllStock(this.state.selectedEmployees)
  //             .then(() => {
  //               if (this.state.editStock) {
  //                 Notify("success", "Successfully saved");
  //               } else {
  //                 Notify("success", "Successfully added");
  //               }

  //               this.stockForm.props.form.resetFields();
  //               let itemCodes = [];
  //               this.props.stock.forEach(stock => {
  //                 if (stock.itemCode) {
  //                   itemCodes.push(
  //                     "Part: " +
  //                     stock.itemCode +
  //                     " - Location: " +
  //                     stock.location
  //                   );
  //                 }
  //               });
  //               // this.setState({ itemCodes: itemCodes });
  //               this.setState({
  //                 loading: false,
  //                 editStock: undefined,
  //                 addStockVisible: false,
  //                 viewSiteVisible: true,
  //                 viewSelectedEmployee: false,
  //               });
  //             })
  //             .catch(e => {
  //               this.setState({ loading: false });
  //               Notify("error", e);
  //             });
  //         })
  //         .catch(e => {
  //           this.setState({ loading: false });
  //           Notify("error", e);
  //         });
  //     }
  //   });
  // };
  // onSaveRecordingMultiple = e => {

  //   this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       // const formData = new FormData();

  //       this.setState({ loading: true });
  //       values.myRecordingNotification = this.stockForm.state.myRecordingNotification;
  //       values.myRecordingInterview = this.stockForm.state.myRecordingInterview;
  //       values.branchImage = this.stockForm.state.branchImage;
  //       values.frontRightImage = this.stockForm.state.frontRightImage;
  //       values.rightSideImage = this.stockForm.state.rightSideImage;
  //       values.wheelsImage = this.stockForm.state.wheelsImage;
  //       values.frontLeftImage = this.stockForm.state.frontLeftImage;
  //       values.frontImage = this.stockForm.state.frontImage;

  //       if (this.state.editStock) {
  //         values._id = this.state.editStock._id;
  //       }
  //       this.props
  //         .addStock(values)
  //         .then(() => {
  //           this.props
  //             .findAllStock(this.state.selectedEmployees)
  //             .then(() => {
  //               if (this.state.editStock) {
  //                 Notify("success", "Successfully saved");
  //               } else {
  //                 Notify("success", "Successfully added");
  //               }

  //               this.stockForm.props.form.resetFields();
  //               let itemCodes = [];
  //               this.props.stock.forEach(stock => {
  //                 if (stock.itemCode) {
  //                   itemCodes.push(
  //                     "Part: " +
  //                     stock.itemCode +
  //                     " - Location: " +
  //                     stock.location
  //                   );
  //                 }
  //               });
  //               // this.setState({ itemCodes: itemCodes });
  //               this.setState({
  //                 loading: false,
  //                 editStock: undefined,
  //                 addStockVisible: false,
  //                 viewSiteVisible: true,
  //                 viewSelectedEmployee: false,
  //               });
  //             })
  //             .catch(e => {
  //               this.setState({ loading: false });
  //               Notify("error", e);
  //             });
  //         })
  //         .catch(e => {
  //           this.setState({ loading: false });
  //           Notify("error", e);
  //         });
  //     }
  //   });
  // };
  handleEditSelectedStocks = () => {
    const { selectedStockIds } = this.state;
    const { stock } = this.props;

    const selectedStockForms = selectedStockIds.map(stockId => {
      const selectedStock = stock.find(item => item._id === stockId);
      return {
        ...selectedStock,
        formValues: { ...selectedStock }, // Copy values of selectedStock into formValues
      };
    });

    // Set the selected stocks to edit in the StockForm component
    this.setState({
      editStock: selectedStockForms,
      addStockVisible: true,
      sigVisible: false,
      addInterviewVisible: "none",
      addNewNotification: false,
    });
  };
  handleEditSelectedStocksInterview = () => {
    const { selectedStockIds } = this.state;
    const { stock } = this.props;

    const selectedStockForms = selectedStockIds.map(stockId => {
      const selectedStock = stock.find(item => item._id === stockId);
      return {
        ...selectedStock,
        formValues: { ...selectedStock }, // Copy values of selectedStock into formValues
      };
    });

    // Set the selected stocks to edit in the StockForm component
    this.setState({
      editStock: selectedStockForms,
      addInterviewVisible: "editInterview",
      addStockVisible: true,
      addNewNotification: false,
    });
  };
  handleAddStock = e => {
    e.preventDefault();
    this.stockForm.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true, loadingProgress: 10 });

        const totalSteps = 2;
        const increment = 100 / totalSteps;

        if (this.signaturePad) {
          values.signature = this.signaturePad.toDataURL();
          this.setState({ frontImage: values.signature });
        }
        if (this.signaturePadEnquiry) {
          values.branchImage = this.signaturePadEnquiry.toDataURL();
          this.setState({ branchImage: values.branchImage });
        }
        if (this.signaturePadInterview) {
          values.frontLeftImage = this.signaturePadInterview.toDataURL();
          this.setState({ frontLeftImage: values.frontLeftImage });
        }
        // values.recordingLinkNotif = this.stockForm.state.recordingLinkNotif;
        // values.recordingLinkInterview = this.stockForm.state.recordingLinkInterview;
        values.branchImage = this.stockForm.state.branchImage;
        values.frontRightImage = this.stockForm.state.frontRightImage;
        values.rightSideImage = this.stockForm.state.rightSideImage;
        values.wheelsImage = this.stockForm.state.wheelsImage;
        values.frontLeftImage = this.stockForm.state.frontLeftImage;
        values.frontImage = this.stockForm.state.frontImage;

        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }

        this.props
          .addStock(values)
          .then(() => {
            this.updateLoadingProgress(increment);
            return this.props.findAllStock(this.state.selectedEmployees);
          })
          .then(() => {
            this.updateLoadingProgress(increment);
            return this.props.findAllEmployeeName(null, this.state.siteSelected);
          })
          .then(() => {
            this.updateLoadingProgress(increment);
            if (this.state.editStock) {
              Notify("success", "Successfully saved");
            } else {
              Notify("success", "Successfully added");
            }
            // Reset form and update state
            this.stockForm.props.form.resetFields();
            this.setState({
              loading: false,
              loadingProgress: 100,
              editStock: undefined,
              addStockVisible: false,
              selectedStockIds: [],
              viewSiteVisible: true,
              selectedEmployees: [],
              viewSelectedEmployee: false,
              visibleSave: false,
              visibleSaveMulti: false,
            });
          })
          .catch((error) => {
            // Handle errors
            console.error("Error:", error);
            Notify("error", "Failed to add stock");
            this.setState({ loading: false, loadingProgress: 66 });
          });
      }
    });
  };


  handleAddStockMulti = e => {
    e.preventDefault();
    const { editStock } = this.state;
    const promises = editStock.map(selectedStock =>
      new Promise((resolve, reject) => {
        // Get the current form values for the current stock
        const formRef = this[`stockForm_${selectedStock._id}`];
        formRef.props.form.validateFieldsAndScroll((err, newFormValues) => {
          if (!err) {
            // Update the formValues with the new form field values
            const values = {
              ...selectedStock,
              ...newFormValues,
              // myRecordingNotification: formRef.state.myRecordingNotification,
              // myRecordingInterview: formRef.state.myRecordingInterview,
              branchImage: formRef.state.branchImage,
              frontRightImage: formRef.state.frontRightImage,
              rightSideImage: formRef.state.rightSideImage,
              wheelsImage: formRef.state.wheelsImage,
              frontLeftImage: formRef.state.frontLeftImage,
              frontImage: formRef.state.frontImage,
            };

            this.setState({ loading: true, loadingProgress: 10 });

            // Assuming addStock function can handle updates
            this.props.addStock(values)
              .then(() => {
                this.setState(prevState => ({
                  loadingProgress: prevState.loadingProgress + 100 / editStock.length
                }));
                resolve();
              })
              .catch(reject);
          } else {
            reject(err);
          }
        });
      })
    );
    Promise.all(promises)
      .then(() => {
        this.props.findAllStock(this.state.selectedEmployees)
          .then(() => {
            Notify("success", "Successfully updated");
            this.setState({
              loading: false,
              editStock: [], // Clear the editStock state after saving
              addStockVisible: false,
              selectedStockIds: [],
              viewSiteVisible: true,
              selectedEmployees: [],
              viewSelectedEmployee: false,
              visibleSaveMulti: false,
            });
            this.stockForm.props.form.resetFields(); // Reset all fields after all stocks are saved
          })
          .catch(e => {
            this.setState({
              loading: false,
              editStock: [], // Clear the editStock state after saving
              addStockVisible: false,
              selectedStockIds: [],
              viewSiteVisible: true,
              selectedEmployees: [],
              viewSelectedEmployee: false,
              visibleSaveMulti: false,
            });
            Notify("error", e);
          });
      })
      .catch(e => {
        this.setState({
          loading: false,
          editStock: [], // Clear the editStock state after saving
          addStockVisible: false,
          selectedStockIds: [],
          viewSiteVisible: true,
          selectedEmployees: [],
          viewSelectedEmployee: false,
          visibleSaveMulti: false,
        });
        Notify("error", e);
      });
  };

  onSaveRecordingMultiple = e => {
    const { editStock } = this.state;

    this.setState({ loading: true, loadingProgress: 10 }); // Set loading state before initiating promises

    const promises = editStock.map(selectedStock => {
      return new Promise((resolve, reject) => {
        const formRef = this[`stockForm_${selectedStock._id}`];
        formRef.props.form.validateFieldsAndScroll((err, newFormValues) => {
          if (!err) {
            const values = {
              ...selectedStock,
              ...newFormValues,
              myRecordingNotification: formRef.state.myRecordingNotification,
              myRecordingInterview: formRef.state.myRecordingInterview,
              branchImage: formRef.state.branchImage,
              frontRightImage: formRef.state.frontRightImage,
              rightSideImage: formRef.state.rightSideImage,
              wheelsImage: formRef.state.wheelsImage,
              frontLeftImage: formRef.state.frontLeftImage,
              frontImage: formRef.state.frontImage,
            };

            // Calculate total steps based on non-empty values
            const fields = [
              values.myRecordingNotification,
              values.myRecordingInterview,
              values.branchImage,
              values.frontRightImage,
              values.rightSideImage,
              values.wheelsImage,
              values.frontLeftImage,
              values.frontImage,
            ];

            const totalSteps = fields.filter(field => field !== undefined && field !== null).length || 1;
            const increment = 100 / (totalSteps * editStock.length);

            this.props.addStock(values)
              .then(() => {
                this.setState(prevState => ({
                  loadingProgress: prevState.loadingProgress + increment
                }));
                resolve();
              })
              .catch(reject);
          } else {
            reject(err);
          }
        });
      });
    });

    Promise.all(promises)
      .then(async () => {
        try {
          await this.props.findAllStock(this.state.selectedEmployees);
          Notify("success", "Successfully updated");
          this.setState({
            loading: false,
            editStock: [], // Clear the editStock state after saving
            addStockVisible: false,
            selectedStockIds: [],
            selectedEmployees: [],
            viewSiteVisible: true,
            viewSelectedEmployee: false,
          });
          editStock.forEach(stock => {
            const formRef = this[`stockForm_${stock._id}`];
            formRef.props.form.resetFields(); // Reset fields for each form
          });
        } catch (e) {
          this.setState({
            loading: false,
            editStock: [], // Clear the editStock state after saving
            addStockVisible: false,
            selectedStockIds: [],
            selectedEmployees: [],
            viewSiteVisible: true,
            viewSelectedEmployee: false,
          });
          // Notify("error", e.message);
        }
      })
      .catch(e => {
        this.setState({
          loading: false,
          editStock: [], // Clear the editStock state after saving
          addStockVisible: false,
          selectedStockIds: [],
          selectedEmployees: [],
          viewSiteVisible: true,
          viewSelectedEmployee: false,
        });
        // Notify("error", e.message);
      });
  };


  onSaveRecordingSingle = e => {
    this.stockForm.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        values.myRecordingNotification = this.stockForm.state.myRecordingNotification;
        values.myRecordingInterview = this.stockForm.state.myRecordingInterview;
        values.branchImage = this.stockForm.state.branchImage;
        values.frontRightImage = this.stockForm.state.frontRightImage;
        values.rightSideImage = this.stockForm.state.rightSideImage;
        values.wheelsImage = this.stockForm.state.wheelsImage;
        values.frontLeftImage = this.stockForm.state.frontLeftImage;
        values.frontImage = this.stockForm.state.frontImage;

        if (this.state.editStock) {
          values._id = this.state.editStock._id;
        }

        // Calculate total steps based on non-empty values
        const fields = [
          values.myRecordingNotification,
          values.myRecordingInterview,
          values.branchImage,
          values.frontRightImage,
          values.rightSideImage,
          values.wheelsImage,
          values.frontLeftImage,
          values.frontImage
        ];

        const totalSteps = fields.filter(field => field !== undefined && field !== null).length || 1;
        const increment = 100 / totalSteps;

        this.setState({ loadingProgress: 50 });

        try {
          await this.props.addStock(values);  // Ensure addStock is awaited
          this.setState({ loadingProgress: 80 });

          await this.props.findAllStock(this.state.selectedEmployees);  // Ensure findAllStock is awaited
          this.setState({ loadingProgress: 90 });

          if (this.state.editStock) {
            Notify("success", "Successfully saved");
          } else {
            Notify("success", "Successfully added");
          }

          this.stockForm.props.form.resetFields();
          this.setState({
            loadingProgress: 100,
            editStock: undefined,
            addStockVisible: false,
            selectedStockIds: [],
            selectedEmployees: [],
            viewSiteVisible: true,
            viewSelectedEmployee: false,
            loading: false,
          });  // Set loading to false after completion
        } catch (e) {
          this.setState({ loading: false, loadingProgress: 66 });  // Ensure loading is reset on error
          Notify("error", e.message);
        }
      }
    });
  };
  // handleAddNotification = e => {
  //   e.preventDefault();
  //   this.notificationForm.props.form.validateFieldsAndScroll((err, values) => {
  //     if (!err) {
  //       this.setState({ loading: true });
  //       if (this.state.editStock) {
  //         values._id = this.state.editStock._id;
  //       }
  //       this.props
  //         .addStock(values)
  //         .then(() => {
  //           this.props
  //             .findAllStock(this.state.selectedEmployees)
  //             .then(() => {
  //               if (this.state.editStock) {
  //                 Notify("success", "Successfully saved");
  //               } else {
  //                 Notify("success", "Successfully added");
  //               }
  //               this.notificationForm.props.form.resetFields();
  //               let itemCodes = [];
  //               this.props.stock.forEach(stock => {
  //                 if (stock.itemCode) {
  //                   itemCodes.push(
  //                     "Part: " +
  //                       stock.itemCode +
  //                       " - Location: " +
  //                       stock.location
  //                   );
  //                 }
  //               });
  //               this.setState({ itemCodes: itemCodes });
  //               this.setState({
  //                 loading: false,
  //                 addStockVisible: false,
  //                 viewSiteVisible: true,
  //                 viewSelectedEmployee: false,
  //                 editStock: undefined,
  //               });
  //             })
  //             .catch(e => {
  //               this.setState({ loading: false });
  //               Notify("error", e);
  //             });
  //         })
  //         .catch(e => {
  //           this.setState({ loading: false });
  //           Notify("error", e);
  //         });
  //     }
  //   });
  // };
  // handleAddDisciplinaryEnquiry = e => {
  //   e.preventDefault();
  //   this.disciplinaryEnquiryForm.props.form.validateFieldsAndScroll(
  //     (err, values) => {
  //       if (!err) {
  //         this.setState({ loading: true });
  //         if (this.state.editStock) {
  //           values._id = this.state.editStock._id;
  //         }
  //         this.props
  //           .addStock(values)
  //           .then(() => {
  //             this.props
  //               .findAllStock(this.state.selectedEmployees)
  //               .then(() => {
  //                 if (this.state.editStock) {
  //                   Notify("success", "Successfully saved");
  //                 } else {
  //                   Notify("success", "Successfully added");
  //                 }
  //                 this.disciplinaryEnquiryForm.props.form.resetFields();
  //                 let itemCodes = [];
  //                 this.props.stock.forEach(stock => {
  //                   if (stock.itemCode) {
  //                     itemCodes.push(
  //                       "Part: " +
  //                         stock.itemCode +
  //                         " - Location: " +
  //                         stock.location
  //                     );
  //                   }
  //                 });
  //                 this.setState({ itemCodes: itemCodes });
  //                 this.setState({
  //                   loading: false,
  //                   addStockVisible: false,
  //                   viewSiteVisible: true,
  //                   viewSelectedEmployee: false,
  //                   editStock: undefined,
  //                 });
  //               })
  //               .catch(e => {
  //                 this.setState({ loading: false });
  //                 Notify("error", e);
  //               });
  //           })
  //           .catch(e => {
  //             this.setState({ loading: false });
  //             Notify("error", e);
  //           });
  //       }
  //     }
  //   );
  // };
  // handleAddDisciplinaryInterview = e => {
  //   e.preventDefault();
  //   this.disciplinaryInterviewForm.props.form.validateFieldsAndScroll(
  //     (err, values) => {
  //       if (!err) {
  //         this.setState({ loading: true });
  //         if (this.state.editStock) {
  //           values._id = this.state.editStock._id;
  //         }
  //         this.props
  //           .addStock(values)
  //           .then(() => {
  //             this.props
  //               .findAllStock(this.state.selectedEmployees)
  //               .then(() => {
  //                 if (this.state.editStock) {
  //                   Notify("success", "Successfully saved");
  //                 } else {
  //                   Notify("success", "Successfully added");
  //                 }
  //                 this.disciplinaryInterviewForm.props.form.resetFields();
  //                 let itemCodes = [];
  //                 this.props.stock.forEach(stock => {
  //                   if (stock.itemCode) {
  //                     itemCodes.push(
  //                       "Part: " +
  //                         stock.itemCode +
  //                         " - Location: " +
  //                         stock.location
  //                     );
  //                   }
  //                 });
  //                 this.setState({ itemCodes: itemCodes });
  //                 this.setState({
  //                   loading: false,
  //                   addStockVisible: false,
  //                   viewSiteVisible: true,
  //                   viewSelectedEmployee: false,
  //                   editStock: undefined,
  //                 });
  //               })
  //               .catch(e => {
  //                 this.setState({ loading: false });
  //                 Notify("error", e);
  //               });
  //           })
  //           .catch(e => {
  //             this.setState({ loading: false });
  //             Notify("error", e);
  //           });
  //       }
  //     }
  //   );
  // };
  handleDeleteStock = e => {
    this.setState({ loading: true, loadingProgress: 10, deleteVisible: false });
    const data = this.state.deleteStock;
    this.props
      .deleteStock(data._id)
      .then(() => {
        this.props
          .findAllStock(this.state.selectedEmployees)
          .then(() => {
            let itemCodes = [];
            this.props.stock.forEach(stock => {
              if (stock.itemCode) {
                itemCodes.push(
                  "Part: " + stock.itemCode + " - Location: " + stock.location
                );
              }
            });
            this.setState({ loading: false, itemCodes: itemCodes });
            Notify("success", "Deleted", "Successfully deleted.");
          })
          .catch(e => {
            this.setState({ loading: false });
            Notify("error", e);
          });
        this.setState({ loading: false });
      })
      .catch(e => {
        this.setState({ loading: false });
        Notify("error", e);
      });
  };
  cancelDelete = e => {
    this.setState({ deleteVisible: false, deleteStock: "" });
  };
  checkQty = e => {
    let setQty = e;
    if (setQty !== "" && setQty !== null) {
      if (this.state.stockOut.qty < setQty) {
        this.setState({
          qty: parseInt(setQty),
          qtyError: true,
        });
      } else {
        this.setState({
          qty: parseInt(setQty),
          qtyError: false,
        });
      }
    } else {
      this.setState({
        qty: 1,
        qtyError: false,
      });
    }
  };
  renderStatus = (status, color) => {
    return (
      <p style={{ margin: 0 }}>
        <StyledBadge color={color} style={{}} />
        {status}
      </p>
    );
  };

  viewRig = rig => {
    this.setState({ currentRig: rig, viewRig: true });
  };

  handleSearchName = () => {
    if (this.state.selectedEmployees.length > 0) {
      // Join the array into a comma-separated string.
      const searchQuery = this.state.selectedEmployees.join(',');
      this.setState({ loading: true, loadingProgress: 0 });
      this.updateLoadingProgress(20);
      this.props.findAllStock(searchQuery)
        .then(() => {
          this.updateLoadingProgress(60);
          setTimeout(() => {
            this.setState({ loading: false, loadingProgress: 100 });
          }, 300);
        })
        .catch(e => {
          this.setState({ loading: false, loadingProgress: 66 });
          Notify("error", e);
        });
    }
  };


  showModalSave = () => {
    this.setState({
      visibleSave: true,
    });
  };

  handleOkSave = e => {
    // Pass a dummy event if e is not provided.
    this.handleAddStock({ preventDefault: () => { } });
  };

  handleCancelSave = e => {
    console.log(e);
    this.setState({
      visibleSave: false,
    });
  };

  showModalSaveMulti = () => {
    this.setState({
      visibleSaveMulti: true,
    });
  };

  handleOkSaveMulti = e => {
    // Pass a dummy event if e is not provided.
    this.handleAddStockMulti({ preventDefault: () => { } });
  };

  handleCancelSaveMulti = e => {
    console.log(e);
    this.setState({
      visibleSaveMulti: false,
    });
  };

  showModalCancel = () => {
    this.setState({
      visibleCancel: true,
    });
  };

  handleOkCancel = e => {
    // Pass a dummy event if e is not provided.
    this.handleCancelAll({ preventDefault: () => { } });
  };

  handleCancelCancel = e => {
    console.log(e);
    this.setState({
      visibleCancel: false,
    });
  };


  render() {
    const { sites } = this.props;
    const { totalSites, currentPage, loading } = this.state;
    const { size } = this.state;
    const signOut = s => {
      if (window.cordova) {
        window.FirebasePlugin.getToken(
          token => {
            window.pubnub.push.deleteDevice(
              {
                device: token,
                pushGateway: "gcm", // apns, gcm, mpns
              },
              function (status) {
                if (status.error) {
                  console.log("operation failed w/ error:", status);
                }
              }
            );
          },
          error => {
            console.error(error);
          }
        );
      }

      const cookies = new Cookies();
      cookies.remove("token");
      cookies.remove("basic");
      localStorage.clear();
      window.location.reload();
    };
    const stopRecordingAndOpenEnquiry = stopRecording => {
      this.setState({ loading: true, loadingProgress: 0 });
      stopRecording();

    };
    const stopRecordingAndOpenInterview = stopRecording => {
      this.setState({ loading: true, loadingProgress: 0 });
      stopRecording();

    };
    const { visiblePrep } = this.state;
    const { selectedStockIds } = this.state;
    async function doesImageExist(url) {
      const response = await fetch(url, { method: 'HEAD' });
      return response.ok; // Returns true if the image exists, false otherwise
    }
    async function imageUrlToBase64(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    }

    const onFinishNotif = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.state.editStock.frontImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [110, 110],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const chargeContent = [];

        for (let i = 1; i <= 20; i++) {
          const chargeKey = `allegedCharges${i}`;
          const chargeValue = this.state.editStock[chargeKey];
          const pleaKey = `accusedPlea${i}`;
          const pleaValue = this.state.editStock[pleaKey];

          if (chargeValue) {
            chargeContent.push(
              {
                text: `Alleged Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: chargeValue,
                style: 'tableChargeContent',
              },
              {
                text: `Accused's Plea to Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: pleaValue, // Change pleaKey to pleaValue here
                style: 'tableChargeContent',
              }
            );
          }
        }
        const docDefinition = {
          content: [
            { text: `${this.state.editStock.access}`, style: "subheader" },
            { text: `Notification to Attend Enquiry`, style: "subheader" },
            {
              text: `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                }  From: ${this.state.editStock.access}`,
            },
            {
              text: `
            Dear ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                },\n
             Please find notification to attend enquiry below.\n
             Alleged Charge(s): See Annexure “A” Below 
             \n Date and time of enquiry: ${moment(
                  this.state.editStock.enquiryDate
                ).format("DD MMMM YYYY")}  ${moment(
                  this.state.editStock.enquiryTime
                ).format("HH:mm")}
            \n ${this.state.editStock.casePostponed === "Yes"
                  ? "Postponed to: " +
                  moment(this.state.editStock.enquiryDatePostponed).format(
                    "DD MMMM YYYY"
                  ) +
                  " " +
                  moment(this.state.editStock.enquiryTimePostponed).format(
                    "HH:mm"
                  )
                  : "Postponed: No"
                } 
           \n Venue of enquiry: ${this.state.editStock.enquiryVenue}\n
          1.4 Take note of your rights as set out hereunder:\n Please inform Company Representative by no later than ${moment(
                  this.state.editStock.enquiryDatePostponed ? this.state.editStock.enquiryDatePostponed : this.state.editStock.enquiryDate
                )
                  .subtract(1, "day")
                  .format("DD MMMM YYYY")} at ${moment(
                    this.state.editStock.enquiryTime
                  ).format("HH:mm")} of the under mentioned:\n
            Name of your representative: ${this.state.editStock.representativeName !== "" &&
                  this.state.editStock.representativeName !== undefined
                  ? this.state.editStock.representativeName
                  : "______________________________ \n(You are entitled to representation by any co-employee available during the scheduled enquiry)"
                }\n
          Name(s) of your witnesses: ${this.state.editStock.witnessesName !== "" &&
                  this.state.editStock.witnessesName !== undefined
                  ? this.state.editStock.witnessesName
                  : "______________________________"
                }\n 
   Do you intend to make use of a interpreter: ${this.state.editStock.interpreterNeeded !== "" &&
                  this.state.editStock.interpreterNeeded !== undefined
                  ? this.state.editStock.interpreterNeeded
                  : "Yes / No"
                }\n 
   If so n which language? ${this.state.editStock.interpreterLang !== "" &&
                  this.state.editStock.interpreterLang !== undefined
                  ? this.state.editStock.interpreterLang
                  : "______________________________"
                }\n (If an interpreter is required such an interpreter will be allocated by management)\n
            Do you need any time to prepare: ${this.state.editStock.preparationNeeded !== "" &&
                  this.state.editStock.preparationNeeded !== undefined
                  ? this.state.editStock.preparationNeeded
                  : "Yes / No"
                }\n
        1.5 Any further information concerning the obove mentioned enquiry can be obtained from the person mentioned in 1.4 above.\n
        Note: that if you fail to attend the scheduled proceedings it will continue in your absence and you will waive all rights in this respect.
            \n Document issued by: ${this.state.editStock.access}
        
          `,
              style: "htmlStyle",
              margin: [0, 0, 0, 0],
            },
            {
              text:
                this.state.editStock.refuseReasonNotification === true
                  ? `\n Refused to sign : ` +
                  this.state.editStock.refuseReasonNotification
                  : null,
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.state.editStock.frontImage !== undefined ? images : null,
            this.state.editStock.frontImage !== undefined ? { text: ` _________________________  ${"  "} ${"  "}Company Representative: _______________________` } : null,
            this.state.editStock.frontImage !== undefined ? {
              text: `I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.`,
            } : null,
            this.state.editStock.frontImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________  ${"  "} Company Representative: ______________________ \n  
            I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                  } the accused hereby acknowledge receipt of this document and declare that I understand the contents thereof.`,
              },
            {
              text: `\n\nDate Issued: ___________ / ___________ / ___________`,
            },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...chargeContent,
            this.state.editStock.finalRuling ?
              { text: `Ruling`, pageBreak: "before", style: "subheader" } : null, // Add a new page
            this.state.editStock.finalRuling ?
              {
                text: `
            ${this.state.editStock.finalRuling}
          `,
                style: "htmlStyle",
                margin: [0, 10, 0, 0],
              } : null,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
          const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

          // Send the email with the PDF file as an attachment
          const MSG = {
            to: this.state.sendEmailNotification,
            from: "no-reply@asambo.co.za",
            subject: `Notification to Attend Enquiry (${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName})`,
            text: " ",
            attachments: [
              {
                filename: `Notification to attend enquiry_${this.state.editStock.employeeName
                  } ${this.state.editStock.employeeLastName}.pdf`,
                content: pdfData,
                type: "application/pdf",
                disposition: "attachment",
              },
            ],
            html: `Dear Client, 

        please find attached Notification to attend enquiry - 

        To: ${this.state.editStock.employeeName} 

        From: ${this.state.editStock.access} `,
          };

          // send the email using fetch or any other email sending library
          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          }).then(async res => {
            if (!res.ok) {
              console.log("handle error");
            }
            console.log("handle success");
            this.setState({
              visibleSendNotif: false,
            });
          });
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const onFinishEnquiry = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.state.editStock.branchImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [180, 180],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const chargeContent = [];

        for (let i = 1; i <= 20; i++) {
          const chargeKey = `allegedCharges${i}`;
          const chargeValue = this.state.editStock[chargeKey];
          const pleaKey = `accusedPlea${i}`;
          const pleaValue = this.state.editStock[pleaKey];

          if (chargeValue) {
            chargeContent.push(
              {
                text: `Alleged Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: chargeValue,
                style: 'tableChargeContent',
              },
              {
                text: `Accused's Plea to Charge ${i}: ${pleaValue}`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },

            );
          }
        }
        const docDefinition = {
          content: [
            { text: `${this.state.editStock.access}`, style: "header" },
            `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            }  From: ${this.state.editStock.access}`,
            { text: "Disciplinary Enquiry", style: "subheader" },
            {
              text: ` Please find Disciplinary Enquiry below.
            \n`,
            },

            {
              table: {
                body: [
                  [`Description`, `Details`],
                  [`Company Name`, `${this.state.editStock.companyName}`],
                  [
                    `Date and time of proceedings`,
                    `${moment(this.state.editStock.enquiryDate).format(
                      "DD MMMM YYYY"
                    )} ${moment(this.state.editStock.enquiryTime).format("HH:mm")}`,
                  ],
                  [
                    `\n ${this.state.editStock.casePostponed === "Yes"
                      ? "Postponed to "
                      : "Postponed"
                    } 
                   \n`,
                    `\n ${this.state.editStock.casePostponed === "Yes"
                      ? moment(this.state.editStock.enquiryDatePostponed).format(
                        "DD MMMM YYYY"
                      ) +
                      " " +
                      moment(this.state.editStock.enquiryTimePostponed).format(
                        "HH:mm"
                      )
                      : "No"
                    } 
                   \n`,
                  ],
                  [`Accused Name`, `${this.state.editStock.accusedName}`],
                  [`Position`, `${this.state.editStock.jobTitle}`],
                  [`Representative`, `${this.state.editStock.representativeName}`],
                  [`Initiator`, `${this.state.editStock.initiatorName}`],
                  [`Chairperson`, `${this.state.editStock.chairpersonName}`],
                  [`Accused Venue / Place`, `${this.state.editStock.access}`],
                  [`Alleged Charge(s)`, `See Annexure “A” Below `],

                  [
                    `Determination by Chairperson`,
                    `${this.state.editStock.determinationFinding}`,
                  ],
                  [
                    `Date of employment`,
                    `${this.state.editStock.selectedemploymentDate}`,
                  ],
                  [
                    `Valid previous transgressions`,
                    `${this.state.editStock.prevTransgressions}`,
                  ],
                  [
                    `Mitigation circumstances`,
                    `${this.state.editStock.mitigationCircumstances}`,
                  ],
                  [
                    `Aggrivating circumstances`,
                    `${this.state.editStock.aggrivatingCircumstances}`,
                  ],
                  [`Suspension`, `${this.state.editStock.suspensionType}`],
                  [
                    `Sanction / Warning (Months)`,
                    `${this.state.editStock.warning +
                    " " +
                    this.state.editStock.sanctionMonthsDisciplinary +
                    " months"}`,
                  ],
                  [`Verdict`, `${this.state.editStock.otherWarnings}`],
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: "black",
                  },
                },
              },
            },
            {
              text:
                this.state.editStock.refuseSignDisciplinaryEnquiry === true
                  ? `\n Refused to sign : ` +
                  this.state.editStock.refuseReasonDisciplinaryEnquiry
                  : null,
              // link:
              //   this.state.editStock.refuseSignDisciplinaryEnquiry === false
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //     "asambo/api/images/" +
              //     this.state.editStock.branchImage
              //     : null,
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.state.editStock.branchImage !== undefined ? images : null,
            this.state.editStock.branchImage !== undefined ? { text: `_______________________________________`, style: "subheader" } : null,
            this.state.editStock.branchImage !== undefined ? {
              text: `I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                } hereby by confirm that I understand the nature of the charges and my right to refer, I furthermore confirm receipt of a copy of this document.`,
            } : null,
            this.state.editStock.branchImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________________ \n  
          I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                  } hereby by confirm that I understand the nature of the charges and my right to refer, I furthermore confirm receipt of a copy of this document.`,
              },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...chargeContent,
            this.state.editStock.finalRuling ?
              { text: `Ruling`, pageBreak: "before", style: "subheader" } : null, // Add a new page
            this.state.editStock.finalRuling ?
              {
                text: `
        ${this.state.editStock.finalRuling}
      `,
                style: "htmlStyle",
                margin: [0, 10, 0, 0],
              } : null,
          ],
          styles: {
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 5],
            },
            htmlStyle: {
              margin: [0, 10, 0, 0],
            },
          },
          defaultStyle: {
            font: "Roboto",
          },
        };

        pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
          const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

          // Send the email with the PDF file as an attachment
          const MSG = {
            to: this.state.sendEmailEnquiry,
            from: "no-reply@asambo.co.za",
            subject: `Disciplinary Enquiry (${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
              })`,
            text: " ",
            attachments: [
              {
                filename: `Disciplinary Enquiry_${this.state.editStock.employeeName
                  } ${this.state.editStock.employeeLastName}.pdf`,
                content: pdfData,
                type: "application/pdf",
                disposition: "attachment",
              },
            ],
            html: `Dear Client, 
    
            please find attached Disciplinary Enquiry - 
    
            To: ${this.state.editStock.employeeName} 
    
            From: ${this.state.editStock.access} `,
          };

          // send the email using fetch or any other email sending library
          // send the email using fetch or any other email sending library
          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          }).then(async res => {
            if (!res.ok) {
              console.log("handle error");
            }
            console.log("handle success");
            this.setState({
              visibleSendEnquiry: false,
            });
          });
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    const onFinishInterview = async () => {
      const imageUrls = [
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/images/" +
        this.state.editStock.frontLeftImage,
      ];

      try {
        const existingImageUrls = await Promise.all(
          imageUrls.map(async (imageUrl) => {
            const exists = await doesImageExist(imageUrl);
            return exists ? imageUrl : null;
          })
        );
        const existingImages = existingImageUrls.filter((url) => url !== null);

        const base64Images = await Promise.all(
          existingImages.map(async (imageUrl) => {
            const base64String = await imageUrlToBase64(imageUrl);
            console.log(base64String); // Displaying the base64 representation (for demonstration)
            return base64String;
          })
        );

        const images = base64Images.map((base64String) => ({
          image: base64String,
          fit: [180, 180],
          margin: [0, 0, 0, 0]
          // pageBreak: 'before'
        }));
        const chargeContent = [];

        for (let i = 1; i <= 20; i++) {
          const chargeKey = `allegedChargesInterview${i}`;
          const chargeValue = this.state.editStock[chargeKey];
          const pleaKey = `accusedPleaInterview${i}`;
          const pleaValue = this.state.editStock[pleaKey];

          if (chargeValue) {
            chargeContent.push(
              {
                text: `Alleged Charge ${i}:`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
              {
                text: chargeValue,
                style: 'tableChargeContent',
              },
              {
                text: `Accused's Plea to Charge ${i}: ${pleaValue}`,
                style: 'tableChargeHeader',
                margin: [0, 10, 0, 10],
                bold: true,
              },
            );
          }
        }
        const docDefinition = {

          content: [
            { text: `${this.state.editStock.access}`, style: "header" },
            `To: ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
            }  From: ${this.state.editStock.access}`,
            { text: "Disciplinary Interview", style: "subheader" },
            {
              text: ` Please find Disciplinary Interview below.
              \n`,
            },

            {
              table: {
                body: [
                  [`Description`, `Details`],
                  [`Company Name`, `${this.state.editStock.companyName}`],
                  [
                    `Date and time of interview`,
                    `${moment(this.state.editStock.interviewDate).format(
                      "DD MMMM YYYY"
                    )} ${moment(this.state.editStock.interviewTime).format(
                      "HH:mm"
                    )}`,
                  ],
                  [`Accused Name`, `${this.state.editStock.accusedName}`],
                  [`Position`, `${this.state.editStock.jobTitle}`],
                  [
                    `Representative`,
                    `${this.state.editStock.representativeNameInterview}`,
                  ],
                  [`Initiator`, `${this.state.editStock.InitiatorInterview}`],
                  [
                    `Chairperson`,
                    `${this.state.editStock.chairpersonNameInterview}`,
                  ],
                  [`Accused Venue / Place`, `${this.state.editStock.access}`],
                  [`Alleged Charge(s)`, `See Annexure “A” Below `],
                  [
                    `Determination by Chairperson`,
                    `${this.state.editStock.determinationFindingInterview}`,
                  ],
                  [
                    `Date of employment`,
                    `${this.state.editStock.selectedemploymentDate}`,
                  ],
                  [
                    `Valid previous transgressions`,
                    `${this.state.editStock.prevTransgressionsInterview}`,
                  ],
                  [
                    `Mitigation circumstances`,
                    `${this.state.editStock.mitigationCircumstancesInterview}`,
                  ],
                  [
                    `Aggrivating circumstances`,
                    `${this.state.editStock.aggrivatingCircumstancesInterview}`,
                  ],
                  [
                    `Sanction / Warning (Months)`,
                    `${this.state.editStock.sanctionMonthsFinal + " months"}`,
                  ],
                ],
                styles: {
                  header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10],
                  },
                  subheader: {
                    fontSize: 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                  },
                  tableExample: {
                    margin: [0, 5, 0, 15],
                  },
                  tableHeader: {
                    bold: true,
                    fontSize: 13,
                    color: "black",
                  },
                },
              },
            },
            {
              text:
                this.state.editStock.refuseSignInterview === true
                  ? `\n Refused to sign : ` +
                  this.state.editStock.refuseReasonInterview
                  : null,
              // link:
              //   this.state.editStock.refuseSignDisciplinaryEnquiry === false
              //     ? process.env.REACT_APP_GLOBAL_SERVER +
              //     "asambo/api/images/" +
              //     this.state.editStock.branchImage
              //     : null,
            },
            // this.props.editStock.frontImage !== undefined ? { text: `Accused Signature` } : null,
            this.state.editStock.frontLeftImage !== undefined ? images : null,
            this.state.editStock.frontLeftImage !== undefined ? { text: `_______________________________________`, style: "subheader" } : null,
            this.state.editStock.frontLeftImage !== undefined ? {
              text: `I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                } hereby confirm that I understand the nature of the offence as well as the sanction taken above.`,
            } : null,
            this.state.editStock.frontLeftImage !== undefined ?
              null
              :
              {
                text: `\n\nAccused Signature: ______________________________ \n  
          I ${this.state.editStock.employeeName} ${this.state.editStock.employeeLastName
                  } hereby confirm that I understand the nature of the offence as well as the sanction taken above.`,
              },
            { text: `Annexure “A”`, pageBreak: "before", style: "subheader" }, // Add a new page
            ...chargeContent,
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10],
            },
            subheader: {
              fontSize: 16,
              bold: true,
              margin: [0, 10, 0, 5],
            },
            tableExample: {
              margin: [0, 5, 0, 15],
            },
            tableHeader: {
              bold: true,
              fontSize: 13,
              color: "black",
            },
          },
          defaultStyle: {
            // alignment: 'justify'
          },
        };

        pdfMake.createPdf(docDefinition).getDataUrl(dataUrl => {
          const pdfData = dataUrl.split(",")[1]; // extract the base64-encoded PDF data

          // Send the email with the PDF file as an attachment
          const MSG = {
            to: this.state.sendEmailInterview,
            from: "no-reply@asambo.co.za",
            subject: `Disciplinary Interview (${this.state.editStock.employeeName
              } ${this.state.editStock.employeeLastName})`,
            text: " ",
            attachments: [
              {
                filename: `Disciplinary Interview_${this.state.editStock.employeeName
                  } ${this.state.editStock.employeeLastName}.pdf`,
                content: pdfData,
                type: "application/pdf",
                disposition: "attachment",
              },
            ],
            html: `Dear Client, 
      
            please find attached Disciplinary Interview - 
      
            To: ${this.state.editStock.employeeName} 
      
            From: ${this.state.editStock.access} `,
          };

          // send the email using fetch or any other email sending library
          // send the email using fetch or any other email sending library
          fetch("https://www.vcsappcloud.com/emailclient/sendmail", {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            body: JSON.stringify(MSG),
          }).then(async res => {
            if (!res.ok) {
              console.log("handle error");
            }
            console.log("handle success");
            this.setState({
              visibleSendInterview: false,
            });
          });
        });
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };
    function EmployeeFilter(props, rigs) {
      return props.employeeName === props.employeeName;
    }
    let uniqueItems = [];


    this.props.stock.forEach(rig => {
      let existingItem = uniqueItems.find(
        uniqueItem =>
          uniqueItem.employeeName === rig.employeeName &&
          uniqueItem.employeeLastName === rig.employeeLastName
      );

      if (!existingItem && rig.access === this.state.siteSelected) {
        uniqueItems.push(rig);
      }
    });
    const filteredSites = this.props.sites.filter(site => {
      // Check if there exists a stock item that matches the conditions
      const existsInStock = this.props.employees.some(stockItem => {
        return (
          stockItem.access === site.name ||
          stockItem.access === site.name
          // stockItem.authorizedUsers === this.props.user.username && stockItem.access === site.name ||
          // stockItem.authorizedUsersInterview === this.props.user.username && stockItem.access === site.name
        );
      });

      // Return true to include the site in filteredSites if a matching stock item was found
      return existsInStock;
    });
    const { stock, employees } = this.props;
    let { sortedInfo, filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    let jobOptions = this.state.jobNumbers.map(d => (
      <Option key={d.sysRefNo}>{d.sysRefNo}</Option>
    ));
    let technicianOptions = this.state.technicians.map(d => (
      <Option key={d.username} value={d.name}>
        {d.name + " - " + d.username.split("|")[1]}
      </Option>
    ));
    let unRigs = [];
    this.props.stock.forEach(rig => {
      if (rig.access === this.state.siteSelected) {
        unRigs.push(rig);
      }
    });
    let rigs = [];
    let unProcessedRigs = JSON.parse(JSON.stringify(unRigs));
    unProcessedRigs.forEach(rig => {
      if (rig.cost) {
        rig.cost.forEach((cost, index) => {
          if (
            moment(cost.date).isBetween(
              this.state.startDate,
              this.state.endDate,
              moment.startOf,
              "[]"
            )
          ) {
            rig.totalCost =
              rig.totalCost && rig.totalCost !== "None"
                ? rig.totalCost + parseFloat(cost.price)
                : parseFloat(cost.price);
          } else {
            rig.cost.splice(index, 1);
          }
        });
        rigs.push(rig);
      }
      if (!rig.cost) {
        rigs.push(rig);
      }
    });
    let totalCost = 0;
    let totalMeters = 0;
    rigs.forEach(rig => {
      if (!rig.totalCost) {
        rig.totalCost = 0;
      }
      totalCost = totalCost + rig.totalCost;
      totalMeters = totalMeters + (rig.meters ? rig.meters : 0);
    });
    let currentRig;
    let currentRigTotal = 0;
    if (this.state.currentRig) {
      currentRig = rigs.find(rig => rig._id === this.state.currentRig._id);
      currentRigTotal = currentRig.totalCost;
    }
    // const interviewDate = moment(stock.interviewDate).add(
    //   stock.sanctionMonthsFinal,
    //   "month"
    // );
    // const editInterviewDate = moment(stock.interviewDate);
    // const isLater = interviewDate.isAfter(editInterviewDate);

    // const enquiryDates = moment(stock.enquiryDate).subtract(
    //   stock.sanctionMonthsDisciplinary,
    //   "month"
    // );
    // const editEnquiryDate = moment(stock.enquiryDate);
    // const isLaterEnquiry = editEnquiryDate.isAfter(enquiryDates);
    if (!this.state.viewRig) {
      return (
        <div>
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              textAlign: "center",
              maxWidth: "90em",
              margin: "auto",
              display:
                !this.state.viewSiteVisible &&
                  !this.state.addStockVisible &&
                  !this.state.viewSelectedEmployee
                  ? "flex"
                  : "none",
            }}
          >
            <Col span={24}>
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                Employee Management
              </span>{" "}
              <Search
                size='large'
                placeholder="Search Company"
                onSearch={this.handleSearchSites}
                enterButton
                style={{ marginTop: "1em", float: "right" }}
              />
            </Col>
          </Row>

          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              textAlign: "center",
              maxWidth: "90em",
              margin: "auto",
              display:
                this.state.viewSiteVisible &&
                  !this.state.addStockVisible &&
                  !this.state.viewSelectedEmployee
                  ? "flex"
                  : "none",
            }}
          >
            {/* <Col xs={24} md={14} style={{ textAlign: "left" }}>
              <span style={{ fontWeight: 750, fontSize: 18 }}>
                <Button
                  type="primary"
                  shape="round"
                  onClick={this.handleBackToSites}
                  size="large"
                  style={{
                    fontSize: "12px",
                    width: "2em",
                    height: "2em",
                    //height: "3em",
                    marginRight: "0.4em",
                    padding: 0,
                  }}
                >
                  <ArrowLeftOutlined />
                </Button>{" "}
                {this.state.siteSelected}
              </span>{" "}
            </Col> */}
            {/* <Col xs={24} md={10} style={{ textAlign: "right" }}>
     
              <Button
                type="primary"
                shape="round"
                onClick={this.onAddStock}
                // onClick={this.onAddNotification}
                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                size="large"
                style={{
                  marginLeft: "0.5em",
                  height: "3em",
                  width: "12em",
                }}
              >
                New Notification
              </Button>
              <Button
                type="primary"
                shape="round"
                onClick={this.onAddStockInterview}
                // onClick={this.onAddNotification}
                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                size="large"
                style={{
                  marginLeft: "0.5em",
                  height: "3em",
                  width: "11em",
                }}
              >
                New Interview
              </Button>
            </Col> */}
          </Row>
          <Row
            type="flex"
            justify="start"
            align="middle"
            style={{
              textAlign: "center",
              maxWidth: "90em",
              margin: "auto",
              display:
                this.state.viewSelectedEmployee && !this.state.addStockVisible
                  ? "flex"
                  : "none",
            }}
          >
            {/* <Col xs={24} md={18} style={{ textAlign: "left" }}>
              <StyledSelect
                value={this.state.siteSelected}
                onChange={e => {
                  this.setState({ siteSelected: e });
                }}
                defaultValue={this.state.siteSelected}
                style={{ height: 55, marginLeft: "1em" }}
              >
                {this.props.sites.map(site => (
                  <Option value={site.name}>{site.name}</Option>
                ))}
              </StyledSelect>
              <br /> <br />
              <Button
                type="primary"
                shape="round"
                onClick={this.onAddNotification}
                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                size="large"
                style={{
                  marginRight: "0.5em",
                  height: "3em",
                  width: "12em",
                }}
              >
                Add Notification
              </Button>
            </Col> */}
          </Row>
          <div style={{ maxWidth: "90em", margin: "auto" }}>
            <Spin spinning={this.state.loading} indicator={<Progress strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }} type="circle" width={80} percent={this.state.loadingProgress} />}>
              {this.state.addStockVisible ? (
                <Row
                  style={{
                    background: "white",
                    borderRadius: 5,
                    marginTop: 20,
                    display:
                      this.state.addStockVisible ||
                        this.state.addNotificationVisible
                        ? "contents"
                        : "none",
                  }}
                // type="flex"
                // justify="space-around"
                // align="middle"
                >
                  {/* <h1
                  style={{
                    fontWeight: 750,
                    fontSize: 20,
                    float: "right",
                    marginLeft: "-7em",
                  }}
                >
                  {this.state.editStock
                    ? this.state.editStock.employeeName +
                      " " +
                      this.state.editStock.employeeLastName
                    : null}
                </h1> */}

                  <div>
                    <div className="container">
                      <div className="inner-container">
                        <div className="buttons">
                          {/* Pause/Resume Button */}
                          <button
                            className="btn-play"
                            style={{ zIndex: 8 }}
                            onClick={
                              this.state.status === RECORD_STATUS.RECORDING
                                ? this.pauseRecording
                                : this.resumeRecording
                            }
                          >
                            {this.state.status === RECORD_STATUS.RECORDING ? (
                              <PauseCircleFilled />
                            ) : (
                              <PlayCircleFilled />
                            )}
                          </button>

                          {/* Start Button */}
                          <button
                            className="btn-record"
                            style={{
                              backgroundColor: "#009BDF",
                              zIndex: this.state.status === RECORD_STATUS.RECORDING ? 8 : 10,
                            }}
                            onClick={this.startRecording}
                            disabled={this.state.isRecording} // Disable start when already recording
                          >
                            <TrademarkCircleFilled />
                          </button>

                          {/* Stop Button */}
                          <button
                            className="btn-stop"
                            style={{
                              backgroundColor:
                                this.state.status === RECORD_STATUS.RECORDING ? "red" : "#009BDF",
                              zIndex: this.state.status === RECORD_STATUS.RECORDING ? 10 : 8,
                            }}
                            onClick={this.stopRecording}
                            disabled={!this.state.isRecording} // Disable stop when not recording
                          >
                            <CloseCircleFilled />
                          </button>
                        </div>
                      </div>
                    </div>


                    {/* Timer */}
                    <p
                      style={{
                        visibility: this.state.isRecording || this.state.isPaused ? "visible" : "hidden",
                      }}
                      className={`timer ${this.state.isPaused ? "blink-animation" : ""}`}
                    >
                      {new Date(this.state.timer * 1000).toISOString().substr(11, 8)}
                    </p>
                  </div>

                  {this.state.addInterviewVisible === "none" ?
                    // <ReactAudioRecorder
                    //   render={({
                    //     timer,
                    //     stopRecording,
                    //     startRecording,
                    //     resumeRecording,
                    //     pauseRecording,
                    //     audioResult,
                    //     status,
                    //     errorMessage,
                    //   }) => (
                    //     <div className="container">
                    //       <div className="inner-container">
                    //         <div className="buttons">
                    //           <button
                    //             className="btn-play"
                    //             style={{ zIndex: 8 }}
                    //             onClick={
                    //               status === RECORD_STATUS.RECORDING
                    //                 ? pauseRecording
                    //                 : resumeRecording
                    //             }
                    //           >
                    //             {status === RECORD_STATUS.RECORDING ? (
                    //               <PauseCircleFilled />
                    //             ) : (
                    //               <PlayCircleFilled />
                    //             )}
                    //           </button>
                    //           <button
                    //             className="btn-record"
                    //             style={{
                    //               backgroundColor: "#009BDF",
                    //               zIndex:
                    //                 status === RECORD_STATUS.RECORDING
                    //                   ? 8
                    //                   : 10,
                    //             }}
                    //             onClick={startRecording}
                    //           >
                    //             <TrademarkCircleFilled />
                    //           </button>
                    //           <button
                    //             style={{
                    //               backgroundColor:
                    //                 status === RECORD_STATUS.RECORDING
                    //                   ? "red"
                    //                   : "#009BDF",
                    //               zIndex:
                    //                 status === RECORD_STATUS.RECORDING
                    //                   ? 10
                    //                   : 8,
                    //             }}
                    //             className="btn-stop"
                    //             onClick={() =>
                    //               stopRecordingAndOpenEnquiry(stopRecording)
                    //             }
                    //           >
                    //             <CloseCircleFilled />
                    //           </button>
                    //         </div>
                    //         <p
                    //           style={{
                    //             visibility:
                    //               status === RECORD_STATUS.RECORDING ||
                    //                 status === RECORD_STATUS.PAUSED
                    //                 ? "visible"
                    //                 : "hidden",
                    //           }}
                    //           className={`timer ${status === RECORD_STATUS.PAUSED
                    //             ? "blink-animation"
                    //             : ""
                    //             }`}
                    //         >
                    //           {new Date(timer * 1000)
                    //             .toISOString()
                    //             .substr(11, 8)}
                    //         </p>

                    //         <audio
                    //           style={{ display: "none" }}
                    //           controls
                    //           src={audioResult}
                    //           onLoadedData={() => this.handleLoadedDataNotif(audioResult)}
                    //         />
                    //       </div>
                    <span>
                      {selectedStockIds.length > 0 && (
                        <Tooltip placement="left" title="Save">
                          <Button
                            disabled={this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? true : false}
                            type="primary"
                            shape="round"
                            // onClick={this.handleAddStockMulti}
                            onClick={this.showModalSaveMulti}
                            size="large"
                            style={{
                              zIndex: 2,
                              position: "fixed",
                              bottom: "9.5em",
                              right: "1em",
                              fontSize: "14px",
                              width: "4em",
                              height: "4em",
                              //height: "3em",
                              marginLeft: "0.7em",
                            }}
                          >
                            <CheckOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      {selectedStockIds.length === 0 && (
                        <Tooltip placement="left" title="Save">
                          <Button
                            disabled={this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? true : false}
                            type="primary"
                            shape="round"
                            // onClick={this.handleAddStock}
                            onClick={this.showModalSave}
                            size="large"
                            style={{
                              zIndex: 2,
                              position: "fixed",
                              bottom: "9.5em",
                              right: "1em",
                              fontSize: "14px",
                              width: "4em",
                              height: "4em",
                              //height: "3em",
                              marginLeft: "0.7em",
                            }}
                          >
                            <CheckOutlined />
                          </Button>
                        </Tooltip>
                      )}


                      <Tooltip placement="left" title="Cancel">
                        <Button
                          disabled={this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? true : false}
                          shape="round"
                          // onClick={this.handleCancelAll}
                          onClick={this.showModalCancel}
                          style={{
                            zIndex: 2,
                            fontSize: "14px",
                            width: "4em",
                            height: "4em",
                            position: "fixed",
                            bottom: "5em",
                            right: "1em",
                            border: "1px solid #EEEFF0",
                            backgroundColor: this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? "#f5f5f5" : "red",
                            color: this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? "lightgrey" : "white",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Tooltip>
                    </span>
                    //   )}
                    // /> 
                    :
                    // <ReactAudioRecorder
                    //   render={({
                    //     timer,
                    //     stopRecording,
                    //     startRecording,
                    //     resumeRecording,
                    //     pauseRecording,
                    //     audioResult,
                    //     status,
                    //     errorMessage,
                    //   }) => (
                    //     <div className="container">
                    //       <div className="inner-container">
                    //         <div className="buttons">
                    //           <button
                    //             className="btn-play"
                    //             style={{ zIndex: 8 }}
                    //             onClick={
                    //               status === RECORD_STATUS.RECORDING
                    //                 ? pauseRecording
                    //                 : resumeRecording
                    //             }
                    //           >
                    //             {status === RECORD_STATUS.RECORDING ? (
                    //               <PauseCircleFilled />
                    //             ) : (
                    //               <PlayCircleFilled />
                    //             )}
                    //           </button>
                    //           <button
                    //             className="btn-record"
                    //             style={{
                    //               backgroundColor: "#009BDF",
                    //               zIndex:
                    //                 status === RECORD_STATUS.RECORDING
                    //                   ? 8
                    //                   : 10,
                    //             }}
                    //             onClick={startRecording}
                    //           >
                    //             <TrademarkCircleFilled />
                    //           </button>
                    //           <button
                    //             style={{
                    //               backgroundColor:
                    //                 status === RECORD_STATUS.RECORDING
                    //                   ? "red"
                    //                   : "#009BDF",
                    //               zIndex:
                    //                 status === RECORD_STATUS.RECORDING
                    //                   ? 10
                    //                   : 8,
                    //             }}
                    //             className="btn-stop"
                    //             onClick={() =>
                    //               stopRecordingAndOpenInterview(stopRecording)
                    //             }
                    //           >
                    //             <CloseCircleFilled />
                    //           </button>
                    //         </div>
                    //         <p
                    //           style={{
                    //             visibility:
                    //               status === RECORD_STATUS.RECORDING ||
                    //                 status === RECORD_STATUS.PAUSED
                    //                 ? "visible"
                    //                 : "hidden",
                    //           }}
                    //           className={`timer ${status === RECORD_STATUS.PAUSED
                    //             ? "blink-animation"
                    //             : ""
                    //             }`}
                    //         >
                    //           {new Date(timer * 1000)
                    //             .toISOString()
                    //             .substr(11, 8)}
                    //         </p>

                    //         <audio
                    //           style={{ display: "none" }}
                    //           controls
                    //           src={audioResult}
                    //           onLoadedData={() => this.handleLoadedDataInterview(audioResult)}
                    //         />
                    //       </div>
                    <span>
                      {selectedStockIds.length > 0 && (
                        <Tooltip placement="left" title="Save">
                          <Button
                            disabled={this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? true : false}
                            type="primary"
                            shape="round"
                            // onClick={this.handleAddStockMulti}
                            onClick={this.showModalSaveMulti}
                            size="large"
                            style={{
                              zIndex: 2,
                              position: "fixed",
                              bottom: "9.5em",
                              right: "1em",
                              fontSize: "14px",
                              width: "4em",
                              height: "4em",
                              //height: "3em",
                              marginLeft: "0.7em",
                            }}
                          >
                            <CheckOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      {selectedStockIds.length === 0 && (
                        <Tooltip placement="left" title="Save">
                          <Button
                            disabled={this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? true : false}
                            type="primary"
                            shape="round"
                            // onClick={this.handleAddStock}
                            onClick={this.showModalSave}
                            size="large"
                            style={{
                              zIndex: 2,
                              position: "fixed",
                              bottom: "9.5em",
                              right: "1em",
                              fontSize: "14px",
                              width: "4em",
                              height: "4em",
                              //height: "3em",
                              marginLeft: "0.7em",
                            }}
                          >
                            <CheckOutlined />
                          </Button>
                        </Tooltip>
                      )}


                      <Tooltip placement="left" title="Cancel">
                        <Button
                          disabled={this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? true : false}
                          shape="round"
                          // onClick={this.handleCancelAll}
                          onClick={this.showModalCancel}
                          style={{
                            zIndex: 2,
                            fontSize: "14px",
                            width: "4em",
                            height: "4em",
                            position: "fixed",
                            bottom: "5em",
                            right: "1em",
                            border: "1px solid #EEEFF0",
                            backgroundColor: this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? "#f5f5f5" : "red",
                            color: this.state.status === RECORD_STATUS.RECORDING || this.state.status === RECORD_STATUS.PAUSED ? "lightgrey" : "white",
                          }}
                        >
                          <CloseOutlined />
                        </Button>
                      </Tooltip>
                    </span>
                    //   )}
                    // />
                  }
                  {this.renderStockForms()}

                </Row>
              )
                : (
                  <span>
                    <Row
                      style={{
                        background: "white",
                        borderRadius: 5,
                        marginTop: 20,
                        display: !this.state.viewSiteVisible ? "flex" : "none",
                        paddingBottom: "10em"
                      }}
                      type="flex"
                      justify="start"
                      align="middle"
                    >
                      <Col span={24}>
                        <StyledTable
                          style={{ background: "white" }}
                          rowKey="_id"
                          bordered={false}
                          dataSource={sites}
                          size="middle"
                          loading={loading}
                          pagination={false}
                          expandedRowRender={
                            window.innerWidth <= 420
                              ? record => (
                                <Card
                                  size="small"
                                  title={record.itemCode}
                                // style={{ width: 300, marginLeft: -35 }}
                                >
                                  <p>Contact Name: {record.contactName}</p>
                                  <p>Number: {record.number}</p>
                                  <p>Address: {record.address}</p>
                                </Card>
                              )
                              : null
                          }
                          onChange={this.handleSort}
                          onRow={record => {
                            if (parseInt(record.qty) === 0) {
                              return { style: { background: "#ffd3d3" } };
                            }
                          }}
                        >
                          <Column
                            {...this.getColumnSearchProps("name")}
                            title="Site Name"
                            dataIndex="name"
                            key="name"
                          />
                          {window.innerWidth > 420 ? (
                            <Column
                              //{...this.getColumnSearchProps("name")}
                              title="Address"
                              dataIndex="address"
                              key="address"
                            />
                          ) : null}
                          {window.innerWidth > 420 ? (
                            <Column
                              //{...this.getColumnSearchProps("name")}
                              title="Contact Name"
                              dataIndex="contactName"
                              key="contactName"
                            />
                          ) : null}
                          {window.innerWidth > 420 ? (
                            <Column
                              //{...this.getColumnSearchProps("name")}
                              title="Number"
                              dataIndex="number"
                              key="number"
                            />
                          ) : null}
                          {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Email"
                      dataIndex="email"
                      key="email"
                    /> */}
                          <Column
                            title="Actions"
                            // width={200}
                            render={(text, site) => (
                              <span>
                                {/* <Button
                            style={{
                              backgroundColor: "#009BDF",
                              color: "white",
                              borderRadius: "3em",
                            }}
                            key={site.name}
                            onClick={() => this.onViewSite(site)}
                          >
                            View
                          </Button> */}
                                <EditFilled
                                  style={{
                                    backgroundColor: "rgb(0, 155, 223)",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  key={site.name}
                                  onClick={() => this.onViewSite(site)}
                                />

                                {/* <Divider type="vertical" />
                          <DeleteFilled
                            style={{
                              backgroundColor: "#CFD5E3",
                              color: "white",
                              padding: "0.5em",
                              borderRadius: "3em",
                            }}
                            onClick={e => {
                              this.setState({
                                deleteVisible: true,
                                deleteStock: stock,
                              });
                            }}
                          /> */}
                              </span>
                            )}
                          />
                        </StyledTable>
                        <Pagination
                          current={currentPage}
                          total={totalSites}
                          pageSize={30}
                          onChange={this.handlePageChange}
                          style={{ marginTop: 16, textAlign: "center" }}
                        />
                      </Col>
                    </Row>
                    <Row
                      style={{
                        background: "white",
                        borderRadius: 5,
                        // marginTop: 20,
                        display:
                          this.state.viewSelectedEmployee &&
                            !this.state.addStockVisible
                            ? "flex"
                            : "none",
                      }}
                      type="flex"
                      justify="start"
                      align="middle"
                    >
                      <Tabs
                        // defaultActiveKey={1}
                        animated={true}
                        onChange={callback1}
                        // activeKey={this.state.currentTab}
                        type="card"
                        style={{ width: "100%" }}
                        tabBarExtraContent={{
                          left: (
                            <span
                              style={{
                                fontWeight: 750,
                                fontSize: 18,
                                marginRight: "1em",
                              }}
                            >
                              <Button
                                type="primary"
                                shape="round"
                                onClick={this.handleBackToEmployees}
                                size="large"
                                style={{
                                  fontSize: "12px",
                                  width: "2em",
                                  height: "2em",
                                  //height: "3em",
                                  marginRight: "0.6em",
                                  padding: 0,
                                }}
                              >
                                <ArrowLeftOutlined />
                              </Button>{" "}
                              {/* {this.state.editStock
                          ? this.state.editStock.employeeName
                          : null}{" "}
                        {this.state.editStock
                          ? this.state.editStock.employeeLastName
                          : null}
                        's */}
                              {/* Charge(s) */}
                            </span>
                          ),
                        }}
                      >
                        <TabPane tab="Notification & Enquiry" key="Enquiry">
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Disciplinary Date:{" "}
                                        {moment(record.enquiryDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Enquiry Date:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsDisciplinary ===
                                              "N/A" ||
                                              moment(record.enquiryDate)
                                                .add(
                                                  record.sanctionMonthsDisciplinary,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsDisciplinary ===
                                            undefined
                                            ? "None"
                                            : record.sanctionMonthsDisciplinary}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsDisciplinary ===
                                              "N/A" ||
                                              moment(record.enquiryDate)
                                                .add(
                                                  record.sanctionMonthsDisciplinary,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsDisciplinary ===
                                            "N/A"
                                            ? "N/A"
                                            : moment(record.enquiryDate)
                                              .add(
                                                record.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.editStock
                                  ? stock.filter((item) =>
                                    item.sanctionMonthsDisciplinary
                                      ? item.employeeName === this.state.editStock.employeeName &&
                                      item.employeeLastName === this.state.editStock.employeeLastName &&
                                      item.employeeEmail === this.state.editStock.employeeEmail &&
                                      !item.interviewDate &&
                                      (
                                        moment(item.enquiryDatePostponed || item.enquiryDate)
                                          .add(item.sanctionMonthsDisciplinary, "month")
                                          .isAfter(moment()) || moment(item.enquiryDatePostponed || item.enquiryDate).isSame(moment(), "day")
                                      )
                                      : item.sanctionMonthsDisciplinary === undefined && !item.enquiryDate
                                        ? item.employeeName === this.state.editStock.employeeName &&
                                        item.employeeLastName === this.state.editStock.employeeLastName &&
                                        item.employeeEmail === this.state.editStock.employeeEmail &&
                                        !item.interviewDate
                                        : item.employeeName === this.state.editStock.employeeName &&
                                        item.employeeLastName === this.state.editStock.employeeLastName &&
                                        item.employeeEmail === this.state.editStock.employeeEmail &&
                                        !item.interviewDate &&
                                        (
                                          moment(item.enquiryDatePostponed || item.enquiryDate)
                                            .add(0, "month")
                                            .isAfter(moment()) || moment(item.enquiryDatePostponed || item.enquiryDate).isSame(moment(), "day")
                                        )
                                  )
                                  // .filter(record => record.authorizedUsers === this.props.user.username)
                                  : null
                              }
                              onChange={this.handleSort}
                              onRow={record => {
                                if (parseInt(record.qty) === 0) {
                                  return { style: { background: "#ffd3d3" } };
                                }
                              }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}

                              <Column
                                {...this.getColumnSearchProps("allegedCharges1")}
                                title="Alleged Charge(s)"
                                dataIndex="allegedCharges1"
                                key="allegedCharges1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedCharges1
                                        ? "None"
                                        : record.allegedCharges1.substring(0, 20)}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />

                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("enquiryDate")}
                                  title="Disciplinary Date"
                                  dataIndex="enquiryDate"
                                  key="enquiryDate"
                                  render={(text, stock) => (
                                    <span>
                                      {moment(stock.enquiryDate).format(
                                        "DD MMMM YYYY"
                                      ) +
                                        " " +
                                        moment(stock.enquiryTime).format("HH:mm")}
                                      {stock.casePostponed === "Yes" ? (
                                        <span>
                                          <Tag
                                            style={{
                                              marginLeft: "0.3em",
                                              fontSize: "10px",
                                              padding: "2px",
                                            }}
                                            color={"orange"}
                                          >
                                            Postponed to
                                          </Tag>
                                          {moment(stock.enquiryDatePostponed).format(
                                            "DD MMMM YYYY"
                                          ) +
                                            " " +
                                            moment(stock.enquiryTimePostponed).format(
                                              "HH:mm"
                                            )}
                                        </span>
                                      ) : null}
                                    </span>
                                  )}
                                />
                              ) : null}
                              {/* {window.innerWidth > 420 ? (
                          <Column
                            {...this.getColumnSearchProps("enquiryDate")}
                            title="Postponed to"
                            dataIndex="enquiryDatePostponed"
                            key="enquiryDatePostponed"
                            render={(text, stock) => (
                              <span>
                                <Tag
                                  color={
                                    stock.sanctionMonthsDisciplinary ===
                                      "N/A" ||
                                    moment(stock.enquiryDate)
                                      .add(
                                        stock.sanctionMonthsDisciplinary,
                                        "month"
                                      )
                                      .isAfter(moment().format())
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {stock.sanctionMonthsDisciplinary ===
                                  undefined
                                    ? "None"
                                    : stock.sanctionMonthsDisciplinary}
                                </Tag>
                                {moment(stock.enquiryDatePostponed).format(
                                  "DD MMMM YYYY"
                                ) +
                                  " " +
                                  moment(stock.enquiryTimePostponed).format(
                                    "HH:mm"
                                  )}
                              </span>
                            )}
                          />
                        ) : null} */}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsDisciplinary === "N/A" ||
                                          moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === undefined
                                        ? "None"
                                        : stock.sanctionMonthsDisciplinary}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.casePostponed === "Yes"
                                          ? stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDatePostponed)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                          : stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDate)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === "N/A"
                                        ? "N/A"
                                        : stock.casePostponed === "Yes"
                                          ? moment(stock.enquiryDatePostponed)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")
                                          : moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warning")}
                                  title="Warning"
                                  dataIndex="warning"
                                  key="warning"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warning === undefined
                                        ? "None"
                                        : stock.warning}
                                    </Tag>
                                  )}
                                />
                              ) : null}

                              <Column
                                title="Actions"
                                // width={200}
                                width={130}
                                align="right"
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditEmployee(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Notification">
                                      <NotificationFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendNotif(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Enquiry">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.showModalEnquiry(stock)}
                                      />
                                    </Tooltip>
                                    {/* <Divider type="vertical" />
                              <Tooltip title="Delete">
                                <DeleteFilled
                                  style={{
                                    backgroundColor: "crimson",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => {
                                    this.setState({
                                      deleteVisible: true,
                                      deleteStock: stock,
                                    });
                                  }}
                                />
                              </Tooltip> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                        <TabPane
                          tab="Disciplinary Interview"
                          key="Disciplinary Interview2"
                        >
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Interview Date:{" "}
                                        {moment(record.interviewDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Months:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === undefined
                                            ? "None"
                                            : record.sanctionMonthsFinal}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === "N/A"
                                            ? "N/A"
                                            : moment(record.interviewDate)
                                              .add(
                                                record.sanctionMonthsFinal,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.editStock
                                  ? stock.filter((item) =>
                                    item.employeeName === this.state.editStock.employeeName &&
                                    item.employeeLastName === this.state.editStock.employeeLastName &&
                                    item.employeeEmail === this.state.editStock.employeeEmail &&
                                    item.interviewDate &&
                                    (
                                      moment(item.interviewDate)
                                        .add(item.sanctionMonthsDisciplinary, "month")
                                        .isAfter(moment()) ||
                                      (
                                        moment(item.interviewDate)
                                          .add(item.sanctionMonthsFinal, "month")
                                          .isAfter(moment())
                                      ) ||
                                      moment(item.interviewDate).isSame(moment(), "day")
                                    )
                                  )
                                  // .filter(record => record.authorizedUsersInterview === this.props.user.username)
                                  : null
                              }
                              onChange={this.handleSort}
                            // onRow={record => {
                            //   if (parseInt(record.qty) === 0) {
                            //     return {
                            //       style: {
                            //         display: this.state.editStock
                            //           ? rigs.filter(
                            //               rig =>
                            //                 rig.employeeName ===
                            //                 this.state.editStock.employeeName
                            //             )
                            //           : "#ffd3d3",
                            //       },
                            //     };
                            //   }
                            // }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}

                              <Column
                                {...this.getColumnSearchProps(
                                  "allegedChargesInterview1"
                                )}
                                title="Alleged Charge(s)"
                                dataIndex="allegedChargesInterview1"
                                key="allegedChargesInterview1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedChargesInterview1
                                        ? "None"
                                        : record.allegedChargesInterview1.substring(
                                          0,
                                          20
                                        )}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />

                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("interviewDate")}
                                  title="Interview Date"
                                  dataIndex="interviewDate"
                                  key="interviewDate"
                                  render={(text, stock) =>
                                    moment(stock.interviewDate).format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " " +
                                    moment(stock.interviewTime).format("HH:mm")
                                  }
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === undefined
                                        ? "None"
                                        : stock.sanctionMonthsFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === "N/A"
                                        ? "N/A"
                                        : stock.sanctionMonthsFinal === "Final"
                                          ? "Final"
                                          : moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warningFinal")}
                                  title="Warning"
                                  dataIndex="warningFinal"
                                  key="warningFinal"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warningFinal === undefined
                                        ? "None"
                                        : stock.warningFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              <Column
                                title="Actions"
                                // width={200}
                                width={90}
                                align="right"
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e =>
                                          this.onEditEmployeeInterview(stock)
                                        }
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Interview">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendInterview(stock)}
                                      />
                                    </Tooltip>
                                    {/* <Divider type="vertical" />
                              <Tooltip title="Delete">
                                <DeleteFilled
                                  style={{
                                    backgroundColor: "crimson",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => {
                                    this.setState({
                                      deleteVisible: true,
                                      deleteStock: stock,
                                    });
                                  }}
                                />
                              </Tooltip> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                        <TabPane tab="Completed Enquiry" key="Completed Enquiry">
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Disciplinary Date:{" "}
                                        {moment(record.enquiryDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Enquiry Date:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsDisciplinary ===
                                              "N/A" ||
                                              moment(record.enquiryDate)
                                                .add(
                                                  record.sanctionMonthsDisciplinary,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsDisciplinary ===
                                            undefined
                                            ? "None"
                                            : record.sanctionMonthsDisciplinary}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsDisciplinary ===
                                              "N/A" ||
                                              moment(record.enquiryDate)
                                                .add(
                                                  record.sanctionMonthsDisciplinary,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsDisciplinary ===
                                            "N/A"
                                            ? "N/A"
                                            : moment(record.enquiryDate)
                                              .add(
                                                record.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.editStock
                                  ? stock.filter((item) =>
                                    item.employeeName === this.state.editStock.employeeName &&
                                    item.employeeLastName === this.state.editStock.employeeLastName &&
                                    item.employeeEmail === this.state.editStock.employeeEmail &&
                                    !item.interviewDate &&
                                    (
                                      moment(item.enquiryDatePostponed || item.enquiryDate)
                                        .add(item.sanctionMonthsDisciplinary, "month")
                                        .isBefore(moment()) &&
                                      !moment(item.enquiryDatePostponed || item.enquiryDate).isSame(moment(), "day")
                                    )
                                  )
                                  // .filter(record => record.authorizedUsers === this.props.user.username)
                                  : null
                              }
                              onChange={this.handleSort}
                              onRow={record => {
                                if (parseInt(record.qty) === 0) {
                                  return { style: { background: "#ffd3d3" } };
                                }
                              }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}

                              <Column
                                {...this.getColumnSearchProps("allegedCharges1")}
                                title="Alleged Charge(s)"
                                dataIndex="allegedCharges1"
                                key="allegedCharges1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedCharges1
                                        ? "None"
                                        : record.allegedCharges1.substring(0, 20)}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />

                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("enquiryDate")}
                                  title="Disciplinary Date"
                                  dataIndex="enquiryDate"
                                  key="enquiryDate"
                                  render={(text, stock) => (
                                    <span>
                                      {moment(stock.enquiryDate).format(
                                        "DD MMMM YYYY"
                                      ) +
                                        " " +
                                        moment(stock.enquiryTime).format("HH:mm")}
                                      {stock.casePostponed === "Yes" ? (
                                        <span>
                                          <Tag
                                            style={{
                                              marginLeft: "0.3em",
                                              fontSize: "10px",
                                              padding: "2px",
                                            }}
                                            color={"orange"}
                                          >
                                            Postponed to
                                          </Tag>
                                          {moment(stock.enquiryDatePostponed).format(
                                            "DD MMMM YYYY"
                                          ) +
                                            " " +
                                            moment(stock.enquiryTimePostponed).format(
                                              "HH:mm"
                                            )}
                                        </span>
                                      ) : null}
                                    </span>
                                  )}
                                />
                              ) : null}
                              {/* {window.innerWidth > 420 ? (
                          <Column
                            {...this.getColumnSearchProps("enquiryDate")}
                            title="Postponed to"
                            dataIndex="enquiryDatePostponed"
                            key="enquiryDatePostponed"
                            render={(text, stock) => (
                              <span>
                                <Tag
                                  color={
                                    stock.sanctionMonthsDisciplinary ===
                                      "N/A" ||
                                    moment(stock.enquiryDate)
                                      .add(
                                        stock.sanctionMonthsDisciplinary,
                                        "month"
                                      )
                                      .isAfter(moment().format())
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {stock.sanctionMonthsDisciplinary ===
                                  undefined
                                    ? "None"
                                    : stock.sanctionMonthsDisciplinary}
                                </Tag>
                                {moment(stock.enquiryDatePostponed).format(
                                  "DD MMMM YYYY"
                                ) +
                                  " " +
                                  moment(stock.enquiryTimePostponed).format(
                                    "HH:mm"
                                  )}
                              </span>
                            )}
                          />
                        ) : null} */}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsDisciplinary === "N/A" ||
                                          moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === undefined
                                        ? "None"
                                        : stock.sanctionMonthsDisciplinary}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.casePostponed === "Yes"
                                          ? stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDatePostponed)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                          : stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDate)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === "N/A"
                                        ? "N/A"
                                        : stock.casePostponed === "Yes"
                                          ? moment(stock.enquiryDatePostponed)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")
                                          : moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warning")}
                                  title="Warning"
                                  dataIndex="warning"
                                  key="warning"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warning === undefined
                                        ? "None"
                                        : stock.warning}
                                    </Tag>
                                  )}
                                />
                              ) : null}

                              <Column
                                title="Actions"
                                // width={200}
                                width={130}
                                align="right"
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditEmployee(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Notification">
                                      <NotificationFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendNotif(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Enquiry">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.showModalEnquiry(stock)}
                                      />
                                    </Tooltip>
                                    {/* <Divider type="vertical" />
                              <Tooltip title="Delete">
                                <DeleteFilled
                                  style={{
                                    backgroundColor: "crimson",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => {
                                    this.setState({
                                      deleteVisible: true,
                                      deleteStock: stock,
                                    });
                                  }}
                                />
                              </Tooltip> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                        <TabPane
                          tab="Completed Interview"
                          key="Completed Interview"
                        >
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Interview Date:{" "}
                                        {moment(record.interviewDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Months:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === undefined
                                            ? "None"
                                            : record.sanctionMonthsFinal}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === "N/A"
                                            ? "N/A"
                                            : moment(record.interviewDate)
                                              .add(
                                                record.sanctionMonthsFinal,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }

                              dataSource={
                                this.state.editStock
                                  ? stock.filter((item) =>
                                    item.employeeName === this.state.editStock.employeeName &&
                                    item.employeeLastName === this.state.editStock.employeeLastName &&
                                    item.employeeEmail === this.state.editStock.employeeEmail &&
                                    item.interviewDate &&
                                    (
                                      // item.sanctionMonthsDisciplinary === "N/A" || item.sanctionMonthsDisciplinary === "Final" ||
                                      moment(item.interviewDate)
                                        .add(item.sanctionMonthsFinal, "month")
                                        .isBefore(moment()) &&
                                      !moment(item.interviewDate).isSame(moment(), "day")
                                    )
                                  )
                                  // .filter(record => record.authorizedUsersInterview === this.props.user.username)
                                  : null
                              }
                              onChange={this.handleSort}
                            // onRow={record => {
                            //   if (parseInt(record.qty) === 0) {
                            //     return {
                            //       style: {
                            //         display: this.state.editStock
                            //           ? rigs.filter(
                            //               rig =>
                            //                 rig.employeeName ===
                            //                 this.state.editStock.employeeName
                            //             )
                            //           : "#ffd3d3",
                            //       },
                            //     };
                            //   }
                            // }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}

                              <Column
                                {...this.getColumnSearchProps(
                                  "allegedChargesInterview1"
                                )}
                                title="Alleged Charge(s)"
                                dataIndex="allegedChargesInterview1"
                                key="allegedChargesInterview1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedChargesInterview1
                                        ? "None"
                                        : record.allegedChargesInterview1.substring(
                                          0,
                                          20
                                        )}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />

                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("interviewDate")}
                                  title="Interview Date"
                                  dataIndex="interviewDate"
                                  key="interviewDate"
                                  render={(text, stock) =>
                                    moment(stock.interviewDate).format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " " +
                                    moment(stock.interviewTime).format("HH:mm")
                                  }
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === undefined
                                        ? "None"
                                        : stock.sanctionMonthsFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === "N/A"
                                        ? "N/A"
                                        : stock.sanctionMonthsFinal === "Final"
                                          ? "Final"
                                          : moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warningFinal")}
                                  title="Warning"
                                  dataIndex="warningFinal"
                                  key="warningFinal"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warningFinal === undefined
                                        ? "None"
                                        : stock.warningFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              <Column
                                title="Actions"
                                // width={200}
                                width={90}
                                align="right"
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e =>
                                          this.onEditEmployeeInterview(stock)
                                        }
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Interview">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendInterview(stock)}
                                      />
                                    </Tooltip>
                                    {/* <Divider type="vertical" />
                              <Tooltip title="Delete">
                                <DeleteFilled
                                  style={{
                                    backgroundColor: "crimson",
                                    color: "white",
                                    padding: "0.5em",
                                    borderRadius: "3em",
                                  }}
                                  onClick={e => {
                                    this.setState({
                                      deleteVisible: true,
                                      deleteStock: stock,
                                    });
                                  }}
                                />
                              </Tooltip> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                      </Tabs>
                    </Row>
                    <Row
                      style={{
                        background: "white",
                        borderRadius: 5,
                        marginTop: 20,
                        display:
                          this.state.viewSiteVisible &&
                            !this.state.addStockVisible &&
                            !this.state.viewSelectedEmployee &&
                            !this.state.addNotificationVisible
                            ? "inline"
                            : "none",
                      }}
                    // type="flex"
                    // justify="start"
                    // align="middle"
                    >
                      <Col xs={24} md={24} >
                        <span style={{ display: "flex", marginBottom: "1em" }}>
                          <Select
                            size="large"
                            showSearch
                            allowClear
                            mode="multiple"
                            value={this.state.selectedEmployees} // Each value should be "FirstName,LastName"
                            onChange={(values) => {
                              this.setState({ selectedEmployees: values }, () => {
                                this.handleSearchName();
                              });
                            }}
                            style={{ width: '100%' }}
                            placeholder="Select employee(s)"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {this.props.employees
                              .filter((employee, index, self) =>
                                index === self.findIndex((t) =>
                                  t.employeeName === employee.employeeName &&
                                  t.employeeLastName === employee.employeeLastName
                                )
                              )
                              .filter(employee => employee.access.includes(this.state.siteSelected))
                              .map(employee => (
                                <Option key={employee._id} value={`${employee.employeeName},${employee.employeeLastName}`}>
                                  {employee.employeeName} {employee.employeeLastName}
                                </Option>
                              ))}
                          </Select>

                          <Button size="large" type="primary" onClick={this.handleSearchName}>
                            Search
                          </Button>
                        </span>
                      </Col>
                      <Tabs type="card"
                        animated={true}>
                        <TabPane disabled={selectedStockIds.length > 0 ? true : false} tab="Employees" key="Info">
                          <Row
                            type="flex"
                            justify="start"
                            align="middle"
                            style={{
                              textAlign: "center",
                              maxWidth: "90em",
                              margin: "auto",
                              marginBottom: "1em",
                              display:
                                this.state.viewSiteVisible &&
                                  !this.state.addStockVisible &&
                                  !this.state.viewSelectedEmployee
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <Col xs={24} md={14} style={{ textAlign: "left" }}>
                              <span style={{ fontWeight: 750, fontSize: 18 }}>
                                <Button
                                  type="primary"
                                  shape="round"
                                  onClick={this.handleBackToSites}
                                  size="large"
                                  style={{
                                    fontSize: "14px",
                                    width: "3em",
                                    height: "3em",
                                    //height: "3em",
                                    marginRight: "0.4em",
                                    padding: 0,
                                  }}
                                >
                                  <ArrowLeftOutlined />
                                </Button>{" "}
                                {this.state.siteSelected}
                              </span>{" "}
                            </Col>
                            {/* <Col xs={24} md={10} style={{ textAlign: "right" }}>
                              <Button
                                type="primary"
                                shape="round"
                                onClick={this.onAddStock}
                                // onClick={this.onAddNotification}
                                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                                size="large"
                                style={{
                                  marginLeft: "0.5em",
                                  height: "3em",
                                  width: "12em",
                                }}
                              >
                                New Notification
                              </Button>
                              <Button
                                type="primary"
                                shape="round"
                                onClick={this.onAddStockInterview}
                                // onClick={this.onAddNotification}
                                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                                size="large"
                                style={{
                                  marginLeft: "0.5em",
                                  height: "3em",
                                  width: "11em",
                                }}
                              >
                                New Interview
                              </Button>
                            </Col> */}
                          </Row>
                          <Col span={24}>
                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card
                                      size="small"
                                      title={record.itemCode}
                                    // style={{ width: 300, marginLeft: -35 }}
                                    >
                                      {/* <p>Number: {record.employeeNumber}</p> */}
                                      <p>Email: {record.employeeEmail}</p>
                                      <p>Contact Number: {record.employeeNumber}</p>
                                      <p>Job Title: {record.jobTitle}</p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={this.state.selectedEmployees != undefined && this.state.selectedEmployees.length > 0 ? uniqueItems : null}

                              onChange={this.handleSort}
                              onRow={record => {
                                if (record.employeeName === record.employeeName) {
                                  return { style: { color: "red" } };
                                }
                              }}
                            >
                              <Column
                                {...this.getColumnSearchProps("employeeName")}
                                title="Name"
                                key="employeeName"
                                render={(text, record) => (
                                  <span>
                                    {record.employeeName} {record.employeeLastName}
                                  </span>
                                )}
                              />
                              {/* {window.innerWidth > 420 ? ( */}
                              {/* <Column
                                {...this.getColumnSearchProps("employeeLastName")}
                                title="Surname"
                                dataIndex="employeeLastName"
                                key="employeeLastName"
                              /> */}
                              <Column
                                title="Suspended"
                                dataIndex="warning"
                                key="warning"
                                render={(text, employee) => {
                                  // Check if any record for this employee has warningFinal as "Suspended"
                                  const isSuspended = this.props.employees.some(
                                    (emp) =>
                                      emp.employeeName === employee.employeeName &&
                                      emp.employeeLastName === employee.employeeLastName &&
                                      emp.warning === "Suspended" ||
                                      emp.employeeName === employee.employeeName &&
                                      emp.employeeLastName === employee.employeeLastName &&
                                      emp.warningFinal === "Suspended"
                                  );

                                  return (
                                    <Tag color={isSuspended ? "red" : "green"}>
                                      {isSuspended ? <CheckOutlined /> : <CloseOutlined />}
                                    </Tag>
                                  );
                                }}
                              />
                              {/* ) : null} */}
                              {window.innerWidth > 420 ? (
                                <Column
                                  title="Email"
                                  dataIndex="employeeEmail"
                                  key="employeeEmail"
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  title="Contact Number"
                                  dataIndex="employeeNumber"
                                  key="employeeNumber"
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  title="Job Title"
                                  dataIndex="jobTitle"
                                  key="jobTitle"
                                />
                              ) : null}
                              <Column
                                title="Actions"
                                // width={100}
                                render={(text, stock) => (
                                  <span>
                                    {/* <Button
                              style={{
                                backgroundColor: "#009BDF",
                                color: "white",
                                borderRadius: "3em",
                              }}
                              onClick={e => this.onEditStock(stock)}
                            >
                              Edit
                            </Button> */}
                                    <EditFilled
                                      style={{
                                        backgroundColor: "rgb(0, 155, 223)",
                                        color: "white",
                                        padding: "0.5em",
                                        borderRadius: "3em",
                                      }}
                                      onClick={e => this.onEditStock(stock)}
                                    />
                                    {/* <Divider type="vertical" />
                            <DeleteFilled
                              style={{
                                backgroundColor: "#CFD5E3",
                                color: "white",
                                padding: "0.5em",
                                borderRadius: "3em",
                              }}
                              onClick={e => {
                                this.setState({
                                  deleteVisible: true,
                                  deleteStock: stock,
                                });
                              }}
                            /> */}
                                  </span>
                                )}
                              />
                            </StyledTable>
                          </Col>
                        </TabPane>
                        <TabPane disabled={selectedStockIds.length > 0 ? true : false} tab="Disciplinary Multi" key="Multi">
                          <Row
                            type="flex"
                            justify="start"
                            align="middle"
                            style={{
                              textAlign: "center",
                              maxWidth: "90em",
                              margin: "auto",
                              marginBottom: "1em",
                              display:
                                this.state.viewSiteVisible &&
                                  !this.state.addStockVisible &&
                                  !this.state.viewSelectedEmployee
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <Col xs={12} md={14} style={{ textAlign: "left" }}>
                              <span style={{ fontWeight: 750, fontSize: 18 }}>
                                <Button
                                  type="primary"
                                  shape="round"
                                  onClick={this.handleBackToSites}
                                  size="large"
                                  style={{
                                    fontSize: "14px",
                                    width: "3em",
                                    height: "3em",
                                    //height: "3em",
                                    marginRight: "0.4em",
                                    padding: 0,
                                  }}
                                >
                                  <ArrowLeftOutlined />
                                </Button>{" "}
                                Enquiries
                              </span>{" "}
                            </Col>
                            <Col xs={12} md={10} style={{ textAlign: "right" }}>

                              <Button
                                type="primary"
                                shape="round"
                                onClick={this.handleEditSelectedStocks}
                                disabled={selectedStockIds.length < 2 ? true : false}
                                // onClick={this.onAddNotification}
                                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                                size="large"
                                style={{
                                  marginLeft: "0.5em",
                                  height: "3em",
                                  width: "11em",
                                }}
                              >
                                Edit Selected
                              </Button>
                            </Col>
                          </Row>
                          <Col span={24}>

                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              rowSelection={{
                                selectedRowKeys: selectedStockIds,
                                onChange: this.handleRowSelectionChange,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Interview Date:{" "}
                                        {moment(record.interviewDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Months:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === undefined
                                            ? "None"
                                            : record.sanctionMonthsFinal}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === "N/A"
                                            ? "N/A"
                                            : moment(record.interviewDate)
                                              .add(
                                                record.sanctionMonthsFinal,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.selectedEmployees != undefined && this.state.selectedEmployees.length > 0 ?
                                  stock.filter(
                                    stock =>
                                      stock.access ===
                                      this.state.siteSelected &&
                                      !stock.interviewDate
                                  )
                                  // .filter(record => record.authorizedUsers === this.props.user.username)
                                  : null

                              }
                              onChange={this.handleSort}
                              onRow={record => {
                                if (parseInt(record.qty) === 0) {
                                  return { style: { background: "#ffd3d3" } };
                                }
                              }}
                            >
                              <Column
                                //{...this.getColumnSearchProps("name")}
                                title="Name"
                                dataIndex="employeeName"
                                key="employeeName"
                              />
                              {/* {window.innerWidth > 420 ? ( */}
                              <Column
                                {...this.getColumnSearchProps("allegedCharges1")}
                                title="Alleged Charge(s)"
                                dataIndex="allegedCharges1"
                                key="allegedCharges1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedCharges1
                                        ? "None"
                                        : record.allegedCharges1.substring(
                                          0,
                                          30
                                        )}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />
                              {/* ) : null} */}

                              {/* {window.innerWidth > 420 ? ( */}
                              {/* <Column
                                {...this.getColumnSearchProps("employeeLastName")}
                                title="Last Name"
                                dataIndex="employeeLastName"
                                key="employeeLastName"
                              /> */}
                              {/* ) : null} */}
                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("enquiryDate")}
                                  title="Disciplinary Date"
                                  dataIndex="enquiryDate"
                                  key="enquiryDate"
                                  render={(text, stock) => (
                                    <span>
                                      {moment(stock.enquiryDate).format(
                                        "DD MMMM YYYY"
                                      ) +
                                        " " +
                                        moment(stock.enquiryTime).format("HH:mm")}
                                      {stock.casePostponed === "Yes" ? (
                                        <span>
                                          <Tag
                                            style={{ marginLeft: "0.5em" }}
                                            color={"orange"}
                                          >
                                            Postponed to
                                          </Tag>
                                          {moment(stock.enquiryDatePostponed).format(
                                            "DD MMMM YYYY"
                                          ) +
                                            " " +
                                            moment(stock.enquiryTimePostponed).format(
                                              "HH:mm"
                                            )}
                                        </span>
                                      ) : null}
                                    </span>
                                  )}
                                />
                              ) : null}
                              {/* {window.innerWidth > 420 ? (
                          <Column
                            {...this.getColumnSearchProps("enquiryDate")}
                            title="Postponed to"
                            dataIndex="enquiryDatePostponed"
                            key="enquiryDatePostponed"
                            render={(text, stock) => (
                              <span>
                                <Tag
                                  color={
                                    stock.sanctionMonthsDisciplinary ===
                                      "N/A" ||
                                    moment(stock.enquiryDate)
                                      .add(
                                        stock.sanctionMonthsDisciplinary,
                                        "month"
                                      )
                                      .isAfter(moment().format())
                                      ? "red"
                                      : "green"
                                  }
                                >
                                  {stock.sanctionMonthsDisciplinary ===
                                  undefined
                                    ? "None"
                                    : stock.sanctionMonthsDisciplinary}
                                </Tag>
                                {moment(stock.enquiryDatePostponed).format(
                                  "DD MMMM YYYY"
                                ) +
                                  " " +
                                  moment(stock.enquiryTimePostponed).format(
                                    "HH:mm"
                                  )}
                              </span>
                            )}
                          />
                        ) : null} */}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsDisciplinary === "N/A" ||
                                          moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === undefined
                                        ? "None"
                                        : stock.sanctionMonthsDisciplinary}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsDisciplinary"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsDisciplinary"
                                  key="sanctionMonthsDisciplinary"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.casePostponed === "Yes"
                                          ? stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDatePostponed)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                          : stock.sanctionMonthsDisciplinary ===
                                            "N/A" ||
                                            moment(stock.enquiryDate)
                                              .add(
                                                stock.sanctionMonthsDisciplinary,
                                                "month"
                                              )
                                              .isAfter(moment().format())
                                            ? "red"
                                            : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsDisciplinary === "N/A"
                                        ? "N/A"
                                        : stock.casePostponed === "Yes"
                                          ? moment(stock.enquiryDatePostponed)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")
                                          : moment(stock.enquiryDate)
                                            .add(
                                              stock.sanctionMonthsDisciplinary,
                                              "month"
                                            )
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warning")}
                                  title="Warning"
                                  dataIndex="warning"
                                  key="warning"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warning === undefined
                                        ? "None"
                                        : stock.warning}
                                    </Tag>
                                  )}
                                />
                              ) : null}

                              {/* <Column
                                title="Actions"
                                width={200}
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditEmployee(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Notification">
                                      <NotificationFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendNotif(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Enquiry">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.showModalEnquiry(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Delete">
                                      <DeleteFilled
                                        style={{
                                          backgroundColor: "crimson",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => {
                                          this.setState({
                                            deleteVisible: true,
                                            deleteStock: stock,
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                )}
                              /> */}
                            </StyledTable>
                          </Col>
                        </TabPane>
                        <TabPane disabled={selectedStockIds.length > 0 ? true : false} tab="Interview Multi" key="Multi 1">
                          <Row
                            type="flex"
                            justify="start"
                            align="middle"
                            style={{
                              textAlign: "center",
                              maxWidth: "90em",
                              margin: "auto",
                              marginBottom: "1em",
                              display:
                                this.state.viewSiteVisible &&
                                  !this.state.addStockVisible &&
                                  !this.state.viewSelectedEmployee
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            <Col xs={12} md={14} style={{ textAlign: "left" }}>
                              <span style={{ fontWeight: 750, fontSize: 18 }}>
                                <Button
                                  type="primary"
                                  shape="round"
                                  onClick={this.handleBackToSites}
                                  size="large"
                                  style={{
                                    fontSize: "14px",
                                    width: "3em",
                                    height: "3em",
                                    //height: "3em",
                                    marginRight: "0.4em",
                                    padding: 0,
                                  }}
                                >
                                  <ArrowLeftOutlined />
                                </Button>{" "}
                                Interviews
                              </span>{" "}
                            </Col>
                            <Col xs={12} md={10} style={{ textAlign: "right" }}>
                              <Button
                                type="primary"
                                shape="round"
                                onClick={this.handleEditSelectedStocksInterview}
                                disabled={selectedStockIds.length < 2 ? true : false}
                                // onClick={this.onAddNotification}
                                icon={<PlusCircleOutlined style={{ fontSize: "110%" }} />}
                                size="large"
                                style={{
                                  marginLeft: "0.5em",
                                  height: "3em",
                                  width: "11em",
                                }}
                              >
                                Edit Selected
                              </Button>
                            </Col>
                          </Row>
                          <Col span={24}>

                            <StyledTable
                              style={{ background: "white" }}
                              rowKey="_id"
                              size="middle"
                              pagination={{
                                defaultPageSize: 25,
                              }}
                              rowSelection={{
                                selectedRowKeys: selectedStockIds,
                                onChange: this.handleRowSelectionChange,
                              }}
                              expandedRowRender={
                                window.innerWidth <= 420
                                  ? record => (
                                    <Card size="small" title={record.itemCode}>
                                      <p>
                                        Interview Date:{" "}
                                        {moment(record.interviewDate).format(
                                          "DD MMMM YYYY"
                                        ) +
                                          " " +
                                          moment(
                                            record.disciplinaryProceedingsTime
                                          ).format("HH:mm")}
                                      </p>
                                      <p>
                                        Months:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === undefined
                                            ? "None"
                                            : record.sanctionMonthsFinal}
                                        </Tag>
                                      </p>
                                      <p>
                                        Transgression Expiry:{" "}
                                        <Tag
                                          color={
                                            record.sanctionMonthsFinal === "N/A" ||
                                              moment(record.interviewDate)
                                                .add(
                                                  record.sanctionMonthsFinal,
                                                  "month"
                                                )
                                                .isAfter(moment().format())
                                              ? "red"
                                              : "green"
                                          }
                                        >
                                          {record.sanctionMonthsFinal === "N/A"
                                            ? "N/A"
                                            : moment(record.interviewDate)
                                              .add(
                                                record.sanctionMonthsFinal,
                                                "month"
                                              )
                                              .format("DD MMMM YYYY")}
                                        </Tag>
                                      </p>
                                    </Card>
                                  )
                                  : null
                              }
                              dataSource={
                                this.state.selectedEmployees != undefined && this.state.selectedEmployees.length > 0 ?
                                  stock.filter(
                                    stock =>
                                      stock.access ===
                                      this.state.siteSelected &&
                                      stock.interviewDate
                                  )
                                  // .filter(record => record.authorizedUsersInterview === this.props.user.username)
                                  : null
                              }
                              onChange={this.handleSort}
                              onRow={record => {
                                if (parseInt(record.qty) === 0) {
                                  return { style: { background: "#ffd3d3" } };
                                }
                              }}
                            >
                              {/* <Column
                      //{...this.getColumnSearchProps("name")}
                      title="Name"
                      dataIndex="employeeName"
                      key="employeeName"
                    /> */}
                              <Column
                                {...this.getColumnSearchProps("employeeName")}
                                title="Employee"
                                dataIndex="employeeName"
                                key="employeeName"
                              />
                              {/* {window.innerWidth > 420 ? ( */}
                              <Column
                                {...this.getColumnSearchProps(
                                  "allegedChargesInterview1"
                                )}
                                title="Alleged Charge(s)"
                                dataIndex="allegedChargesInterview1"
                                key="allegedChargesInterview1"
                                render={(text, record, index) => {
                                  return (
                                    <span>
                                      {!record.allegedChargesInterview1
                                        ? "None"
                                        : record.allegedChargesInterview1.substring(
                                          0,
                                          30
                                        )}{" "}
                                      {/* Display first 10 characters */}
                                    </span>
                                  );
                                }}
                              />
                              {/* ) : null} */}

                              {/* {window.innerWidth > 420 ? ( */}
                              {/* <Column
                                {...this.getColumnSearchProps("employeeLastName")}
                                title="Last Name"
                                dataIndex="employeeLastName"
                                key="employeeLastName"
                              /> */}
                              {/* <Column
                      {...this.getColumnSearchProps("enquiryDate")}
                      title="Enquiry"
                      dataIndex="enquiryDate"
                      key="enquiryDate"
                      render={(text, stock) =>
                        moment(stock.enquiryDate).format("DD MMMM YYYY") +
                        " " +
                        moment(stock.enquiryTime).format("HH:mm")
                      }
                    /> */}

                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("interviewDate")}
                                  title="Interview Date"
                                  dataIndex="interviewDate"
                                  key="interviewDate"
                                  render={(text, stock) =>
                                    moment(stock.interviewDate).format(
                                      "DD MMMM YYYY"
                                    ) +
                                    " " +
                                    moment(stock.interviewTime).format("HH:mm")
                                  }
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Months"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === undefined
                                        ? "None"
                                        : stock.sanctionMonthsFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps(
                                    "sanctionMonthsFinal"
                                  )}
                                  title="Transgression Expiry"
                                  dataIndex="sanctionMonthsFinal"
                                  key="sanctionMonthsFinal"
                                  render={(text, stock) => (
                                    <Tag
                                      color={
                                        stock.sanctionMonthsFinal === "N/A" ||
                                          stock.sanctionMonthsFinal === "Final" ||
                                          moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .isAfter(moment().format())
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {stock.sanctionMonthsFinal === "N/A"
                                        ? "N/A"
                                        : stock.sanctionMonthsFinal === "Final"
                                          ? "Final"
                                          : moment(stock.interviewDate)
                                            .add(stock.sanctionMonthsFinal, "month")
                                            .format("DD MMMM YYYY")}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {window.innerWidth > 420 ? (
                                <Column
                                  {...this.getColumnSearchProps("warningFinal")}
                                  title="Warning"
                                  dataIndex="warningFinal"
                                  key="warningFinal"
                                  render={(text, stock) => (
                                    <Tag color={"red"}>
                                      {stock.warningFinal === undefined
                                        ? "None"
                                        : stock.warningFinal}
                                    </Tag>
                                  )}
                                />
                              ) : null}
                              {/* <Column
                                title="Actions"
                                width={200}
                                render={(text, stock) => (
                                  <span>
                                    <Tooltip title="Edit">
                                      <EditFilled
                                        style={{
                                          backgroundColor: "rgb(0, 155, 223)",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e =>
                                          this.onEditEmployeeInterview(stock)
                                        }
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Send Disciplinary Interview">
                                      <MailFilled
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => this.onEditSendInterview(stock)}
                                      />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Delete">
                                      <DeleteFilled
                                        style={{
                                          backgroundColor: "crimson",
                                          color: "white",
                                          padding: "0.5em",
                                          borderRadius: "3em",
                                        }}
                                        onClick={e => {
                                          this.setState({
                                            deleteVisible: true,
                                            deleteStock: stock,
                                          });
                                        }}
                                      />
                                    </Tooltip>
                                  </span>
                                )}
                              /> */}
                            </StyledTable>
                          </Col>
                        </TabPane>
                      </Tabs>
                    </Row>
                  </span>
                )}
            </Spin>
          </div>
          <Modal
            title="Send Notification"
            visible={this.state.visibleSendNotif}
            onOk={onFinishNotif}
            okText="Send"
            onCancel={this.handleCancel}
          >
            <Select
              style={{ width: "100%" }}
              type="emailNotification"
              // value={this.state.sendEmailNotification}
              onChange={this.handleEmailChangeNotification}
              placeholder="Enter Email"
            >
              {this.props.sites.flatMap((site, index) =>
                this.state.editStock ? (
                  this.state.editStock.access === site.name ? (
                    Array.isArray(site.email) ? (
                      site.email.map(email => (
                        <Option key={`${email}-${index}`} value={email.trim()}>
                          {email.trim()}
                        </Option>
                      ))
                    ) : (
                      <Option
                        key={`${site.email}-${index}`}
                        value={site.email.trim()}
                      >
                        {site.email.trim()}
                      </Option>
                    )
                  ) : null
                ) : null
              )}
            </Select>

            {/* <Input
                        type="emailNotification"
                        value={this.state.sendEmailNotification}
                        onChange={this.handleEmailChangeNotification}
                        placeholder="Enter Email"
                      /> */}
          </Modal>
          <Modal
            title="Send Disciplinary Interview"
            visible={this.state.visibleSendInterview}
            onOk={onFinishInterview}
            okText="Send"
            onCancel={this.handleCancel}
          >
            <Select
              style={{ width: "100%" }}
              type="emailInterview"
              // value={this.state.sendEmailInterview}
              onChange={this.handleEmailChangeInterview}
              placeholder="Enter Email"
            >
              {this.props.sites.flatMap((site, index) =>
                this.state.editStock ? (
                  this.state.editStock.access === site.name ? (
                    Array.isArray(site.email) ? (
                      site.email.map(email => (
                        <Option key={`${email}-${index}`} value={email.trim()}>
                          {email.trim()}
                        </Option>
                      ))
                    ) : (
                      <Option
                        key={`${site.email}-${index}`}
                        value={site.email.trim()}
                      >
                        {site.email.trim()}
                      </Option>
                    )
                  ) : null
                ) : null
              )}
            </Select>

            {/* <Input
                        type="emailInterview"
                        value={this.state.sendEmailInterview}
                        onChange={this.handleEmailChangeInterview}
                        placeholder="Enter Email"
                      /> */}
          </Modal>
          <Modal
            title="Send Disciplinary Enquiry"
            visible={this.state.visibleSendEnquiry}
            onOk={onFinishEnquiry}
            okText="Send"
            onCancel={this.handleCancel}
          >
            <Select
              style={{ width: "100%" }}
              type="emailEnquiry"
              // value={this.state.sendEmailEnquiry}
              onChange={this.handleEmailChangeEnquiry}
              placeholder="Enter Email"
            >
              {this.props.sites.flatMap((site, index) =>
                this.state.editStock ? (
                  this.state.editStock.access === site.name ? (
                    Array.isArray(site.email) ? (
                      site.email.map(email => (
                        <Option key={`${email}-${index}`} value={email.trim()}>
                          {email.trim()}
                        </Option>
                      ))
                    ) : (
                      <Option
                        key={`${site.email}-${index}`}
                        value={site.email.trim()}
                      >
                        {site.email.trim()}
                      </Option>
                    )
                  ) : null
                ) : null
              )}
            </Select>
            {/* <Input
                        type="emailEnquiry"
                        value={this.state.sendEmailEnquiry}
                        onChange={this.handleEmailChangeEnquiry}
                        placeholder="Enter Email"
                      /> */}
          </Modal>
          <Modal
            title="Confirm Save"
            visible={this.state.visibleSave}
            onOk={this.handleOkSave}
            onCancel={this.handleCancelSave}
            footer={[
              <Button key="back" onClick={this.handleCancelSave}>
                Don't Save
              </Button>,
              <Button key="submit" type="primary" onClick={this.handleOkSave}>
                Save Changes
              </Button>,
            ]}
          >
            <p>Do you want to save?</p>

          </Modal>
          <Modal
            title="Confirm Cancel"
            visible={this.state.visibleCancel}
            onOk={this.handleOkCancel}
            onCancel={this.handleCancelCancel}
            footer={[
              <Button key="back" onClick={this.handleCancelCancel}>
                Don't Cancel
              </Button>,
              <Button key="submit" type="primary" style={{ backgroundColor: "red", borderColor: "red" }} onClick={this.handleOkCancel}>
                Cancel Changes
              </Button>,
            ]}
          >
            <p>Do you want to cancel?</p>
            <p>Note: No changes will be saved</p>
          </Modal>
          <Modal
            title="Confirm Save"
            visible={this.state.visibleSaveMulti}
            onOk={this.handleOkSaveMulti}
            onCancel={this.handleCancelSaveMulti}
            footer={[
              <Button key="back" onClick={this.handleCancelSaveMulti}>
                Don't Save
              </Button>,
              <Button key="submit" type="primary" onClick={this.handleOkSaveMulti}>
                Save Changes
              </Button>,
            ]}
          >
            <p>Do you want to save?</p>

          </Modal>
          <Modal
            title={"Delete Notification"}
            visible={this.state.deleteVisible}
            onOk={this.handleDeleteStock}
            onCancel={this.cancelDelete}
            okText="Accept"
            cancelText="Cancel"
          >
            <span>
              {" "}
              Are you sure you want to delete
              {/* {this.props.stock.map(stock => ( */}
              {/* <span>{stock.currentRig}</span> */}
              {/* ))} */}
            </span>
          </Modal>
          {this.state.editStock ? null :
            <Layout.Footer
              style={{
                zIndex: 5,
                width: "100%",
                position: "fixed",
                bottom: 0,
                left: 0,
                background: "#001529",
                lineHeight: "40px",
                padding: 0,
                height: "3.5em",
                boxShadow: "0px 11px 20px 0 rgba(0, 0, 0, 10)",
                display: window.isPhone ? "" : "none",
              }}
            >
              <Row type="flex" justify="space-around" style={{}}>
                <Col span={8} style={{ display: "flex" }}>
                  {/* <Link to={this.props.user.type === 'buyer' ? "/buyer/stock" : "/user/stock"} style={{ margin: "0.5em" }}> */}
                  <div
                    onClick={this.onShiftActive}
                    style={{
                      color: "white",
                      margin: "auto",
                      marginBottom: "1.2em",
                      display: "grid",
                      cursor: "pointer",
                      borderRadius: "0.3em",
                      backgroundColor: this.state.shift ? "#009BDF" : "",
                      display: this.props.user.type === 'buyer' ? "none" : "flex"
                    }}
                  >
                    {/* <FaUserFriends
                      style={{
                        margin: "auto",
                        padding: "0.2em",
                        fontSize: "20px",
                        width: "1.8em",
                        height: "1.8em",
                      }}
                    /> */}

                    {/*<span
                style={{
                  marginTop: -11,
                  fontSize: 12,
                }}
              >
                Search
              </span>*/}
                  </div>{" "}
                  {/* </Link> */}
                </Col>
                <Col span={4} style={{ display: "flex" }}>
                  <Link to={this.props.user.type === 'buyer' ? "/buyer/dashboard" : "/user/dashboard"} style={{ marginTop: "0.5em" }}>
                    <div
                      onClick={this.onDashActive}
                      style={{
                        margin: "auto",
                        background: "#009BDF",
                        borderRadius: "50%",
                        width: 60,
                        height: 60,
                        bottom: 20,
                        position: "relative",
                        display: "flex",
                        border: "4px solid white",
                      }}
                    >
                      <FaDashcube
                        style={{
                          margin: "auto",
                          color: "white",
                          padding: "0.2em",
                          fontSize: "20px",
                          width: "1.6em",
                          height: "1.6em",
                        }}
                      />
                    </div>
                  </Link>
                </Col>

                <Col span={8} style={{ display: "flex" }}>
                  <div
                    onClick={signOut}
                    style={{
                      color: "white",
                      margin: "auto",
                      marginBottom: "1.7em",
                      marginRight: 0,
                      display: "grid",
                    }}
                  >
                    <FaSignOutAlt
                      style={{
                        margin: "auto",

                        padding: "0.2em",
                        fontSize: "20px",
                        width: "1.8em",
                        height: "1.8em",
                      }}
                    />
                    {/*<span
                style={{
                  marginTop: -11,
                  fontSize: 12,
                }}
              >
                Profile
              </span>*/}
                  </div>
                </Col>
              </Row>
            </Layout.Footer>
          }
        </div>
      );
    }
  }
}

export default Stock;
