import { getToken } from "src/redux/selectors";

export function addStock(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/addstock",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to add stock" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function saveStock(values, activateDisplayId) {
  if (activateDisplayId) {
    values.activateDisplayId = true;
  }
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/setstock",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify(values),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to edit stock" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function deleteStock(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/deletestock",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      )
        .then(res => {
          if (!res.ok) {
            return reject({ message: "Failed to delete stock" });
          }
          res.text();
        })
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            return resolve({ message: response });
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAllStock(searchQuery) {
  let url = `${process.env.REACT_APP_GLOBAL_SERVER}asambo/api/stock/findallstock`;

  if (searchQuery && searchQuery.length > 0) {
    // Ensure searchQuery is a string
    const encodedSearchQuery = encodeURIComponent(searchQuery);
    url += `?searchQuery=${encodedSearchQuery}`;
  } else {
    // Return an empty array if searchQuery is empty.
    return dispatch => {
      dispatch({
        type: "STOCK_FIND_ALLSTOCK",
        data: { allStock: [] },
      });
    };
  }

  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          const data = await res.json();
          return resolve(
            dispatch({
              type: "STOCK_FIND_ALLSTOCK",
              data,
            })
          );
        })

        .catch(err => {
          throw err.message;
        });
    });
}


export function findAllStocks(searchQuery) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      let url = `${process.env.REACT_APP_GLOBAL_SERVER}asambo/api/stock/findallstock`;

      // Encode the single query and append to the URL
      if (searchQuery && searchQuery.length > 0) {
        const encodedSearchQuery = encodeURIComponent(searchQuery); // Single search query
        url += `?searchQuery=${encodedSearchQuery}`;
      } else {
        // Return empty array if searchQuery is empty
        return dispatch => {
          dispatch({
            type: "STOCK_FIND_ALLSTOCK",
            data: { allStock: [] },
          });
        };
      }

      // Fetch data from the constructed URL
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),  // Add token for authorization
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          return resolve(
            dispatch({
              type: "STOCK_FIND_ALLSTOCK",
              data: await res.json(),
            })
          );
        })
        .catch(err => {
          throw err.message;
        });
    });
  };
}




export function findAllEmployeeName(searchQuery, site) {
  let url = `${process.env.REACT_APP_GLOBAL_SERVER}asambo/api/stock/findallemployeename`;

  const params = [];
  if (searchQuery) {
    params.push(`searchQuery=${encodeURIComponent(searchQuery)}`);
  }
  if (site) {
    params.push(`site=${encodeURIComponent(site)}`);
  }
  if (params.length > 0) {
    url += `?${params.join('&')}`;
  }

  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json; charset=utf-8",
        },
      })
        .then(async res => {
          if (!res.ok) {
            return reject({ message: await res.text() });
          }
          return resolve(
            dispatch({
              type: "STOCK_FIND_ALLEMPLOYEES",
              data: await res.json(),
            })
          );
        })
        .catch(err => {
          throw err.message;
        });
    });
}

export function findMyStock(make) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/findmystock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "STOCKS_FIND_MYSTOCKS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findJobNumbers(value) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/getjobnumbers?value=" +
        value,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to get job numbers" });
        }
        return resolve(await res.json());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findStockDetails(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/findstockdetails?id=" +
        id,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "STOCKS_FIND_STOCKDETAILS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/findauctionstock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "STOCKS_FIND_AUCTIONSTOCKS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findDealDoneStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/finddealdonestock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "STOCKS_FIND_DEALDONESTOCKS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionWonStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/findauctionwonstock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "STOCKS_FIND_WONSTOCKS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function findAuctionLostStock() {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/findauctionloststock",
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: await res.text() });
        }
        return resolve(
          dispatch({
            type: "STOCKS_FIND_LOSTSTOCKS",
            data: await res.json(),
          })
        );
      });
    }).catch(err => {
      throw err.message;
    });
}

export function setEditStock(stock) {
  return dispatch =>
    dispatch({
      type: "STOCKS_SET_EDITSTOCK",
      data: stock,
    });
}

export function setStockDetailsId(id) {
  return dispatch =>
    dispatch({
      type: "STOCKS_SET_STOCKDETAILSID",
      data: id,
    });
}

export function findVariants(model) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/cddata/findvariants?model=" +
        model,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "CDDATA_FIND_VARIANTS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function findYears(variant) {
  return dispatch =>
    new Promise((resolve, reject) => {
      fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/cddata/findyears?variant=" +
        variant,
        {
          method: "GET",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
        }
      )
        .then(res => res.text())
        .catch(async error => {
          return reject(error);
        })
        .then(async response => {
          try {
            const parsedResponse = JSON.parse(response);
            return resolve(
              dispatch({
                type: "CDDATA_FIND_YEARS",
                data: parsedResponse,
              })
            );
          } catch (e) {
            return reject({ message: response });
          }
        });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/acceptoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to accept offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER + "asambo/api/stock/rejectoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function acceptAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/acceptadminoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to accept offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}

export function rejectAdminOffer(id) {
  return dispatch =>
    new Promise((resolve, reject) => {
      return fetch(
        process.env.REACT_APP_GLOBAL_SERVER +
        "asambo/api/stock/rejectadminoffer",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Authorization: "Bearer " + getToken(),
            "Content-Type": "application/json; charset=utf-8",
          },
          body: JSON.stringify({ id: id }),
        }
      ).then(async res => {
        if (!res.ok) {
          return reject({ message: "Failed to reject offer" });
        }
        return resolve(await res.text());
      });
    }).catch(err => {
      throw err.message;
    });
}
